import React, { useState } from "react";
import { BiMessageSquareAdd } from "react-icons/bi";
import "./GestionPointEdk.css";
import LogoEdk from "../../../assets/appImages/logo-edk.png";
import Location from "../../../assets/icons/location.png";
import { MdModeEditOutline } from "react-icons/md";
import { BsTrashFill } from "react-icons/bs";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { useGetListStoreAdminQuery } from "../../../utils/api/store/store.api";
import { AlertInfo } from "../../common/Alert";
import CrudStoreModal from "./modal/CrudStoreModal";
import { GoogleApikey } from "../../../utils/http";
import Pagination from "../../common/Pagination";
import { UseDeletePoint } from "./requestCrudStore/UseCrudStoreForm";
import Skeleton from "react-loading-skeleton";

const CardSkeleton = () => {
  return (
    <>
      {Array(12)
        .fill(12)
        .map((shopCard, i) => (
          <div className="col-md-4 col-adresse-item mb-3" key={i}>
            <div className="content-col-adresse-item">
              <div className="card-adresse-admin-item">
                <div className="header-card-adresse-admin">
                  <div className="content-left-header-adresse-admin">
                    <div className="content-img-app-adresse-admin">
                      <Skeleton
                        circle={true}
                        className="img-app-adresse-admin"
                        width={35}
                        height={35}
                      />
                    </div>
                    <div className="content-text-app-adresse-admin">
                      <Skeleton
                        className="title-text-left-adreese-admin mb-0"
                        width={150}
                        height={20}
                      />
                      <Skeleton
                        className="text-left-adreese-admin mb-0"
                        width={200}
                        height={15}
                      />
                      <Skeleton
                        className="text-left-adreese-admin mb-0"
                        width={200}
                        height={15}
                      />
                    </div>
                  </div>
                  <div className="content-img-location-adresse-admin">
                    <Skeleton
                      circle={true}
                      className="img-location-adresse-admin"
                      width={30}
                      height={30}
                    />
                  </div>
                </div>
                <div className="content-maps-adresse-admin py-3">
                  <div
                    style={{
                      height: "150px",
                      width: "100%",
                      position: "relative",
                    }}
                    className="mb-3"
                  >
                    <Skeleton width={`100%`} height={`100%`} />
                  </div>
                </div>
                <div className="content-btn-action-adresse-admin">
                  <div className="container-btn-action-icon-modal">
                    <Skeleton width={30} height={30} circle={true} />
                  </div>
                  <div className="container-btn-action-icon-modal">
                    <Skeleton width={30} circle={true} height={30} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
    </>
  );
};

function DeletePointButton({ id }: { id: string }) {
  const onDelete = UseDeletePoint(id);

  return (
    <button
      className="btn with-tooltip btn-action-icon-delete"
      data-tooltip-content="Supprimer"
      onClick={() => onDelete()}
    >
      <BsTrashFill />
    </button>
  );
}
const GestionPointEdk = () => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GoogleApikey,
    libraries: ["geometry", "drawing"],
  });
  const [name, setName] = useState("");
  const [page, setPage] = useState(1);
  const { data = { results: [], nbPage: 0 }, isLoading } =
    useGetListStoreAdminQuery({
      page,
      limit: 12,
      name,
    });
  const mapStyles = {
    height: "100%",
    width: "100%",
  };
  return (
    <div className="content-graph-admin">
      <div className="dashbord-admin-component admidashboard-bg mb-3">
        <div className="dash-admin-page-content-container mb-3">
          <div className="container-title-content-page mb-4">
            <div className="content-title">
              <h2 className="title-admin">Adresses EDK</h2>
            </div>
            <div className="content-title flex-r mb-4">
              <button
                className="btn btn-add-admin"
                // onClick={openModalAddAdresse}
                data-bs-toggle="modal"
                data-bs-target="#addStoreModal"
              >
                <BiMessageSquareAdd />
                <span className="ps-2">Ajouter une adresse</span>
              </button>
              {/* <AddAdresseModal
                modalAddAdresse={modalAddAdresse}
                setModalAddAdresse={setModalAddAdresse}
              /> */}
            </div>
          </div>
        </div>
      </div>
      <div className="admin-table mb-3">
        <div className="tab-dash-admin-filter-container">
          <form className="row g-3 tab-dash-admin-filter-form">
            <div className="col-auto">
              <label
                htmlFor="filtreProduit"
                className="tab-dash-admin-filter-label"
              >
                Filtrer
              </label>
            </div>
            <div className="col-auto">
              <input
                type="text"
                className="form-control tab-dash-admin-filter-input"
                id="filtreProduit"
                placeholder="Entrer le nom d'un point"
                onChange={(e) => setName(e?.target?.value)}
              />
            </div>
          </form>
        </div>
        <div className="container-adresse-admin mt-5">
          <div className="row">
            {!!isLoading && <CardSkeleton />}
            {!isLoading &&
              (data?.results?.length ? (
                data?.results?.map((item) => (
                  <div
                    className="col-md-4 col-adresse-item mb-3"
                    key={item?._id}
                  >
                    <div className="content-col-adresse-item">
                      <div className="card-adresse-admin-item">
                        <div className="header-card-adresse-admin">
                          <div className="content-left-header-adresse-admin">
                            <div className="content-img-app-adresse-admin">
                              <img
                                src={LogoEdk}
                                alt="Logo EDK"
                                className="img-app-adresse-admin"
                                loading="lazy"
                              />
                            </div>
                            <div className="content-text-app-adresse-admin">
                              <p className="title-text-left-adreese-admin mb-0">
                                {item?.name}
                              </p>
                              <p className="text-left-adreese-admin mb-0">
                                {item?.ville} +{item?.telephone}
                              </p>
                              <p className="text-left-adreese-admin mb-0">
                                {item?.email}
                              </p>
                            </div>
                          </div>
                          <div className="content-img-location-adresse-admin">
                            <img
                              src={Location}
                              alt="Logo EDK"
                              className="img-location-adresse-admin"
                              loading="lazy"
                            />
                          </div>
                        </div>
                        <div className="content-maps-adresse-admin py-3">
                          <div
                            style={{
                              height: "150px",
                              width: "100%",
                              position: "relative",
                            }}
                            className="mb-3"
                          >
                            {!!isLoaded && (
                              <GoogleMap
                                mapContainerStyle={mapStyles}
                                zoom={13}
                                // defaultOptions={{ mapTypeControl: false }}
                                options={{ mapTypeControl: false }}
                                center={{
                                  lat: parseFloat(item?.latitude),
                                  lng: parseFloat(item?.longitude),
                                }}
                              >
                                <Marker
                                  position={{
                                    lat: parseFloat(item?.latitude),
                                    lng: parseFloat(item?.longitude),
                                  }}
                                  title={item?.adresse}
                                ></Marker>
                              </GoogleMap>
                            )}
                          </div>
                        </div>
                        <div className="content-btn-action-adresse-admin">
                          <div className="container-btn-action-icon-modal">
                            <button
                              className="btn btn-action-modal-icon with-tooltip"
                              data-bs-toggle="modal"
                              data-bs-target={`#editStoreModal${item?._id}`}
                              data-tooltip-content="Modifier"
                            >
                              <MdModeEditOutline />
                            </button>
                          </div>
                          <div className="container-btn-action-icon-modal">
                            <DeletePointButton id={item?._id} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <CrudStoreModal
                      modalId={`editStoreModal${item?._id}`}
                      store={item}
                    />
                  </div>
                ))
              ) : (
                <AlertInfo message="Aucun point EDK disponible pour le moment!" />
              ))}
          </div>
          <div className="d-flex justify-content-center">
            {data?.nbPage && data?.nbPage > 0 ? (
              <Pagination
                page={page}
                total={data?.nbPage}
                onPageChange={(page) => setPage(page)}
              />
            ) : null}
          </div>
        </div>
      </div>
      <CrudStoreModal modalId="addStoreModal" />
    </div>
  );
};

export default GestionPointEdk;
