import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ConfigAppFormData } from "../../../../utils/api/config/config.type";
import {
  useEditConfigMutation,
  useGetConfigAppQuery,
} from "../../../../utils/api/config/config.api";
import { cleannerError, getImage, useLocationState, validatePhone } from "../../../../utils/Utils";
import { Color } from "../../../../utils/theme";
import { useNavigate } from "react-router-dom";

function UseEditConfigApp(type: "cgu" | "pc" | "pi" | "rs" | "banner") {
  const [phone, setPhone] = useState("")
  const [code, setCode] = useState("")
  const validationSchema = yup.object().shape({
    type: yup.string(),
    hasEdit: yup.boolean().default(false),
    cgu: yup
      .string()
      .when("type", {
        is: (val) => val === "cgu",
        then: () => yup.string().required().label("Le contenu").nullable(),
      })
      .nullable(),
    iosAppUrl: yup.string().url().label("Le lien").nullable(),
    androidAppUrl: yup.string().url().label("Le lien").nullable(),
    twitter: yup.string().url().label("Le lien").nullable(),
    tiktok: yup.string().url().label("Le lien").nullable(),
    linkedin: yup.string().url().label("Le lien").nullable(),
    facebook: yup.string().url().label("Le lien").nullable(),
    instagram: yup.string().url().label("Le lien").nullable(),
    email: yup.string().email().label("L'email").nullable(),
    telephone: validatePhone(yup, code),
    ptc: yup
      .string()
      .when("type", {
        is: (val) => val === "pc",
        then: () => yup.string().required().label("Le contenu").nullable(),
      })
      .nullable(),
    copyright: yup
      .string()
      .when("type", {
        is: (val) => val === "pi",
        then: () => yup.string().required().label("Le contenu").nullable(),
      })
      .nullable(),
    banner: yup
      .mixed()
      .when("type", {
        is: (val) => val === "banner",
        then: () =>
          yup.mixed().when("hasEdit", {
            is: false,
            then: () => yup.mixed().required().label("L'image").nullable(),
          }).nullable(),
      })
      .nullable(),
    bannerLink: yup
      .string()
      .when("type", {
        is: (val) => val === "banner",
        then: () =>
          yup.string().required().label("Le lien de redirection").nullable(),
      })
      .nullable(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm<ConfigAppFormData>({
    resolver: yupResolver(validationSchema),
  });
  const { data } = useGetConfigAppQuery();
  const item = useLocationState<any>(undefined);
  const [banner, setBanner] = useState<any>(null)
  const [contenu, setContenu] = useState<string | undefined>("");
  const [sendData, { isLoading, isSuccess, isError, error }] =
    useEditConfigMutation();
  const navigate = useNavigate();

  const handleChangePhone = (item, country) => {
    setCode(country?.countryCode);
    setPhone(item);
    if (parseInt(item) !== parseInt(country?.dialCode)) {
      setValue("telephone", item);
    } else {
      setValue("telephone", "");
    }
  };

  const handleChangeBanner = (e) => {
    let file = e?.target?.files[0]
    if (file) {
      setBanner(URL.createObjectURL(file));
      setValue("banner", file);
    }
  }

  const handleChange = (val) => {
    setContenu(val);
    if (type === "cgu") {
      setValue("cgu", val);
    }

    if (type === "pc") {
      setValue("ptc", val);
    }

    if (type === "pi") {
      setValue("copyright",val)
    }
  };

  useEffect(() => {
    console.log("item", item, data);
    if (data?._id) {
      setValue("hasEdit", true);
      const fields: (keyof ConfigAppFormData)[] = [
        "_id",
        "cgu",
        "androidAppUrl",
        "facebook",
        "instagram",
        "iosAppUrl",
        "linkedin",
        "ptc",
        "tiktok",
        "twitter",
        "copyright",
        "telephone",
        "email"
      ];
      for (let field of fields) {
        register(field);
        setValue(field, data[field]);
      }
      if (data?.telephone) {
        setPhone(data?.telephone);
      }
      if (data?.banner) {
        setBanner(getImage(data?.banner));
      }
      if (type) {
        setValue("type", type);
        if (type === "cgu") {
          setContenu(data?.cgu);
        }

        if (type === "pc") {
          setContenu(data?.ptc);
        }

        if (type === "pi") {
          setContenu(data?.copyright);
        }
      } else {
        navigate(-1);
      }
    } else {
      setValue("hasEdit", false);
    }
    
  }, [data, item]);

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        iconColor: Color.success,
        confirmButtonColor: Color.success,
        title: "Informations modifiées avec succès !",
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {
        if (type === "cgu") {
          navigate("/admin/parametres", { state: "cgu" });
        } else if (type === "pc") {
          navigate("/admin/parametres", { state: "policy" });
        } else if (type === "pi") {
          navigate("/admin/parametres", { state: "intellectual-property" });
        } else if (type === "rs"){
          navigate("/admin/parametres", { state: "rs" });
        } else {
          navigate("/admin/parametres", { state: "bannerV" });
        }
      });
    }
    const err = error as any;

    if (isError) {
      console.log("error", err);

      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Une erreur de statut ${err?.status} est survenue`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [error, isError, isSuccess]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [clearErrors, errors]);

  const onSubmit = async (data: ConfigAppFormData) => {
    console.log("data", data);
    const fd = new FormData();
    for (let key of Object.keys(data)) {
      if (key === "banner") {
        if (data[key]) {
          fd.append(key, data[key] as any);
        }
      } else {
        fd.append(key, data[key]);
      }
    }

    await sendData(fd);
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    contenu,
    handleChange,
    phone,
    handleChangePhone,
    handleChangeBanner,
    banner
  };
}

export default UseEditConfigApp;
