import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { prepareHeaders } from "../user/user.api";
import { ConfigApp, ConfigAppFormData } from "./config.type";

export const ConfigApi = createApi({
  reducerPath: "configApi",
  tagTypes: ["config"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getConfigApp: builder.query<ConfigApp, void>({
      providesTags: ["config"],
      query: () => "config/",
    }),
    editConfig: builder.mutation<ConfigApp, ConfigAppFormData | FormData>({
      query: (data) => ({
        url: `config/`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["config"],
    }),
  }),
});

export const { useEditConfigMutation, useGetConfigAppQuery } = ConfigApi;
