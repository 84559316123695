import React, { useCallback, useEffect, useState } from "react";
import { useDropzone, FileWithPath } from "react-dropzone";
import { MdAddAPhoto } from "react-icons/md";
import { getImage } from "../../utils/Utils";
import { IconDelete3 } from "../shared/Icons";

function MyDropzone({ setImages }) {
  const [maxFileError, setMaxFileError] = useState<string>("");
  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    if (acceptedFiles) {
      setImages((prevImages: any) => {
        return [...prevImages, ...acceptedFiles];
      });
    }
    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === "too-many-files") {
          setMaxFileError("Maximum 4 images");
        }
        if (err.code === "file-invalid-type") {
          setMaxFileError("Types de fichiers non prises en charge");
        }
      });
    });
  }, []);

  const { getRootProps, getInputProps, acceptedFiles, fileRejections } =
    useDropzone({
      onDrop,
      multiple: true,
      maxSize: 209715200,
      accept: {
        "image/jpeg": [],
        "image/png": [],
        "image/jpg": [],
        "image/gif": [],
      },
    });
  let acceptedFileItems = acceptedFiles.map((file: FileWithPath) => (
  	<div className="col-3" key={file.path}>
  		<div className="image-item">
  			<img src={URL.createObjectURL(file)} alt={file.path} loading="lazy" />
  		</div>
  	</div>
  ));
  const fileRejectionItems = fileRejections.map(({ file, errors }, index) => {
    return (
      <div key={index}>
        <div className="d-none">
          {file.size} bytes
        </div>
        
        <ul className="p-0">
          {errors.map((e) => (
            <li
              key={e.code}
              style={{ listStyle: "none" }}
              className="text-danger"
            >
              {e.code === "file-too-large" &&
                `L'image ${index + 1} est trop grande`}
            </li>
          ))}
        </ul>
      </div>
    );
  });

  useEffect(() => {
    setTimeout(() => setMaxFileError(""), 3000);
  });
  return (
    <section className="dropzone">
      {/* <label htmlFor="annonces-images" className="espace-client-form-label">
				label
			</label> */}
      <div
        {...getRootProps()}
        style={{
          border: ".2px solid #959595",
          overflow: "hidden",
          opacity: 1,
          borderRadius: "7px",
          display: "flex",
          justifyContent: "center",
          padding: "3rem 15px",
          background: "rgb(243, 243, 243)",
        }}
      >
        <input
          {...getInputProps()}
          type="file"
          id="annonces-images"
          name="annonces-images"
          accept="image/*"
        />
        <div className="start-label-input-choose-file">
          <div className="flex-c">
            <MdAddAPhoto style={{ color: "#666666", fontSize: "36px" }} />
          </div>
          <span className="first-label-input-choose-file">
            Déposez une image ici ou sélectionnez un fichier.
          </span>
          <span className="second-label-input-choose-file">
            Format acceptés : JPG, PNG, GIF, TIFF ou BMP, inférieurs à 200Mo.
          </span>
        </div>
      </div>
      {/* {maxFileError && <ErrorMessage message={maxFileError} />} */}
      <div className="row pt-4">
        {acceptedFileItems}
        {!maxFileError && fileRejectionItems}
      </div>
    </section>
  );
}

export default MyDropzone;

type TImageGrid = {
  images: File[] | any[];
  deleteFile?: (i:number,file:any) => void
};

export const ImageGrid = ({ images, deleteFile }: TImageGrid) => {
  return (
    <>
      {images.length
        ? images.map((file: FileWithPath | any, idx) => (
            // <div className="col-md-3 mb-1" key={file.path}>
            //   <div className="image-item">
            //     <img
            //       src={URL.createObjectURL(file)}
            //       alt={file.path}
            //       loading="lazy"
            //       className="img-thumbnail"
            //       id="overlay"
            //     />
            //   </div>
            // </div>
            <div className="col-md-4 col-xl-3" key={idx}>
              <div className="product-img-card">
                <button
                  className="no-style-btn del-product-img"
                  title="Supprimer"
                  type="button"
                  onClick={() => {
                    if (deleteFile) {
                      deleteFile(idx, file);
                    }
                  }}
                >
                  <IconDelete3 />
                </button>
                <img
                  src={
                    file?.image
                      ? getImage(file?.image)
                      : URL.createObjectURL(file)
                  }
                  alt="product-img"
                  loading="lazy"
                  style={{
                    width: "100%",
                    height: "15vw",
                    objectFit: "fill",
                  }}
                />
              </div>
            </div>
          ))
        : ""}
    </>
  );
};
