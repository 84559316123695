import React from "react";
import "./HeaderAdmin.css";
import { NavLink } from "react-router-dom";
import { RiNotification3Line } from "react-icons/ri";
import SidebarMobile from "../SidebarAdmin/SidebarMobile";
import { FiSearch } from "react-icons/fi";
import "../SidebarAdmin/SidebarAdmin.css";
import { useAppDispatch, useAppSelector } from "../../../../redux/hook";
import { onlogout } from "../../../../redux/slice/User.slice";
import { getAvatar, isPreparateur } from "../../../../utils/Utils";
import {
  useGetListNotificationAdminQuery,
  useReadNotificationMutation,
} from "../../../../utils/api/notification/notification.api";
import moment from "moment";
import { AlertInfo } from "../../../common/Alert";
const HeaderAdmin: React.FC = () => {
  const { user } = useAppSelector((s) => s?.user);
  const dispatch = useAppDispatch();
  const [readNotif] = useReadNotificationMutation();
  const { data = { results: [], count: 0 }, isLoading } =
    useGetListNotificationAdminQuery({ read: false });

  const onLogout = () => {
    dispatch(onlogout());
  };

  return (
    <div className="admin-header-component">
      <nav className="empty-fluidy alling-header navbar navbar-expand-md navbar-dark fixed-top">
        <div className="headering-admin">
          <div className="search-form-container">
            <div className="search-form-content d-none">
              <label>
                <FiSearch />
              </label>
              <input
                name="search"
                className="form-control search-input"
                placeholder="Rechercher"
              />
            </div>
          </div>
          <div className="header-others-items">
            <div className="container-info-user-connect-header">
              <div className="container-menu-header-admin gap-3">
                <div className="nav-item dropdown notification-ui">
                  <div
                    className="notification-item-container linkable position-relative notification-ui_icon"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <div className="notification-item-content">
                      <RiNotification3Line className="notif-icon" />
                      <span className="notification-counter">
                        {data?.count}
                      </span>
                    </div>
                  </div>

                  <div
                    className="dropdown-menu notification-ui_dd"
                    aria-labelledby="navbarDropdown"
                  >
                    <div className="notification-ui_dd-header">
                      <h3 className="text-center">Notification</h3>
                    </div>
                    <div className="notification-ui_dd-content">
                      {!isLoading &&
                        (data?.count && data?.count > 0 ? (
                          data?.results?.map((notif) => (
                            <NavLink
                              to={
                                isPreparateur(user)
                                  ? `/préparateur/commande/${notif?.order?._id}`
                                  : `/admin/commande/${notif?.order?._id}`
                              }
                              state={notif}
                              className="no-link"
                              key={notif?._id}
                            >
                              <div
                                className={`notification-list ${
                                  notif?.read ? "" : "notification-list--unread"
                                }`}
                                onClick={() => readNotif(notif?._id)}
                              >
                                <div className="notification-list_img">
                                  <img
                                    src={getAvatar(notif?.order?.user?.avatar)}
                                    alt="user"
                                    loading="lazy"
                                  />
                                </div>
                                <div className="notification-list_detail">
                                  <p>{notif?.content}</p>
                                  <p>
                                    <small>
                                      {moment(notif?.createdAt)?.fromNow()}
                                    </small>
                                  </p>
                                </div>
                              </div>
                            </NavLink>
                          ))
                        ) : (
                          <div className="notification-list">
                            <AlertInfo message="Aucune notification disponible pour le moment" />
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="connected-user-container" id="header-user-admin">
                <div className="connected-user-content ml-auto">
                  <div className="dropdown dis-flex">
                    <button
                      className="user-acount-dashboard-header dropdown-toggle btn"
                      type="button"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <div className="user-acount-dashboard-header-display g-2">
                        <img
                          src={getAvatar(user?.avatar)}
                          className="user-avatar"
                          alt="User Avatar"
                          loading="lazy"
                        />
                      </div>
                    </button>
                    <div
                      className="dropdown-menu dropdown-menu-custom"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <NavLink
                        to={
                          isPreparateur(user)
                            ? "/préparateur/profil"
                            : "/admin/profil"
                        }
                        className="dropdown-item"
                        style={{ fontSize: 16 }}
                      >
                        {user?.prenom + " " + user?.nom}
                      </NavLink>
                      <div className="dropdown-divider"></div>
                      <button
                        type="button"
                        className="dropdown-item"
                        style={{ fontSize: 16 }}
                        onClick={() => onLogout()}
                      >
                        Déconnexion
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hiddenable-nav-mobile-container no-view-desktop">
                <div className="hiddenable-nav-mobile-content">
                  <div className="container-nav-mobile">
                    <SidebarMobile />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default HeaderAdmin;
