import React from "react";
import { MdOutlineAdd } from "react-icons/md";
import { NavLink } from "react-router-dom";
import { useGetConfigAppQuery } from "../../../../utils/api/config/config.api";
import { createMarkup } from "../../../../utils/Utils";
import { AlertInfo } from "../../../common/Alert";
import { TextSkeleton } from "./CguAdmin";

const PolicyAdmin = () => {
  const { data, isLoading } = useGetConfigAppQuery();
  return (
    <div className="policy-admin">
      <div className="container-title-content-page mb-4">
        <div className="content-title">
          <h3 className="title-content-dash-admin">
            Politique de Confidentialité
          </h3>
        </div>
        <div className="content-btn-action-title-page-admin flex-r">
          <NavLink
            to="/admin/ajouter-politique-de-confidentialite"
            className="btn btn-submit-form-add-product-admin"
            state={{
              type: "pc",
            }}
          >
            <MdOutlineAdd />
            <span className="ps-2">Mettre à jour</span>
          </NavLink>
        </div>
      </div>
      <div className="content-text-policy-admin py-4">
        {!!isLoading && <TextSkeleton />}
        {!isLoading &&
          (data?.ptc ? (
            <p
              className="text-policy-admin"
              dangerouslySetInnerHTML={createMarkup(data?.ptc)}
            />
          ) : (
            <AlertInfo message="Le contenu de la politique de confidentialité n'est pas encore disponible" />
          ))}
      </div>
      <div className="content-btn-action-title-page-admin flex-r">
        <NavLink
          to="/admin/ajouter-politique-de-confidentialite"
          className="btn btn-submit-form-add-product-admin"
          state={{
            type: "pc",
          }}
        >
          <MdOutlineAdd />
          <span className="ps-2">Mettre à jour</span>
        </NavLink>
      </div>
    </div>
  );
};

export default PolicyAdmin;
