import React, { Dispatch, SetStateAction } from "react";
import { Modal } from "reactstrap";
import AdresseBoutiqueEdk from "../../../HomePage/AdresseBoutiqueEdk";

function StoreConfigModal({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}) {
  return (
    <Modal isOpen={isOpen} size="lg">
      <div className="modal-header">
        <h1 className="modal-title fs-5" id="ChoiceStoreModalLabel">
          Choisir une boutique
        </h1>
        {/* <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={() => setIsOpen(false)}
        ></button> */}
      </div>
      <div className="modal-body modal-config-store">
        <AdresseBoutiqueEdk onSelect={() => setIsOpen(false)} />
      </div>
    </Modal>
  );
}

export default StoreConfigModal;
