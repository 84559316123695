import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { cleannerError, onHide, validatePhone } from "../../../../utils/Utils";
import { Store, StoreFormData } from "../../../../utils/api/store/store.type";
import {
  useAddOrEditStoreMutation,
  useDeleteStoreMutation,
} from "../../../../utils/api/store/store.api";
import { Color } from "../../../../utils/theme";
import { useGetZoneListByVisitorQuery } from "../../../../utils/api/zone/zone.api";

function UseCrudStoreForm(modalId: string, store?: Store) {
  const [code, setCode] = useState<string>("");
  const [phone, setPhone] = useState<string | null>(null);
  const validationSchema = yup.object().shape({
    name: yup.string().required().label("Le nom").nullable(),
    adresse: yup.string().required().label("L'adresse").nullable(),
    email: yup.string().email().required().label("L'email").nullable(),
    ville: yup.string().required().label("La ville").nullable(),
    telephone: validatePhone(yup, code).required(),
    zone: yup.string().required().label("La zone").nullable(),
    boutiqueId: yup
      .string()
      .required()
      .label("L'id de la boutique sur léo2")
      .nullable(),
    boutiquePubKey: yup
      .string()
      .required()
      .label("La clé publique de la boutique sur léo2")
      .nullable(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
    control,
  } = useForm<StoreFormData>({
    resolver: yupResolver(validationSchema),
  });
  const [options, setOptions] = useState<{ label: string; value: string }[]>(
    []
  );
  const [option, setOption] = useState<
    { label: string; value: string } | undefined
  >();
  const { data: zones = [] } = useGetZoneListByVisitorQuery();
  const [sendData, { isLoading, isSuccess, error, isError }] =
    useAddOrEditStoreMutation();
  const [address, setAddress] = useState("");

  useEffect(() => {
    if (zones?.length) {
      // console.log("zones", zones);
      setOptions(
        zones?.map((z) => {
          return {
            label: z?.ville,
            value: z?._id,
          };
        })
      );
    }
  }, [zones]);

  const handleSelectZone = (zone) => {
    setOption(zone);
    setValue(`zone`, zone?.value);
  };

  const onChangeAddress = (val: {
    rue: string;
    latitude: string;
    longitude: string;
    ville: string;
    country: string;
  }) => {
    setAddress(val?.rue);
    setValue(`adresse`, val?.rue);
    setValue(`latitude`, val?.latitude);
    setValue(`longitude`, val?.longitude);
    if (val?.ville) {
      setValue(`ville`, val?.ville);
    }
  };

  const handleChangePhone = (item, country) => {
    setCode(country?.countryCode);
    setPhone(item);
    if (parseInt(item) !== parseInt(country?.dialCode)) {
      setValue(`telephone`, item);
    } else {
      setValue(`telephone`, "");
    }
  };

  const handleReset = () => {
    if (!store) {
      reset();
      setPhone("+221");
      setAddress("");
    }
  };

  useEffect(() => {
    // window.scroll(0, 10);
    if (isSuccess) {
      window.scroll(0, 10);
      Swal.fire({
        icon: "success",
        title: !store
          ? "Point ajouté avec succès!"
          : `Point modifié avec succès!`,
        // text: "Un email de confirmation vous a été envoyé pour valider votre inscription.",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {
        if (!store) {
          handleReset();
        }
        onHide(modalId);
        // navigate("/admin/point-edk", { replace: true });
      });
    }
    const err = error as any;

    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Une erreur de statut ${err?.status} est survenue`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [error, isError, isSuccess]);
  useEffect(() => {
    cleannerError(errors, clearErrors, 3000);
  }, [clearErrors, errors]);

  const onSubmit = async (data: StoreFormData) => {
    console.log("data register", data);

    await sendData({ id: data?._id, data: data });
  };

  useEffect(() => {
    if (store?._id) {
      const fields: (keyof StoreFormData)[] = [
        "_id",
        "name",
        "telephone",
        "adresse",
        "ville",
        "latitude",
        "longitude",
        "email",
        "zone",
        "boutiqueId",
        "boutiquePubKey",
      ];
      for (let field of fields) {
        register(field);
        if (field !== "zone") {
          setValue(field, store[field]);
        } else {
          setValue(field, store[field]?._id);
        }
      }
      setAddress(store?.adresse);
      if (store?.zone?._id) {
        setOption({
          label: store?.zone?.ville,
          value: store?.zone?._id,
        });
      }
      if (store?.telephone) {
        setPhone(store?.telephone);
      }
    }
  }, [store]);

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    phone,
    handleChangePhone,
    onChangeAddress,
    address,
    isSuccess,
    handleReset,
    handleSelectZone,
    options,
    option,
    Controller,
    control,
  };
}

export default UseCrudStoreForm;

export function UseDeletePoint(id: string) {
  const [deleteData] = useDeleteStoreMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer ce point ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(id);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      console.log(result, "archiver");
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `Le point a été supprimé avec succès!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };
  return onDelete;
}
