import React from "react";
import FrontFooter from "../shared/FrontFooter/FrontFooter";
import FrontHeader from "../shared/FrontHeader/FrontHeader";
import "./MonCompte.css";
import MonCompteSidebar from "./MonCompteSidebar";
import { Outlet } from "react-router-dom";
import FooterPage from "../auth/FooterPage";

const MonCompteLayout = () => {
  return (
    <div className="landing-page-component">
      <FrontHeader />
      <div className="page-content">
        <div className="container-low-price">
          <div className="content-title-register-page pb-5">
            <h2 className="title-register-page">Mon compte</h2>
          </div>
          <div className="row row-register-page mb-5">
            <div className="col-lg-2 col-md-3 col-left-register-page">
              <div className="col-left-register-page">
                <MonCompteSidebar />
              </div>
            </div>
            <div className="col-lg-10 col-md-9 col-right-register-page">
              <div className="content-col-right-register-page">
                <Outlet />
              </div>
            </div>
          </div>
          <FooterPage />
        </div>
      </div>
      <FrontFooter />
    </div>
  );
};

export default MonCompteLayout;
