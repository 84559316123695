import React, { useState } from "react";
import { BsCameraFill } from "react-icons/bs";
import "./ProfilAdmin.css";
import PhoneInput from "react-phone-input-2";
import { InputAdmin } from "../../common/Input";
import { FiEdit } from "react-icons/fi";
import ChangePasswordModal from "./modal/ChangePasswordModal";
import UseEditInfosForm from "./requestForm/UseEditInfosForm";
import ErrorMessage from "../../common/ErrorMessage";
import { getAvatar } from "../../../utils/Utils";

const ProfilAdmin = () => {
  const {
    register,
    handelImageChange,
    handleChangePhone,
    phone,
    avatar,
    onSubmit,
    isLoading,
    errors,
    user
  } = UseEditInfosForm();
  const [modalChangePassword, setModalChangePassword] = useState(false);

  function openModalChangePassword(e: any) {
    e.preventDefault();
    setModalChangePassword(true);
  }
  return (
    <div className="content-graph-admin">
      <div className="dashbord-admin-component admidashboard-bg mb-3">
        <div className="dash-admin-page-content-container mb-3">
          <div className="container-title-content-page mb-4">
            <div className="content-title">
              <h2 className="title-admin">Mon profil</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="profil-admin-component">
        <div className="container-profil-admin">
          <p className="sub-title-form-register">Information personnelles</p>
          <div className="card-profil-admin mb-3">
            <div className="container-info-user-profil">
              <form onSubmit={onSubmit}>
                <div className="content-pp-profil-admin text-center pb-3">
                  <div className="d-flex justify-content-center align-items-center pb-4 mb-4 admin-user-avatar-container">
                    <label
                      htmlFor="input-test"
                      className="input-testt label-profil-user-admin"
                    >
                      <img
                        src={avatar || getAvatar(user?.avatar)}
                        alt="profil utilisateur"
                        className="pp-profil-user-admin"
                        loading="lazy"
                      />
                      <div className="admin-user-avatar-edit-hover">
                        <BsCameraFill className="edit-avatar-camera-icon" />
                      </div>
                    </label>
                    <input
                      type="file"
                      className="input-add-avatar"
                      id="input-test"
                      onChange={handelImageChange}
                      accept="image/*"
                    />
                  </div>
                </div>
                <div className="formulaire-midifier-profile-container">
                  <div className="row">
                    <div className="col-md-6">
                      <InputAdmin
                        type="text"
                        label="Prénom"
                        id="prenom"
                        placeholder="Prénom"
                        {...register("prenom")}
                      />
                      <ErrorMessage
                        message={errors?.prenom && errors?.prenom?.message}
                      />
                    </div>
                    <div className="col-md-6">
                      <InputAdmin
                        type="text"
                        label="Nom"
                        id="nom"
                        placeholder="Nom"
                        {...register("nom")}
                      />
                      <ErrorMessage
                        message={errors?.nom && errors?.nom?.message}
                      />
                    </div>
                    <div className="col-md-6">
                      <InputAdmin
                        type="email"
                        label="Adresse email"
                        id="adresse_email"
                        placeholder="Email"
                        {...register("email")}
                      />
                      <ErrorMessage
                        message={errors?.email && errors?.email?.message}
                      />
                    </div>
                    <div className="col-md-6">
                      {/* <InputAdmin
                        type="tel"
                        label="Télépone"
                        id="telepone"
                        placeholder="Télépone"
                      /> */}
                      <label className="form-label form-label-admin">
                        Télépone
                      </label>

                      <PhoneInput
                        inputProps={{
                          name: "phone",
                        }}
                        defaultMask={".. ... .. .."}
                        country="sn"
                        value={phone}
                        enableSearch={true}
                        onChange={(phone, country) =>
                          handleChangePhone(phone, country)
                        }
                        inputClass="form-control form-control-admin-tel"
                        enableAreaCodes={true}
                        prefix="+"
                        countryCodeEditable={false}
                      />
                      <ErrorMessage
                        message={
                          errors?.telephone && errors?.telephone?.message
                        }
                      />
                    </div>
                  </div>

                  <div className="info-user-profil-btn-edit-container mt-3">
                    <button className="btn btn-theme" disabled={isLoading}>
                      {isLoading ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                            role="status"
                          ></span>
                          <span>Modification...</span>
                        </>
                      ) : (
                        "Modifier"
                      )}
                    </button>
                  </div>
                </div>
              </form>

              <div className="admin-edit-profil-param-main-page-container py-5">
                <div className="d-flex justify-content-between align-items-center">
                  <InputAdmin
                    type="password"
                    label="Mot de passe"
                    id="password"
                    placeholder="****************"
                    readOnly
                  />
                  <div>
                    <button
                      className="btn btn-submit-form-add-product-admin"
                      onClick={openModalChangePassword}
                    >
                      <FiEdit /> Changer de mot de passe
                    </button>
                  </div>
                </div>
                <ChangePasswordModal
                  modalChangePassword={modalChangePassword}
                  setModalChangePassword={setModalChangePassword}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilAdmin;
