import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "./CommandesTable.css";
import TableSkeleton from "../../../common/TableSkeleton";
import { AiFillEye } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import { useGetListOrderAdminQuery } from "../../../../utils/api/order/order.api";
import { formatDate, isAdmin, isPreparateur } from "../../../../utils/Utils";
import { IUser } from "../../../../utils/api/user/user.type";
import ChangeStatutOrder from "../modal/ChangeStatutOrder";
import { orderType } from "../CommandeItem";
import { CustomPagination } from "../../GestionProduits/ProductsTable/ProductsTable";
import { useAppSelector } from "../../../../redux/hook";
import { Order } from "../../../../utils/api/order/order.type";

function CommandesTable({
  numOrder,
  status,
}: {
  numOrder: string;
  status: string;
}) {
  const { user } = useAppSelector((s) => s?.user);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  // const [findOrders, { isLoading, data = { results: [], nbPage: 0 } }] =
  //   useGetListOrderAdminQuery();
  const {
    isLoading,
    data = { results: [], nbPage: 0 },
    refetch,
  } = useGetListOrderAdminQuery({ numOrder, page, status, limit: perPage });
  // console.log({ data });

  useEffect(() => {
    if (!isLoading) {
      refetch();
    }
  }, []);
  useEffect(() => {
    let timer = setInterval(() => {
      refetch();
    }, 120000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const actionFormatter = (cell: string, row: Order) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex gap-3">
          <div className="container-btn-action-icon-modal">
            <NavLink
              to={
                isPreparateur(user)
                  ? `/préparateur/commande/${cell}`
                  : `/admin/commande/${cell}`
              }
              state={row}
              className="btn btn-action-filter-icon with-tooltip"
              data-tooltip-content="Voir"
            >
              <AiFillEye />
            </NavLink>
          </div>
          {/* <div className="container-btn-action-icon-modal">
            <button
              className="btn with-tooltip btn-action-icon-delete"
              data-tooltip-content="Supprimer"
              style={{ color: "#E53E3E" }}
            >
              <BsTrashFill />
            </button>
          </div> */}
        </div>
      </>
    );
  };

  const priceFormatter = (cell) => {
    return (
      <div className="table-actions-btn-container-img gap-2 d-flex align-items-center">
        <span>
          {cell} <span className="text-gray-etoile">fcfa</span>
        </span>
      </div>
    );
  };

  const statutFormatter = (
    cell:
      | "livré"
      | "en cours de préparation"
      | "nouvelle"
      | "annulée"
      | "prête pour livraison"
      | "en attente",
    row: Order
  ) => {
    return (
      <>
        <button
          // className="statut-nouvelle statut-commande"
          className={`${
            orderType?.find((el) => el?.value === cell)?.class
          } statut-commande ${
            (isAdmin(user) || row?.preparer?._id === user?._id) && "linkable"
          }`}
          data-bs-toggle="modal"
          data-bs-target={
            (isAdmin(user) || row?.preparer?._id === user?._id) &&
            `#changeStatusOrder${row?._id}`
          }
        >
          {cell}
        </button>

        <ChangeStatutOrder item={row} />
      </>
    );
  };

  const preOrderFormatter = (cell: boolean) => {
    if (cell) {
      return (
        <span className="type-pre-order statut-commande"> Pré-commande </span>
      );
    }

    return <span className="type-order statut-commande">Commande</span>;
  };

  const columns = [
    {
      dataField: "numOrder",
      text: "N°",
    },
    {
      dataField: "preOrder",
      text: "Type",
      formatter: preOrderFormatter,
    },
    {
      dataField: "createdAt",
      text: "Date",
      formatter: (cell: Date) => formatDate(cell),
    },

    {
      dataField: "user",
      text: "Acheteur",
      formatter: (cell: IUser) => cell?.prenom + " " + cell?.nom,
    },
    {
      dataField: "totalAmount",
      text: "Prix",
      formatter: (cell: string) => priceFormatter(cell),
    },
    {
      dataField: "status",
      text: "Statut",
      formatter: (
        cell:
          | "livré"
          | "en cours de préparation"
          | "nouvelle"
          | "annulée"
          | "prête pour livraison"
          | "en attente",
        row: Order
      ) => statutFormatter(cell, row),
    },
    {
      dataField: "_id",
      text: "Actions",
      formatter: (cell: string, row: Order) => actionFormatter(cell, row),
    },
  ];

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="_id"
            data={data?.results}
            columns={columns}
            striped
            condensed
            hover
            responsive={true}
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => "Aucune donnée trouvée"}
            // pagination={paginationFactory({ sizePerPage: 10 })}
          />
          <CustomPagination
            nbPages={data?.nbPage}
            page={page}
            onChange={(page, perPage) => {
              setPerPage(perPage);
              setPage(page);
            }}
            perPage={perPage}
          />
        </>
      )}
    </>
  );
}

export default CommandesTable;
