import React, { useState } from "react";
import { useGetHistiquesQuery } from "../../../../utils/api/dashboard/dashboard.api";
import BootstrapTable from "react-bootstrap-table-next";
import { IUser } from "../../../../utils/api/user/user.type";
import { formatDateHour, getAvatar } from "../../../../utils/Utils";
import TableSkeleton from "../../../common/TableSkeleton";
import { CustomPagination } from "../../GestionProduits/ProductsTable/ProductsTable";
import { userTypes } from "../../Parametres/tables/AdminsTable/AdminsTable";

function HistoriqueTable() {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const { data = { results: [], count: 0 }, isLoading } = useGetHistiquesQuery({
    page,
    limit,
  });

  const nameFormatter = (cell: IUser) => {
    return (
      <div className="table-actions-btn-container-img gap-2">
        <div className="content-img-product">
          <img
            src={getAvatar(cell?.avatar)}
            alt={cell?._id}
            className="img-product-table"
            loading="lazy"
          />
        </div>
        <p className="text-nom-produit-table mb-0">
          {cell?.prenom + " " + cell?.nom}
        </p>
      </div>
    );
  };

  const statutFormatter = (cell: string) => {
    return (
      <>
        <div
          className={`${
            userTypes?.find((el) => el?.value === cell)?.class
          } statut-commande`}
        >
          {userTypes?.find((el) => el?.value === cell)?.label}
        </div>
      </>
    );
  };

  const columns = [
    {
      dataField: "user",
      text: "Nom",
      formatter: (cell: IUser) => nameFormatter(cell),
    },
    {
      dataField: "user",
      text: "Email",
      formatter: (cell: IUser) => cell?.email,
    },
    {
      dataField: "user",
      text: "Téléphone",
      formatter: (cell: IUser) => cell?.telephone,
    },
    {
      dataField: "user",
      text: "Type",
      formatter: (cell: IUser) => statutFormatter(cell?.userType),
    },

    {
      dataField: "createdAt",
      text: "Date",
      formatter: (cell: string) => formatDateHour(cell),
    },
  ];
  return (
    <div className="chart-item-container">
      <div className="content-chart-title mb-4">
        <h2 className="chart-item-admin-title mb-0">
          Historique des connexions
        </h2>
      </div>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="_id"
            data={data?.results}
            columns={columns}
            striped
            condensed
            hover
            responsive={true}
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => "Aucune donnée trouvée"}
            // pagination={paginationFactory({ sizePerPage: 10 })}
          />
          <CustomPagination
            nbPages={Math.ceil((data?.count || 0) / limit)}
            page={page}
            onChange={(page, perPage) => {
              setLimit(perPage);
              setPage(page);
            }}
            perPage={limit}
          />
        </>
      )}
    </div>
  );
}

export default HistoriqueTable;
