/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, useEffect } from "react";
import "./Login.css";
import FrontFooter from "../shared/FrontFooter/FrontFooter";
import FrontHeader from "../shared/FrontHeader/FrontHeader";
import { HiUser } from "react-icons/hi";
import { ImLocation2 } from "react-icons/im";
import { useStepType } from "../../utils/type";
import { useStep } from "react-hooks-helper";
import InfoPersoTabs from "./RegisterTabs/InfoPersoTabs";
import AdresseTabs from "./RegisterTabs/AdresseTabs";

import FooterPage from "./FooterPage";
import ConnexionModal from "../shared/FrontHeader/modal/ConnexionModal";
import UseRegisterForm from "./requestForm/UseRegisterForm";
import { UseFormRegister, FieldErrorsImpl } from "react-hook-form";
import { RegisterFormData } from "../../utils/api/auth/auth.type";
import ConfirmPhoneNumber from "./ConfirmPhoneNumber";

export type PropsType = {
  navigation?: any;
  register: UseFormRegister<RegisterFormData>;
  errors: Partial<FieldErrorsImpl<RegisterFormData>>;
  passwordHandleChange: (
    e: ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => void;
  phone: any;
  handleChangePhone: (phone: any, country: any) => void;
  haveMinCharacter: boolean;
  haveMinLowercase: boolean;
  haveMinNumber: boolean;
  haveMinSpecialCharacter: boolean;
  haveMinUppercase: boolean;
  password: string;
  phone1: any;
  phone2: any;
  handleChangePhone1: (phone: any, country: any) => void;
  handleChangePhone2: (phone: any, country: any) => void;
  onChangeAddress: any;
  address: string;
  isLoading: boolean;
  handleSelectZone: any;
  options: any;
  option: any;
  Controller: any;
  control: any;
};
const steps = [
  { id: "info-perso", Component: InfoPersoTabs },
  { id: "adresse", Component: AdresseTabs },
];
const tabs = [
  {
    title: "Mon profil",
    icon: <HiUser />,
  },
  {
    title: "Mes adresses",
    icon: <ImLocation2 />,
  },
];

const Register = () => {
  const { step, navigation, index }: useStepType = useStep({
    initialStep: 0,
    steps,
  });
  const { Component } = step;
  const form = UseRegisterForm();
  const {
    onSubmit,
    modalConnexion,
    setModalConnexion,
    dataForm,
    level,
    setValue,
    phone,
  } = form;
  const props = {
    navigation,
    ...form,
  };

  useEffect(() => {
    window.scrollTo(10, 10);
    setValue("step", index);
  }, [index, setValue]);

  useEffect(() => {
    if (level === 1) {
      navigation.go("adresse");
    }
  }, [level, dataForm]);

  return (
    <div className="landing-page-component">
      <FrontHeader />
      <div className="page-content">
        <div className="container-low-price">
          <div className="content-title-register-page pb-5">
            <h2 className="title-register-page">Créer un compte</h2>
          </div>
          <div className="row row-register-page mb-5">
            <div className="col-lg-2 col-md-3 col-left-register-page sidebar-tabs-container">
              <div className="col-left-register-page">
                <ul className="tabs-container">
                  {tabs.map((tab, key) => (
                    <li
                      className={`tab-item  ${
                        index === key && "tab-active-item"
                      }`}
                      key={key}
                      role="button"
                    >
                      {tab?.icon} {tab?.title}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-10 col-md-9 col-right-register-page">
              <form
                className="content-col-right-register-page"
                onSubmit={onSubmit}
                data-testid="register-form"
              >
                <Component {...props} />
              </form>
              <ConnexionModal
                modalConnexion={modalConnexion}
                setModalConnexion={setModalConnexion}
              />
              {!modalConnexion && (
                <ConfirmPhoneNumber
                  phone={phone}
                  setModalConnexion={setModalConnexion}
                />
              )}
            </div>
          </div>
          <FooterPage />
        </div>
      </div>
      <FrontFooter />
    </div>
  );
};

export default Register;
