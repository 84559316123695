import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import TableSkeleton from "../../../../common/TableSkeleton";
import { MdModeEditOutline } from "react-icons/md";
import { useGetListAdminUserQuery } from "../../../../../utils/api/user/user.api";
import { IUser } from "../../../../../utils/api/user/user.type";
import { CustomPagination } from "../../../GestionProduits/ProductsTable/ProductsTable";
import { DeleteUserButton } from "../../../GestionUsers/UsersTable/UsersTable";
import CrudAdminModal from "../../modal/CrudAdminModal";

export const userTypes = [
  {
    value: "admin",
    label: "Administrateur",
    class: "statut-livre",
  },
  {
    value: "user",
    label: "Utilisateur",
    class: "statut-en-cours-de-prepation",
  },
  {
    value: "preparer",
    label: "Préparateur",
    class: "statut-nouvelle",
  },
  {
    value: "deliveryman",
    label: "Livreur",
    class: "statut-prete-pour-livraison",
  },
];
function AdminsTable() {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const { data = { results: [], nbPage: 0 }, isLoading } =
    useGetListAdminUserQuery({
      page,
      // search: name,
    });

  const actionFormatter = (cell: string, row: IUser) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex gap-3">
          <div className="container-btn-action-icon-modal">
            <div className="container-btn-action-icon-modal">
              <button
                className="btn btn-action-modal-icon with-tooltip"
                data-tooltip-content="Modifier"
                data-bs-toggle="modal"
                data-bs-target={`#editAdminModal${cell}`}
              >
                <MdModeEditOutline />
              </button>
            </div>
          </div>
          <div className="container-btn-action-icon-modal">
            <DeleteUserButton item={row} />
          </div>
        </div>
        <CrudAdminModal modalId={`editAdminModal${cell}`} user={row} />
      </>
    );
  };

  const statutFormatter = (
    cell:
      | "admin"
      | "user"
      | "preparer"
      | "deliveryman",
    row
  ) => {
    return (
      <>
        <div
          // className="statut-nouvelle statut-commande"
          className={`${
            userTypes?.find((el) => el?.value === cell)?.class
          } statut-commande linkable`}
          data-bs-toggle="modal"
          data-bs-target={`#changeStatusOrder${row?._id}`}
        >
          {userTypes?.find((el) => el?.value === cell)?.label}
        </div>
      </>
    );
  };

  const columns = [
    {
      dataField: "nom",
      text: "Prénom & Nom",
      formatter: (cell: string, row: IUser) => row?.prenom + " " + cell,
    },
    {
      dataField: "email",
      text: "Email",
    },
    {
      dataField: "telephone",
      text: "Téléphone",
    },
    {
      dataField: "userType",
      text: "Type",
      formatter: (cell: any, row: any) => statutFormatter(cell, row),
    },
    {
      dataField: "_id",
      text: "Actions",
      formatter: (cell: string, row: IUser) => actionFormatter(cell, row),
    },
  ];

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="_id"
            data={data?.results}
            columns={columns}
            striped
            condensed
            hover
            responsive={true}
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => "Aucune donnée trouvée"}
          />
          <CustomPagination
            nbPages={data?.nbPage}
            page={page}
            onChange={(page, perPage) => {
              setPerPage(perPage);
              setPage(page);
            }}
            perPage={perPage}
          />
        </>
      )}
    </>
  );
}

export default AdminsTable;
