import React from 'react'
import { MdOutlineAdd } from "react-icons/md";
import { NavLink } from "react-router-dom";
import { useGetConfigAppQuery } from '../../../../utils/api/config/config.api';
import { TextSkeleton } from './CguAdmin';
import { createMarkup } from '../../../../utils/Utils';
import { AlertInfo } from '../../../common/Alert';

function ConfidentialAdmin() {
  const { data, isLoading } = useGetConfigAppQuery();
  return (
    <div className="cgu-admin">
      <div className="container-title-content-page mb-4">
        <div className="content-title">
          <h3 className="title-content-dash-admin">Propriété intellectuelle</h3>
        </div>
        <div className="content-btn-action-title-page-admin flex-r">
          <NavLink
            to="/admin/ajouter-propriete-intellectuelle"
            className="btn btn-submit-form-add-product-admin"
            state={{
              type: "pi",
            }}
          >
            <MdOutlineAdd />
            <span className="ps-2">Mettre à jour</span>
          </NavLink>
        </div>
      </div>
      <div className="content-text-policy-admin py-4">
        {!!isLoading && <TextSkeleton />}
        {!isLoading &&
          (data?.copyright ? (
            <p
              className="text-policy-admin"
              dangerouslySetInnerHTML={createMarkup(data?.copyright)}
            />
          ) : (
            <AlertInfo message="Le contenu de la propriété intellectuelle n'est pas encore disponible" />
          ))}
      </div>
      {!!data?.copyright && (
        <div className="content-btn-action-title-page-admin flex-r">
          <NavLink
            to="/admin/ajouter-propriete-intellectuelle"
            className="btn btn-submit-form-add-product-admin"
            state={{
              type: "pi",
            }}
          >
            <MdOutlineAdd />
            <span className="ps-2">Mettre à jour</span>
          </NavLink>
        </div>
      )}
    </div>
  );
}

export default ConfidentialAdmin