import React from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import PhoneInput from "react-phone-input-2";
import ErrorMessage from "../../common/ErrorMessage";
import GoogleInput from "../../common/GoogleInput";
import { PropsType } from "../Register";

const AdresseTabs = ({
  navigation,
  register,
  errors,
  phone1,
  phone2,
  handleChangePhone1,
  handleChangePhone2,
  onChangeAddress,
  address,
  isLoading,
  handleSelectZone,
  options,
  option,
  Controller,
  control,
}: PropsType) => {
  const animatedComponents = makeAnimated();

  return (
    <div className="form-register-tabs-component">
      <div className="content-title-form-register pb-4">
        <h3 className="title-form-register">Mes adresses</h3>
      </div>
      {/* <div className="row mb-5">
        <div className="col-lg-4 col-md-6">
          <div className="container-default-adresse">
            <div className="content-info-default-adresse pb-3">
              <div className="content-text-info-adresse">
                <p className="form-label-register-tabs">Makhtar Mbaye</p>
                <p className="text-info-adresse">
                  Grand, Mbao, quartier Médine
                </p>
                <p className="text-info-adresse">Pikine, Dakar</p>
                <p className="text-info-adresse">+221 773288402</p>
              </div>
              <div className="content-icon-edit-default-adresse">
                <AiFillEdit className="icon-edit-default-adresse" />
              </div>
            </div>
            <p className="text-info-adresse-right mb-0">Adresse par défaut</p>
          </div>
        </div>
      </div>
      <p className="sub-title-form-register">Nouvelle adresse</p> */}
      <div className="content-form-register-tabs mt-5">
        <div>
          <div className="row row-add">
            <div className="col-md-6 col-form-register-tabs mb-3">
              <div className="content-col-form-register-tabs">
                <label className="form-label form-label-register-tabs">
                  PRÉNOM*
                </label>
                <input
                  className="form-control form-control-register-tabs"
                  type="text"
                  placeholder="Prénom"
                  {...register(`adresses.${0}.prenom`)}
                  data-testid="adressPrenom-input"
                />
                <ErrorMessage
                  message={
                    errors?.adresses &&
                    errors?.adresses[0]?.prenom &&
                    errors?.adresses[0]?.prenom?.message
                  }
                />
              </div>
            </div>
            <div className="col-md-6 col-form-register-tabs mb-3">
              <div className="content-col-form-register-tabs">
                <label className="form-label form-label-register-tabs">
                  NOM*
                </label>
                <input
                  className="form-control form-control-register-tabs"
                  type="text"
                  placeholder="Nom"
                  {...register(`adresses.${0}.nom`)}
                  data-testid="adressNom-input"
                />
                <ErrorMessage
                  message={
                    errors?.adresses &&
                    errors?.adresses[0]?.nom &&
                    errors?.adresses[0]?.nom?.message
                  }
                />
              </div>
            </div>
            <div className="col-md-6 col-form-register-tabs mb-3">
              <div className="content-col-form-register-tabs">
                <label className="form-label form-label-register-tabs">
                  Adresse*
                </label>
                {/* <input
                  className="form-control form-control-register-tabs"
                  type="text"
                  placeholder="Grand yoff, Dakr"
                /> */}
                <GoogleInput
                  className="form-control form-control-register-tabs"
                  value={address}
                  onChange={onChangeAddress}
                  dataTestid="adresse-input"
                />
                <ErrorMessage
                  message={
                    errors?.adresses &&
                    errors?.adresses[0]?.adresse &&
                    errors?.adresses[0]?.adresse?.message
                  }
                />
              </div>
            </div>
            <div className="col-md-6 col-form-register-tabs mb-3">
              <div className="content-col-form-register-tabs">
                <label className="form-label form-label-register-tabs">
                  Complément d’adresse
                </label>
                <input
                  className="form-control form-control-register-tabs"
                  type="text"
                  placeholder="Complément d'adresse"
                  {...register(`adresses.${0}.complementAdresse`)}
                  data-testid="complementAdresse-input"
                />
                <ErrorMessage
                  message={
                    errors?.adresses &&
                    errors?.adresses[0]?.complementAdresse &&
                    errors?.adresses[0]?.complementAdresse?.message
                  }
                />
              </div>
            </div>
            <div className="col-md-6 col-form-register-tabs mb-3">
              <div className="content-col-form-register-tabs">
                <label className="form-label form-label-register-tabs">
                  Quartier
                </label>
                <input
                  className="form-control form-control-register-tabs"
                  type="text"
                  placeholder="Quartier"
                  {...register(`adresses.${0}.quartier`)}
                  data-testid="quartier-input"
                />
                <ErrorMessage
                  message={
                    errors?.adresses &&
                    errors?.adresses[0]?.quartier &&
                    errors?.adresses[0]?.quartier?.message
                  }
                />
              </div>
            </div>
            <div className="col-md-6 col-add-product-admin mb-3">
              <div className="mb-3">
                <label className="form-label form-label-register-tabs">
                  Zone*
                </label>

                <Controller
                  name="zone"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Select
                        {...field}
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        placeholder="Sélectionnez votre zone"
                        options={options}
                        classNamePrefix="react-select-zone"
                        onChange={handleSelectZone}
                        value={option}
                        data-testid="zone-input"
                      />
                    );
                  }}
                />
                <ErrorMessage
                  message={
                    errors?.adresses &&
                    errors?.adresses[0]?.zone &&
                    errors?.adresses[0]?.zone?.message
                  }
                />
              </div>
            </div>
            {/* <div className="col-md-6 col-form-register-tabs mb-3">
              <div className="content-col-form-register-tabs">
                <label className="form-label form-label-register-tabs">
                  Ville/Région*
                </label>
                <input
                  className="form-control form-control-register-tabs"
                  type="text"
                  placeholder="Ville/Région"
                  {...register(`adresses.${0}.ville`)}
                />
                <ErrorMessage
                  message={
                    errors?.adresses &&
                    errors?.adresses[0]?.ville &&
                    errors?.adresses[0]?.ville?.message
                  }
                />
              </div>
            </div> */}
            <div className="col-md-6 col-form-register-tabs mb-3">
              <div className="content-col-form-register-tabs">
                <label className="form-label form-label-register-tabs">
                  Téléphone 1*
                </label>
                <PhoneInput
                  inputProps={{
                    name: "phone",
                  }}
                  defaultMask={".. ... .. .."}
                  country={"sn"}
                  enableSearch={true}
                  value={phone1}
                  onChange={(phone, country) =>
                    handleChangePhone1(phone, country)
                  }
                  inputClass="form-control form-control-register-tabs-tel"
                  enableAreaCodes={true}
                  prefix="+"
                  countryCodeEditable={false}
				  data-testid="phone1-input"
				  placeholder="Téléphone1"
                />
              </div>
            </div>
            <div className="col-md-6 col-form-register-tabs mb-3">
              <div className="content-col-form-register-tabs">
                <label className="form-label form-label-register-tabs">
                  TÉLÉPHONE 2
                </label>
                <PhoneInput
                  inputProps={{
                    name: "phone",
                  }}
                  defaultMask={".. ... .. .."}
                  country={"sn"}
                  enableSearch={true}
                  value={phone2}
                  onChange={(phone, country) =>
                    handleChangePhone2(phone, country)
                  }
                  inputClass="form-control form-control-register-tabs-tel"
                  enableAreaCodes={true}
                  prefix="+"
                  countryCodeEditable={false}
				  data-testid="phone2-input"
				  placeholder="Téléphone2"
                />
              </div>
            </div>
            <div className="col-md-12 col-form-register-tabs mb-3">
              <div className="content-col-form-register-tabs cpntrol">
                <label className="label-checkbox-register-tabs">
                  <input
                    type="checkbox"
                    className="input-checkbox-register-tabs me-2"
                    defaultChecked={true}
                    value={`${true}`}
                    {...register(`adresses.${0}.isDefault`)}
					data-testid="isDefault-input"
                  />
                  Définir par défaut
                </label>
              </div>
            </div>
            <div className="col-md-12 col-form-register-tabs mb-3">
              <div className="content-col-form-register-tabs cpntrol">
                <label className="label-checkbox-register-tabs">
                  <input
                    type="checkbox"
                    className="input-checkbox-register-tabs me-2"
                    defaultChecked={true}
                    value={`${true}`}
                    {...register(`newsletter`)}
					data-testid="newsletter-input"
                  />
                  J'accepte de recevoir des notifications concernant nos
                  promotions et nos nouveaux produits
                </label>
              </div>
            </div>
            <div className="col-md-6 py-5 d-flex">
              <div className="content-btn-submit">
                <button
                  className="btn btn-cancelled w-100"
                  onClick={() => navigation.previous()}
                >
                  RETOUR
                </button>
              </div>
            </div>
            <div className="col-md-6 d-flex justify-content-end py-5">
              <div className="content-btn-submit">
                <button className="btn btn-theme w-100" disabled={isLoading} data-testid="submit-btn">
                  {isLoading ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                        role="status"
                      ></span>
                      <span>Enregistrer...</span>
                    </>
                  ) : (
                    "ENREGISTRER"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdresseTabs;
