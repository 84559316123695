import React from "react";
import { useUserFromLocation } from "../../../utils/api/user/user.api";
import { formatDate, getAvatar } from "../../../utils/Utils";

const UserDetailAdmin = () => {
  const [item] = useUserFromLocation()
  return (
    <div className="container-detail-user-admin">
      <div className="content-graph-admin mb-4">
        <div className="content-img-detail-user-admin text-center py-3">
          <img
            src={getAvatar(item?.avatar)}
            alt="profil"
            className="img-pp-user-detail-admin"
            loading="lazy"
          />
        </div>
        <p className="title-user-detail-admin">
          {item?.prenom + " " + item?.nom}
        </p>
        <div className="content-title-section-detail-user-admin pt-4">
          <p className="title-section-detail-user-admin">
            Informations personnelles
          </p>
        </div>
      </div>
      <div className="content-graph-admin mb-4">
        <div className="content-info-user-detail-admin-item py-3">
          <div className="row">
            <div className="col-md-6 mb-3">
              <p className="text-libelle-user-detail-admin-item">Nom </p>
            </div>
            <div className="col-md-6 mb-3">
              <p className="text-value-user-detail-admin-item">
                {item?.prenom + " " + item?.nom}{" "}
              </p>
            </div>
          </div>
        </div>
        <div className="content-info-user-detail-admin-item py-3">
          <div className="row">
            <div className="col-md-6 mb-3">
              <p className="text-libelle-user-detail-admin-item">Telephone </p>
            </div>
            <div className="col-md-6 mb-3">
              <p className="text-value-user-detail-admin-item">
                {item?.telephone}{" "}
              </p>
            </div>
          </div>
        </div>
        <div className="content-info-user-detail-admin-item py-3">
          <div className="row">
            <div className="col-md-6 mb-3">
              <p className="text-libelle-user-detail-admin-item">Mail </p>
            </div>
            <div className="col-md-6 mb-3">
              <p className="text-value-user-detail-admin-item">
                {item?.email}{" "}
              </p>
            </div>
          </div>
        </div>
        <div className="content-info-user-detail-admin-item py-3">
          <div className="row">
            <div className="col-md-6 mb-3">
              <p className="text-libelle-user-detail-admin-item">
                Date de naissance{" "}
              </p>
            </div>
            <div className="col-md-6 mb-3">
              <p className="text-value-user-detail-admin-item">
                {!!item?.birthDate && formatDate(item?.birthDate)}{" "}
              </p>
            </div>
          </div>
        </div>
        {!!item?.adresses?.length &&
          item?.adresses?.map((address, i) => (
            <div className="content-info-user-detail-admin-item py-3">
              <div className="row">
                <div className="col-md-6 mb-3">
                  <p className="text-libelle-user-detail-admin-item">
                    Adresse {i + 1}{" "}
                  </p>
                </div>
                <div className="col-md-6 mb-3">
                  <p className="text-value-user-detail-admin-item">
                    {address?.adresse}{" "}
                  </p>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default UserDetailAdmin;
