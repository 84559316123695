import React from "react";
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
// import Product from "../../../assets/appImages/commande.png";
import {
  useGetRateOrderQuery,
  useOrderFromLocation,
} from "../../../utils/api/order/order.api";
import { formatCurrency, getImage } from "../../../utils/Utils";
import { orderType } from "../../admin/GestionCommande/CommandeItem";
import AvisCommandeModal from "./modal/AvisCommandeModal";

const DetailCommandePage = () => {
  const [show, setShow] = React.useState(false);
  const [item] = useOrderFromLocation();
  const navigate = useNavigate();
  const { data, isLoading } = useGetRateOrderQuery({
    slug: item?._id,
  });

  return (
    <div className="form-register-tabs-component">
      <div className="content-title-form-register pb-4">
        <h3
          className="title-form-register"
          onClick={() => navigate(-1)}
          style={{ cursor: "pointer" }}
        >
          <BsArrowLeft /> <span className="ps-2">Retour</span>{" "}
        </h3>
      </div>
      <div className="content-detail-commande-page mt-5 mb-3">
        <div className="card-detail-commande-item mb-3">
          {item?.orderItems?.map((order, i) => (
            <div
              className="row row-detail-commande-admin"
              key={order?.product?._id}
            >
              <div className="col-md-6 mb-3 col-detail-commande-admin">
                <div className="content-col-detail-commande-admin">
                  {i === 0 && (
                    <h3 className="title-col-detail-commande-admin">Produit</h3>
                  )}

                  <div className="content-infdetail-commande-admin">
                    <div className="content-img-commande-admin">
                      <img
                        src={getImage(order?.product?.pictures[0]?.image)}
                        alt={order?.product?.name}
                        className="img-commande-detail-admin"
                        loading="lazy"
                      />
                    </div>
                    <p className="nom-commande-detail-admin mb-0">
                      {order?.product?.name}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-2 mb-3 col-detail-commande-admin">
                <div className="content-col-detail-commande-admin">
                  {i === 0 && (
                    <h3 className="title-col-detail-commande-admin">
                      Quantité
                    </h3>
                  )}
                  <p className="nom-commande-detail-admin mb-0 pt-5">
                    {order?.quantity}
                  </p>
                </div>
              </div>
              <div className="col-md-2 mb-3 col-detail-commande-admin">
                <div className="content-col-detail-commande-admin">
                  {i === 0 && (
                    <h3 className="title-col-detail-commande-admin">Prix</h3>
                  )}
                  <p className="nom-commande-detail-admin mb-0 pt-5">
                    {formatCurrency(order?.product?.price)}
                  </p>
                </div>
              </div>
              <div className="col-md-2 mb-3 col-detail-commande-admin">
                <div className="content-col-detail-commande-admin">
                  {i === 0 && (
                    <h3 className="title-col-detail-commande-admin">Total</h3>
                  )}
                  <p className="info-produit-sim-value mb-0 pt-5">
                    {order?.product?.price * order?.quantity}
                    <span style={{ fontWeight: 300 }}>FCFA</span>
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="row mb-2">
          <div className="col-md-6 mb-3 col-left-detail-commande">
            <div className="card-detail-commande-item mb-3">
              <div className="content-col-detail-commande-admin">
                <h3 className="title-col-detail-commande-admin">Acheteur</h3>
                <p className="nom-commande-detail-admin mb-0 pt-3">
                  {item?.user?.prenom + " " + item?.user?.nom}
                </p>
              </div>
            </div>
            <div className="card-detail-commande-item mb-3">
              <div className="content-col-detail-commande-admin">
                <h3 className="title-col-detail-commande-admin">Adresse</h3>
                <p className="nom-commande-detail-admin mb-0 pt-3">
                  {item?.adresse?.adresse}
                </p>
              </div>
            </div>
            <div className="card-detail-commande-item mb-3">
              <div className="content-col-detail-commande-admin">
                <h3 className="title-col-detail-commande-admin">
                  Mode de paiement
                </h3>
                <p className="nom-commande-detail-admin mb-0 pt-3">
                  {item?.paymentMethod?.name}
                </p>
              </div>
            </div>
            <div className="card-detail-commande-item mb-3">
              <div className="content-col-detail-commande-admin">
                <h3 className="title-col-detail-commande-admin">
                  Statut de la commande
                </h3>
                {/* <p className="nom-commande-detail-admin mb-0 pt-3">
                  {item?.status}
                </p> */}
                <div
                  className={`${
                    orderType?.find((el) => el?.value === item?.status)?.class
                  } statut-commande`}
                >
                  {item?.status}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 mb-3 col-right-detail-commande">
            <div className="content-col-right-detail-commande">
              <div className="info-detail-commande-item mb-3">
                <p className="nom-commande-detail-admin mb-0">
                  Prix sous-total
                </p>
                <p className="title-col-detail-commande-admin mb-0">
                  {formatCurrency(item?.subTotal)}
                </p>
              </div>
              <div className="info-detail-commande-item mb-3">
                <p className="nom-commande-detail-admin mb-0">
                  Frais de livraison (+):
                </p>
                <p className="title-col-detail-commande-admin mb-0">
                  {formatCurrency(item?.livraison)}
                </p>
              </div>
              {/* <div className="info-detail-commande-item mb-3">
                <p className="nom-commande-detail-admin mb-0">TVA (+):</p>
                <p className="title-col-detail-commande-admin mb-0">
                  {formatCurrency(item?.tva)}
                </p>
              </div> */}
              <div className="info-detail-commande-item mb-3">
                <p className="title-col-detail-commande-admin mb-0">Total :</p>
                <p className="title-col-detail-commande-admin mb-0">
                  {formatCurrency(item?.totalAmount)}
                </p>
              </div>
            </div>
          </div>
        </div>
        {item?.status === "livré" && !data?._id && !isLoading && (
          <div className="row py-4 b-b-1 mb-3">
            <h3 className="solicitation-title">Évaluez la livraison</h3>

            <div className="btn-solicitation-container">
              <button
                className="btn btn-solicitation btn-theme-outline"
                onClick={() => setShow(true)}
              >
                Donnez votre avis
              </button>
            </div>
            <AvisCommandeModal show={show} setShow={setShow} order={item} />
          </div>
        )}
      </div>
    </div>
  );
};

export default DetailCommandePage;
