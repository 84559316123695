/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useState } from "react";
import FrontHeader from "../../shared/FrontHeader/FrontHeader";
import FooterPage from "../../auth/FooterPage";
import FrontFooter from "../../shared/FrontFooter/FrontFooter";
import Geocode from "react-geocode";
import InfoPersoFinaliserCommande from "./StepsFinaliserCommande/InfoPersoFinaliserCommande";
import AdresseTabs from "./StepsFinaliserCommande/AdresseTabs";
import ModeLivraison from "./StepsFinaliserCommande/ModeLivraison";
import ModePaiement from "./StepsFinaliserCommande/ModePaiement";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { formatCurrency, getAddress } from "../../../utils/Utils";
import { Navigate } from "react-router-dom";
import StepProgressBar from "../../common/StepProgressBar/StepProgressBar";
import OtpValidationModal from "./modal/OtpValidationModal";
import PhoneNumberModal from "./modal/PhoneNumberModal";
import {
  getDeliveryAmount,
  getTotalAmount,
} from "../../../redux/slice/useCartSlice";
import {
  getAddressFromReults,
} from "../../common/GoogleInput";
import { useGetDeliveryModeListQuery } from "../../../utils/api/deliveryMode/deliveryMode.api";
import { DeliveryMode } from "../../../utils/api/deliveryMode/deliveryMode.type";
import { useGetAmountDeliveryMutation } from "../../../utils/api/order/order.api";
// import MultiStepProgressBar from "../../common/StepProgressBar/StepProgressBar";

const FinaliserCommande = () => {
  const dispatch = useAppDispatch();
  let steps: any = [];
  const { user } = useAppSelector((s) => s?.user);
  const { zone, store } = useAppSelector((state) => state.config);
  const [index, setIndex] = useState(0);
  const [deliveryAmount, setDeliveryAmount] = useState({ price: 0, taux: 0 });
  const { data = [], isLoading } = useGetDeliveryModeListQuery({});
  const [deliveryModes, setDeliveryModes] = useState<DeliveryMode[]>(data);
  const [deliveryModesPrice, setDeliveryModesPrice] = useState<any[]>(data);
  const [getAmount] = useGetAmountDeliveryMutation();
  const { subAmount, cartItems, totalAmount, delivery, preOrder } =
    useAppSelector((state) => state.cart);
  const [orderData, setOrderData] = useState<any>({
    modeLivraison: "",
    paymentMethod: "",
    // store: "",
    preOrder: preOrder,
    adresse: getAddress(user)?._id || "",
    orderItems: cartItems?.map((item) => {
      return {
        product: item?.product?._id,
        quantity: item?.qty,
      };
    }) || [
      {
        product: "",
        quantity: 0,
      },
    ],
    user: user?._id || "",
    redirectUrl: "",
    cancelUrl: `${window.location.origin}/valider-commande`,
    userObj: {
      email: user?.email || "",
      prenom: user?.prenom || "",
      nom: user?.nom || "",
      telephone: user?.telephone || "",
      birthDate: user?.birthDate || "",
    },
    adresseObj: {
      prenom: getAddress(user)?.prenom || user?.prenom || "",
      nom: getAddress(user)?.nom || user?.nom || "",
      adresse: getAddress(user)?.adresse || "",
      ville: getAddress(user)?.ville || "",
      latitude: getAddress(user)?.latitude || "",
      longitude: getAddress(user)?.longitude || "",
      complementAdresse: getAddress(user)?.complementAdresse || "",
      quartier: getAddress(user)?.quartier || "",
      telephone1: getAddress(user)?.telephone1 || user?.telephone || "",
      telephone2: getAddress(user)?.telephone2 || "",
      isDefault: getAddress(user)?.isDefault || true,
      zone: zone?._id || getAddress(user)?.zone?._id || "",
    },
    telephone: user?.telephone || "",
  });

  const [position, setPosition] = useState<
    { lat: number; lng: number } | undefined
  >();

  const [hasPermissions, setHasPermissions] = useState(false);

  useEffect(() => {
    navigator.permissions
      .query({ name: "geolocation" })
      .then((permissionStatus) => {
        if (permissionStatus?.state === "granted") {
          setHasPermissions(true);
        } else if (permissionStatus?.state === "denied") {
          setHasPermissions(false);
        }
        permissionStatus.onchange = () => {
          if (permissionStatus?.state === "granted") {
            setHasPermissions(true);
          } else if (permissionStatus?.state === "denied") {
            setHasPermissions(false);
          }
        };
      });
  }, []);

  useEffect(() => {
    if (navigator?.geolocation) {
      navigator.geolocation.watchPosition(
        function (position) {
          // console.log("Latitude is :", position.coords.latitude);
          // console.log("Longitude is :", position.coords.longitude);
          setPosition({
            lat: position?.coords?.latitude,
            lng: position?.coords?.longitude,
          });
        },
        function (error) {
          setPosition(undefined);
          console.error("Error Code = " + error.code + " - " + error.message);
        }
      );
    }

    // console.log(navigator);
  }, [hasPermissions]);

  useEffect(() => {
    let prevState = { ...orderData };
    // prevState["adresseObj"] = data;
    if (position) {
      Geocode.fromLatLng(position?.lat, position?.lng).then(
        (response) => {
          const infos: any = getAddressFromReults(response?.results, "geocode");
          prevState.adresseObj["latitude"] = infos?.latitude;
          prevState.adresseObj["longitude"] = infos?.longitude;
          prevState.adresseObj["ville"] = infos?.ville;
          prevState.adresseObj["adresse"] = infos?.rue;
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }, [position]);
  const onFormSubmitAddress = async (val: any) => {
    let dataCopy = [...deliveryModesPrice];
    // console.log("start", dataCopy);
    const res = await getAmount(val);
    if ("data" in res) {
      if (res?.data?.price) {
        let result = {
          amount: res?.data?.price,
          _id: val?.modeLivraison,
        };
        dataCopy.push(result);
        console.log("res", dataCopy);

        setDeliveryModesPrice((prevState) =>
          Array.from(new Set([...prevState, ...dataCopy]))
        );
      }
    }
  };

  useEffect(() => {
    if (deliveryModesPrice?.length > 1) {
      setDeliveryModes(
        deliveryModes?.map((el) => {
          let item = deliveryModesPrice?.find((d) => d?._id === el?._id);
          return {
            ...el,
            amount: item?.amount || null,
          };
        })
      );
    }
  }, [deliveryModesPrice]);


  useEffect(() => {
    if (data?.length) {
      setDeliveryModes(data);
      if (orderData?.adresseObj?.adresse) {
        data?.forEach((item) => {
          if (item?.type === "paps" || item?.type === "yexpress") {
            let dataSend = {
              orderItems: orderData?.orderItems,
              zone: orderData?.adresseObj?.zone,
              store: store?._id,
              modeLivraison: item?._id,
              recipient: {
                address: orderData?.adresseObj?.adresse,
                latitude: orderData?.adresseObj?.latitude,
                longitude: orderData?.adresseObj?.longitude,
              },
            };
            // console.log("data submit", dataSend);
            onFormSubmitAddress(dataSend);
          }
        });
      }
    }
  }, [data, orderData?.adresseObj]);

  useEffect(() => {
    if (deliveryModes?.length) {
      let item = deliveryModes?.find(
        (el) => el?._id === orderData?.modeLivraison
      );

      if (item && item?.amount) {
        dispatch(getDeliveryAmount(item?.amount));
      } else {
        dispatch(getDeliveryAmount(0));
      }
      dispatch(getTotalAmount());
    }
  }, [deliveryModes, orderData?.modeLivraison]);

  const step1Content = (
    <InfoPersoFinaliserCommande
      setOrderData={setOrderData}
      orderData={orderData}
      setIndex={setIndex}
      index={index}
      // setDeliveryAmount={setDeliveryAmount}
    />
  );
  const step2Content = (
    <AdresseTabs
      setOrderData={setOrderData}
      orderData={orderData}
      setIndex={setIndex}
      index={index}
      setDeliveryAmount={setDeliveryAmount}
    />
  );

  const step3Content = (
    // @ts-ignore
    <ModeLivraison
      setOrderData={setOrderData}
      orderData={orderData}
      setIndex={setIndex}
      data={deliveryModes}
      isLoading={isLoading}
      index={index}
    />
  );
  const step4Content = (
    <ModePaiement
      setOrderData={setOrderData}
      orderData={orderData}
      setIndex={setIndex}
      index={index}
    />
  );

  if (user) {
    steps = [
      {
        label: "Adresse",
        name: "step 2",
        content: step2Content,
        // validator: step2Validator,
      },
      {
        label: "Mode de livraison",
        name: "step 3",
        content: step3Content,
        // validator: step3Validator,
      },
      {
        label: "Mode de paiement",
        name: "step 4",
        content: step4Content,
        // validator: step4Validator,
      },
    ];
  } else {
    steps = [
      {
        label: "Informations personnelles",
        name: "step 1",
        content: step1Content,
        // validator: step1Validator,
      },
      {
        label: "Adresse",
        name: "step 2",
        content: step2Content,
        // validator: step2Validator,
      },
      {
        label: "Mode de livraison",
        name: "step 3",
        content: step3Content,
        // validator: step3Validator,
      },
      {
        label: "Mode de paiement",
        name: "step 4",
        content: step4Content,
        // validator: step4Validator,
      },
    ];
  }

  function onFormSubmit() {}

  useEffect(() => {
    if (!orderData?.modeLivraison) {
      dispatch(getDeliveryAmount(0));
      dispatch(getTotalAmount());
    }
  }, [orderData?.modeLivraison]);

  if (!cartItems?.length) {
    return <Navigate to={"/"} replace />;
  }
  return (
    <div className="landing-page-component">
      <FrontHeader />
      <div className="page-content">
        <div className="container-low-price">
          <div className="content-title-register-page pb-5">
            <h2 className="title-register-page">Valider ma commande</h2>
          </div>
          <div className="row row-register-page mb-5">
            <div className="col-lg-9 col-md-12 col-left-register-page">
              <div className="content-col-right-register-page">
                {/* <MultiStepProgressBar /> */}
                <StepProgressBar
                  startingStep={index}
                  currentStep={index}
                  onSubmit={onFormSubmit}
                  steps={steps}
                  previousBtnName="Précédant"
                  nextBtnName="Suivant"
                  // primaryBtnClass="d-none"
                  // secondaryBtnClass="d-none"
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-12 col-right-finaliser-commande">
              <div className="content-col-right-finaliser-commande">
                <h3 className="title-col-right-panier-page">
                  RÉSUMÉ DU PANIER
                </h3>
                <div className="content-info-produit-panier-page pt-3">
                  {delivery > 0 && (
                    <div className="info-produit-item-panier-page b-b-1">
                      <p className="info-produit-item-libelle mb-0">
                        Frais de livraison
                      </p>
                      <p className="info-produit-item-value mb-0">
                        {formatCurrency(delivery)}
                      </p>
                    </div>
                  )}
                  {/* <div className="info-produit-item-panier-page b-b-1">
                    <p className="info-produit-item-libelle mb-0">Tax (18%)</p>
                    <p className="info-produit-item-value mb-0">
                      {formatCurrency(tax)}
                    </p>
                  </div> */}
                  <div className="info-produit-item-panier-page b-b-1">
                    <p className="info-produit-item-libelle mb-0">Sous-total</p>
                    <p className="info-produit-item-value mb-0">
                      {formatCurrency(subAmount)}
                    </p>
                  </div>
                  <div className="info-produit-item-panier-page">
                    <p
                      className="info-produit-item-libelle-total mb-0"
                      style={{ color: "#E20613" }}
                    >
                      TOTAL
                    </p>
                    <p
                      className="info-produit-item-value-total mb-0"
                      style={{ color: "#E20613" }}
                    >
                      {formatCurrency(totalAmount)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <PhoneNumberModal
              setOrderData={setOrderData}
              orderData={orderData}
            />
            <OtpValidationModal orderData={orderData} />
          </div>
          <FooterPage />
        </div>
      </div>
      <FrontFooter />
    </div>
  );
};

export default FinaliserCommande;
