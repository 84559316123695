import React from "react";
import { AiFillAndroid, AiFillApple } from "react-icons/ai";
import { useGetConfigAppQuery } from "../../../utils/api/config/config.api";

function AppsLink() {
  const { data } = useGetConfigAppQuery();
  return (
    <div className="content-download-app-footer pt-5">
      <p className="title-download-app-footer">
        Téléchargez notre application gratuite !
      </p>
      <div className="content-btn-download-app-footer">
        <div className="content-btn-download-item">
          <a
            className="btn btn-download-app-footer no-link"
            target="_blank"
            href={data?.androidAppUrl || "#"}
          >
            <AiFillAndroid />
            <span className="ps-2">Android</span>
          </a>
        </div>
        <div className="content-btn-download-item">
          <a
            className="btn btn-download-app-footer no-link"
            target="_blank"
            href={data?.iosAppUrl || "#"}
          >
            <AiFillApple />
            <span className="ps-2">Apple</span>
          </a>
        </div>
      </div>
    </div>
  );
}

export default AppsLink;
