import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiBaseUrl } from "../../http";
import { QueryUrl, useLocationState } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import {
  ConfirmTransactionFormData,
  DeliveryAmount,
  DeliveryAmountFormData,
  Order,
  OrderFormData,
  RateOrder,
  RateOrderFormData,
} from "./order.type";

export const OrderApi = createApi({
  reducerPath: "orderApi",
  tagTypes: [
    "order",
    "orderList",
    "orderByVisitor",
    "orderById",
    "allOrderList",
    "rateOrder",
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getListOrderAdmin: builder.query<PaginationResults<Order>, TypeQuery>({
      providesTags: ["orderList"],
      query: (query) => QueryUrl("order/", query),
    }),
    getOrderList: builder.query<PaginationResults<Order>, TypeQuery>({
      providesTags: ["order"],
      query: (query) => QueryUrl("order/me/", query),
    }),
    addOrEditOrder: builder.mutation<
      Order | any,
      { id?: string; data: OrderFormData | FormData }
    >({
      query: ({ id, data }) => {
        if (id) {
          return {
            url: `order/${id}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `order/`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["orderList", "orderByVisitor", "order"],
    }),
    deleteOrder: builder.mutation<Order, string>({
      query: (id) => ({
        url: `order/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["orderList", "orderByVisitor", "order"],
    }),
    OrderById: builder.query<Order | any, string>({
      query: (id) => `order/${id}/`,
      providesTags: ["orderById"],
    }),
    OrderByTransactionID: builder.query<Order | any, string>({
      query: (id) => `order/order-by-transaction/${id}/`,
      providesTags: ["orderById"],
    }),
    supportOrder: builder.mutation<Order, string>({
      query: (id) => ({
        url: `order/support/${id}/`,
        method: "GET",
      }),
      invalidatesTags: ["order", "orderById", "orderList", "allOrderList"],
    }),
    getAmountDelivery: builder.mutation<DeliveryAmount, DeliveryAmountFormData>(
      {
        query: (data) => ({
          url: `order/delivary-fees/`,
          method: "POST",
          body: data,
        }),
      }
    ),
    confirmTransaction: builder.mutation<any, ConfirmTransactionFormData>({
      query: (data) => ({
        url: `order/kpay/confirm-payment/`,
        method: "POST",
        body: data,
      }),
    }),
    codeByTransactionID: builder.query<Order | any, string>({
      query: (id) => `order/kpay/resend-otp/${id}/`,
      // providesTags: ["orderById"],
    }),
    addOrEditRateOrder: builder.mutation<RateOrder, RateOrderFormData>({
      query: ({ _id, ...data }) => {
        if (_id) {
          return {
            url: `rate-order/${_id}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `rate-order/`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["rateOrder"],
    }),
    getRateOrder: builder.query<RateOrder, TypeQuery>({
      providesTags: ["rateOrder"],
      query: ({slug,...query}) => QueryUrl(`rate-order/order/${slug}/` , query),
    }),
  }),
});

export const {
  useAddOrEditOrderMutation,
  useGetListOrderAdminQuery,
  useLazyOrderByIdQuery,
  useGetOrderListQuery,
  useSupportOrderMutation,
  useLazyOrderByTransactionIDQuery,
  useGetAmountDeliveryMutation,
  useConfirmTransactionMutation,
  useLazyCodeByTransactionIDQuery,
  useLazyGetListOrderAdminQuery,
  useGetRateOrderQuery,
  useAddOrEditRateOrderMutation
} = OrderApi;

export function useOrderFromLocation(): [
  Order,
  boolean,
  string,
  (id: string) => any
] {
  const itemState = useLocationState<Order | undefined>(undefined);
  const [item, setItem] = useState(itemState);
  const { id } = useParams<{ id: string }>();
  const [findById, { data, isError, isLoading }] = useLazyOrderByIdQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (id || itemState?._id) {
      findById((id as string) || itemState?._id as string);
    }
  }, [id, itemState]);
  useEffect(() => {
    if (isError && !itemState) {
      navigate(-1);
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
      console.log("data", data);
      setItem(data);
    }
  }, [data]);

  return [item as Order, isLoading, id as string, findById];
}
