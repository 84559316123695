import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { Order, RateOrderFormData } from "../../../../utils/api/order/order.type";
import { useAddOrEditRateOrderMutation } from "../../../../utils/api/order/order.api";
import { Color } from "../../../../utils/theme";
import { cleannerError } from "../../../../utils/Utils";

function UseAddAvisCommandeForm(order: Order) {
  const validationSchema = yup.object().shape({
    stars: yup
      .number()
      .required()
      .label("La note")
      .nullable()
      .transform((value) => (isNaN(value) ? null : value)),
    comment: yup.string().nullable().label("Le commentaire"),
    annonyme: yup.boolean().default(false).nullable(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
  } = useForm<RateOrderFormData>({
    resolver: yupResolver(validationSchema),
  });
  const [sendData, { isLoading, isSuccess, error, isError }] =
    useAddOrEditRateOrderMutation();
  const [note, setNote] = useState(0);

  const handleChangeNote = (nextValue) => {
    setNote(nextValue);
    setValue("stars", nextValue);
  };

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        iconColor: Color.success,
        confirmButtonColor: Color.success,
        title: "Votre avis a été envoyé avec succès !",
        showConfirmButton: false,
        timer: 3000,
      });
    }
    const err = error as any;

    if (isError) {
      console.log("error", err);

      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Une erreur de statut ${err?.status} est survenue`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [error, isError, isSuccess, isLoading]);

  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [clearErrors, errors]);

  const handleReset = () => {
    setNote(0);
    reset();
  };

  const onSubmit = async (data: RateOrderFormData) => {
    data["order"] = order?._id;
    console.log("data", data);
    await sendData(data);
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    handleChangeNote,
    note,
    isSuccess,
    handleReset,
  };
}

export default UseAddAvisCommandeForm;
