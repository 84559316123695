import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import PhoneInput from "react-phone-input-2";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  cleannerError,
  showModal,
  validatePhone,
} from "../../utils/Utils";
import { ResendCode } from "../../utils/api/auth/auth.type";
import ErrorMessage from "../common/ErrorMessage";
import { useResendCodeMutation } from "../../utils/api/auth/auth.api";
import { Color } from "../../utils/theme";
import ConfirmPhoneNumber from "./ConfirmPhoneNumber";

function PhoneModal({
  setModalConnexion,
}: {
  setModalConnexion: Dispatch<SetStateAction<boolean>>;
}) {
  const [code, setCode] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const validationSchema = yup.object().shape({
    telephone: validatePhone(yup, code).required(),
  });

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm<ResendCode>({
    resolver: yupResolver(validationSchema),
  });
  const [sendCode, { isLoading, isSuccess, isError, error }] =
    useResendCodeMutation();
  const handleChangePhone = (item, country) => {
    setCode(country?.countryCode);
    setPhone("+" + item);
    if (parseInt(item) !== parseInt(country?.dialCode)) {
      setValue("telephone", item);
    } else {
      setValue("telephone", "");
    }
  };

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: "Code envoyé avec succès!",
        text: "Un code de confirmation vous a été envoyé pour valider votre compte.",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 5000,
      }).then(() => {
        // closeModal("phoneModal");
        console.log("here")
        showModal("phoneValidationModal");
        
      });
    }
    const err = error as any;

    if (isError) {
      console.log("error", err);

      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Une erreur de statut ${err?.status} est survenue lors de l'envoie`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [error, isError, isSuccess]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [clearErrors, errors]);

  const onSubmit = async (data) => {
    await sendCode(data);
  };
  return (
    <div
      className="modal fade"
      id="phoneModal"
      tabIndex={-1}
      //   data-bs-backdrop="static"
      aria-labelledby="phoneModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body">
            <div className="d-flex justify-content-center align-items-center">
              {" "}
              <div className="position-relative">
                {" "}
                <div className="card cart-otp p-2 text-center">
                  {" "}
                  <h6>Veuillez entrer votre numéro de téléphone.</h6>{" "}
                  <div className="mb-4">
                    {" "}
                    <span>Un code vous sera envoyé pour validation</span>{" "}
                  </div>{" "}
                  <form onSubmit={handleSubmit(onSubmit)} className="w-100">
                    <PhoneInput
                      inputProps={{
                        name: "phone",
                      }}
                      defaultMask={".. ... .. .."}
                      country="sn"
                      value={phone}
                      enableSearch={true}
                      onChange={(phone, country) =>
                        handleChangePhone(phone, country)
                      }
                      inputClass="form-control-register-tabs-tel"
                      enableAreaCodes={true}
                      prefix="+"
                      countryCodeEditable={false}
                    />
                    <ErrorMessage
                      message={errors?.telephone && errors?.telephone?.message}
                    />
                    <div className="mt-4 mb-3">
                      {" "}
                      <button
                        className="btn px-4 py-2 validate"
                        type="submit"
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <>
                            <span
                              className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                              role="status"
                            ></span>
                            <span>Envoie...</span>
                          </>
                        ) : (
                          "Envoyer"
                        )}
                      </button>{" "}
                    </div>{" "}
                  </form>
                  <ConfirmPhoneNumber
                    phone={phone}
                    setModalConnexion={setModalConnexion}
                  />
                </div>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PhoneModal;
