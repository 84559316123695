import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "./UsersTable.css";
import TableSkeleton from "../../../common/TableSkeleton";
import { AiFillEye } from "react-icons/ai";
import { BsTrashFill } from "react-icons/bs";
import { NavLink } from "react-router-dom";
import { useGetListAdminUserQuery } from "../../../../utils/api/user/user.api";
import { IUser } from "../../../../utils/api/user/user.type";
import { getAvatar } from "../../../../utils/Utils";
import { CustomPagination } from "../../GestionProduits/ProductsTable/ProductsTable";
import { UseBlockUser, UseDeleteUser } from "../requestUserForm/UseCrudUserForm";
import { MdDeleteForever } from "react-icons/md";

export function DeleteUserButton({ item }: { item: IUser }) {
  const onDelete = UseDeleteUser(item);

  return (
    <button
      className="btn with-tooltip btn-action-icon-delete"
      data-tooltip-content={item?.deleted ? "Désarchiver" : "Archiver"}
      onClick={() => onDelete()}
      style={{ color: "#E53E3E" }}
    >
      {item?.deleted ? <MdDeleteForever /> : <BsTrashFill />}
    </button>
  );
}

export function BlockUserButton({ item }: { item: IUser }) {
  const onBlock = UseBlockUser(item);

  return (
    <button
      className={`${
        item?.isActive ? "statut-livre" : "statut-en-cours-de-prepation"
      } statut-commande btn with-tooltip`}
      onClick={() => onBlock()}
      data-tooltip-content={item?.isActive ? "Désactiver" : "Activer"}
    >
      {item?.isActive ? "Activé" : "Désactivé"}
    </button>
  );
}

function UsersTable({ name }: { name: string }) {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const { data = { results: [], nbPage: 0 }, isLoading } =
    useGetListAdminUserQuery({
      page,
      userType: "user",
      search: name,
    });

  const actionFormatter = (cell: string, row: IUser) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex gap-3">
          <div className="container-btn-action-icon-modal">
            <NavLink
              to={`/admin/utilisateur/${cell}`}
              state={row}
              className="btn btn-action-filter-icon with-tooltip"
              data-tooltip-content="Voir"
            >
              <AiFillEye />
            </NavLink>
          </div>

          <div className="container-btn-action-icon-modal">
            <DeleteUserButton item={row} />
          </div>
        </div>
      </>
    );
  };

  const nameFormatter = (cell: string, row: IUser) => {
    return (
      <div className="table-actions-btn-container-img gap-2">
        <div className="content-img-user-table">
          <img
            src={getAvatar(row?.avatar)}
            alt="Produit"
            className="img-user-table"
            loading="lazy"
          />
        </div>
        <p className="text-nom-produit-table mb-0">
          {row?.prenom + " " + cell}
        </p>
      </div>
    );
  };

  const statutFormatter = (cell: boolean, row: IUser) => {
    return (
      <>
        <BlockUserButton item={row} />
      </>
    );
  };

  const columns = [
    {
      dataField: "nom",
      text: "Pseudo",
      formatter: (cell: string, row: IUser) => nameFormatter(cell, row),
    },
    {
      dataField: "email",
      text: "Email",
    },
    {
      dataField: "telephone",
      text: "Téléphone",
    },
    {
      dataField: "status",
      text: "Statut",
      formatter: (cell: boolean, row: IUser) => statutFormatter(cell, row),
    },
    {
      dataField: "_id",
      text: "Actions",
      formatter: (cell: string, row: IUser) => actionFormatter(cell, row),
    },
  ];

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="_id"
            data={data?.results}
            columns={columns}
            striped
            condensed
            hover
            responsive={true}
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => "Aucune donnée trouvée"}
            // pagination={paginationFactory({ sizePerPage: 10 })}
          />
          <CustomPagination
            nbPages={data?.nbPage}
            page={page}
            onChange={(page, perPage) => {
              setPerPage(perPage);
              setPage(page);
            }}
            perPage={perPage}
          />
        </>
      )}
    </>
  );
}

export default UsersTable;
