/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from "@hookform/resolvers/yup";
import { ChangeEvent, useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useAddOrEditPackMutation } from "../../../../utils/api/product/product.api";
import {
  Product,
  ProductFormData,
} from "../../../../utils/api/product/product.type";
import { Color } from "../../../../utils/theme";
import { cleannerError } from "../../../../utils/Utils";

function UseCrudPackForm(product?: Product) {
  const validationSchema = yup.object().shape({
    hasEdit: yup.boolean(),
    name: yup.string().required().label("Le nom"),
    description: yup.string().required().label("La description"),
    price: yup.string().required().label("Le prix"),
    productId: yup
      .string()
      .required()
      .label("L'id du produit sur léo2")
      .nullable(),
    isActive: yup.boolean().label("Le statut").nullable().default(false),
    pictures: yup.mixed().when("hasEdit", {
      is: false,
      then: () =>
        yup
          .mixed()
          .required("Veuillez uploader au moins une image")
          .label("Image")
          .nullable(),
    }),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    control,
    resetField,
  } = useForm<ProductFormData>({
    resolver: yupResolver(validationSchema),
  });
  const { fields } = useFieldArray({
    control,
    name: "stocks",
  });
  const [sendData, { isLoading, isSuccess, error, isError }] =
    useAddOrEditPackMutation();
  const navigate = useNavigate();
  const [description, setDescription] = useState("");
  const [images, setImages] = useState<any>([]);
  const [status, setStatus] = useState(true);

  useEffect(() => {
    setValue("isActive", status);
  }, [status]);
  useEffect(() => {
    if (images?.length) {
      setValue(
        "pictures",
        images?.filter((s) => !s?._id)
      );
    } else {
      resetField("pictures");
    }
  }, [images]);

  const onChangeDescription = (value) => {
    setValue("description", value);
    setDescription(value);
  };

  const handleChangeImage = (e: any) => {
    const file = e.target.files;
    if (file && file?.length > 0) {
      setImages(URL.createObjectURL(file));
      setValue("pictures", file);
    }
  };

  useEffect(() => {
    if (product?._id) {
      setValue("hasEdit", true);
      const fields: (keyof ProductFormData)[] = [
        "_id",
        "name",
        "description",
        "isActive",
        "price",
        // "height",
        // "length",
        // "weight",
        // "width",
        "stockDisponible",
        "productId",
      ];
      for (let field of fields) {
        register(field);
        setValue(field, product[field]);
      }
      setStatus(product?.isActive);

      if (product?.description) {
        setDescription(product?.description);
      }

      if (product?.pictures?.length) {
        setImages(product?.pictures);
      }
    } else {
      setValue("hasEdit", false);
    }
  }, [product]);

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        iconColor: Color.success,
        confirmButtonColor: Color.success,
        title: product
          ? "Pack modifié avec succès !"
          : "Pack ajouté avec succès !",
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {
        navigate("/admin/produits", { state: { step: 3 } });
      });
    }
    const err = error as any;

    if (isError) {
      console.log("error", err);

      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Une erreur de statut ${err?.status} est survenue`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [error, isError, isSuccess, isLoading]);

  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [clearErrors, errors]);

  const handleChangeStatus = (e: ChangeEvent<HTMLInputElement>) => {
    setStatus(e.target.checked);
    console.log(e.target.checked);
  };

  const onSubmit = async (data: ProductFormData) => {
    const fd = new FormData();
    for (let key of Object.keys(data)) {
      if (key === "pictures") {
        if (data[key] && data[key]?.length) {
          data[key]?.forEach((item) => {
            fd.append(key, item);
          });
        }
      } else {
        fd.append(key, data[key]);
      }
    }
    // console.log("data", data);
    await sendData({ id: data?._id, data: fd });
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    handleChangeImage,
    images,
    handleChangeStatus,
    setImages,
    description,
    onChangeDescription,
    status,
    fields,
  };
}

export default UseCrudPackForm;
