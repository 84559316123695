import React, { useState } from "react";
import Logo from "../../assets/appImages/logo.png";
import ErrorMessage from "../common/ErrorMessage";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { UseResetPasswordForm } from "./requestForm/UseResetPasswordForm";
import ConnexionModal from "../shared/FrontHeader/modal/ConnexionModal";

function ResetPassword() {
  const [openModal, setOpenModal] = useState(false)
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const onOpenModal = () => {
    setOpenModal(true)
  }

  const {
    register,
    errors,
    onSubmit,
    isLoading,
    passwordHandleChange,
    haveMinCharacter,
    haveMinLowercase,
    haveMinNumber,
    haveMinSpecialCharacter,
    haveMinUppercase,
  } = UseResetPasswordForm(onOpenModal);
  return (
    <div className="container-page-login-app">
      <div className="auth-row">
        <div className="auth-col auth-right-side-col">
          <div className="auth-right-side-container">
            <div className="auth-form-container text-center pb-4 w-100">
              <div className="content-logo-auth pb-2 pt-5">
                <img
                  src={Logo}
                  alt="logo"
                  className="logo-auth-page"
                  loading="lazy"
                />
              </div>
              <div className="text-center pb-4">
                <h2 className="title-login-modal">
                  Réinitialiser votre mot de passe
                </h2>
                <div className="content-text-create-account">
                  <span className="text-resgister-modal-login">
                    Veuillez renseigner votre nouveau mot de passe
                  </span>
                </div>
              </div>
              <form className="mt-4" onSubmit={onSubmit}>
                <div className="row row-add-student">
                  <div className="col-md-12 col-form-add-student mb-3">
                    <div className="content-col-form-login-modal text-start">
                      <label className="form-label form-label-login-modal">
                        code
                      </label>
                      <input
                        className="form-control form-control-login-modal"
                        type="number"
                        placeholder="Entrer le code"
                        {...register("code")}
                      />
                      <ErrorMessage
                        message={errors?.code && errors?.code?.message}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 col-form-add-student mb-3">
                    <div className="content-col-form-login-modal text-start position-relative">
                      <label className="form-label form-label-login-modal">
                        Nouveau mot de passe
                      </label>
                      <input
                        className="form-control form-control-login-modal"
                        type={showPassword ? "text" : "password"}
                        placeholder="Mot de passe"
                        onChange={(e) => passwordHandleChange(e)}
                      />
                      <span
                        className="show-hide-password-modal"
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                      >
                        {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                      </span>
                    </div>
                    <ErrorMessage
                      message={errors?.password && errors?.password?.message}
                    />
                  </div>
                  <div className="col-md-12 col-form-add-student mb-3">
                    <div className="content-col-form-login-modal text-start position-relative">
                      <label className="form-label form-label-login-modal">
                        Nouveau mot de passe
                      </label>
                      <input
                        className="form-control form-control-login-modal"
                        type={showConfirmPassword ? "text" : "password"}
                        placeholder="Mot de passe"
                        {...register("confirm_password")}
                      />
                      <span
                        className="show-hide-password-modal"
                        onClick={() => {
                          setShowConfirmPassword(!showConfirmPassword);
                        }}
                      >
                        {showConfirmPassword ? (
                          <AiFillEyeInvisible />
                        ) : (
                          <AiFillEye />
                        )}
                      </span>
                    </div>
                    <ErrorMessage
                      message={
                        errors?.confirm_password &&
                        errors?.confirm_password?.message
                      }
                    />
                  </div>
                  <div className="col-md-12">
                    <div className="input-group my-2 password-validation-terms-row">
                      <div className="password-validation-terms-container">
                        <h4 className="password-validation-terms-title">
                          Votre mot de passe doit contenir :{" "}
                        </h4>
                        <ul className="password-validation-terms-ul p-l-14 text-start">
                          <li
                            className={
                              "password-rule-item " +
                              (!!haveMinCharacter && "text-success")
                            }
                          >
                            Au minimum 8 caractères
                          </li>
                          <li
                            className={
                              "password-rule-item " +
                              (!!haveMinUppercase && "text-success")
                            }
                          >
                            Au minimum 1 majuscule
                          </li>
                          <li
                            className={
                              "password-rule-item " +
                              (!!haveMinLowercase && "text-success")
                            }
                          >
                            Au minimum 1 minuscule
                          </li>
                          <li
                            className={
                              "password-rule-item " +
                              (!!haveMinNumber && "text-success")
                            }
                          >
                            Au minimum 1 chiffre
                          </li>
                          <li
                            className={
                              "password-rule-item " +
                              (!!haveMinSpecialCharacter && "text-success")
                            }
                          >
                            Au minimum 1 caractère spécial
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 pt-3">
                    <button
                      type="submit"
                      className="btn btn-theme w-100"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                            role="status"
                          ></span>
                          <span>Enregistrement...</span>
                        </>
                      ) : (
                        "Enregistrer"
                      )}
                    </button>
                  </div>
                </div>
              </form>
              <ConnexionModal
                modalConnexion={openModal}
                setModalConnexion={setOpenModal}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
