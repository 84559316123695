import React, { useState } from "react";
import { BiEdit, BiMessageSquareAdd } from "react-icons/bi";
import { BsTrashFill } from "react-icons/bs";
import { useGetListCategoryQuery } from "../../../../utils/api/category/category.api";
import { getImage } from "../../../../utils/Utils";
import AddCategoryModal from "../modal/AddCategoryModal";
import { UseDeleteCategory } from "../requestForm/UseCrudCategoriesForm";
import Skeleton from "react-loading-skeleton";
import Pagination from "../../../common/Pagination";
import { AlertInfo } from "../../../common/Alert";

const CardSkeleton = () => {
  return (
    <>
      {Array(24)
        .fill(24)
        .map((shopCard, i) => (
          <div className="col-md-3 mb-3" key={i}>
            <div className="content-category-product-admin-item w-100">
              <Skeleton height={100} width={220} />
            </div>
          </div>
        ))}
    </>
  );
};

function DeleteCategoryButton({ id, type }: { id: string; type: string }) {
  const onDelete = UseDeleteCategory(id, type);

  return (
    <button
      className="btn with-tooltip btn-action-icon-category-delete"
      data-tooltip-content="Supprimer"
      onClick={() => onDelete()}
    >
      <BsTrashFill />
    </button>
  );
}
const CategoryTabs = () => {
  const [page, setPage] = useState(1);
  const { data = { results: [], count: 0, nbPage: 0 }, isLoading } =
    useGetListCategoryQuery({ limit: 24, page });

  const handleChangePage = (selected) => {
    setPage(selected);
    console.log("page", selected);
  };
  return (
    <div className="admin-tabs-component">
      <div className="content-title flex-r mb-4">
        <button
          className="btn btn-add-admin"
          data-bs-toggle="modal"
          data-bs-target="#addCategoryModal"
        >
          <BiMessageSquareAdd />
          <span className="ps-2">Ajouter une catégorie</span>
        </button>
        <AddCategoryModal isSub={false} modalId={"addCategoryModal"} />
      </div>
      <div className="content-category-product-admin mb-5 row">
        {!!isLoading && <CardSkeleton />}

        {!isLoading &&
          (data?.results?.length ? (
            data?.results?.map((item) => (
              <div className="col-md-3 mb-3">
                <div
                  className="content-category-product-admin-item px-1 py-3"
                  key={item?._id}
                  style={{
                    backgroundImage: `url(${getImage(
                      item?.image ||
                        `https://ui-avatars.com/api/?name=${item?.name}`
                    )})`,
                    // height: 74,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "50%",
                  }}
                >
                  <div className="category-content">
                    <p className="name-category-product-admin-item mb-4">
                      {item?.name}{" "}
                    </p>
                    <div className="table-actions-btn-container-commandes d-flex gap-3 justify-content-center">
                      <div className="container-btn-action-icon-category">
                        <button
                          className="btn with-tooltip btn-action-icon-category-edit"
                          data-tooltip-content="Modifier"
                          data-bs-toggle="modal"
                          data-bs-target={`#editCategoryModal${item?._id}`}
                        >
                          <BiEdit />
                        </button>
                      </div>
                      <div className="container-btn-action-icon-category">
                        <DeleteCategoryButton
                          type={"catégorie"}
                          id={item?._id}
                        />
                      </div>
                    </div>
                  </div>
                  <AddCategoryModal
                    isSub={false}
                    category={item}
                    modalId={`editCategoryModal${item?._id}`}
                  />
                </div>
              </div>
            ))
          ) : (
            <AlertInfo message="Aucune catégorie disponible pour le moment !" />
          ))}
      </div>
      <div className="d-flex justify-content-center">
        {data?.nbPage && data?.nbPage > 0 ? (
          <Pagination
            page={page}
            total={data?.nbPage}
            onPageChange={handleChangePage}
          />
        ) : null}
      </div>
    </div>
  );
};

export default CategoryTabs;
