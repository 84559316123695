import React from "react";

const PointFidelitePage = () => {
  return (
    <div className="form-register-tabs-component">
      <div className="content-title-form-register pb-4">
        <h3 className="title-form-register">Mes points de fidélité</h3>
      </div>
      <div className="content-panier-page mt-5">
        <div className="row row-panier-page">
          <div className="col-md-4 col-point-de-fidelite-page mb-3">
            <div className="card-point-de-fidelite">
              <p className="text-point-de-fidelite-libelle">Total des points</p>
              <p className="text-point-de-fidelite-value">56</p>
            </div>
          </div>
          <div className="col-md-4 col-point-de-fidelite-page mb-3">
            <div className="card-point-de-fidelite">
              <p className="text-point-de-fidelite-libelle">Points utiliés</p>
              <p className="text-point-de-fidelite-value">126</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PointFidelitePage;
