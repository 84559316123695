import React from "react";
import { BiMessageSquareAdd } from "react-icons/bi";
import { NavLink } from "react-router-dom";
import PacksTable from "../PacksTable/PacksTable";

const PacksTabs = () => {
  const [name, setName] = React.useState("");
  return (
    <div className="admin-tabs-component">
      <div className="container-title-content-page mb-4">
        <div className="tab-dash-admin-filter-container">
          <form className="row g-3 tab-dash-admin-filter-form">
            <div className="col-auto">
              <label
                htmlFor="filtreProduit"
                className="tab-dash-admin-filter-label"
              >
                Filtrer
              </label>
            </div>
            <div className="col-auto">
              <input
                type="text"
                className="form-control tab-dash-admin-filter-input"
                id="filtreProduit"
                placeholder="Entrer un nom"
                value={name}
                onChange={(e) => setName(e?.target?.value)}
              />
            </div>
          </form>
        </div>

        <div className="content-title">
          <NavLink to="/admin/ajouter-pack" className="btn btn-add-admin">
            <BiMessageSquareAdd />
            <span className="ps-2">Ajouter un pack</span>
          </NavLink>
        </div>
      </div>
      <div className="admin-table">
        <PacksTable name={name} />
      </div>
    </div>
  );
};

export default PacksTabs;
