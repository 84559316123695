import React from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { usePromotionFromLocation } from "../../../../utils/api/promotion/promotion.api";
import { FormError, InputAdmin } from "../../../common/Input";
import UseCrudPromotionForm from "../requestForm/UseCrudPromotionForm";

function AddOrEditPromotion() {
  const [item] = usePromotionFromLocation();
  const {
    register,
    errors,
    onSubmit,
    isLoading,
    handleChangeImage,
    image,
    handleChangeStatus,
    status,
  } = UseCrudPromotionForm(item);
  const navigate = useNavigate();
  return (
    <div className="content-graph-admin">
      <div className="container-title-content-page mb-4">
        <div
          className="content-title-page-admin"
          onClick={() => navigate(-1)}
          style={{ cursor: "pointer" }}
        >
          <h2 className="title-page-admin">
            <AiOutlineArrowLeft style={{ color: "#E20613" }} />
            <span className="ps-2">
              {!item ? "Ajouter" : "Modifier"} une promotion
            </span>
          </h2>
        </div>
      </div>
      <div className="container-form-add-product-admin mt-5">
        <form onSubmit={onSubmit}>
          <div className="row row-add-product-admin">
            <div className="col-md-6 col-add-product-admin mb-3">
              <InputAdmin
                label="Titre de la promotion"
                type={"text"}
                placeholder="Titre de la promotion"
                {...register("title")}
                error={errors?.title?.message}
              />
            </div>
            <div className="col-md-6 col-add-product-admin mb-3">
              <InputAdmin
                label="Taux de réduction"
                type={"number"}
                step={"any"}
                min={1}
                placeholder="Taux de réduction (%)"
                {...register("taux")}
                error={errors?.taux?.message}
              />
            </div>
            <div className="col-md-6 col-add-product-admin mb-3">
              <InputAdmin
                label="Photo"
                type={"file"}
                accept="image/*"
                onChange={(e) => handleChangeImage(e)}
                error={errors?.image?.message}
              />
              {!!image && (
                <div>
                  <div className="product-img-card">
                    <img
                      src={image}
                      alt="category-img"
                      style={{
                        width: "100%",
                        height: "15vw",
                        objectFit: "contain",
                      }}
                      loading="lazy"
                    />
                  </div>
                </div>
              )}
            </div>
            {/* <div className="col-md-6 col-add-product-admin mb-3">
              <InputAdmin
                label="Bande vertical"
                type={"file"}
                accept="image/*"
                onChange={(e) => handleChangeBande(e)}
                error={errors?.banner?.message}
                hasSmallText
                smallText="Dimensions : 250 × 746"
              />
              {!!bande && (
                <div>
                  <div className="product-img-card">
                    <img
                      src={bande}
                      alt="category-img"
                      style={{
                        width: "100%",
                        height: "15vw",
                        objectFit: "contain",
                      }}
                      loading="lazy"
                    />
                  </div>
                </div>
              )}
            </div> */}

            <div className="col-md-6 col-add-product-admin mb-3">
              <div className="mb-3">
                <label className="form-label form-label-admin">
                  Statut de la promotion
                </label>
                <div className="switch2">
                  <label className="switch">
                    <input
                      type="checkbox"
                      onChange={(e) => handleChangeStatus(e)}
                      checked={status}
                    />
                    <span className="slider"></span>
                  </label>
                </div>
                {<FormError error={errors?.active?.message} />}
              </div>
            </div>

            <div className="col-md-3 offset-md-9 col-add-product-admin mb-3">
              <div className="mb-3">
                <button
                  className="btn btn-submit-form-add-product-admin w-100"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                        role="status"
                      ></span>
                      <span>En cours...</span>
                    </>
                  ) : item ? (
                    "Modifier"
                  ) : (
                    "Ajouter"
                  )}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddOrEditPromotion;
