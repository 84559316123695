import React from "react";
import Swal from "sweetalert2";
import { Color } from "../../../utils/theme";
import Magasin from "../../../assets/icons/magasin.png";
import Home from "../../../assets/icons/domicile.png";
import Skeleton from "react-loading-skeleton";
import {
  useAddOrEditDeliveryModeMutation,
  useGetListDeliveryModeAdminQuery,
} from "../../../utils/api/deliveryMode/deliveryMode.api";
import { DeliveryMode } from "../../../utils/api/deliveryMode/deliveryMode.type";

const getLogo = (type: "sursite" | "domicile" | "paps" | "yexpress") => {
  if (type === "sursite") {
    return Magasin;
  }

  return Home;
};

const CardSkeleton = () => {
  return (
    <>
      {Array(2)
        .fill(2)
        .map((shopCard, i) => (
          <div className="mode-paiement-admin-item mb-3" key={i}>
            <div className="content-img-mode-paiement-admin d-flex align-items-center">
              <Skeleton
                className="img-mode-paiement-admin"
                style={{ marginRight: "1rem" }}
              />
              <Skeleton
                className="title-text-left-adreese-admin mb-0"
                width={200}
                height={15}
              />
            </div>
            <div>
              <div className="switch2">
                <label className="switch">
                  <Skeleton width={65} height={23} />
                </label>
              </div>
            </div>
          </div>
        ))}
    </>
  );
};
function GestionModeLivraison() {
  const { data, isLoading } = useGetListDeliveryModeAdminQuery({});

  const [sendData] = useAddOrEditDeliveryModeMutation();
  const onToggle = async (item: DeliveryMode) => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir ${
        item?.isActive ? "désactiver" : "activer"
      } ce mode de livraison  ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return sendData({
          id: item?._id,
          data: {
            isActive: !item?.isActive,
          },
        });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      console.log(result, "archiver");
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `Le mode de livraison a été ${
              item?.isActive ? "désactivé" : "activé"
            } avec succès!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue lors de l'ajout`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };
  return (
    <div className="content-graph-admin">
      <div className="dashbord-admin-component admidashboard-bg mb-3">
        <div className="dash-admin-page-content-container mb-3">
          <div className="container-title-content-page mb-4">
            <div className="content-title">
              <h2 className="title-admin">Mode de livraison</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="admin-table mb-3">
        {!!isLoading && <CardSkeleton />}
        {!isLoading &&
          data?.map((item) => (
            <div className="mode-paiement-admin-item mb-3" key={item?._id}>
              <div className="content-img-mode-paiement-admin d-flex align-items-center">
                <img
                  src={getLogo(item?.type)}
                  alt={item?.name}
                  className="img-mode-paiement-admin"
                  style={{ marginRight: "1rem" }}
                  loading="lazy"
                />
                <p className="title-text-left-adreese-admin mb-0">
                  {" "}
                  {item?.name}{" "}
                </p>
              </div>
              <div>
                <div className="switch2">
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={item?.isActive}
                      onClick={() => onToggle(item)}
                    />
                    <span className="slider"></span>
                  </label>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default GestionModeLivraison;
