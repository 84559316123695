import React from "react";
import { BsTrashFill } from "react-icons/bs";
import { MdModeEditOutline } from "react-icons/md";
import { NavLink } from "react-router-dom";
import { useGetbannerListQuery } from "../../../../utils/api/banner/banner.api";
import { FaImage } from "react-icons/fa";
import { Banner } from "../../../../utils/api/banner/banner.type";
import { AlertInfo } from "../../../common/Alert";
import { getImage } from "../../../../utils/Utils";
import Pagination from "../../../common/Pagination";
import { PageList } from "../AddOrEditBanner";
import Skeleton from "react-loading-skeleton";
import {
  UseDeleteBanner,
  UseToggleBanner,
} from "../request/UseAddOrEditBanner";

export const getPageName = (el: string) => {
  let name = "";
  let page = PageList?.find((v) => v?.value === el);
  if (page) {
    name = page?.label;
  }

  return name;
};

const CardSkeleton = () => {
  return (
    <>
      {[...Array(6)]?.map((el, i) => (
        <div className="col-lg-6 col-md-6 mb-3" key={i}>
          <div className="container-banner-admin mb-4">
            <Skeleton className="title-banner-admin mb-3" width={`30%`} />
            <div className="content-banner-admin-item">
              <Skeleton className="title-banner-admin" width={`30%`} />
              <div className="content-img-banner-admin mb-4">
                <Skeleton className="img-banner-admin" />
              </div>

              <Skeleton className="title-banner-admin mb-3" width={`30%`} />
              <div className="content-text-banner-admin mb-4">
                <Skeleton className="text-banner-admin pt-1" />
              </div>
              <div className="content-btn-edit-banner-admin flex-r">
                <Skeleton className="btn btn-action-modal-icon with-tooltip mx-2" />
                <Skeleton
                  className="btn with-tooltip btn-action-icon-delete"
                  data-tooltip-content="Supprimer"
                  style={{ color: "#E53E3E" }}
                />
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

function DeleteBannerBtn({ id }: { id: string }) {
  const onDelete = UseDeleteBanner(id);

  return (
    <button
      className="btn with-tooltip btn-action-icon-delete"
      data-tooltip-content="Supprimer"
      onClick={() => onDelete()}
      style={{ color: "#E53E3E" }}
    >
      <BsTrashFill />
    </button>
  );
}
function ToggleBannerBtn({ item }: { item: Banner }) {
  const onToggle = UseToggleBanner(item);

  return (
    <label className="switch">
      <input
        type="checkbox"
        checked={item?.active}
        onClick={() => onToggle()}
      />
      <span className="slider"></span>
    </label>
  );
}
function BannerAdmin() {
  const [page, setPage] = React.useState(1);
  const [nbPages, setNbPages] = React.useState(0);
  const [currentList, setCurrentList] = React.useState<Banner[]>([]);
  const { data = [], isLoading } = useGetbannerListQuery();

  const formatData = (arr) => {
    let total = Math.ceil(arr?.length / 4);
    setNbPages(total);
    const indexOfLastPost = page * 4;
    const indexOfFirstPage = indexOfLastPost - 4;

    const currentList = arr.slice(indexOfFirstPage, indexOfLastPost);
    setCurrentList(currentList);
  };
  React.useEffect(() => {
    if (data?.length) {
      formatData(data);
    }
  }, [data, page]);
  return (
    <div className="admin-tabs-component">
      <div className="content-title flex-r pb-4">
        <NavLink
          to="/admin/ajouter-banniere"
          className="btn btn-add-admin no-link"
        >
          <FaImage />
          <span className="ps-2">Ajouter une bannière</span>
        </NavLink>
      </div>
      <div className="admin-table">
        <div className="row">
          {!!isLoading && <CardSkeleton />}
          {!isLoading &&
            (currentList?.length ? (
              currentList?.map((item) => (
                <div className="col-lg-6 col-md-6 mb-3" key={item?._id}>
                  <div className="container-banner-admin mb-4">
                    <p className="title-banner-admin">
                      Bannière {item?.position}
                    </p>
                    <div
                      className={`content-banner-admin-item ${
                        item?.active ? "active" : "inactive"
                      }`}
                    >
                      <p className="title-banner-admin">Photo</p>
                      <div className="content-img-banner-admin mb-4">
                        <img
                          src={getImage(item?.banner)}
                          alt={item?._id}
                          className="img-banner-admin"
                          loading="lazy"
                        />
                      </div>

                      <p className="title-banner-admin">Page de redirection</p>
                      <div className="content-text-banner-admin mb-4">
                        <div className="text-banner-admin pt-1">
                          <a
                            href={item?.page}
                            className="no-link"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {getPageName(item?.page)}
                          </a>
                        </div>
                      </div>
                      <div className="content-btn-edit-banner-admin flex-r">
                        <ToggleBannerBtn item={item} />
                        <NavLink
                          to={`/admin/modifier-banniere/${item?._id}`}
                          state={item}
                          className="btn btn-action-modal-icon with-tooltip mx-2"
                          data-tooltip-content="Modifier"
                        >
                          <MdModeEditOutline />
                        </NavLink>
                        <DeleteBannerBtn id={item?._id} />
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <AlertInfo message="Aucune donnée n'est disponible pour le moment!" />
            ))}
        </div>
      </div>
      <div className="d-flex justify-content-center">
        {nbPages && nbPages > 0 ? (
          <Pagination
            page={page}
            total={nbPages}
            onPageChange={(page) => setPage(page)}
          />
        ) : null}
      </div>
    </div>
  );
}

export default BannerAdmin;
