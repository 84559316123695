import React, { useEffect, useState } from "react";
import { BiEdit, BiMessageSquareAdd } from "react-icons/bi";
import { BsTrashFill } from "react-icons/bs";
import { useGetListCategoryQuery } from "../../../../utils/api/category/category.api";
import AddCategoryModal from "../modal/AddCategoryModal";
import { UseDeleteCategory } from "../requestForm/UseCrudCategoriesForm";
import Skeleton from "react-loading-skeleton";
import Pagination from "../../../common/Pagination";
import { AlertInfo } from "../../../common/Alert";

const CardSkeleton = () => {
  return (
    <>
      {Array(5)
        .fill(5)
        .map((shopCard, i) => (
          <div className="subcategory-item-container w-100" key={i}>
            <Skeleton
              className="categorie-titre position-relative mb-3"
              width={`25%`}
            />
            {/* <Skeleton width={100} height={100} /> */}
            <div className="row px-2">
              {Array(16)
                .fill(16)
                .map((shopCard, i) => (
                  <div className="col-md-3 mb-3" key={i}>
                    <div className="content-category-product-admin-item ">
                      <Skeleton height={100} width={220} />
                    </div>
                  </div>
                ))}
            </div>
          </div>
        ))}
    </>
  );
};

function DeleteCategoryButton({ id, type }: { id: string; type: string }) {
  const onDelete = UseDeleteCategory(id, type);

  return (
    <button
      className="btn with-tooltip btn-action-icon-category-delete"
      data-tooltip-content="Supprimer"
      onClick={() => onDelete()}
    >
      <BsTrashFill />
    </button>
  );
}

function SubcatégoryTabs() {
  const [page, setPage] = useState(1);
  const { data = { results: [], count: 0, nbPage: 0 }, isLoading } =
    useGetListCategoryQuery({ limit: 24, page });

  useEffect(() => {
    console.log("data", data);
  }, [data]);

  const handleChangePage = (selected) => {
    setPage(selected);
    console.log("page", selected);
  };
  return (
    <div className="admin-tabs-component">
      <div className="content-title flex-r mb-4">
        <button
          className="btn btn-add-admin"
          data-bs-toggle="modal"
          data-bs-target="#addSubCategoryModal"
        >
          <BiMessageSquareAdd />
          <span className="ps-2">Ajouter une sous catégorie</span>
        </button>
        <AddCategoryModal isSub={true} modalId={"addSubCategoryModal"} />
      </div>
      <div className="content-category-product-admin mb-5">
        {!!isLoading && <CardSkeleton />}

        {!isLoading &&
          (data?.results?.length ? (
            data?.results?.map((item) => (
              <div className="subcategory-item-container w-100" key={item?._id}>
                <div className="categorie-titre position-relative mb-3">
                  {item?.name}
                </div>
                <div className="row px-2">
                  {item?.subCategories?.length ? (
                    item?.subCategories?.map((el) => (
                      <div className="col-md-3 mb-3" key={el?._id}>
                        <div
                          className="content-category-product-admin-item px-1 py-3"
                          style={
                            {
                              // height: 74,
                              // width: 100,
                              // marginRight: ".5rem",
                            }
                          }
                        >
                          <div className="category-content">
                            <p className="name-subcategory-product-admin-item mb-4 text-center">
                              {el?.name}{" "}
                            </p>
                            <div className="table-actions-btn-container-commandes d-flex gap-3 justify-content-center">
                              <div className="container-btn-action-icon-category">
                                <button
                                  className="btn with-tooltip btn-action-icon-category-edit"
                                  data-tooltip-content="Modifier"
                                  data-bs-toggle="modal"
                                  data-bs-target={`#editCategoryModal${el?._id}`}
                                >
                                  <BiEdit />
                                </button>
                              </div>
                              <div className="container-btn-action-icon-category">
                                <DeleteCategoryButton
                                  type={"sous catégorie"}
                                  id={el?._id}
                                />
                              </div>
                            </div>
                          </div>
                          <AddCategoryModal
                            isSub={true}
                            category={el}
                            modalId={`editCategoryModal${el?._id}`}
                          />
                        </div>
                      </div>
                    ))
                  ) : (
                    <AlertInfo message="Aucune sous catégorie disponible pour cette catégorie !" />
                  )}
                </div>
              </div>
            ))
          ) : (
            <AlertInfo message="Aucune catégorie disponible pour le moment !" />
          ))}
      </div>
      <div className="d-flex justify-content-center">
        {data?.nbPage && data?.nbPage > 0 ? (
          <Pagination
            page={page}
            total={data?.nbPage}
            onPageChange={handleChangePage}
          />
        ) : null}
      </div>
    </div>
  );
}

export default SubcatégoryTabs;
