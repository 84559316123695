import React, { useEffect, useState } from "react";
import { HiOutlineArrowLeft } from "react-icons/hi";
import { useLocation, useNavigate } from "react-router-dom";
import FooterPage from "../../auth/FooterPage";
import FrontFooter from "../../shared/FrontFooter/FrontFooter";
import FrontHeader from "../../shared/FrontHeader/FrontHeader";
import StarRatingComponent from "react-star-rating-component";
import { MdOutlineCallToAction } from "react-icons/md";
import { Counter } from "../../MonCompte/MonComptePage/PanierPage";
import { FaShoppingCart } from "react-icons/fa";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import GaleryProduct from "./GaleryProduct";
import Fade from "react-reveal/Fade";
import { useProductV2FromLocation } from "../../../utils/api/product/product.api";
import { createMarkup, formatCurrency } from "../../../utils/Utils";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import {
  addCartProduct,
  getCartCount,
  getSubTotal,
  calculateTax,
  getTotalAmount,
} from "../../../redux/slice/useCartSlice";
import AvisProduct from "./AvisProduct";
import ProductSimilary from "./ProductSimilary";
import {
  useAddOrDeleteFavoriteMutation,
  useLazyGetFavoriteListQuery,
} from "../../../utils/api/favories/favorie.api";
import Swal from "sweetalert2";
import { Color } from "../../../utils/theme";
import { AddedCartConform } from "./ParcoursAchat";
import { Product } from "../../../utils/api/product/product.type";
import Skeleton from "react-loading-skeleton";
import ShareBtn from "../../shared/ShareBtn";
import StoreConfigModal from "../../shared/FrontHeader/modal/StoreConfigModal";
import Accordion from "react-bootstrap/Accordion";

export function useToggleFavorite(item: Product) {
  const [isFavorite, setIsFavorite] = React.useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const { user } = useAppSelector((s) => s?.user);

  const [sendData, { isLoading, isSuccess, isError, error }] =
    useAddOrDeleteFavoriteMutation();

  const onToggleFavorite = async () => {
    if (!user) {
      navigate(`/?next=${location?.pathname}`, { state: "open" });
    } else {
      await sendData({ product: item?._id });
    }
  };

  React.useEffect(() => {
    if (item?.favorite?.product === item?._id) {
      setIsFavorite(true);
    } else {
      setIsFavorite(false);
    }
  }, [item]);

  React.useEffect(() => {
    // window.scFade(0, 10);
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: isFavorite
          ? `Ce produit a été supprimé de vos favories avec succès!`
          : `Ce produit a été ajouté à vos favories avec succès!`,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 3000,
      });
    }
    const err = error as any;

    if (isError) {
      console.log("err", err);
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Une erreur de statut ${err?.status} est survenue lors de l'ajout`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [error, isError, isSuccess]);

  return {
    isFavorite,
    isLoading,
    onToggleFavorite,
  };
}

const ProduitItemPage = () => {
  const [item, isLoading] = useProductV2FromLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [count, setCount] = React.useState(1);
  const { store } = useAppSelector((s) => s?.config);
  const onConfirm = AddedCartConform(item);
  const { user } = useAppSelector((s) => s?.user);

  const {
    onToggleFavorite,
    isFavorite,
    isLoading: loading,
  } = useToggleFavorite(item);
  const [open, setOpen] = useState(false);
  const [getData] = useLazyGetFavoriteListQuery();

  React.useEffect(() => {
    if (user) {
      getData();
    }
  }, [user]);

  useEffect(() => {
    if (!store) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [store]);

  const addToCart = () => {
    dispatch(addCartProduct({ product: item, qty: count }));
    dispatch(getCartCount());
    dispatch(getSubTotal());
    dispatch(calculateTax());
    dispatch(getTotalAmount());
    onConfirm();
  };

  return (
    <div className="landing-page-component">
      <FrontHeader />
      <div className="page-content bg-white pb-5">
        <div className="container-low-price">
          <Fade top cascade>
            <div
              className="content-back-to pb-5 pt-3"
              onClick={(e) => {
                e?.preventDefault();
                navigate(-1);
              }}
            >
              <HiOutlineArrowLeft style={{ fontSize: 18 }} />
              <span className="ps-2">RETOUR</span>
            </div>
          </Fade>
          <div className="row row-product-item-page mb-3">
            <div className="col-lg-8 col-md-12 col-left-product-item-page d-flex">
              <div className="content-col-left-product-item-page container-product-item-page">
                <div className="d-flex justify-content-end">
                  <ShareBtn item={item} />
                </div>
                {isLoading && !item?._id && (
                  <Skeleton width={`100%`} height={500} />
                )}
                {!!item?._id && <GaleryProduct pictures={item?.pictures} />}
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-left-product-item-page d-flex">
              <div className="content-col-left-product-item-page container-product-item-page">
                {!!item?._id && (
                  <p className="category-product-name mb-1">
                    {item?.category?.name}
                  </p>
                )}
                {isLoading && !item?._id && (
                  <Skeleton width={`100%`} height={15} />
                )}
                <div className="d-flex gap-3">
                  <div>
                    {!!item?._id && (
                      <p className="name-prduct mb-2">{item?.name}</p>
                    )}
                    {isLoading && !item?._id && (
                      <Skeleton width={`100%`} height={25} />
                    )}
                  </div>
                  <div className="">
                    {!!item?._id && (
                      <button
                        className="btn icon-heart-product"
                        onClick={() => onToggleFavorite()}
                        disabled={loading}
                      >
                        {isFavorite ? (
                          <AiFillHeart
                            style={{ color: "#E20613", fontSize: 20 }}
                          />
                        ) : (
                          <AiOutlineHeart />
                        )}
                      </button>
                    )}
                    {isLoading && !item?._id && (
                      <Skeleton className="btn icon-heart-product" />
                    )}
                  </div>
                </div>
                <div className="container-rate-product pb-3">
                  <Fade right cascade>
                    <div className="content-rate-product">
                      <StarRatingComponent
                        name="rate2"
                        starCount={5}
                        value={item?.avreageRate}
                        starColor="#DDB012"
                        emptyStarColor="#D4D6D5"
                      />
                    </div>
                  </Fade>
                  {item?.rateCount > 0 ? (
                    <Fade right cascade>
                      <span className="text-describe-rate-product-item">
                        Lire les {item?.rateCount} avis
                      </span>
                    </Fade>
                  ) : null}
                </div>
                <Fade right cascade>
                  <div className="content-price-product-item-solde mb-3">
                    {!!item?._id && (
                      <p className="text-price-commande-item mb-1 pb-1">
                        {item?.promote?.price || item?.price}{" "}
                        <span style={{ fontWeight: 400 }}>FCFA</span>
                      </p>
                    )}
                    {isLoading && !item?._id && (
                      <Skeleton width={`20%`} height={20} />
                    )}

                    {!!item?.promote?._id && (
                      <p className="price-solde-product-item mb-0 pb-1">
                        {formatCurrency(item?.price)}
                      </p>
                    )}
                  </div>
                </Fade>
                {/* {!isLoading && item?.stock > 0 && (
                  <Fade right cascade>
                    <p className="text-describe-rate-product-item">
                      <BiCube />
                      <span className="ps-2">
                        {" "}
                        {item?.stock} disponible(s) en stock{" "}
                      </span>
                    </p>
                  </Fade>
                )} */}

                <Fade right cascade>
                  <p className="text-describe-rate-product-item">
                    <MdOutlineCallToAction />
                    <span className="ps-2">Livraison sous 48h</span>
                  </p>
                </Fade>
                <div className="counter my-3">
                  <Counter setCount={setCount} count={count} />
                </div>
                <div className="content-btn-commande pt-1">
                  {isLoading && !item?._id && (
                    <Skeleton className="btn btn-theme w-100" />
                  )}
                  {!!item?._id && (
                    <button
                      className="btn btn-theme w-100 custom"
                      onClick={() => addToCart()}
                    >
                      <FaShoppingCart />
                      <span className="ps-2">AJOUTER AU PANIER</span>
                    </button>
                  )}
                </div>
                {/* <div className="content-btn-commande pt-4">
                  {!!item?._id && (
                    <button
                      className="btn btn-theme-outline w-100"
                      onClick={() => onToggleFavorite()}
                      disabled={loading}
                    >
                      {loading ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm me-1 d-inline-block"
                            role="status"
                          ></span>
                        </>
                      ) : (
                        <AiOutlineHeart />
                      )}

                      <span className="ps-2">
                        {" "}
                        {loading
                          ? "AJOUT EN COURS..."
                          : isFavorite
                          ? "Supprimer de vos favoris"
                          : "AJOUTER AUX favoris"}{" "}
                      </span>
                    </button>
                  )}
                  {isLoading && !item?._id && (
                    <Skeleton className="btn btn-theme-outline w-100" />
                  )}
                </div> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Accordion
              // defaultActiveKey="0"
              >
                <Accordion.Item
                  eventKey="0"
                  className="container-product-item-page mb-3"
                >
                  <Accordion.Header className="custom-produit-acc">
                    <h3 className="title-section-product-item">
                      Caractéristiques
                    </h3>
                  </Accordion.Header>
                  <Accordion.Body className="custom-produit-acc-body">
                    <div className="content-text-describe-product-item">
                      <p
                        className="text-describe-product-item mb-0"
                        dangerouslySetInnerHTML={createMarkup(
                          item?.description
                        )}
                      />
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div className="col-md-6">
              <AvisProduct product={item} />
            </div>
          </div>

          <ProductSimilary item={item} />
          <FooterPage />
        </div>
      </div>
      <FrontFooter />
      <StoreConfigModal isOpen={open} setIsOpen={setOpen} />
    </div>
  );
};

export default ProduitItemPage;
