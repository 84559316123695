/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import {
  useAddOrEditCategoryMutation,
  useDeleteCategoryMutation,
  useGetCategoryListQuery,
} from "../../../../utils/api/category/category.api";
import {
  Category,
  CategoryFormData,
} from "../../../../utils/api/category/category.type";
import { Color } from "../../../../utils/theme";
import { cleannerError, getImage, onHide } from "../../../../utils/Utils";

function UseCrudCategoriesForm(
  isSub: boolean,
  modalId: string,
  category?: Category
) {
  const validationSchema = yup.object().shape({
    isSub: yup.boolean(),
    name: yup.string().required().label("Le nom"),
    image: yup.mixed().nullable().label("L'image"),
    category: yup.string().when("isSub", {
      is: true,
      then: () => yup.string().required().label("La catégorie").nullable(),
    }),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    clearErrors,
    control,
  } = useForm<CategoryFormData>({
    resolver: yupResolver(validationSchema),
  });
  const [sendData, { isLoading, isSuccess, error, isError }] =
    useAddOrEditCategoryMutation();
  const { data } = useGetCategoryListQuery();
  const [image, setImage] = useState<any>(null);
  const [option, setOption] = useState<any>(null);
  const [options, setOptions] = useState<any>([]);

  useEffect(() => {
    if (data?.length) {
      setOptions(
        data
          ?.filter((el) => !el?.category)
          ?.map((item) => {
            return {
              label: item?.name,
              value: item?._id,
              ...item,
            };
          })
      );
    }
  }, [data]);

  useEffect(() => {
    setValue("isSub", isSub);
  }, [isSub]);

  const handleSelected = (item) => {
    setValue("category", item?.value);
    setOption(item);
  };

  const handleChangeImage = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      setImage(URL.createObjectURL(file));
      setValue("image", file);
    }
  };

  useEffect(() => {
    if (category?._id) {
      const fields: (keyof CategoryFormData)[] = ["_id", "name", "category"];
      for (let field of fields) {
        register(field);
        if (field !== "category") {
          setValue(field, category[field]);
        }
      }

      if (category?.category) {
        let item = data?.find((el) => el?._id === category?.category);
        // console.log(category,item);
        setOption({
          value: item?._id,
          label: item?.name,
          ...item,
        });
        if (item) {
          setValue("category", item?._id);
        }
      }
      if (category?.image) {
        setImage(getImage(category?.image));
      }
    }
  }, [category, data]);

  const handleReset = () => {
    reset();
    setImage(null);
    setOption(null);
  };
  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        iconColor: Color.success,
        confirmButtonColor: Color.success,
        title: category
          ? "Catégorie modifiée avec succès !"
          : "Catégorie ajoutée avec succès !",
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {
        onHide(modalId);
        if (!category) {
          handleReset();
        }
      });
    }
    const err = error as any;

    if (isError) {
      console.log("error", err);

      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : err?.data?.code === 11000
          ? "Cette catégorie existe déja dans la base de données"
          : `Une erreur de statut ${err?.status} est survenue`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [error, isError, isSuccess, isLoading]);

  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [clearErrors, errors]);

  const onSubmit = async (data: CategoryFormData) => {
    const fd = new FormData();
    for (let key of Object.keys(data)) {
      if (key === "category" || key === "image") {
        if (data[key]) {
          fd.append(key, data[key]);
        }
      } else {
        fd.append(key, data[key]);
      }
    }
    console.log("data", data);
    await sendData({ id: data?._id, data: fd });
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    handleChangeImage,
    image,
    handleReset,
    option,
    options,
    handleSelected,
    control,
    Controller,
  };
}

export default UseCrudCategoriesForm;
export function UseDeleteCategory(id: string, type: string) {
  const [deleteData] = useDeleteCategoryMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer cette ${type} ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(id);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      console.log(result, "archiver");
      if (result?.value) {
        Swal.fire({
          icon: "success",
          title: `La ${type} a été supprimée avec succès!`,
          iconColor: Color.themeColor,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };
  return onDelete;
}
