import React from "react";
import { useStep } from "react-hooks-helper";
import { useStepType } from "../../../utils/type";
import CategoryTabs from "./GestionTabs/CategoryTabs";
import ProduitsTabs from "./GestionTabs/ProduitsTabs";
import "./GestionProduits.css";
import SubcatégoryTabs from "./GestionTabs/SubcatégoryTabs";
import PacksTabs from "./GestionTabs/PacksTabs";
import { useLocationState } from "../../../utils/Utils";
const steps = [
  { id: "produits", Component: ProduitsTabs },
  { id: "categories", Component: CategoryTabs },
  { id: "subcategorues", Component: SubcatégoryTabs },
  { id: "pack", Component: PacksTabs },
];
const tabs = ["Produits", "Catégories", "Sous catégories", "Packs"];

const GestionProduits = () => {
  const itemState = useLocationState<{step:number} | undefined>(undefined)
  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: itemState?.step || 0, steps });
  const { Component } = step;
  return (
    <div className="content-graph-admin">
      <div className="dashbord-admin-component admidashboard-bg mb-3">
        <div className="dash-admin-page-content-container mb-3">
          <div className="container-tabs-admin-setting">
            <ul className="tabs-container">
              {tabs.map((tab, key) => (
                <li
                  className={`tab-item  ${index === key && "tab-active-item"}`}
                  key={key}
                  onClick={() => go(key)}
                  role="button"
                >
                  {tab}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="dashbord-admin-component admidashboard-bg mb-3">
        <div className="dash-admin-page-content-container mb-3">
          <div className="tabs-component-container pt-4">
            <Component />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GestionProduits;
