import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PhoneInput from "react-phone-input-2";
import { useAppSelector } from "../../../../redux/hook";
import ErrorMessage from "../../../common/ErrorMessage";
import { cleannerError, validatePhone } from "../../../../utils/Utils";
import { UserFormData } from "../../../../utils/api/user/user.type";
export type PropsStep = {
  orderData: any;
  setOrderData: Dispatch<SetStateAction<any>>;
  setIndex: Dispatch<SetStateAction<number>>;
  index: number;
  setDeliveryAmount?:any;
};
const InfoPersoFinaliserCommande = ({
  orderData,
  setOrderData,
  setIndex,
  index,
}: PropsStep) => {
  const [code, setCode] = useState<string>("");
  const validationSchema = yup.object().shape({
    prenom: yup.string().required().label("Le prénom"),
    nom: yup.string().required().label("Le nom"),
    telephone: validatePhone(yup, code).required(),
    email: yup.string().email().nullable().label("L'email"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm<UserFormData>({
    resolver: yupResolver(validationSchema),
    defaultValues: orderData?.userObj,
  });
  const { user } = useAppSelector((s) => s?.user);
  const [phone, setPhone] = useState<string | null>(
    orderData?.userObj?.telephone || null
  );

  const handleChangePhone = (item, country) => {
    setCode(country?.countryCode);
    setPhone(item);
    if (parseInt(item) !== parseInt(country?.dialCode)) {
      setValue("telephone", item);
    } else {
      setValue("telephone", "");
    }
  };

  useEffect(() => {
    if (user?._id) {
      // console.log("user",user)
      const fields: (keyof UserFormData)[] = [
        "prenom",
        "nom",
        "email",
        "telephone",
        "_id",
        "birthDate",
      ];
      for (let field of fields) {
        register(field);
        setValue(field, user[field]);
      }

      if (user?.telephone) {
        setPhone(user?.telephone);
      }
    }
  }, [user, orderData?.userObj]);

  // useEffect(() => {
  //   if (orderData?.userObj?.prenom) {
  //     // console.log("user",user)
  //     const fields: (keyof UserFormData)[] = [
  //       "prenom",
  //       "nom",
  //       "email",
  //       "telephone",
  //       "_id",
  //       "birthDate",
  //     ];
  //     for (let field of fields) {
  //       register(field);
  //       setValue(field, orderData?.userObj[field]);
  //     }

  //     if (orderData?.userObj?.telephone) {
  //       setPhone(orderData?.userObj?.telephone);
  //     }
  //   }
  // }, [orderData?.userObj]);

  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [clearErrors, errors]);

  const onSubmit = async (data: UserFormData) => {
    let prevState = { ...orderData };
    prevState["userObj"] = data;
    // console.log(prevState,data);
    if (!prevState?.adresseObj?.prenom) {
      prevState["adresseObj"].prenom = data?.prenom;
      prevState["adresseObj"].nom = data?.nom;
      prevState["adresseObj"].telephone1 = data?.telephone;
    }
    setOrderData(prevState);
    setIndex(index + 1);
  };

  return (
    <div className="content-form-register-tabs my-5">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row row-add mb-4">
          <div className="col-md-6 col-form-register-tabs mb-3">
            <div className="content-col-form-register-tabs">
              <label className="form-label form-label-register-tabs">
                PRÉNOM*
              </label>
              <input
                className="form-control form-control-register-tabs"
                type="text"
                placeholder="Prénom"
                // onChange={(e) => handleChange(e)}
                // defaultValue={orderData?.userObj?.prenom}
                {...register("prenom")}
              />
              <ErrorMessage
                message={errors?.prenom && errors?.prenom?.message}
              />
            </div>
          </div>
          <div className="col-md-6 col-form-register-tabs mb-3">
            <div className="content-col-form-register-tabs">
              <label className="form-label form-label-register-tabs">
                NOM*
              </label>
              <input
                className="form-control form-control-register-tabs"
                type="text"
                placeholder="Nom"
                {...register("nom")}
              />
              <ErrorMessage message={errors?.nom && errors?.nom?.message} />
            </div>
          </div>
          <div className="col-md-6 col-form-register-tabs mb-3">
            <div className="content-col-form-register-tabs">
              <label className="form-label form-label-register-tabs">
                Adresse mail
              </label>
              <input
                className="form-control form-control-register-tabs"
                type="email"
                placeholder="Email"
                {...register("email")}
              />
              <ErrorMessage message={errors?.email && errors?.email?.message} />
            </div>
          </div>
          <div className="col-md-6 col-form-register-tabs mb-3">
            <div className="content-col-form-register-tabs">
              <label className="form-label form-label-register-tabs">
                TÉLÉPHONE*
              </label>
              <PhoneInput
                inputProps={{
                  name: "phone",
                }}
                defaultMask={".. ... .. .."}
                country="sn"
                value={phone}
                enableSearch={true}
                onChange={(phone, country) => handleChangePhone(phone, country)}
                inputClass={"form-control form-control-register-tabs-tel"}
                enableAreaCodes={true}
                prefix="+"
                countryCodeEditable={false}
              />
              <ErrorMessage
                message={errors?.telephone && errors?.telephone?.message}
              />
            </div>
          </div>
          <div className="col-md-6 col-form-register-tabs mb-3">
            <div className="content-col-form-register-tabs">
              <label className="form-label form-label-register-tabs">
                Date de naissance
              </label>
              <input
                className="form-control form-control-register-tabs"
                type="date"
                {...register("birthDate")}
              />
              <ErrorMessage
                message={errors?.birthDate && errors?.birthDate?.message}
              />
            </div>
          </div>
        </div>

        {/* <div className="row _3uApM ">
          
          <div className="col-md-6 d-flex py-5">
            <div className="content-btn-submit">
              <button className="btn btn-theme w-100">Suivant</button>
            </div>
          </div>
        </div> */}
        <div className={`${"step-buttons"}`}>
          <button
            className={`${"step-action-btn"} ${"action-btn-secondary"} ${"disabled"} `}
            disabled
          >
            Précédant
          </button>

          <button
            className={`step-action-btn action-btn-primary`}
            type="submit"
          >
            Suivant
          </button>
        </div>
      </form>
    </div>
  );
};

export default InfoPersoFinaliserCommande;
