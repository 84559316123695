import React from "react";
import { MdOutlineAdd } from "react-icons/md";
import CrudAdminModal from "../modal/CrudAdminModal";
import AdminsTable from "../tables/AdminsTable/AdminsTable";

const ListAdmins = () => {
 
  return (
    <div className="admin-tabs">
      <div className="content-btn-action-title-page-admin flex-r">
        <button
          data-bs-toggle="modal"
          data-bs-target="#addAdminModal"
          className="btn btn-add-admin"
        >
          <MdOutlineAdd />
          <span className="ps-2">Ajouter un collaborateur</span>
        </button>
        
        <CrudAdminModal modalId="addAdminModal" />
      </div>
      <div className="admin-table mt-5">
        <AdminsTable />
      </div>
    </div>
  );
};

export default ListAdmins;
