import React from "react";
import Modal from "react-modal";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { FormError, InputAdmin } from "../../../common/Input";
import CloseModalBtn from "../../../shared/CloseModalBtn";
import UseAddProductToPromotionForm from "../requestForm/UseAddProductToPromotionForm";

export const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
    zIndex: 99999,
  },
};

function AddProduitPromoModal({
  modalAddProduitPromo,
  setModalAddProduitPromo,
}) {
  const {
    register,
    onSubmit,
    errors,
    isLoading,
    promo,
    handleChange,
    handleReset,
    options,
    option,
    handleSelected,
    control,
    Controller,
    promos,
    handleSelectedPromo,
  } = UseAddProductToPromotionForm(setModalAddProduitPromo);
  const animatedComponents = makeAnimated();
  function closeModalAddProduitPromo() {
    setModalAddProduitPromo(false);
    handleReset();
  }

  return (
    <Modal
      isOpen={modalAddProduitPromo}
      onRequestClose={closeModalAddProduitPromo}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="modal-header flex-sb">
        <h5 className="modal-title-admin">Ajouter un produit en promo</h5>
        <CloseModalBtn onClick={closeModalAddProduitPromo} />
      </div>

      <div className="pt-3">
        <form onSubmit={onSubmit}>
          <div className="row py-3 px-2">
            <div className="col-md-12 mb-4">
              <div className="mb-3">
                <label
                  htmlFor={"product"}
                  className="form-label form-label-admin"
                  aria-labelledby={"product"}
                >
                  Produit{" "}
                  <span
                    className="text-danger custom-required-text"
                    style={{ fontSize: 17 }}
                  >
                    *
                  </span>
                </label>

                <Controller
                  name="product"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Select
                        {...field}
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        placeholder="Produit..."
                        onInputChange={handleChange}
                        options={options}
                        classNamePrefix="react-select-custom"
                        onChange={handleSelected}
                        value={option}
                      />
                    );
                  }}
                />
                {<FormError error={errors?.product?.message} />}
              </div>
            </div>
            <div className="col-md-12 mb-4">
              <div className="mb-3">
                <label
                  htmlFor={"promotion"}
                  className="form-label form-label-admin"
                  aria-labelledby={"promotion"}
                >
                  Promotion{" "}
                  <span
                    className="text-danger custom-required-text"
                    style={{ fontSize: 17 }}
                  >
                    *
                  </span>
                </label>

                <Controller
                  name="promotion"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Select
                        {...field}
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        placeholder="Promotion..."
                        options={promos}
                        classNamePrefix="react-select-custom"
                        onChange={handleSelectedPromo}
                        value={promo}
                      />
                    );
                  }}
                />
                {<FormError error={errors?.promotion?.message} />}
              </div>
            </div>
            <div className="col-md-12 mb-4">
              <InputAdmin
                label="Prix promo"
                type={"number"}
                placeholder="Prix promo"
                step={"any"}
                required
                min={0}
                {...register("price")}
                error={errors?.price?.message}
              />
            </div>
            <div className="flex-r">
              <button
                className="btn btn-cancelled"
                type="button"
                onClick={closeModalAddProduitPromo}
              >
                Annuler
              </button>
              <button
                type="submit"
                className="btn btn-theme"
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                      role="status"
                    ></span>
                    <span>En cours...</span>
                  </>
                ) : (
                  "Ajouter"
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default AddProduitPromoModal;

