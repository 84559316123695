/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import Logo from "../../assets/appImages/logo.png";
import Select from "react-select";
import LogoBoutik from "../../assets/icons/edk.svg";
import { useGetZoneListByVisitorQuery } from "../../utils/api/zone/zone.api";
import { useLazyGetStoreListQuery } from "../../utils/api/store/store.api";
import { Store } from "../../utils/api/store/store.type";
import { AlertInfo } from "../common/Alert";
import { useAppDispatch, useAppSelector } from "../../redux/hook";
import { onSetConfig } from "../../redux/slice/Config.slice";
import { useNavigate, useSearchParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

const ResultatItem = ({
  item,
  onClick,
}: {
  item: Store;
  onClick: (item: Store) => void;
}) => {
  return (
    <div
      className="container-resultat-search-boutik mb-3 linkable"
      onClick={() => onClick(item)}
    >
      <div className="content-img-icon-edk">
        <img
          src={LogoBoutik}
          alt="Boutique"
          className="resultat-img-icon-edk"
          loading="lazy"
        />
      </div>
      <div className="content-text-resultat-search-boutik">
        <h3 className="title-name-boutik-search mb-1">{item?.name}</h3>
        <p className="text-adresse-boutik-search mb-0">{item?.adresse}</p>
      </div>
    </div>
  );
};

const StoreSkeleton = () => {
  return (
    <>
      {[...Array(5)]?.map((el, i) => (
        <Skeleton
          key={i}
          className="container-resultat-search-boutik mb-3"
          height={`25%`}
        />
      ))}
    </>
  );
};

function AdresseBoutiqueEdk({ onSelect }: { onSelect?: () => void }) {
  const { zone } = useAppSelector((state) => state.config);
  const { data = [] } = useGetZoneListByVisitorQuery();
  const [shops, setShops] = useState<any>([]);
  const [getStores, { data: stores = [], isSuccess, status, isFetching }] =
    useLazyGetStoreListQuery();
  const [searchParams] = useSearchParams();
  let next = searchParams.get("next");
  const [options, setOptions] = useState<any>([]);
  const [option, setOption] = useState<any>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (data?.length) {
      setOptions(
        data?.map((item) => ({
          label: item?.ville,
          value: item?._id,
          ...item,
        }))
      );
    }
  }, [data]);

  useEffect(() => {
    if (zone) {
      setOption(zone);
      getStores({ zone: zone?._id });
    }
  }, [zone]);

  useEffect(() => {
    // console.log("her shops", isSuccess, status);
    if (stores?.length) {
      setShops(stores);
    } else {
      setShops([]);
    }
  }, [isSuccess, status]);

  const handleSelectZone = (zone) => {
    setOption(zone);
    if (zone?.value) {
      getStores({ zone: zone?.value });
    } else {
      setShops([]);
    }
  };

  const handleSelectStore = async (store) => {
    await dispatch(
      onSetConfig({
        zone: option,
        store: store,
      })
    );
    if (onSelect) {
      onSelect();
    } else {
      if (next) {
        navigate(next);
      } else {
        navigate("/");
      }
    }
  };

  const customStyles = {
    // option: (defaultStyles, state) => ({
    //   ...defaultStyles,
    //   backgroundColor: "#fff",
    // }),

    // control: (defaultStyles) => ({
    //   ...defaultStyles,
    //   backgroundColor: "#fff",
    //   padding: "20px",
    //   border: "none",
    //   boxShadow: "none",
    //   borderRadius: "10px",
    // }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#2A71D5" }),
  };
  return (
    <div className="container-page-adresse-boutique-edk">
      <div className="content-page-adresse-boutique-edk">
        <div className="content-logo-edk text-center">
          <img src={Logo} alt="Logo" className="img-logo-edk" loading="lazy" />
        </div>
        <div className="content-title-adresse-boutique-edk mt-4">
          <h3 className="title-adresse-boutique-edk">
            Choisissez votre BOUTIQUE
          </h3>
        </div>
        <div className="ontainer-form-adresse-boutique-edk pt-3">
          <form>
            <label className="form-label title-resultat-search-boutik">
              Adresse
            </label>
            <Select
              options={options}
              styles={customStyles}
              onChange={(opt) => handleSelectZone(opt)}
              classNamePrefix="home-select-zone"
              placeholder="Sélectionnez une zone, une ville, une région..."
              value={option}
            />
          </form>
        </div>
        {!!option?.value && (
          <>
            <p className="title-resultat-search-boutik mt-4">
              Les boutiques les plus proches
            </p>
            <div className="bloc-container-resutat-search-boutik pt-1">
              {!!isFetching && <StoreSkeleton />}
              {!isFetching &&
                (shops?.length ? (
                  shops?.map((item) => (
                    <ResultatItem
                      key={item?._id}
                      item={item}
                      onClick={handleSelectStore}
                    />
                  ))
                ) : (
                  <AlertInfo message="Aucune boutique trouvée dans cette zone" />
                ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default AdresseBoutiqueEdk;
