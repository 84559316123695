/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from "@hookform/resolvers/yup";
import { ChangeEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";
import {
  useAddOrEditPromotionMutation,
  useDeletePromotionMutation,
} from "../../../../utils/api/promotion/promotion.api";
import {
  Promotion,
  PromotionFormData,
} from "../../../../utils/api/promotion/promotion.type";
import { Color } from "../../../../utils/theme";
import { cleannerError, getImage } from "../../../../utils/Utils";

function UseCrudPromotionForm(promotion?: Promotion) {
  const validationSchema = yup.object().shape({
    hasEdit: yup.boolean(),
    title: yup.string().required().label("Le titre"),
    taux: yup
      .number()
      .required()
      .label("Le taux de réduction")
      .transform((value) => (isNaN(value) ? null : value)),
    image: yup
      .mixed()
      .when("hasEdit", {
        is: false,
        then: () => yup.mixed().required().label("L'image").nullable(),
      })
      .nullable(),
    active: yup.boolean().label("Le statut").nullable().default(false),
    // banner: yup
    //   .mixed()
    //   .when("hasEdit", {
    //     is: false,
    //     then: () => yup.mixed().required().label("La bande").nullable(),
    //   })
    //   .nullable(),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm<PromotionFormData>({
    resolver: yupResolver(validationSchema),
  });
  const navigate = useNavigate();
  const [image, setImage] = useState<any>(null);
  const [bande, setBande] = useState<any>(null);
  const [sendData, { isLoading, isSuccess, error, isError }] =
    useAddOrEditPromotionMutation();
  const [status, setStatus] = useState(true);

  const handleChangeImage = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      setImage(URL.createObjectURL(file));
      setValue("image", file);
    }
  };

  const handleChangeBande = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      setBande(URL.createObjectURL(file));
      setValue("banner", file);
    }
  };

  useEffect(() => {
    setValue("active", status);
  }, [status]);
  useEffect(() => {
    if (promotion?._id) {
      setValue("hasEdit", true);
      const fields: (keyof PromotionFormData)[] = ["_id", "title", "taux"];
      for (let field of fields) {
        register(field);
        setValue(field, promotion[field]);
      }
      setStatus(promotion?.active);

      if (promotion?.image) {
        setImage(getImage(promotion?.image));
      }

      if (promotion?.banner) {
        setBande(getImage(promotion?.banner));
      }
    } else {
      setValue("hasEdit", false);
    }
  }, [promotion]);

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        iconColor: Color.success,
        confirmButtonColor: Color.success,
        title: promotion
          ? "Promotion modifiée avec succès !"
          : "Promotion ajoutée avec succès !",
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {
        navigate("/admin/promos", { state: { step: "promotion" } });
      });
    }
    const err = error as any;

    if (isError) {
      console.log("error", err);

      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Une erreur de statut ${err?.status} est survenue`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [error, isError, isSuccess, isLoading]);

  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [clearErrors, errors]);

  const handleChangeStatus = (e: ChangeEvent<HTMLInputElement>) => {
    setStatus(e.target.checked);
    console.log(e.target.checked);
  };

  const onSubmit = async (data: PromotionFormData) => {
    const fd = new FormData();
    for (let key of Object.keys(data)) {
      if (key === "image" || key === "banner") {
        if (data[key]) {
          fd.append(key, data[key]);
        }
      } else {
        fd.append(key, data[key]);
      }
    }
    console.log("data", data);
    await sendData({ id: data?._id, data: fd });
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    isLoading,
    handleChangeImage,
    image,
    handleChangeStatus,
    status,
    handleChangeBande,
    bande
  };
}

export default UseCrudPromotionForm;

export function UseDeletePromotion(id: string) {
  const [deleteData] = useDeletePromotionMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer cette promotion ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(id);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      console.log(result, "archiver");
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `La promotion a été supprimée avec succès!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue lors de la suppression`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };
  return onDelete;
}

export function UseTogglePromotion(item: Promotion) {
  const [sendData] = useAddOrEditPromotionMutation();

  const onToggle = async () => {
    let fd = new FormData();
    fd.append("active", !item?.active as any);
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir ${
        item?.active ? "désactiver" : "activer"
      } cette promotion ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return sendData({
          id: item?._id,
          data: fd,
        });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `La promotion a été ${
              item?.active ? "désactivée" : "activée"
            } avec succès!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };
  return onToggle;
}