import React, { useState } from "react";
import "./Login.css";
import { Link } from "react-router-dom";
import Logo from "../../assets/appImages/logo-login.png";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { FaUser } from "react-icons/fa";
import { RiLockFill } from "react-icons/ri";
import UseLoginForm from "./requestForm/UseLoginForm";
import ErrorMessage from "../common/ErrorMessage";

const Login = () => {
  const { register, errors, isLoading, onSubmit } =
    UseLoginForm();
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className="container-page-login-app">
      <div className="auth-row">
        <div className="auth-col auth-right-side-col">
          <div className="auth-right-side-container">
            <div className="auth-form-container text-center pb-4">
              <div className="content-logo-auth pb-2 pt-5">
                <img
                  src={Logo}
                  alt="logo"
                  className="logo-auth-page"
                  loading="lazy"
                />
              </div>
              <h2 className="title-auth-form-text pt-5">Connectez-vous</h2>
              <div className="mt-5">
                <form id="auth-form" onSubmit={onSubmit}>
                  <div className="row auth-form-row">
                    <div className="col-md-12 auth-input-col mb-3">
                      <div className="auth-form-group-login">
                        <span className="icon-login-input">
                          <FaUser />
                        </span>
                        <input
                          type={`text`}
                          className="form-control auth-form-control"
                          id="email-or-username"
                          placeholder="Adresse mail/N° de téléphone"
                          aria-label="Username"
                          {...register("email")}
                        />
                        <ErrorMessage
                          message={errors?.email && errors?.email?.message}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 auth-input-col mb-2">
                      <div className="auth-form-group-login">
                        <span className="icon-login-input">
                          <RiLockFill />
                        </span>
                        <input
                          type={showPassword ? "text" : "password"}
                          className="form-control auth-form-control"
                          id="password"
                          placeholder="Mot de passe"
                          aria-label="Password"
                          {...register("password")}
                        />
                        <span
                          className="show-hide-password"
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                        >
                          {showPassword ? (
                            <AiFillEyeInvisible />
                          ) : (
                            <AiFillEye />
                          )}
                        </span>
                      </div>
                      <ErrorMessage
                        message={errors?.password && errors?.password?.message}
                      />
                    </div>
                    <div className="content-forget-password-link mb-3">
                      <Link
                        to={"/mot-de-passe-oublie"}
                        className="forget-password-link"
                      >
                        Mot de passe oublié ?
                      </Link>
                    </div>
                    <div className="col-md-6 offset-md-3 auth-submit-btn-container mt-5">
                      <button
                        // to="/admin/dashboard"
                        className="btn auth-submit-btn trans-0-2"
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <>
                            <span
                              className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                              role="status"
                            ></span>
                            <span>Connexion...</span>
                          </>
                        ) : (
                          "Connexion"
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
