import React, { useState, useEffect, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "./FrontHeader.css";
import Logo from "../../../assets/appImages/logo.png";
import LogoMobile from "../../../assets/appImages/logo-mobile.png";

import { AiOutlineHeart, AiOutlineShoppingCart } from "react-icons/ai";
import { FiShoppingCart, FiUser } from "react-icons/fi";
import ConnexionModal from "./modal/ConnexionModal";
import { useAppSelector } from "../../../redux/hook";
import $ from "jquery";
import { useLocationState } from "../../../utils/Utils";
import ShoppingCart from "./modal/ShoppingCart";
import CategoriesList from "./CategoriesList";
import SearchForm from "./SearchForm";
import ChoiceStoreModal from "../../HomePage/ChoiceStoreModal";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useGetConfigAppQuery } from "../../../utils/api/config/config.api";
import CategoriesListHeader from "./CategoriesListHeader";
import { BiCategoryAlt } from "react-icons/bi";

const MenuIcon = ({ open }) => {
  return (
    <>
      <svg
        className={open ? "close" : "menu"}
        fill="#ffffff"
        viewBox="0 0 448 512"
        width="20"
      >
        <path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z" />
      </svg>
    </>
  );
};

const CloseIcon = ({ open }) => {
  return (
    <svg
      className={open ? "menu" : "close"}
      fill="#ffffff"
      viewBox="0 0 512 512"
      width="20"
    >
      <path d="M464 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-83.6 290.5c4.8 4.8 4.8 12.6 0 17.4l-40.5 40.5c-4.8 4.8-12.6 4.8-17.4 0L256 313.3l-66.5 67.1c-4.8 4.8-12.6 4.8-17.4 0l-40.5-40.5c-4.8-4.8-4.8-12.6 0-17.4l67.1-66.5-67.1-66.5c-4.8-4.8-4.8-12.6 0-17.4l40.5-40.5c4.8-4.8 12.6-4.8 17.4 0l66.5 67.1 66.5-67.1c4.8-4.8 12.6-4.8 17.4 0l40.5 40.5c4.8 4.8 4.8 12.6 0 17.4L313.3 256l67.1 66.5z" />
    </svg>
  );
};
const FrontHeader = () => {
  const { data } = useGetConfigAppQuery();
  const item = useLocationState(undefined);
  const user = useAppSelector((s) => s?.user);
  const { totalCount } = useAppSelector((s) => s?.cart);
  const navigate = useNavigate();
  const [modalConnexion, setModalConnexion] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { store } = useAppSelector((s) => s?.config);
  const [open, setOpen] = useState(false);
  const wrapperRef = useRef<any>(null);

  const toggleMenu = () => {
    setOpen(!open);
  };
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        wrapperRef.current &&
        !wrapperRef?.current?.contains(event.target) &&
        open
      ) {
        toggleMenu();
        $("#togglebtn").click();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, open]);

  function openModalConnexion(e: any) {
    e.preventDefault();
    setModalConnexion(true);
  }

  useEffect(() => {
    if (item && item === "open") {
      setModalConnexion(true);
    }
  }, [item]);

  return (
    <>
      <div className="frontheader-component headering fixed-top no-view-mobile">
        <Navbar expand="lg" className="">
          <Navbar.Brand href="#" onClick={() => navigate("/")}>
            <img
              src={Logo}
              alt="Low Price"
              className="app-logo"
              loading="lazy"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="collapse navbar-collapse header-navbar-collapse"
          >
            <Nav className="w-100 header-navbar-contents-container">
              <div className="bloc-flex-info-user-header-content">
                <div className="bloc-flex-info-user-header">
                  <div className="categorie-btn-container no-view-mobile">
                    {/* <CategoriesList /> */}
                    <div className="dropdown dropdown-categorie-header-desktop">
                      <button
                        className="btn-dropdown-categorie-header btn nav-link menu-link menu-link-category"
                        type="button"
                        id="dropdownMenuButton"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <BiCategoryAlt />
                        <span className="ps-2">Catégories</span>
                      </button>
                      <div
                        className="dropdown-menu content-dropdown-categorie-header"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <CategoriesListHeader />
                      </div>
                    </div>
                  </div>
                  <div className="search-form-container">
                    <SearchForm />
                  </div>
                </div>
              </div>
              <ul className="navbar-nav navbar-nav-header">
                {!!store && (
                  <li className="nav-item">
                    <ChoiceStoreModal store={store} />
                  </li>
                )}
                <li className="nav-item">
                  <button
                    // to={"/mon-compte/favoris"}
                    className="btn nav-link menu-link"
                    onClick={(e) =>
                      !user?.token
                        ? openModalConnexion(e)
                        : navigate("/mon-compte/favoris")
                    }
                  >
                    <AiOutlineHeart />
                    <span className="ps-2">Mes favoris</span>
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className="btn nav-link menu-link"
                    onClick={(e) =>
                      !user?.token
                        ? openModalConnexion(e)
                        : navigate("/mon-compte/profil")
                    }
                  >
                    <FiUser />
                    <span className="ps-2">Mon compte</span>
                  </button>
                  <ConnexionModal
                    modalConnexion={modalConnexion}
                    setModalConnexion={setModalConnexion}
                  />
                </li>
                <li className="nav-item no-view-mobile">
                  <button
                    className="btn nav-link menu-link position-relative"
                    // to="/mon-compte/panier"
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    <FiShoppingCart style={{ fontSize: 15 }} />
                    <span className="counter-shop-notif">{totalCount}</span>
                  </button>
                </li>
              </ul>
              <div className="content-nav-mobile-category no-view-desktop">
                <div className="navbar-nav">
                  <li className="nav-item">
                    <NavLink
                      className="btn nav-link menu-link position-relative"
                      to="/mon-compte/panier"
                    >
                      <AiOutlineShoppingCart />
                      <span className="counter-shop-notif">{totalCount}</span>
                    </NavLink>
                  </li>
                </div>
                <div className="categorie-btn-container">
                  <CategoriesList />
                </div>
              </div>
              <ShoppingCart isOpen={isOpen} setIsOpen={setIsOpen} />
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <div className="second-header-custom">
          <div className="content-left-second-header">
            <p className="text-second-header-custom">
              Contactez nous au{" "}
              <a
                className="no-link"
                href={`tel:+${data?.telephone}`}
              >{`+${data?.telephone}`}</a>
            </p>
            {/* <p className="text-second-header-custom">
            Profitez d’une réduction de -50% sur nos produits
          </p> */}
          </div>
          <div className="content-right-second-header">
            {" "}
            <NavLink to="/nos-packs" className="btn text-second-header-custom">
              Nos packs
            </NavLink>
            <NavLink to="/nos-promos" className="btn text-second-header-custom">
              Nos promos
            </NavLink>
          </div>
        </div>
      </div>
      <div
        className="frontheader-component headering fixed-top no-view-desktop"
        ref={wrapperRef}
      >
        <Navbar expand="lg">
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={toggleMenu}
            id="togglebtn"
          >
            <MenuIcon open={open} />
            <CloseIcon open={open} />
          </Navbar.Toggle>
          <Navbar.Brand href="/" className="me-0 p-0">
            <img
              src={LogoMobile}
              alt="Low Price"
              className="app-logo"
              loading="lazy"
            />
          </Navbar.Brand>
          <div className="search-form-container">
            <SearchForm />
          </div>
          <div className="navbar-nav">
            <li className="nav-item">
              <button
                className="btn nav-link menu-link-panier position-relative"
                onClick={() => setIsOpen(!isOpen)}
              >
                <AiOutlineShoppingCart />
                <span className="counter-shop-notif">{totalCount}</span>
              </button>
            </li>
          </div>
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="collapse navbar-collapse header-navbar-collapse close-collapse-menu-mobile"
          >
            <Nav className="w-100 header-navbar-contents-container">
              {/* <div className="categorie-btn-container">
                <CategoriesList />
              </div> */}
              <ul className="navbar-nav navbar-nav-header mb-3">
                <li className="nav-item">
                  <NavLink className="btn nav-link menu-link" to="/categories">
                    <BiCategoryAlt />
                    <span className="ps-2">Catégories</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <button
                    // to={"/mon-compte/favoris"}
                    className="btn nav-link menu-link"
                    onClick={(e) =>
                      !user?.token
                        ? openModalConnexion(e)
                        : navigate("/mon-compte/favoris")
                    }
                  >
                    <AiOutlineHeart />
                    <span className="ps-2">Mes favoris</span>
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className="btn nav-link menu-link"
                    onClick={(e) =>
                      !user?.token
                        ? openModalConnexion(e)
                        : navigate("/mon-compte/profil")
                    }
                  >
                    <FiUser />
                    <span className="ps-2">Mon compte</span>
                  </button>
                  <ConnexionModal
                    modalConnexion={modalConnexion}
                    setModalConnexion={setModalConnexion}
                  />
                </li>
                {!!store && (
                  <li className="nav-item">
                    <ChoiceStoreModal store={store} />
                  </li>
                )}
              </ul>
              <ShoppingCart isOpen={isOpen} setIsOpen={setIsOpen} />
            </Nav>
            <div className="second-header-custom">
              <div className="content-left-second-header">
                <p className="text-second-header-custom">
                  Contactez nous au{" "}
                  <a
                    className="no-link"
                    href={`tel:+${data?.telephone}`}
                  >{`+${data?.telephone}`}</a>
                </p>
                {/* <p className="text-second-header-custom">
            Profitez d’une réduction de -50% sur nos produits
          </p> */}
              </div>
              <div className="content-right-second-header">
                {" "}
                <NavLink
                  to="/nos-packs"
                  className="btn text-second-header-custom"
                >
                  Nos packs
                </NavLink>
                <NavLink
                  to="/nos-promos"
                  className="btn text-second-header-custom"
                >
                  Nos promos
                </NavLink>
              </div>
            </div>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </>
  );
};

export default FrontHeader;
