import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { cleannerError, onHide, validatePhone } from "../../../../utils/Utils";
import { IUser, UserFormData } from "../../../../utils/api/user/user.type";
import {
  useAddOrEditAdminMutation,
  useArchiveUserMutation,
  useUnArchiveUserMutation,
} from "../../../../utils/api/user/user.api";
import { Color } from "../../../../utils/theme";

function UseCrudUserForm(
  setModalAddAdmin?: any,
  user?: IUser,
  userType?: string,
  modalId?: string
) {
  const [code, setCode] = useState<string>("");
  const validationSchema = yup.object().shape({
    prenom: yup.string().required().label("Le prénom"),
    nom: yup.string().required().label("Le nom"),
    telephone: validatePhone(yup, code).required(),
    email: yup.string().email().required().label("L'email"),
    userType: yup.string().required().label("Le type").nullable(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
  } = useForm<UserFormData>({
    resolver: yupResolver(validationSchema),
  });
  const [sendData, { isLoading, isSuccess, error, isError }] =
    useAddOrEditAdminMutation();
  const [phone, setPhone] = useState<string | null>(null);

  const handleChangePhone = (item, country) => {
    setCode(country?.countryCode);
    setPhone(item);
    if (parseInt(item) !== parseInt(country?.dialCode)) {
      setValue("telephone", item);
    } else {
      setValue("telephone", "");
    }
  };

  useEffect(() => {
    if (userType) {
      setValue("userType", userType);
    }
  }, [userType]);

  useEffect(() => {
    if (user?._id) {
      // console.log("user",user)
      const fields: (keyof UserFormData)[] = [
        "prenom",
        "nom",
        "email",
        "telephone",
        "_id",
        "userType",
      ];
      for (let field of fields) {
        register(field);
        setValue(field, user[field]);
      }

      if (user?.telephone) {
        setPhone(user?.telephone);
      }
    }
  }, [user]);

  const handlereset = () => {
    if (!user) {
      reset();
      setPhone("+221");
    }
  };

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        iconColor: Color.success,
        confirmButtonColor: Color.success,
        title: user
          ? "Informations modifiées avec succès !"
          : `${userType ? "Utilisateur" : "Collaborateur"} ajouté avec succès!`,
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {
        if (setModalAddAdmin) {
          setModalAddAdmin(false);
        } else {
          onHide(modalId);
        }
        if (!user) {
          handlereset();
        }
      });
    }
    const err = error as any;

    if (isError) {
      console.log("error", err);

      let message = err?.data?.message
        ? err?.data?.message
        : `Une erreur de statut ${err?.status} est survenue lors de la validation`;

      if (err?.data?.code === 11000) {
        if (err?.data?.keyPattern?.hasOwnProperty("telephone"))
          message = `Le numéro de téléphone "${err?.data?.keyValue["telephone"]}" existe déjà !`;
        if (err?.data?.keyPattern?.hasOwnProperty("email"))
          message = `Cet email "${err?.data?.keyValue["email"]}" existe déjà !`;
      }

      Swal.fire({
        icon: "error",
        title: message,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [error, isError, isSuccess]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [clearErrors, errors]);

  

  const onSubmit = async (data: UserFormData) => {
    console.log("data", data);
    await sendData({ id: data?._id, data: data });
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    phone,
    handleChangePhone,
    code,
    handlereset,
  };
}

export default UseCrudUserForm;
export function UseDeleteUser(item: IUser) {
  const [archiveUser] = useArchiveUserMutation();
  const [unArchiveUser] = useUnArchiveUserMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir ${
        item?.deleted ? "désarchiver" : "archiver"
      } ${item?.userType === "user" ? "cet utilisateur" : "ce collaborateur"}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return item?.deleted
          ? unArchiveUser(item?._id)
          : archiveUser(item?._id);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      console.log(result, "archiver");
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `${
              item?.userType === "user" ? "L'utilisateur" : "Le collaborateur"
            } a été ${item?.deleted ? "désarchivé" : "archivé"} avec succès!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };
  return onDelete;
}

export function UseBlockUser(item: IUser) {
  const [sendData] = useAddOrEditAdminMutation();
  const onBlock = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir ${
        item?.isActive ? "désactiver" : "activer"
      } ${item?.userType === "user" ? "cet utilisateur" : "ce collaborateur"}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return sendData({ id: item?._id, data: { isActive: !item?.isActive } });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      console.log(result, "archiver");
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `${
              item?.userType === "user" ? "L'utilisateur" : "Le collaborateur"
            } a été ${item?.deleted ? "désactivé" : "activé"} avec succès!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };
  return onBlock;
}
