import React from "react";
import "./FrontFooter.css";
import Logo from "../../../assets/appImages/logo.png";
import { NavLink } from "react-router-dom";
import {
  useGetListCategoryQuery,
} from "../../../utils/api/category/category.api";
import { QueryUrl } from "../../../utils/Utils";
import ContactUsForm from "./ContactUsForm";
import SocialLink from "./SocialLink";
import AppsLink from "./AppsLink";

// let categories = [
//   "Alimentaire",
//   "Boucherie",
//   "Épiceries(salée et sucrée)",
//   "Hygiene & Beauté",
// ];

const FrontFooter = () => {
  const { data = {results:[]} } = useGetListCategoryQuery({
    limit: 5,
    order: "ASC",
    orderBy: "name",
  });
  // console.log("cat", data)
  return (
    <footer className="footer-component">
      <div className="container-low-price">
        <div className="row">
          <div className="col-lg-3 col-md-6 mb-3 footer-grid">
            <div className="content-logo-app-footer pb-5">
              <img
                src={Logo}
                alt="logo"
                className="logo-app-footer"
                loading="lazy"
              />
            </div>
            <p className="titlsociamedia">
              Suivez-nous sur les réseaux sociaux
            </p>
            <div className="container-image-sociaux pt-3">
              <SocialLink />
            </div>
            <AppsLink />
          </div>
          <div className="mb-3 col-lg-3 col-md-6 footer-grid">
            <p className="link-footer-title">Qui sommes-nous</p>
            <NavLink className="link-footer trans-0-2 d-block" to="/CGU">
              Conditions Générales d'utilisation
            </NavLink>
            <NavLink
              className="link-footer trans-0-2 d-block"
              to="https://groupe-edk.com/fr/carriere"
              target="_blank"
            >
              Carrières
            </NavLink>
            <NavLink
              className="link-footer trans-0-2 d-block"
              to="/propriete-intellectuelle"
            >
              Propriété intellectuelle
            </NavLink>
            <NavLink
              className="link-footer trans-0-2 d-block"
              to="/mentions-legales"
              // target="_blank"
            >
              Politique de Confidentialité
            </NavLink>
          </div>
          <div className="mb-3 col-lg-3 col-md-6 footer-grid">
            <p className="link-footer-title">Toutes les catégories</p>
            {/* {categories?.map((el, i) => (
              <NavLink className="link-footer trans-0-2 d-block" to={`/achats`} key={i}>
                {el}
              </NavLink>
            ))} */}

            {data?.results?.map((item) => (
              <NavLink
                className="link-footer trans-0-2 d-block"
                key={item?._id}
                // to={`/achats/${QueryUrl("", {
                //   category: JSON.stringify([item?._id]),
                // })}`}
                to={`/achats/${QueryUrl("", {
                  category: item?.subCategories?.length
                    ? JSON.stringify(item?.subCategories?.map((el) => el?._id))
                    : "",
                })}`}
              >
                {item?.name}
              </NavLink>
            ))}
          </div>
          <div className="mb-3 col-lg-3 col-md-6 footer-grid">
            <p className="link-footer-title">Nous contacter</p>
            <ContactUsForm />
          </div>
        </div>
        <div className="copyright-container">
          <div className="copyright-content">
            <span className="copyright-text">
              © <em>2024 LOW PRICE tous droits réservés</em>
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FrontFooter;
