import React from "react";
import { HiUser } from "react-icons/hi";
import { ImLocation2 } from "react-icons/im";
import { FaFolderMinus, FaShoppingCart } from "react-icons/fa";
import { AiFillHeart } from "react-icons/ai";
import "./MonCompte.css";
import { NavLink } from "react-router-dom";
import { useAppDispatch } from "../../redux/hook";
import { onlogout } from "../../redux/slice/User.slice";

const MonCompteSidebar = () => {
  const dispatch = useAppDispatch();

  const onLogout = () => {
    dispatch(onlogout());
  };
  return (
    <div className="sidebar-tabs-container">
      <ul className="tabs-container">
        <li className="list-sidebar-compte">
          <NavLink to="/mon-compte/profil" className="btn btn-compte-sidebar">
            <HiUser />
            <span>Mon profil</span>
          </NavLink>
        </li>
        <li className="list-sidebar-compte">
          <NavLink
            to="/mon-compte/commandes"
            className="btn btn-compte-sidebar"
          >
            <FaFolderMinus />
            <span>Mes commandes</span>
          </NavLink>
        </li>
        <li className="list-sidebar-compte">
          <NavLink to="/mon-compte/panier" className="btn btn-compte-sidebar">
            <FaShoppingCart />
            <span>Mon panier</span>
          </NavLink>
        </li>
        <li className="list-sidebar-compte">
          <NavLink to="/mon-compte/favoris" className="btn btn-compte-sidebar">
            <AiFillHeart />
            <span>Ma liste d’envies</span>
          </NavLink>
        </li>
        <li className="list-sidebar-compte">
          <NavLink
            to="/mon-compte/mes-adresses"
            className="btn btn-compte-sidebar"
          >
            <ImLocation2 />
            <span>Mes adresses</span>
          </NavLink>
        </li>
        <li className="list-sidebar-compte">
          <NavLink
            to="/mon-compte/points-de-fidelite"
            className="btn btn-compte-sidebar"
          >
            <ImLocation2 />
            <span>Points de fidélité</span>
          </NavLink>
        </li>
      </ul>
      <button className="btn btn-logout-compte" onClick={() => onLogout()}>
        Déconnexion
      </button>
    </div>
  );
};

export default MonCompteSidebar;
