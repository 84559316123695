import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { cleannerError, onHide } from "../../../../utils/Utils";
import { Color } from "../../../../utils/theme";
import { Zone, ZoneFormData } from "../../../../utils/api/zone/zone.type";
import {
  useAddOrEditZoneMutation,
  useDeleteZoneMutation,
} from "../../../../utils/api/zone/zone.api";
import { regions } from "../../../../utils/constante";

function UseCrudZoneForm(modalId: string, zone?: Zone) {
  const validationSchema = yup.object().shape(
    {
      ville: yup.string().required().label("La ville").nullable(),
    }
    // [["taux", "price"]]
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
  } = useForm<ZoneFormData>({
    resolver: yupResolver(validationSchema),
  });

  const [sendData, { isLoading, isSuccess, error, isError }] =
    useAddOrEditZoneMutation();
  const [address, setAddress] = useState("");

  const onChangeAddress = (val: {
    rue: string;
    latitude: string;
    longitude: string;
    ville: string;
    country: string;
  }) => {
    setAddress(val?.rue);
    setValue(`latitude`, val?.latitude);
    setValue(`longitude`, val?.longitude);
    setValue(`country`, val?.country);
    setValue(`ville`, val?.rue);
  };

  const handleReset = () => {
    if (!zone) {
      reset();
      setAddress("");
    }
  };

  useEffect(() => {
    // window.scroll(0, 10);
    if (isSuccess) {
      window.scroll(0, 10);
      Swal.fire({
        icon: "success",
        title: !zone
          ? "Zone ajoutée avec succès!"
          : `Zone modifiée avec succès!`,
        // text: "Un email de confirmation vous a été envoyé pour valider votre inscription.",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {
        if (!zone) {
          handleReset();
        }
        onHide(modalId);
        // navigate("/admin/point-edk", { replace: true });
      });
    }
    const err = error as any;

    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Une erreur de statut ${err?.status} est survenue`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [error, isError, isSuccess]);
  useEffect(() => {
    cleannerError(errors, clearErrors, 3000);
  }, [clearErrors, errors]);

  const onSubmit = async (data) => {
    console.log("data register", data);
    // if (!data?.taux) {
    //   data.taux = null
    // }

    // if (!data?.price) {
    //   data.price = null;
    // }

    await sendData(data);
  };

  useEffect(() => {
    if (zone?._id) {
      const fields: (keyof ZoneFormData)[] = [
        "_id",
        "region",
        "ville",
        "latitude",
        "longitude",
        "country",
      ];
      for (let field of fields) {
        register(field);

        setValue(field, zone[field]);
      }
      setAddress(zone?.ville);
    }
  }, [zone]);

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    onChangeAddress,
    address,
    isSuccess,
    handleReset,
    options: regions,
  };
}

export default UseCrudZoneForm;

export function UseDeleteZone(id: string) {
  const [deleteData] = useDeleteZoneMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer cette zone ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(id);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      console.log(result, "archiver");
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `La zone a été supprimée avec succès!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };
  return onDelete;
}
