export const regions = [
  {
    label: "Dakar",
    value: "Dakar",
  },
  {
    label: "Diourbel",
    value: "Diourbel",
  },
  {
    label: "Fatick",
    value: "Fatick",
  },
  {
    label: "Kaffrine",
    value: "Kaffrine",
  },
  {
    label: "Kaolack",
    value: "Kaolack",
  },
  {
    label: "kédougou",
    value: "kédougou",
  },
  {
    label: "Kolda",
    value: "Kolda",
  },
  {
    label: "Louga",
    value: "Louga",
  },
  {
    label: "Matam",
    value: "Matam",
  },
  {
    label: "Saint-Louis",
    value: "Saint-Louis",
  },
  {
    label: "Sédhiou",
    value: "Sédhiou",
  },
  {
    label: "Tambacounda",
    value: "Tambacounda",
  },
  {
    label: "Thiès",
    value: "Thiès",
  },
  {
    label: "Ziguinchor",
    value: "Ziguinchor",
  },
];