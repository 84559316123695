/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { AiFillEdit } from "react-icons/ai";
import PhoneInput from "react-phone-input-2";
import { useEffect, useState } from "react";
import * as yup from "yup";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppSelector } from "../../../../redux/hook";
import ErrorMessage from "../../../common/ErrorMessage";
import {
  cleannerError,
  getAddress,
  validatePhone,
} from "../../../../utils/Utils";
import { AddressFormData } from "../../../../utils/api/user/user.type";
import GoogleInput from "../../../common/GoogleInput";
import { useGetZoneListByVisitorQuery } from "../../../../utils/api/zone/zone.api";
import { PropsStep } from "./InfoPersoFinaliserCommande";

const AdresseTabs = ({
  orderData,
  setOrderData,
  setIndex,
  setDeliveryAmount,
  index,
}: PropsStep) => {
  const animatedComponents = makeAnimated();
  const [code1, setCode1] = useState<string>("");
  const [code2, setCode2] = useState<string>("");
  const [phone1, setPhone1] = useState<string | null>(
    orderData?.adresseObj?.telephone1 || null
  );
  const [phone2, setPhone2] = useState<string | null>(
    orderData?.adresseObj?.telephone2 || null
  );

  const validationSchema = yup.object().shape({
    prenom: yup.string().required().label("Le prénom").nullable(),
    nom: yup.string().required().label("Le nom").nullable(),
    adresse: yup.string().required().label("L'adresse").nullable(),
    complementAdresse: yup.string().label("Le complément d'adresse").nullable(),
    quartier: yup.string().label("Le quartier").nullable(),
    zone: yup.string().required().label("La zone").nullable(),
    telephone1: validatePhone(yup, code1).required(),
    telephone2: validatePhone(yup, code2),
    isDefault: yup.boolean().default(false),
  });
  const { zone } = useAppSelector((state) => state.config);
  const { user } = useAppSelector((s) => s?.user);
  const [adresse] = useState(getAddress(user));
  const [selected, setSelected] = useState(
    orderData.adresse || getAddress(user)?._id
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    control,
  } = useForm<AddressFormData>({
    resolver: yupResolver(validationSchema),
    defaultValues: orderData?.adresseObj,
  });
  const [options, setOptions] = useState<any>([]);
  const [option, setOption] = useState<
    { label: string; value: string; price: number; taux?: number } | undefined
  >();
  const { data: zones } = useGetZoneListByVisitorQuery();

  const [address, setAddress] = useState(orderData?.adresseObj?.adresse || "");

  useEffect(() => {
    if (zone?._id) {
      setValue(`zone`, zone?._id);
      setOption({
        label: zone?.ville,
        value: zone?._id,
        price: zone?.price,
        taux: zone?.taux,
      });
    }
  }, [zone]);

  useEffect(() => {
    if (zones?.length) {
      // console.log("zones",zones)
      setOptions(
        zones?.map((z) => {
          return {
            label: z?.ville,
            value: z?._id,
            price: z?.price,
            taux: z?.taux,
          };
        })
      );
    }
  }, [zones]);

  const handleSelectZone = (zone) => {
    setOption(zone);
    setValue(`zone`, zone?.value);
  };

  useEffect(() => {
    if (selected) {
      setValue("adresse", selected);
    }
  }, [selected]);

  useEffect(() => {
    if (adresse?._id) {
      const fields: (keyof AddressFormData)[] = [
        "prenom",
        "nom",
        "telephone1",
        "telephone2",
        "adresse",
        "complementAdresse",
        "quartier",
        "zone",
        "latitude",
        "longitude",
        "isDefault",
      ];
      for (let field of fields) {
        register(field);

        if (field === "zone") {
          setValue(field, adresse[field]?._id);
        } else {
          setValue(field, adresse[field]);
        }
      }
      setAddress(adresse?.adresse);
      if (adresse?.zone?._id) {
        setDeliveryAmount({
          price: adresse?.zone?.price,
          taux: adresse?.zone?.taux,
        });
        setOption({
          label: adresse?.zone?.ville,
          value: adresse?.zone?._id,
          price: adresse?.zone?.price,
          taux: adresse?.zone?.taux,
        });
      }
      if (adresse?.telephone1) {
        setPhone1(adresse?.telephone1);
      }

      if (adresse?.telephone2) {
        setPhone2(adresse?.telephone2);
      }
    }
  }, [adresse]);

  const onChangeAddress = (val: {
    rue: string;
    latitude: string;
    longitude: string;
    ville: string;
    country: string;
  }) => {
    setAddress(val?.rue);
    setValue(`adresse`, val?.rue);
    setValue(`latitude`, val?.latitude);
    setValue(`longitude`, val?.longitude);
    // if (val?.ville) {
    //   setValue(`ville`, val?.ville);
    // }
  };

  const handleChangePhone1 = (item, country) => {
    setCode1(country?.countryCode);
    setPhone1(item);
    if (parseInt(item) !== parseInt(country?.dialCode)) {
      setValue(`telephone1`, item);
    } else {
      setValue(`telephone1`, "");
    }
  };

  const handleChangePhone2 = (item, country) => {
    setCode2(country?.countryCode);
    setPhone2(item);
    if (parseInt(item) !== parseInt(country?.dialCode)) {
      setValue(`telephone2`, item);
    } else {
      setValue(`telephone2`, "");
    }
  };

  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [clearErrors, errors]);

  const onSubmit = async (data: AddressFormData) => {
    let prevState = { ...orderData };
    prevState["adresseObj"] = data;
    if (selected) {
      prevState["adresse"] = selected;
    }

    // console.log(prevState,data);
    setOrderData(prevState);
    setIndex(index + 1);
  };

  return (
    <div className="form-register-tabs-component">
      <div className="row mb-5">
        {!!user?.adresses?.length &&
          user?.adresses?.map((address) => (
            <div className="col-lg-4 col-md-6" key={address?._id}>
              <div
                className={`container-default-adresse h-100 ${
                  address?._id === selected ? "active" : ""
                }`}
                onClick={() => setSelected(address?._id)}
              >
                <div className="content-info-default-adresse pb-3">
                  <div className="content-text-info-adresse">
                    <p className="form-label-register-tabs">
                      {address?.prenom + " " + address?.nom}
                    </p>
                    <p className="text-info-adresse">{address?.adresse}</p>
                    <p className="text-info-adresse">
                      {address?.quartier
                        ? `${address?.quartier},${address?.ville}`
                        : ""}{" "}
                    </p>
                    <p className="text-info-adresse">{address?.telephone1}</p>
                  </div>
                  <div className="content-icon-edit-default-adresse">
                    <AiFillEdit className="icon-edit-default-adresse linkable" />
                  </div>
                </div>
                {!!address?.isDefault && (
                  <p className="text-info-adresse-right mb-0">
                    Adresse par défaut
                  </p>
                )}
              </div>
            </div>
          ))}
      </div>
      <p className="sub-title-form-register">
        {!user ? "Adresse de livraison" : "Nouveau adresse"}
      </p>
      <div className="content-form-register-tabs mt-5">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row row-add mb-4">
            <div className="col-md-6 col-form-register-tabs mb-3">
              <div className="content-col-form-register-tabs">
                <label className="form-label form-label-register-tabs">
                  PRÉNOM*
                </label>
                <input
                  className="form-control form-control-register-tabs"
                  type="text"
                  placeholder="Prénom"
                  {...register("prenom")}
                />
                <ErrorMessage
                  message={errors?.prenom && errors?.prenom?.message}
                />
              </div>
            </div>
            <div className="col-md-6 col-form-register-tabs mb-3">
              <div className="content-col-form-register-tabs">
                <label className="form-label form-label-register-tabs">
                  NOM*
                </label>
                <input
                  className="form-control form-control-register-tabs"
                  type="text"
                  placeholder="Nom"
                  {...register("nom")}
                />
                <ErrorMessage message={errors?.nom && errors?.nom?.message} />
              </div>
            </div>
            <div className="col-md-6 col-form-register-tabs mb-3">
              <div className="content-col-form-register-tabs">
                <label className="form-label form-label-register-tabs">
                  Adresse*
                </label>
                {/* <input
                  className="form-control form-control-register-tabs"
                  type="text"
                  placeholder="Grand yoff, Dakr"
                /> */}
                <GoogleInput
                  className="form-control form-control-register-tabs"
                  value={address}
                  onChange={onChangeAddress}
                  placeholder="Adresse"
                />
                <ErrorMessage
                  message={errors?.adresse && errors?.adresse?.message}
                />
              </div>
            </div>
            <div className="col-md-6 col-form-register-tabs mb-3">
              <div className="content-col-form-register-tabs">
                <label className="form-label form-label-register-tabs">
                  Complément d’adresse
                </label>
                <input
                  className="form-control form-control-register-tabs"
                  type="text"
                  placeholder="Complément d’adresse"
                  {...register("complementAdresse")}
                />
                <ErrorMessage
                  message={
                    errors?.complementAdresse &&
                    errors?.complementAdresse?.message
                  }
                />
              </div>
            </div>
            <div className="col-md-6 col-form-register-tabs mb-3">
              <div className="content-col-form-register-tabs">
                <label className="form-label form-label-register-tabs">
                  Quartier
                </label>
                <input
                  className="form-control form-control-register-tabs"
                  type="text"
                  placeholder="Quartier"
                  {...register(`quartier`)}
                />
                <ErrorMessage
                  message={errors?.quartier && errors?.quartier?.message}
                />
              </div>
            </div>
            {!zone && (
              <div className="col-md-6 col-add-product-admin mb-3">
                <div className="mb-3">
                  <label className="form-label form-label-register-tabs">
                    ZONE *
                  </label>

                  <Controller
                    name="zone"
                    control={control}
                    render={({ field }) => {
                      return (
                        <Select
                          {...field}
                          closeMenuOnSelect={true}
                          components={animatedComponents}
                          placeholder="Sélectionnez votre zone"
                          options={options}
                          classNamePrefix="react-select-zone"
                          onChange={handleSelectZone}
                          value={option}
                        />
                      );
                    }}
                  />
                  <ErrorMessage
                    message={errors?.zone && errors?.zone?.message}
                  />
                </div>
              </div>
            )}
            <div className="col-md-6 col-form-register-tabs mb-3">
              <div className="content-col-form-register-tabs">
                <label className="form-label form-label-register-tabs">
                  TÉLÉPHONE 1*
                </label>

                <PhoneInput
                  inputProps={{
                    name: "phone",
                  }}
                  defaultMask={".. ... .. .."}
                  country={"sn"}
                  enableSearch={true}
                  value={phone1}
                  onChange={(phone, country) =>
                    handleChangePhone1(phone, country)
                  }
                  inputClass="form-control form-control-register-tabs-tel"
                  enableAreaCodes={true}
                  prefix="+"
                  countryCodeEditable={false}
                />
                <ErrorMessage
                  message={errors?.telephone1 && errors?.telephone1?.message}
                />
              </div>
            </div>
            <div className="col-md-6 col-form-register-tabs mb-3">
              <div className="content-col-form-register-tabs">
                <label className="form-label form-label-register-tabs">
                  TÉLÉPHONE 2
                </label>
                <PhoneInput
                  inputProps={{
                    name: "phone",
                  }}
                  defaultMask={".. ... .. .."}
                  country={"sn"}
                  enableSearch={true}
                  value={phone2}
                  onChange={(phone, country) =>
                    handleChangePhone2(phone, country)
                  }
                  inputClass="form-control form-control-register-tabs-tel"
                  enableAreaCodes={true}
                  prefix="+"
                  countryCodeEditable={false}
                />
                <ErrorMessage
                  message={errors?.telephone2 && errors?.telephone2?.message}
                />
              </div>
            </div>
            <div className="col-md-12 col-form-register-tabs mb-3">
              <div className="content-col-form-register-tabs cpntrol">
                <label className="label-checkbox-register-tabs">
                  <input
                    type="checkbox"
                    className="input-checkbox-register-tabs"
                    defaultChecked={adresse && adresse?.isDefault}
                    // value={true}
                    {...register(`isDefault`)}
                  />
                  Définir par défaut
                </label>
                <ErrorMessage
                  message={errors?.isDefault && errors?.isDefault?.message}
                />
              </div>
            </div>
          </div>
          <div className={`${"step-buttons"}`}>
            <button
              className={`${"step-action-btn"} ${"action-btn-secondary"} ${
                index === 0 ? "disabled" : ""
              }`}
              type="button"
              onClick={() => setIndex(index - 1)}
            >
              Précédant
            </button>

            <button
              className={`step-action-btn action-btn-primary`}
              type="submit"
            >
              Suivant
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdresseTabs;
