import React from "react";
import { useGetConfigAppQuery } from "../../../utils/api/config/config.api";

function SocialLink() {
  const { data } = useGetConfigAppQuery();
  return (
    <div className="wrapper-social-media">
      {!!data?.instagram && (
        <a
          className="button no-link instagram-link"
          href={data?.instagram}
          target="_blank"
        >
          <div className="icon">
            <i className="fab fa-instagram"></i>
          </div>
          <span>Instagram</span>
        </a>
      )}
      {!!data?.tiktok && (
        <a
          className="button no-link tiktok-link"
          href={data?.tiktok}
          target="_blank"
        >
          <div className="icon">
            <i className="fab fa-tiktok"></i>
          </div>
          <span>Tiktok</span>
        </a>
      )}

      {!!data?.twitter && (
        <a
          className="button no-link twitter-link"
          href={data?.twitter}
          target="_blank"
        >
          <div className="icon">
            <i className="fab fa-twitter"></i>
          </div>
          <span>Twitter</span>
        </a>
      )}

      {!!data?.linkedin && (
        <a
          className="button no-link linkedin-link"
          href={data?.linkedin}
          target="_blank"
        >
          <div className="icon">
            <i className="fab fa-linkedin"></i>
          </div>
          <span>Linkedin</span>
        </a>
      )}

      {!!data?.facebook && (
        <a
          className="button no-link facebook-link"
          href={data?.facebook}
          target="_blank"
        >
          <div className="icon ">
            <i className="fab fa-facebook"></i>
          </div>
          <span>Facebook</span>
        </a>
      )}
    </div>
  );
}

export default SocialLink;
