import { useEffect } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoginFormData } from "../../../utils/api/auth/auth.type";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { useLoginUserMutation } from "../../../utils/api/auth/auth.api";
import { onSetUserToken } from "../../../redux/slice/User.slice";
import { cleannerError, showModal } from "../../../utils/Utils";

function UseLoginForm(closeModalConnexion?: () => void) {
  const validationSchema = yup.object().shape({
    email: yup.string().required().label("L'email ou le N° téléphone"),
    password: yup.string().required().label("Le mot de passe"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setError,
    reset,
  } = useForm<LoginFormData>({
    resolver: yupResolver(validationSchema),
  });
  const user = useAppSelector((s) => s?.user);
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const dispatch = useAppDispatch();
  const [loginUser, { isLoading, isSuccess, error, isError, data }] =
    useLoginUserMutation();

  useEffect(() => {
    if (isSuccess && !user?.token) {
      console.log("data", data);

      let next = params.get("next");
      if (closeModalConnexion) {
        closeModalConnexion();
      }

      if (data) {
        dispatch(
          onSetUserToken({ user: data?.user, token: data?.access_token })
        );
      }
      if (data?.user?.userType === "user") {
        navigate(next || "/mon-compte/profil");
      } else if (data?.user?.userType === "admin") {
        navigate("/admin/dashboard");
      } else if (data?.user?.userType === "preparer") {
        navigate("/préparateur/commandes");
      }
      reset();
    }
    const err = error as any;

    if (isError) {
      console.log("error", err);
      setError("email", {
        message: err?.data?.message
          ? err?.data?.message
          : err?.status < 500
          ? "Email ou mot de passe incorrect"
          : `Une erreur de statut ${err?.status} est survenue.`,
      });
      if (
        err?.data?.message?.includes(
          "Vous n'avez pas valider votre numéro de téléphone"
        )
      ) {
        if (window.location.pathname?.includes("inscription")) {
          navigate("/");
        } else {
          showModal("phoneModal");
        }
      }
      setTimeout(() => {
        cleannerError(errors, clearErrors);
      }, 3000);
      //   Swal.fire({
      //     icon: "error",
      //     title: err?.data?.message
      //       ? err?.data?.message
      //       : err?.status < 500
      //       ? "Email ou mot de passe incorrect"
      //       : `Une erreur de statut ${err?.status} est survenue.`,
      //     showConfirmButton: false,
      //     timer: 5000,
      //   });
    }
  }, [error, isError, isSuccess, navigate]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const onSubmit = async (data: LoginFormData) => {
    // console.log("data login", data);
    await loginUser(data);
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    isLoading,
  };
}

export default UseLoginForm;
