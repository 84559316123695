import React from "react";
import { Order } from "../../../../utils/api/order/order.type";
import { FormError } from "../../../common/Input";
import { orderType } from "../CommandeItem";
import UseEditOrderForm from "../requestForm/UseEditOrderForm";

function ChangeStatutOrder({ item }: { item: Order }) {
	const { register, errors, onSubmit, isLoading } = UseEditOrderForm(item, `changeStatusOrder${item?._id}`);
	return (
		<div
			className="modal fade"
			id={`changeStatusOrder${item?._id}`}
			tabIndex={-1}
			aria-labelledby={`changeStatusOrder${item?._id}Label`}
			aria-hidden="true"
		>
			<div className="modal-dialog">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title" id={`changeStatusOrder${item?._id}Label`}>
							Commande N° {item?.numOrder}
						</h5>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div className="modal-body">
						<div className="pt-3">
							<form onSubmit={onSubmit}>
								<div className="row py-3 px-2">
									<div className="col-md-12">
										<div className="mb-3">
											<label
												htmlFor="nombre_stock"
												className="form-label form-label-modal-custom"
												aria-labelledby={"status"}
											>
												Statut
											</label>
											<select
												id="status"
												className="form-select form-select-modal-custom mb-2"
												{...register("status")}
												disabled={item?.preparer === null}
											>
												<option value="">Statut de la commande</option>
												{orderType?.map((option) => (
													<option value={option.value} key={option.value}>
														{option.value}
													</option>
												))}
											</select>
											{item?.preparer === null && (
												<small style={{ color: "#2a71d5" }}>
													Il faut que la commande soit prise en charge par un
													préparateur avant de pouvoir changer son statut
												</small>
											)}
											{errors?.status && <FormError error={errors?.status?.message} />}
										</div>
									</div>
									<div className="flex-r">
										<button
											className="btn btn-cancelled"
											type="button"
											data-bs-dismiss="modal"
											aria-label="Close"
										>
											Annuler
										</button>
										<button type="submit" className="btn btn-theme" disabled={isLoading}>
											{isLoading ? (
												<>
													<span
														className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
														role="status"
													></span>
													<span>En cours...</span>
												</>
											) : (
												"Modifier"
											)}
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ChangeStatutOrder;
