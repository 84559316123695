import React from "react";
import { FaUser } from "react-icons/fa";
import { IoMdWallet } from "react-icons/io";
import "./DashboardAdmin.css";
import LineChart from "./LineChat/LineChart";
import { useGetKpiQuery } from "../../../utils/api/dashboard/dashboard.api";
import Skeleton from "react-loading-skeleton";
import HistoriqueTable from "./table/HistoriqueTable";

const CardSkeleton = () => {
  return (
    <>
      {Array(3)
        .fill(3)
        .map((el, i) => (
          <div className="col-lg-4 col-md-12 mb-3 dis-flex" key={i}>
            <div className="item-stat-dashboad-view ">
              <div className="content-item-stat-dashboad-view mt-2">
                <Skeleton
                  className="titre-item-stat-dashboad-view mb-2"
                  width={`70%`}
                />
              </div>
              <div className="content-stat-dashboard-view">
                <div className="content-text-item-stat-dashboad-view">
                  <div className="icon-item-stat-dashboad-view">
                    <Skeleton
                      className="chiffre-item-stat-dashboad-view mb-1"
                      width={`50%`}
                    />
                  </div>
                </div>
                <div className="content-item-icon-stat-dashboard-view">
                  <Skeleton className="img-icon-dash-view" width={50} />
                </div>
              </div>
            </div>
          </div>
        ))}
    </>
  );
};
const DashboardAdmin = () => {
  const { data, isLoading } = useGetKpiQuery();
  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <div className="row mb-4">
          {!!isLoading && <CardSkeleton />}
          {!isLoading && (
            <>
              <div className="col-lg-4 col-md-12 mb-3 dis-flex">
                <div className="item-stat-dashboad-view ">
                  <div className="content-item-stat-dashboad-view mt-2">
                    <p className="titre-item-stat-dashboad-view mb-1">
                      Nombre d’utilisateurs
                    </p>
                  </div>
                  <div className="content-stat-dashboard-view">
                    <div className="content-text-item-stat-dashboad-view">
                      <div className="icon-item-stat-dashboad-view">
                        <p className="chiffre-item-stat-dashboad-view mb-1">
                          {data?.userCount}
                        </p>
                      </div>
                    </div>
                    <div className="content-item-icon-stat-dashboard-view">
                      <FaUser className="img-icon-dash-view" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 mb-3 dis-flex">
                <div className="item-stat-dashboad-view">
                  <div className="content-item-stat-dashboad-view mt-2">
                    <p className="titre-item-stat-dashboad-view mb-1">
                      Nombre de produits
                    </p>
                  </div>
                  <div className="content-stat-dashboard-view">
                    <div className="content-text-item-stat-dashboad-view">
                      <div className="icon-item-stat-dashboad-view">
                        <p className="chiffre-item-stat-dashboad-view mb-1">
                          {data?.productCount}
                        </p>
                      </div>
                    </div>
                    <div className="content-item-icon-stat-dashboard-view">
                      <IoMdWallet className="img-icon-dash-view" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 mb-3 dis-flex">
                <div className="item-stat-dashboad-view">
                  <div className="content-item-stat-dashboad-view mt-2">
                    <p className="titre-item-stat-dashboad-view mb-1">
                      Nombre de commande
                    </p>
                  </div>
                  <div className="content-stat-dashboard-view">
                    <div className="content-text-item-stat-dashboad-view">
                      <div className="icon-item-stat-dashboad-view">
                        <p className="chiffre-item-stat-dashboad-view mb-1">
                          {data?.orderCount}
                        </p>
                      </div>
                    </div>
                    <div className="content-item-icon-stat-dashboard-view">
                      <IoMdWallet className="img-icon-dash-view" />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="content-graph-admin mt-5 mb-4">
          <LineChart />
        </div>
        <div className="content-graph-admin mt-5">
          <HistoriqueTable />
        </div>
      </div>
    </div>
  );
};

export default DashboardAdmin;
