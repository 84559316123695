import React from "react";
import PhoneInput from "react-phone-input-2";
import ErrorMessage from "../../common/ErrorMessage";
import { PropsType } from "../Register";

const InfoPersoTabs = ({
  register,
  errors,
  phone,
  handleChangePhone,
  passwordHandleChange,
  haveMinCharacter,
  haveMinLowercase,
  haveMinNumber,
  haveMinSpecialCharacter,
  haveMinUppercase,
  password,
}: PropsType) => {
  return (
    <div className="form-register-tabs-component">
      <div className="content-title-form-register pb-4">
        <h3 className="title-form-register">Créer un compte</h3>
      </div>
      <p className="sub-title-form-register">Information personnelles</p>
      <div className="content-form-register-tabs mt-5">
        <div>
          <div className="row row-add">
            <div className="col-md-6 col-form-register-tabs mb-3">
              <div className="content-col-form-register-tabs">
                <label className="form-label form-label-register-tabs">
                  PRÉNOM*
                </label>
                <input
                  className="form-control form-control-register-tabs"
                  type="text"
                  placeholder="Prénom"
                  {...register("prenom")}
                  data-testid="prenom-input"
                />
                <ErrorMessage
                  message={errors?.prenom && errors?.prenom?.message}
                />
              </div>
            </div>
            <div className="col-md-6 col-form-register-tabs mb-3">
              <div className="content-col-form-register-tabs">
                <label className="form-label form-label-register-tabs">
                  NOM*
                </label>
                <input
                  className="form-control form-control-register-tabs"
                  type="text"
                  placeholder="Nom"
                  {...register("nom")}
                  data-testid="nom-input"
                />
                <ErrorMessage message={errors?.nom && errors?.nom?.message} />
              </div>
            </div>
            <div className="col-md-6 col-form-register-tabs mb-3">
              <div className="content-col-form-register-tabs">
                <label className="form-label form-label-register-tabs">
                  Adresse mail
                </label>
                <input
                  className="form-control form-control-register-tabs"
                  type="email"
                  placeholder="Email"
                  {...register("email")}
                  data-testid="email-input"
                />
                <ErrorMessage
                  message={errors?.email && errors?.email?.message}
                />
              </div>
            </div>
            <div className="col-md-6 col-form-register-tabs mb-3">
              <div className="content-col-form-register-tabs">
                <label className="form-label form-label-register-tabs">
                  TÉLÉPHONE*
                </label>

                <PhoneInput
                  inputProps={{
                    name: "phone",
                  }}
                  defaultMask={".. ... .. .."}
                  country="sn"
                  value={phone}
                  data-testid="phone-input"
                  enableSearch={true}
                  onChange={(phone, country) =>
                    handleChangePhone(phone, country)
                  }
                  inputClass="form-control-register-tabs-tel"
                  enableAreaCodes={true}
                  prefix="+"
                  countryCodeEditable={false}
                  placeholder="Téléphone"
                />
                <ErrorMessage
                  message={errors?.telephone && errors?.telephone?.message}
                />
              </div>
            </div>
            <div className="col-md-6 col-form-register-tabs mb-3">
              <div className="content-col-form-register-tabs">
                <label className="form-label form-label-register-tabs">
                  MOT DE PASSE*
                </label>
                <input
                  className="form-control form-control-register-tabs"
                  type="password"
                  placeholder="Mot de passe"
                  value={password}
                  onChange={(e) => passwordHandleChange(e)}
                  data-testid="password-input"
                />
                <ErrorMessage
                  message={errors?.password && errors?.password?.message}
                />
              </div>
            </div>
            <div className="col-md-6 col-form-register-tabs mb-3">
              <div className="content-col-form-register-tabs">
                <label className="form-label form-label-register-tabs">
                  CONFIRMER LE MOT DE PASSE*
                </label>
                <input
                  className="form-control form-control-register-tabs"
                  type="password"
                  placeholder="Confirmation du mot de passe"
                  {...register("confirmPassword")}
                  data-testid="confirmPassword-input"
                />
                <ErrorMessage
                  message={
                    errors?.confirmPassword && errors?.confirmPassword?.message
                  }
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="input-group my-2 password-validation-terms-row">
                <div className="password-validation-terms-container">
                  <h4 className="password-validation-terms-title">
                    Votre mot de passe doit contenir :{" "}
                  </h4>
                  <ul className="password-validation-terms-ul p-l-14 text-start">
                    <li
                      className={
                        "password-rule-item " +
                        (!!haveMinCharacter && "text-success")
                      }
                    >
                      Au minimum 8 caractères
                    </li>
                    <li
                      className={
                        "password-rule-item " +
                        (!!haveMinUppercase && "text-success")
                      }
                    >
                      Au minimum 1 majuscule
                    </li>
                    <li
                      className={
                        "password-rule-item " +
                        (!!haveMinLowercase && "text-success")
                      }
                    >
                      Au minimum 1 minuscule
                    </li>
                    <li
                      className={
                        "password-rule-item " +
                        (!!haveMinNumber && "text-success")
                      }
                    >
                      Au minimum 1 chiffre
                    </li>
                    <li
                      className={
                        "password-rule-item " +
                        (!!haveMinSpecialCharacter && "text-success")
                      }
                    >
                      Au minimum 1 caractère spécial
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-12 py-5 d-flex justify-content-end">
              <div className="content-btn-submit">
                <button
                  // onClick={(e) => handleSubmit(e)}
                  type="submit"
                  className="btn btn-theme w-100"
                  data-testid="next-btn"
                >
                  Suivant
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoPersoTabs;
