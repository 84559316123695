import React from "react";
import { AiOutlineMenuUnfold } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import Logo from "../../../../assets/appImages/logo.png";
import { RiBarChartBoxFill, RiSettings3Fill } from "react-icons/ri";
import { FaMapMarkedAlt, FaMoneyBillAlt, FaUserPlus } from "react-icons/fa";
import { IoLocationSharp } from "react-icons/io5";
import { BsFillBagFill, BsFillChatDotsFill } from "react-icons/bs";
import { TbChartDots } from "react-icons/tb";
import { ImLocation2 } from "react-icons/im";
import { MdDeliveryDining } from "react-icons/md";
import { isAdmin, isPreparateur } from "../../../../utils/Utils";
import { useAppSelector } from "../../../../redux/hook";
import { HiOutlineUserCircle } from "react-icons/hi";
const SidebarMobile = () => {
  const { user } = useAppSelector((s) => s?.user);
  return (
    <div>
      <div className="container-nav">
        <input type="checkbox" id="check" />
        <label htmlFor="check">
          <AiOutlineMenuUnfold />
          <i className="fas fa-times" id="cancel"></i>
        </label>
        <div className="sidebar" id="scrollbar-nav-mobile">
          <div className="content-nav-mobile">
            <div className="container-user-connect-info pb-4 d-flex justify-content-center">
              <div className="container-info-user-connect-navmenu">
                <img
                  src={Logo}
                  className="low-price-logo-header"
                  alt="logo"
                  loading="lazy"
                />
              </div>
            </div>
            <div className="content-nav-mobile-scroll">
              <ul className="nav nav-items-custom flex-column">
                {!!isAdmin(user) && (
                  <>
                    <li className="nav-item nav-item-vertical-custom">
                      <NavLink
                        className="nav-link nav-link-vertival-custom-admin"
                        to="/admin/dashboard"
                      >
                        <RiBarChartBoxFill />
                        <span className="item-nav-menu drop-header">
                          Dashboard
                        </span>
                      </NavLink>
                    </li>
                    <li className="nav-item nav-item-vertical-custom">
                      <NavLink
                        className="nav-link nav-link-vertival-custom-admin"
                        to="/admin/utilisateurs"
                      >
                        <FaUserPlus />
                        <span className="item-nav-menu drop-header">
                          Utilisateurs
                        </span>
                      </NavLink>
                    </li>
                    <li className="nav-item nav-item-vertical-custom">
                      <NavLink
                        className="nav-link nav-link-vertival-custom-admin"
                        to="/admin/produits"
                      >
                        <IoLocationSharp />
                        <span className="item-nav-menu drop-header">
                          Produits
                        </span>
                      </NavLink>
                    </li>
                  </>
                )}

                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin"
                    to={
                      isPreparateur(user)
                        ? "/préparateur/commandes"
                        : "/admin/commandes"
                    }
                  >
                    <TbChartDots />
                    <span className="item-nav-menu drop-header">Commandes</span>
                  </NavLink>
                </li>
                {!!isAdmin(user) && (
                  <>
                    <li className="nav-item nav-item-vertical-custom">
                      <NavLink
                        className="nav-link nav-link-vertival-custom-admin"
                        to="/admin/promos"
                      >
                        <BsFillBagFill />
                        <span className="item-nav-menu drop-header">
                          Promos
                        </span>
                      </NavLink>
                    </li>
                    <li className="nav-item nav-item-vertical-custom">
                      <NavLink
                        className="nav-link nav-link-vertival-custom-admin"
                        to="/admin/point-edk"
                      >
                        <ImLocation2 />
                        <span className="item-nav-menu drop-header">
                          Point EDK
                        </span>
                      </NavLink>
                    </li>
                    <li className="nav-item nav-item-vertical-custom">
                      <NavLink
                        className="nav-link nav-link-vertival-custom-admin"
                        to="/admin/zones"
                      >
                        <FaMapMarkedAlt />
                        <span className="item-nav-menu drop-header">Zones</span>
                      </NavLink>
                    </li>
                    <li className="nav-item nav-item-vertical-custom">
                      <NavLink
                        className="nav-link nav-link-vertival-custom-admin"
                        to="/admin/mode-de-paiement"
                      >
                        <FaMoneyBillAlt />
                        <span className="item-nav-menu drop-header">
                          Mode de paiement
                        </span>
                      </NavLink>
                    </li>
                    <li className="nav-item nav-item-vertical-custom">
                      <NavLink
                        className="nav-link nav-link-vertival-custom-admin"
                        to="/admin/mode-de-livraison"
                      >
                        <MdDeliveryDining />
                        <span className="item-nav-menu drop-header">
                          Mode de livraison
                        </span>
                      </NavLink>
                    </li>
                    <li className="nav-item nav-item-vertical-custom">
                      <NavLink
                        className="nav-link nav-link-vertival-custom-admin"
                        to="/admin/messages"
                      >
                        <BsFillChatDotsFill />
                        <span className="item-nav-menu drop-header">
                          Message
                        </span>
                      </NavLink>
                    </li>
                    <li className="nav-item nav-item-vertical-custom">
                      <NavLink
                        className="nav-link nav-link-vertival-custom-admin"
                        to="/admin/parametres"
                      >
                        <RiSettings3Fill />
                        <span className="item-nav-menu drop-header">
                          Paramètres
                        </span>
                      </NavLink>
                    </li>
                  </>
                )}

                {!!isPreparateur(user) && (
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                      to="/préparateur/profil"
                      data-tooltip-content="Profil"
                    >
                      <span className="icon-container-nav-link-vertival">
                        <HiOutlineUserCircle />
                      </span>
                      <span className="hiddenable-navlink-label">Profil</span>
                    </NavLink>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarMobile;
