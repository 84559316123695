import React from "react";
import { InputAdmin } from "../../../common/Input";
import UseEditConfigApp from "../request/UseEditConfigApp";
import PhoneInput from "react-phone-input-2";
import ErrorMessage from "../../../common/ErrorMessage";

function ReseauxSociaux() {
  const { onSubmit, errors, isLoading, register, phone, handleChangePhone } =
    UseEditConfigApp("rs");
  return (
    <div className="content-graph-admin">
      <div className="container-title-content-page mb-4">
        <div className="content-title">
          <h3 className="title-content-dash-admin">Réseaux sociaux</h3>
        </div>
      </div>

      <div className="admin-table mt-5">
        {/* <RessourcesTable /> */}
        <form onSubmit={onSubmit}>
          <div className="formulaire-midifier-profile-container">
            <div className="row">
              <div className="col-md-6">
                <InputAdmin
                  label="Facebook"
                  placeholder="Facebook"
                  id="facebook"
                  type="url"
                  {...register("facebook")}
                  error={errors?.facebook?.message}
                />
              </div>
              <div className="col-md-6">
                <InputAdmin
                  label="Linkedin"
                  placeholder="Linkedin"
                  id="linkedin"
                  type="url"
                  {...register("linkedin")}
                  error={errors?.linkedin?.message}
                />
              </div>
              <div className="col-md-6">
                <InputAdmin
                  label="Instagram"
                  placeholder="Instagram"
                  id="instagram"
                  type="url"
                  {...register("instagram")}
                  error={errors?.instagram?.message}
                />
              </div>
              <div className="col-md-6">
                <InputAdmin
                  label="Twitter"
                  placeholder="Twitter"
                  id="twitter"
                  type="url"
                  {...register("twitter")}
                  error={errors?.twitter?.message}
                />
              </div>
              <div className="col-md-6">
                <InputAdmin
                  label="PlayStore"
                  placeholder="PlayStore"
                  id="androidAppUrl"
                  type="url"
                  {...register("androidAppUrl")}
                  error={errors?.androidAppUrl?.message}
                />
              </div>
              <div className="col-md-6">
                <InputAdmin
                  label="AppStore"
                  placeholder="AppStore"
                  id="AppStore"
                  type="url"
                  {...register("iosAppUrl")}
                  error={errors?.iosAppUrl?.message}
                />
              </div>
              <div className="col-md-6">
                <InputAdmin
                  label="Tiktok"
                  placeholder="Tiktok"
                  id="tiktok"
                  type="url"
                  {...register("tiktok")}
                  error={errors?.tiktok?.message}
                />
              </div>
              <div className="col-md-6 mb-4">
                <label className="form-label form-label-admin">Télépone</label>

                <PhoneInput
                  inputProps={{
                    name: "phone",
                  }}
                  defaultMask={".. ... .. .."}
                  country="sn"
                  value={phone}
                  enableSearch={true}
                  onChange={(phone, country) =>
                    handleChangePhone(phone, country)
                  }
                  inputClass="form-control form-control-admin-tel"
                  enableAreaCodes={true}
                  prefix="+"
                  countryCodeEditable={false}
                />
                <ErrorMessage
                  message={errors?.telephone && errors?.telephone?.message}
                />
              </div>
            </div>

            <div className="info-user-profil-btn-edit-container mt-3 flex-r">
              <button className="btn btn-theme" disabled={isLoading}>
                {isLoading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm me-1 d-inline-block"
                      role="status"
                    ></span>
                    <span>Enregistrement...</span>
                  </>
                ) : (
                  "Enregistrer"
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ReseauxSociaux;
