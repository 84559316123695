import React from "react";
import logo from "../../../assets/appImages/logo.png";
import { formatCurrency, formatDate, formatDateHour } from "../../../utils/Utils";
import { useReactToPrint } from "react-to-print";
import { MdOutlineLocalPrintshop } from "react-icons/md";
import { Order } from "../../../utils/api/order/order.type";


function DeliveryNote({item}:{item:Order}) {
  const componentRef = React.useRef<any>(null);

  const onBeforeGetContentResolve = React.useRef<any>(null);

  const [loading, setLoading] = React.useState(false);

  const handleOnBeforeGetContent = React.useCallback(() => {
    setLoading(true);

    return new Promise((resolve: any) => {
      onBeforeGetContentResolve.current = resolve;
      resolve();
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    });
  }, [setLoading]);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: `Bon_commande_${item?.numOrder}`,
    onBeforeGetContent: handleOnBeforeGetContent,
    removeAfterPrint: true,
  });

  React.useEffect(() => {
    if (typeof onBeforeGetContentResolve.current === "function") {
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve.current]);
  return (
    <div>
      <button onClick={handlePrint} className="btn-print mb-3">
        {loading ? (
          <>
            <span
              className="spinner-border spinner-border-sm me-1 d-inline-block"
              role="status"
            ></span>
            Impréssion...
          </>
        ) : (
          <>
            <MdOutlineLocalPrintshop />
            Imprimer le bon de livraison
          </>
        )}
      </button>
      <div className="d-none">
        <div className="delivery-note-container px-3" ref={componentRef}>
          <div className="border-bottom note-title-container">
            <h4 className="note-title">Bon de livraison</h4>
            <div>
              <img src={logo} alt="edk" loading="lazy" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-4">
              <div className="info-detail-commande-item mb-3">
                <p className="title-delivery-note mb-0">{item?.store?.name}</p>
              </div>
              <div className="info-detail-commande-item mb-3">
                <p className="nom-delivery-note mb-0">{item?.store?.adresse}</p>
              </div>
              <div className="info-detail-commande-item mb-3">
                <p className="nom-delivery-note mb-0">
                  Téléphone: {item?.store?.telephone}
                </p>
              </div>
            </div>
            <div className="col-md-6 info-order-container py-3 mb-4">
              <div className="info-detail-commande-item mb-3">
                <p className="nom-delivery-note mb-0">N° commande:</p>
                <p className="nom-delivery-note mb-0">{item?.numOrder}</p>
              </div>
              <div className="info-detail-commande-item mb-3">
                <p className="nom-delivery-note mb-0">Date:</p>
                <p className="nom-delivery-note mb-0">
                  {formatDateHour(item?.createdAt)}
                </p>
              </div>
              <div className="info-detail-commande-item mb-3">
                <p className="nom-delivery-note mb-0">Client:</p>
                <p className="nom-delivery-note mb-0">
                  {item?.user?.prenom + " " + item?.user?.nom}
                </p>
              </div>
              <div className="info-detail-commande-item mb-3">
                <p className="nom-delivery-note mb-0">Adresse:</p>
                <p className="nom-delivery-note mb-0">
                  {item?.adresse?.adresse}
                </p>
              </div>
              <div className="info-detail-commande-item mb-3">
                <p className="nom-delivery-note mb-0">Téléphone:</p>
                <p className="nom-delivery-note mb-0">
                  {item?.adresse?.telephone1 || item?.user?.telephone}
                </p>
              </div>
              <div className="info-detail-commande-item mb-3">
                <p className="nom-delivery-note mb-0">Mode de paiement:</p>
                <p className="nom-delivery-note mb-0">
                  {" "}
                  {item?.paymentMethod?.name}{" "}
                </p>
              </div>
              <div className="info-detail-commande-item mb-3">
                <p className="nom-delivery-note mb-0">Mode de livraison:</p>
                <p className="nom-delivery-note mb-0">
                  {item?.modeLivraison?.name}
                </p>
              </div>
              <div className="info-detail-commande-item mb-3">
                <p className="nom-delivery-note mb-0">Fait le:</p>
                <p className="nom-delivery-note mb-0">
                  {formatDate(new Date())}
                </p>
              </div>
            </div>
            <div className="col-md-12 mb-3">
              <div className="table-container">
                <table className="table table striped">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        style={{
                          textAlign: "left",
                          color: "#010235",
                          fontFamily: "Poppins",
                          fontSize: "15px",
                          fontWeight: "400",
                        }}
                      >
                        Réf. Produit
                      </th>
                      <th
                        scope="col"
                        style={{
                          textAlign: "left",
                          color: "#010235",
                          fontFamily: "Poppins",
                          fontSize: "15px",
                          fontWeight: "400",
                        }}
                      >
                        Quantité
                      </th>
                      <th
                        scope="col"
                        style={{
                          textAlign: "left",
                          color: "#010235",
                          fontFamily: "Poppins",
                          fontSize: "15px",
                          fontWeight: "400",
                        }}
                      >
                        P.Unité
                      </th>
                      <th
                        scope="col"
                        style={{
                          textAlign: "left",
                          color: "#010235",
                          fontFamily: "Poppins",
                          fontSize: "15px",
                          fontWeight: "400",
                        }}
                      >
                        Total
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {item?.orderItems?.map((item, i) => (
                      <tr key={i}>
                        <td>{item?.product?.name}</td>
                        <td>{item?.quantity}</td>
                        <td>{formatCurrency(item?.product?.price)}</td>
                        <td>
                          {formatCurrency(
                            item?.product?.price * item?.quantity
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-md-12">
              <div className="price-note-container">
                <div className="content-col-right-detail-commande w-50">
                  <div className="info-detail-commande-item mb-3">
                    <p className="nom-commande-detail-admin mb-0">
                      Prix sous-total
                    </p>
                    <p className="title-col-detail-commande-admin mb-0">
                      {formatCurrency(item?.subTotal)}
                    </p>
                  </div>

                  {item?.livraison > 0 && (
                    <div className="info-detail-commande-item mb-3">
                      <p className="nom-commande-detail-admin mb-0">
                        Frais de livraison (+):
                      </p>
                      <p className="title-col-detail-commande-admin mb-0">
                        {formatCurrency(item?.livraison)}
                      </p>
                    </div>
                  )}
                  <div className="info-detail-commande-item mb-3">
                    <p className="title-col-detail-commande-admin mb-0">
                      Total :
                    </p>
                    <p className="title-col-detail-commande-admin mb-0">
                      {formatCurrency(item?.totalAmount)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeliveryNote;
