import React, { useState } from "react";
import "./shareBtn.css";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { Product } from "../../utils/api/product/product.type";
import { BsTwitterX } from "react-icons/bs";

function ShareBtn({ item }: { item: Product }) {
  const [checked, setChecked] = useState(false);
  const shareUrl = `https://api-low-price.withvolkeno.com/api/share-product/${item?._id}`;
  return (
    <div className="btn-multi">
      <div className="menu menu-btn-share">
        <input
          type="checkbox"
          className="menu-open"
          name="menu-open"
          id="menu-open"
          onChange={(e) => setChecked(e?.target?.checked)}
        />
        <label className="menu-open-button" htmlFor="menu-open">
          <i className="fa fa-share-alt share-icon"></i>
        </label>

        <FacebookShareButton
          url={shareUrl}
          className={`menu-item facebook_share_btn ${
            checked ? "menu-item-open" : ""
          }`}
          hashtag="#lowPrice"
        >
          {" "}
          <i className="fab fa-facebook"></i>{" "}
        </FacebookShareButton>
        <TwitterShareButton
          url={shareUrl}
          title={item?.name}
          className={`menu-item twitter_share_btn ${
            checked ? "menu-item-open" : ""
          }`}
        >
          {" "}
          {/* <i className="fab fa-twitter"></i>{" "} */}
          <BsTwitterX />
        </TwitterShareButton>
        <WhatsappShareButton
          url={shareUrl}
          title={item?.name}
          separator=":: "
          className={`menu-item whatsapp_share_btn ${
            checked ? "menu-item-open" : ""
          }`}
        >
          {" "}
          <i className="fab fa-whatsapp"></i>{" "}
        </WhatsappShareButton>
        <TelegramShareButton
          url={shareUrl}
          title={item?.name}
          className={`menu-item telegram_share_btn ${
            checked ? "menu-item-open" : ""
          }`}
        >
          {" "}
          <i className="fab fa-telegram"></i>{" "}
        </TelegramShareButton>
        <LinkedinShareButton
          url={shareUrl}
          title={item?.name}
          className={`menu-item linkedin_share_btn ${
            checked ? "menu-item-open" : ""
          }`}
        >
          {" "}
          <i className="fab fa-linkedin"></i>{" "}
        </LinkedinShareButton>
        <EmailShareButton
          url={shareUrl}
          //   title={item?.name}
          subject={item?.name}
          body={`Ce produit pourrait bien t'intéresser.`}
          className={`menu-item google_plus_share_btn ${
            checked ? "menu-item-open" : ""
          }`}
          style={{}}
        >
          <i className="fas fa-envelope"></i>{" "}
        </EmailShareButton>
      </div>
    </div>
  );
}

export default ShareBtn;
