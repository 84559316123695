/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useAddOrEditOrderMutation } from "../../../../utils/api/order/order.api";
import {
  Order,
  OrderStatusFormData,
} from "../../../../utils/api/order/order.type";
import { Color } from "../../../../utils/theme";
import { cleannerError, onHide } from "../../../../utils/Utils";

function UseEditOrderForm(order: Order, modalId: string) {
  const validationSchema = yup.object().shape({
    status: yup.string().required().label("Le statut"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm<OrderStatusFormData>({
    resolver: yupResolver(validationSchema),
  });
  const [sendData, { isLoading, isSuccess, error, isError }] =
    useAddOrEditOrderMutation();

  useEffect(() => {
    if (order?._id) {
      const fields: (keyof OrderStatusFormData)[] = ["_id", "status"];
      for (let field of fields) {
        register(field);
        setValue(field, order[field]);
      }
    }
  }, [order]);

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        iconColor: Color.success,
        confirmButtonColor: Color.success,
        title: "Statut de la commande modifié avec succès !",
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {
        onHide(modalId);
      });
    }
    const err = error as any;

    if (isError) {
      console.log("error", err);

      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Une erreur de statut ${err?.status} est survenue`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [error, isError, isSuccess, isLoading]);

  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [clearErrors, errors]);

  const onSubmit = async (data: OrderStatusFormData) => {
    await sendData({ id: data?._id, data: data });
  }

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    isLoading,
  };
}

export default UseEditOrderForm;
