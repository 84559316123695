/* eslint-disable @typescript-eslint/no-use-before-define */
import * as React from "react";
import FrontHeader from "../shared/FrontHeader/FrontHeader";
import FrontFooter from "../shared/FrontFooter/FrontFooter";
import { useGetCategoryListQuery } from "../../utils/api/category/category.api";
import { QueryUrl, sortByAlphabetical } from "../../utils/Utils";
import { NavLink } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import { AlertInfo } from "../common/Alert";
import Accordion from "react-bootstrap/Accordion";

const CategoryPageMobile = () => {
  const { data = [], isLoading } = useGetCategoryListQuery();

  return (
    <div className="landing-page-component">
      <FrontHeader />
      <div className="my-5 pt-5">
        <div className="container-category-list-page-mobile px-3">
          <h3 className="tiitle-category-list-page-mobile pb-2">Catégories</h3>
          <div className="category-list-page-mobile-content">
            <Accordion>
              {!isLoading &&
                (data?.length ? (
                  sortByAlphabetical(data)?.map((item) => (
                    <Accordion.Item eventKey={item?._id} key={item?._id}>
                      <Accordion.Header>
                        <div className="dropdown-item-categorie-menu">
                          <div className="container-selected-sub-category-front-header">
                            <span>{item?.name}</span>
                            {!!item?.subCategories?.length && (
                              <div>
                                <MdKeyboardArrowRight />
                              </div>
                            )}
                          </div>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        {!!item?.subCategories?.length ? (
                          <div className="dropdown-content-sub-category">
                            <p className="sub-category-list text-capitalize">
                              {sortByAlphabetical(item?.subCategories)?.map(
                                (el) => (
                                  <ul className="ps-0">
                                    <li className="list-sub-category-content-front-header">
                                      <NavLink
                                        to={`/achats/${QueryUrl("", {
                                          category: JSON.stringify([el?._id]),
                                        })}`}
                                        className="no-link"
                                        key={el?._id}
                                      >
                                        {el?.name}
                                      </NavLink>
                                    </li>
                                  </ul>
                                )
                              )}
                            </p>
                            {/* <div className="">
                              {selected?._id === item?._id && product && (
                                <div className="content-info-category-header">
                                  <div className="content-reduction-card">
                                    <h3 className="text-title-card-reduction mb-1 text-center">
                                      REDUCTION DE
                                    </h3>
                                    <h2
                                      className="title-card-reduction mb-3 text-center"
                                      style={{
                                        fontSize: 50,
                                        lineHeight: "75.4%",
                                      }}
                                    >
                                      -{product?.promote?.promotion?.taux}%
                                    </h2>
                                    <div className="content-img-product-banner">
                                      <img
                                        src={getImage(
                                          product?.pictures[0]?.image
                                        )}
                                        alt="Produit en réduction"
                                        className="img-product-reduction"
                                        loading="lazy"
                                      />
                                    </div>
                                    <div className="content-btn-card-reduction pt-3 text-center">
                                      <NavLink
                                        to={`/produit/${product?._id}`}
                                        state={product}
                                        className="btn btn-card-reduction"
                                      >
                                        J’en profite
                                      </NavLink>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div> */}
                          </div>
                        ) : (
                          <AlertInfo message="Aucune sous-catégorie disponible pour le moment!" />
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  ))
                ) : (
                  <AlertInfo message="Aucune catégorie disponible pour le moment!" />
                ))}
            </Accordion>
          </div>
        </div>
      </div>
      <FrontFooter />
    </div>
  );
};

export default CategoryPageMobile;
