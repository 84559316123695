import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ConfigHome } from "../../utils/api/user/user.type";
import { AppLocalStorage } from "../../utils/storage";

const initialState = AppLocalStorage.getItem<ConfigHome>("config", {
  zone: undefined,
  store: undefined,
});
export const ConfigSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    onSetZone: (state, action: PayloadAction<ConfigHome["zone"]>) => {
      state.zone = action.payload;
      AppLocalStorage.setItem("zone", state);
    },
    onSetStore: (state, action: PayloadAction<ConfigHome["store"]>) => {
      state.store = action.payload;
      AppLocalStorage.setItem("store", state.store);
    },
    onSetConfig: (state, action: PayloadAction<ConfigHome>) => {
      state.zone = action.payload.zone;
      state.store = action.payload.store;
      AppLocalStorage.setItem("config", state);
    },
    onCleanConfig: (state:any) => {
      state.zone = null;
      state.store = null;
      AppLocalStorage.setItem("config", state);
    },
  },
});

export const { onSetConfig, onSetStore, onSetZone, onCleanConfig } =
  ConfigSlice.actions;
export default ConfigSlice.reducer;
