import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { Store, StoreFormData } from "./store.type";

export const StoreApi = createApi({
  reducerPath: "storeApi",
  tagTypes: [
    "store",
    "storeList",
    "storeByVisitor",
    "storeById",
    "allStoreList",
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getListStoreAdmin: builder.query<PaginationResults<Store>, TypeQuery>({
      providesTags: ["storeList"],
      query: (query) => QueryUrl("store/for-admin/paginate", query),
    }),
    getStoreList: builder.query<Store[], TypeQuery>({
      providesTags: ["store"],
      query: (query) => QueryUrl("store/", query),
    }),
    addOrEditStore: builder.mutation<
      Store,
      { id?: string; data: StoreFormData | FormData }
    >({
      onCacheEntryAdded(arg, { dispatch }) {
        setTimeout(() => {
          dispatch(
            StoreApi.util.invalidateTags([
              "storeList",
              "storeByVisitor",
              "store",
            ])
          );
        }, 1000);
      },
      query: ({ id, data }) => {
        if (id) {
          return {
            url: `store/${id}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `store/`,
          method: "POST",
          body: data,
        };
      },
    }),
    deleteStore: builder.mutation<Store, string>({
      query: (id) => ({
        url: `store/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["store", "storeList", "storeByVisitor"],
    }),
    storeById: builder.query<Store | any, string>({
      query: (id) => `store/${id}/`,
      providesTags: ["storeById"],
    }),
  }),
});

export const {
  useAddOrEditStoreMutation,
  useDeleteStoreMutation,
  useGetListStoreAdminQuery,
  useGetStoreListQuery,
  useLazyStoreByIdQuery,
  useLazyGetStoreListQuery,
  useStoreByIdQuery
} = StoreApi;
