import moment from "moment";
import React from "react";
import StarRatingComponent from "react-star-rating-component";
import { useAppSelector } from "../../../redux/hook";
import { Product } from "../../../utils/api/product/product.type";
import { useGetRateListByProductQuery } from "../../../utils/api/rate/rate.api";
import { getLabelRate } from "../../../utils/Utils";
import AvisProductModal from "./modal/AvisProductModal";
import Accordion from "react-bootstrap/Accordion";

function AvisProduct({ product }: { product: Product }) {
  const [show, setShow] = React.useState(false);
  const { user } = useAppSelector((s) => s?.user);
  const {
    data = {
      data: [],
      states: [
        { percent: 0, stars: 5, count: 0 },
        { percent: 0, stars: 4, count: 0 },
        { percent: 0, stars: 3, count: 0 },
        { percent: 0, stars: 2, count: 0 },
        { percent: 0, stars: 1, count: 0 },
      ],
      rateCount: 0,
      avreageRate: 0,
    },
    isLoading,
  } = useGetRateListByProductQuery({
    product: product?._id,
  });
  return (
    <Accordion
    // defaultActiveKey="0"
    >
      <Accordion.Item eventKey="0" className="container-product-item-page mb-3">
        <Accordion.Header className="custom-produit-acc">
          <h3 className="title-section-product-item">Avis clients</h3>
        </Accordion.Header>
        <Accordion.Body className="custom-produit-acc-body">
          <div className="row py-4 b-b-1 mb-3">
            <div className="col-lg-6 col-md-6 col-avis-produit mb-3">
              <p className="title-rate-product-item mb-0">NOTE MOYENNE</p>
              <div className="content-note-product-item-rate">
                <p className="note-product-item-rate">
                  {data?.avreageRate?.toFixed(2) || 0}
                  <span style={{ color: "#65635F", fontSize: 30 }}>/5</span>
                </p>
              </div>
              <div className="content-rate-product">
                <StarRatingComponent
                  name="rate2"
                  starCount={5}
                  value={data?.avreageRate}
                  starColor="#DDB012"
                  emptyStarColor="#D4D6D5"
                />
              </div>
              {data?.rateCount && data?.rateCount > 0 ? (
                <p className="text--rate-product-item">
                  sur {data?.rateCount} avis
                </p>
              ) : null}
            </div>
            <div className="col-lg-6 col-md-6 col-avis-rate-produit mb-3">
              <div className="content-col-avis-rate-produit">
                {[...data?.states]
                  ?.sort((a, b) => b?.stars - a?.stars)
                  ?.map((item) => (
                    <div className="col-avis-rate-produit-item mb-2">
                      <p
                        className="note-avis-libelle mb-0"
                        style={{
                          marginRight: item?.stars === 1 ? ".5rem" : "0",
                        }}
                      >
                        {item?.stars} étoile{item?.stars !== 1 ? "s" : ""}
                      </p>

                      <div className="progress content-note-rate-avis">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          aria-label="Basic example"
                          aria-valuenow={0}
                          aria-valuemin={0}
                          aria-valuemax={100}
                          style={{ width: `${item?.percent}%` }}
                        />
                      </div>

                      <p className="note-avis-libelle mb-0">{item?.count}</p>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          {!!user?._id && (
            <div className="row py-4 b-b-1 mb-3">
              <h3 className="solicitation-title">Évaluer ce produit</h3>
              <div className="solicitation-subtitle">
                Partagez votre opinion avec les autres clients
              </div>
              <div className="btn-solicitation-container">
                <button
                  className="btn btn-solicitation btn-theme-outline"
                  onClick={() => setShow(true)}
                >
                  Donner mon avis client
                </button>
              </div>
              <AvisProductModal
                show={show}
                setShow={setShow}
                product={product}
              />
            </div>
          )}

          <div className="row py-4">
            <div className="col-lg-12 col-md-12 col-table-rate mb-3">
              {!isLoading &&
                (data?.data?.length
                  ? data?.data?.map((item, i) => (
                      <div className="row b-1-color pt-4" key={i}>
                        <div className="col-md-3 mb-3">
                          <div className="content-col-table-rate">
                            {i === 0 && (
                              <p className="title-table-rate">AUTEUR</p>
                            )}

                            <div className="content-text-value-table-rate">
                              <p className="text-value-table-rate mb-0">
                                {item?.annonyme
                                  ? "Anonyme"
                                  : item?.user?.prenom + " " + item?.user?.nom}
                              </p>
                              <p className="text-libelle-table-rate mb-0">
                                {moment(item?.createdAt).format("DD MMMM YYYY")}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 mb-3">
                          <div className="content-col-table-rate">
                            {i === 0 && (
                              <p className="title-table-rate">NOTES</p>
                            )}
                            <div className="content-text-value-table-rate">
                              <p className="text-value-table-rate mb-0">
                                {item?.stars}/5
                              </p>
                              <div className="content-rate-product">
                                <StarRatingComponent
                                  name="rate2"
                                  starCount={5}
                                  value={item?.stars}
                                  starColor="#DDB012"
                                  emptyStarColor="#D4D6D5"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 mb-3">
                          <div className="content-col-table-rate">
                            {i === 0 && (
                              <p className="title-table-rate">MOTIF</p>
                            )}
                            <div className="content-text-value-table-rate">
                              <p className="text-libelle-table-rate mb-0">
                                {item?.motif || ""}
                              </p>
                              {/* <p className="text-libelle-table-rate mb-0">
                            du 12 Décembre 2022
                          </p> */}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 mb-3">
                          <div className="content-col-table-rate">
                            {i === 0 && (
                              <p className="title-table-rate">Commentaire</p>
                            )}
                            <div className="content-text-value-table-rate">
                              <p className="text-value-table-rate mb-0">
                                {getLabelRate(item?.stars)}
                              </p>
                              <p className="text-libelle-table-rate mb-0">
                                {item?.comment}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  : null)}
            </div>
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>

    // <div className="container-product-item-page mb-3">
    //   <h3 className="title-section-product-item">Avis clients</h3>
    //   <div className="row py-4 b-b-1 mb-3">
    //     <div className="col-lg-3 col-md-4 col-avis-produit mb-3">
    //       <p className="title-rate-product-item mb-0">NOTE MOYENNE</p>
    //       <div className="content-note-product-item-rate">
    //         <p className="note-product-item-rate">
    //           {data?.avreageRate?.toFixed(2) || 0}
    //           <span style={{ color: "#65635F", fontSize: 30 }}>/5</span>
    //         </p>
    //       </div>
    //       <div className="content-rate-product">
    //         <StarRatingComponent
    //           name="rate2"
    //           starCount={5}
    //           value={data?.avreageRate}
    //           starColor="#DDB012"
    //           emptyStarColor="#D4D6D5"
    //         />
    //       </div>
    //       {data?.rateCount && data?.rateCount > 0 ? (
    //         <p className="text--rate-product-item">
    //           sur {data?.rateCount} avis
    //         </p>
    //       ) : null}
    //     </div>
    //     <div className="col-lg-3 col-md-4 col-avis-rate-produit mb-3">
    //       <div className="content-col-avis-rate-produit">
    //         {[...data?.states]
    //           ?.sort((a, b) => b?.stars - a?.stars)
    //           ?.map((item) => (
    //             <div className="col-avis-rate-produit-item mb-2">
    //               <p
    //                 className="note-avis-libelle mb-0"
    //                 style={{ marginRight: item?.stars === 1 ? ".5rem" : "0" }}
    //               >
    //                 {item?.stars} étoile{item?.stars !== 1 ? "s" : ""}
    //               </p>

    //               <div className="progress content-note-rate-avis">
    //                 <div
    //                   className="progress-bar"
    //                   role="progressbar"
    //                   aria-label="Basic example"
    //                   aria-valuenow={0}
    //                   aria-valuemin={0}
    //                   aria-valuemax={100}
    //                   style={{ width: `${item?.percent}%` }}
    //                 />
    //               </div>

    //               <p className="note-avis-libelle mb-0">{item?.count}</p>
    //             </div>
    //           ))}
    //       </div>
    //     </div>
    //   </div>
    //   {!!user?._id && (
    //     <div className="row py-4 b-b-1 mb-3">
    //       <h3 className="solicitation-title">Évaluer ce produit</h3>
    //       <div className="solicitation-subtitle">
    //         Partagez votre opinion avec les autres clients
    //       </div>
    //       <div className="btn-solicitation-container">
    //         <button
    //           className="btn btn-solicitation btn-theme-outline"
    //           onClick={() => setShow(true)}
    //         >
    //           Donner mon avis client
    //         </button>
    //       </div>
    //       <AvisProductModal show={show} setShow={setShow} product={product} />
    //     </div>
    //   )}

    //   <div className="row py-4">
    //     <div className="col-lg-10 col-md-12 col-table-rate mb-3">
    //       {!isLoading &&
    //         (data?.data?.length
    //           ? data?.data?.map((item, i) => (
    //               <div className="row b-1-color pt-4">
    //                 <div className="col-md-3 mb-3">
    //                   <div className="content-col-table-rate">
    //                     {i === 0 && <p className="title-table-rate">AUTEUR</p>}

    //                     <div className="content-text-value-table-rate">
    //                       <p className="text-value-table-rate mb-0">
    //                         {item?.annonyme
    //                           ? "Anonyme"
    //                           : item?.user?.prenom + " " + item?.user?.nom}
    //                       </p>
    //                       <p className="text-libelle-table-rate mb-0">
    //                         {moment(item?.createdAt).format("DD MMMM YYYY")}
    //                       </p>
    //                     </div>
    //                   </div>
    //                 </div>
    //                 <div className="col-md-3 mb-3">
    //                   <div className="content-col-table-rate">
    //                     {i === 0 && <p className="title-table-rate">NOTES</p>}
    //                     <div className="content-text-value-table-rate">
    //                       <p className="text-value-table-rate mb-0">
    //                         {item?.stars}/5
    //                       </p>
    //                       <div className="content-rate-product">
    //                         <StarRatingComponent
    //                           name="rate2"
    //                           starCount={5}
    //                           value={item?.stars}
    //                           starColor="#DDB012"
    //                           emptyStarColor="#D4D6D5"
    //                         />
    //                       </div>
    //                     </div>
    //                   </div>
    //                 </div>
    //                 <div className="col-md-3 mb-3">
    //                   <div className="content-col-table-rate">
    //                     {i === 0 && <p className="title-table-rate">MOTIF</p>}
    //                     <div className="content-text-value-table-rate">
    //                       <p className="text-libelle-table-rate mb-0">
    //                         {item?.motif || ""}
    //                       </p>
    //                       <p className="text-libelle-table-rate mb-0">
    //                         du 12 Décembre 2022
    //                       </p>
    //                     </div>
    //                   </div>
    //                 </div>
    //                 <div className="col-md-3 mb-3">
    //                   <div className="content-col-table-rate">
    //                     {i === 0 && (
    //                       <p className="title-table-rate">Commentaire</p>
    //                     )}
    //                     <div className="content-text-value-table-rate">
    //                       <p className="text-value-table-rate mb-0">
    //                         {getLabelRate(item?.stars)}
    //                       </p>
    //                       <p className="text-libelle-table-rate mb-0">
    //                         {item?.comment}
    //                       </p>
    //                     </div>
    //                   </div>
    //                 </div>
    //               </div>
    //             ))
    //           : null)}
    //     </div>
    //   </div>
    // </div>
  );
}

export default AvisProduct;
