import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiBaseUrl } from "../../http";
import { useLocationState } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { Banner, BannerFormData } from "./banner.type";

export const BannerApi = createApi({
  reducerPath: "bannerApi",
  tagTypes: ["banner", "bannerList", "bannerByVisitor"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getbannerList: builder.query<Banner[], void>({
      providesTags: ["bannerList"],
      query: () => {
        return "banner/";
      },
    }),
    getAllbannerVisitor: builder.query<Banner[], void>({
      providesTags: ["bannerByVisitor"],
      query: () => {
        return "banner/active-only/";
      },
    }),
    addOrEditBanner: builder.mutation<
      Banner,
      { id?: string; data: BannerFormData | FormData }
    >({
      invalidatesTags: ["bannerList", "bannerByVisitor", "banner"],
      query: ({ id, data }) => {
        if (id) {
          return {
            url: `banner/${id}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `banner/`,
          method: "POST",
          body: data,
        };
      },
    }),
    deleteBanner: builder.mutation<Banner, string>({
      query: (id) => ({
        url: `banner/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["banner", "bannerList", "bannerByVisitor"],
    }),
    bannerById: builder.query<Banner | any, string>({
      query: (id) => `banner/${id}/`,
      providesTags: ["banner"],
    }),
  }),
});

export const {
  useAddOrEditBannerMutation,
  useBannerByIdQuery,
  useDeleteBannerMutation,
  useGetAllbannerVisitorQuery,
  useGetbannerListQuery,
  useLazyBannerByIdQuery
} = BannerApi;

export function useBannerFromLocation(): [
  Banner,
  boolean,
  string,
  (id: string) => any
] {
  const itemState = useLocationState<Banner | undefined>(undefined);
  const [item, setItem] = useState(itemState);
  const { id } = useParams<{ id: string }>();
  const [findById, { data, isError, isLoading }] = useLazyBannerByIdQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      findById(id as string);
    }
  }, [id]);
  useEffect(() => {
    if (isError && !itemState) {
      navigate(-1);
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
      //   console.log("data", data);
      setItem(data);
    }
  }, [data]);

  return [item as Banner, isLoading, id as string, findById];
}