import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useOnImportProductFromLeo2Mutation } from "../../../../utils/api/product/product.api";
import { Color } from "../../../../utils/theme";
import { cleannerError } from "../../../../utils/Utils";
import { useGetStoreListQuery } from "../../../../utils/api/store/store.api";

function UseImportProductFromLeo2Form() {
  const validationSchema = yup.object().shape({
    store: yup.string().required().label("La boutique").nullable(),
  });
  const {
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors,
    setValue,
    control
  } = useForm<{ store: string }>({
    resolver: yupResolver(validationSchema),
  });
  const [sendData, { isLoading, isSuccess, error, isError, data }] =
    useOnImportProductFromLeo2Mutation();
  const [option, setOption] = useState<any>(null);
  const [options, setOptions] = useState<any>([]);
  const { data: stores = [] } = useGetStoreListQuery({});

  useEffect(() => {
    if (stores?.length) {
      setOptions(
        stores?.map((item) => {
          return {
            label: item?.name,
            value: item?._id,
          };
        })
      );
    }
  }, [stores]);

  const handleSelect = (val) => {
    setOption(val)
    setValue("store",val?.value)
  }

  const handleReset = () => {
    reset();
    setOption(null);
  }

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        iconColor: Color.success,
        confirmButtonColor: Color.success,
        title: data?.message || "Produits importés avec succès !",
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {
        handleReset()
      });
    }
    const err = error as any;

    if (isError) {
      console.log("error", err);

      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Une erreur de statut ${err?.status} est survenue`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [error, isError, isSuccess, isLoading]);

  useEffect(() => {
    console.log(errors);
    cleannerError(errors, clearErrors);
  }, [clearErrors, errors]);

  const onSubmit = async (data: { store: string }) => {
    console.log("data", data);
    await sendData(data);
  };

  return {
    errors,
    onSubmit: handleSubmit(onSubmit),
    isLoading,
    isSuccess,
    handleReset,
    handleSelect,
    options,
    option,
    Controller,
    control
  };
}

export default UseImportProductFromLeo2Form;
