import React, { useEffect, useState } from "react";
import {
  useGetListContactAdminQuery,
  useSendResponseContactMutation,
} from "../../../utils/api/contact/contact.api";
import { Contact } from "../../../utils/api/contact/contact.type";
import { formatDateHour, getAvatar } from "../../../utils/Utils";
import { AlertInfo } from "../../common/Alert";
import "./GestionMessages.css";
import DetailMessageModal, { contactStatus } from "./modal/DetailMessageModal";
import ReactQuill from "react-quill";
import { Color } from "../../../utils/theme";
import Swal from "sweetalert2";
import Pagination from "../../common/Pagination";
import Accordion from "react-bootstrap/Accordion";

const ContactItem = ({ message }: { message: Contact }) => {
  const [sendData, { isLoading, isSuccess, isError, error }] =
    useSendResponseContactMutation();
  const [modalDetailMessage, setModalDetailMessage] = useState(false);
  const [value, setValue] = useState("");

  function openModalDetailMessage(e: any) {
    e.preventDefault();
    setModalDetailMessage(true);
  }

  useEffect(() => {
    // window.scroll(0, 10);
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: "Message envoyé avec succès!",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {
        setValue("");
      });
    }
    const err = error as any;

    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Une erreur de statut ${err?.status} est survenue`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [error, isError, isSuccess]);

  const onSubmitResponse = async (e) => {
    e?.preventDefault();
    var plaintext = value?.replace(/(<([^>]+)>)/gi, "");
    if (plaintext && plaintext?.length) {
      await sendData({ contact: message?._id, message: value });
    }
  };
  return (
    <div key={message?._id}>
      <div className="mb-3">
        {/* ===========REACT-BOOTSTRAP ACCORDION============ */}
        <Accordion
          className="category-filter-acordion"
          id="accordionCategories"
        >
          <Accordion.Item
            eventKey={message?._id}
            className="category-filter-acordion-item mb-1"
          >
            <Accordion.Header className="admin-message-contact-card pb-0">
              <div className="px-2 py-3 mb-2 flex-m gap-1 w-100">
                <div className="row w-100 align-items-center">
                  <div className="col-md-4">
                    <div
                      className="admin-message-contact-card-description d-flex btn p-0"
                      style={{
                        textOverflow: "ellipsis",
                      }}
                      // data-bs-toggle="modal"
                      // data-bs-target="#DetailsMessageModal"
                      data-bs-toggle="collapse"
                      data-bs-target={`#responseContact${message?._id}`}
                      role="button"
                    >
                      {message?.message?.slice(0, 70)}...
                    </div>
                    <p className="admin-message-contact-card-frequence fz-14 mb-0">
                      {formatDateHour(message?.createdAt)}
                    </p>
                  </div>
                  <div className="col-md-2">
                    <button
                      className={`${
                        contactStatus?.find(
                          (el) => el?.value === message?.status
                        )?.class
                      } message-btn`}
                      onClick={openModalDetailMessage}
                    >
                      {
                        contactStatus?.find(
                          (el) => el?.value === message?.status
                        )?.label
                      }
                    </button>
                    <DetailMessageModal
                      modalDetailMessage={modalDetailMessage}
                      setModalDetailMessage={setModalDetailMessage}
                      contact={message}
                    />
                  </div>
                  <div className="col-md-6 flex-r">
                    <p className="admin-message-contact-card-description mb-0">
                      {message?.email}
                    </p>
                  </div>
                </div>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="card card-body admin-content-message mb-1">
                <div className="border-bottom">{message?.message}</div>
                <div className="border-bottom">
                  {message?.responses?.length
                    ? message?.responses.map((resp) => (
                        <div className="row my-3">
                          <div className="col-12 itm-center d-flex">
                            <img
                              className="avatar-auteur-response-card mx-1"
                              src={getAvatar(resp?.user?.avatar)}
                              alt="Avatar"
                              width={"50"}
                              height={"50"}
                              loading="lazy"
                            />
                            <p className="text-response-card fs-12 fw-300 sender-name">
                              Par&nbsp;
                              <strong>
                                {resp?.user?.prenom + " " + resp?.user?.nom}
                              </strong>
                              <br />
                              {/* <span className="cm-sender-email">
                            {resp?.user?.email}{" "}
                          </span>
                          <br /> */}
                              <span className="cm-sender-email">
                                {formatDateHour(resp?.createdAt)}{" "}
                              </span>
                            </p>
                          </div>
                          <div className="col-12 itm-center mx-1">
                            <p
                              className="text-response-card fs-12 fw-300"
                              // style={style.resp_style}
                              dangerouslySetInnerHTML={{
                                __html: resp?.message,
                              }}
                            />
                          </div>
                        </div>
                      ))
                    : null}
                </div>
                <form>
                  <div className="row mb-4 mt-4">
                    <div className="col-md-12 mb-3">
                      <div className="mb-4 form-group">
                        <label className="form-label label-form-admin">
                          Réponse
                        </label>
                        <ReactQuill
                          value={value}
                          onChange={setValue}
                          className="product-ql-description"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="gap-3 flex-r align-center row my-4">
                    <div className="col-md-6 text-end">
                      <button
                        type="submit"
                        className="btn btn-theme"
                        onClick={onSubmitResponse}
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <>
                            <span
                              className="spinner-border spinner-border-sm me-1 d-inline-block"
                              role="status"
                            ></span>
                            <span>Envoie...</span>
                          </>
                        ) : (
                          "Envoyer"
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        {/* ======================= */}
        {/* <div className="px-2 py-3 admin-message-contact-card mb-2 flex-m gap-1 w-100">
          <div className="row w-100 align-items-center">
            <div className="col-md-7">
              <div
                className="admin-message-contact-card-description d-flex btn p-0"
                style={{
                  textOverflow: "ellipsis",
                }}
                // data-bs-toggle="modal"
                // data-bs-target="#DetailsMessageModal"
                data-bs-toggle="collapse"
                data-bs-target={`#responseContact${message?._id}`}
                role="button"
              >
                {message?.message?.slice(0, 100)}...
              </div>
              <p className="admin-message-contact-card-frequence fz-14 mb-0">
                {formatDateHour(message?.createdAt)}
              </p>
            </div>
            <div className="col-md-2">
              <button
                className={`${
                  contactStatus?.find((el) => el?.value === message?.status)
                    ?.class
                } message-btn`}
                onClick={openModalDetailMessage}
              >
                {
                  contactStatus?.find((el) => el?.value === message?.status)
                    ?.label
                }
              </button>
              <DetailMessageModal
                modalDetailMessage={modalDetailMessage}
                setModalDetailMessage={setModalDetailMessage}
                contact={message}
              />
            </div>
            <div className="col-md-3 flex-r">
              <p className="admin-message-contact-card-description mb-0">
                {message?.email}
              </p>
            </div>
          </div>
          <button
            className="no-style-btn accordion-button w-2"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={`#responseContact${message?._id}`}
            aria-expanded="false"
            aria-controls="responseContact"
          >
            <MdKeyboardArrowDown />
          </button>
        </div>
        <div className="collapse" id={`responseContact${message?._id}`}>
          <div className="card card-body admin-content-message mb-1">
            <div className="border-bottom">{message?.message}</div>
            <div className="border-bottom">
              {message?.responses?.length
                ? message?.responses.map((resp) => (
                    <div className="row my-3">
                      <div className="col-12 itm-center d-flex">
                        <img
                          className="avatar-auteur-response-card mx-1"
                          src={getAvatar(resp?.user?.avatar)}
                          alt="Avatar"
                          width={"50"}
                          height={"50"}
                        />
                        <p className="text-response-card fs-12 fw-300 sender-name">
                          Par&nbsp;
                          <strong>
                            {resp?.user?.prenom + " " + resp?.user?.nom}
                          </strong>
                          <br />
                          
                          <span className="cm-sender-email">
                            {formatDateHour(resp?.createdAt)}{" "}
                          </span>
                        </p>
                      </div>
                      <div className="col-12 itm-center mx-1">
                        <p
                          className="text-response-card fs-12 fw-300"
                          
                          dangerouslySetInnerHTML={{ __html: resp?.message }}
                        />
                      </div>
                    </div>
                  ))
                : null}
            </div>
            <form>
              <div className="row mb-4 mt-4">
                <div className="col-md-12 mb-3">
                  <div className="mb-4 form-group">
                    <label className="form-label label-form-admin">
                      Réponse
                    </label>
                    <ReactQuill
                      value={value}
                      onChange={setValue}
                      className="product-ql-description"
                    />
                  </div>
                </div>
              </div>
              <div className="gap-3 flex-r align-center row my-4">
                <div className="col-md-6 text-end">
                  <button
                    type="submit"
                    className="btn btn-theme"
                    onClick={onSubmitResponse}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm me-1 d-inline-block"
                          role="status"
                        ></span>
                        <span>Envoie...</span>
                      </>
                    ) : (
                      "Envoyer"
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div> */}
      </div>
    </div>
  );
};
const GestionMessages = () => {
  const [page, setPage] = useState(1);
  const { data = { results: [], nbPage: 0 }, isLoading } =
    useGetListContactAdminQuery({ page, limit: 10 });

  return (
    <div className="content-graph-admin">
      <div className="dashbord-admin-component admidashboard-bg mb-3">
        <div className="dash-admin-page-content-container mb-3">
          <div className="container-title-content-page mb-4">
            <div className="content-title">
              <h2 className="title-admin">Messages contacts</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="admin-table mb-3">
        <div className="col-left-dashboard col-left-dashboard-responsive mb-3">
          <div className="">
            {!!isLoading && <p>Loading...</p>}
            {!isLoading &&
              (data?.results?.length ? (
                data?.results?.map((item) => <ContactItem message={item} />)
              ) : (
                <AlertInfo message="Aucun message reçu pour le moment!" />
              ))}
          </div>
          <div className="d-flex justify-content-center">
            {data?.nbPage && data?.nbPage > 0 ? (
              <Pagination
                page={page}
                total={data?.nbPage}
                onPageChange={(page) => setPage(page)}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GestionMessages;


