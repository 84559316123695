import { yupResolver } from "@hookform/resolvers/yup";
import { ChangeEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { Product } from "../../../../utils/api/product/product.type";
import { RateFormData } from "../../../../utils/api/rate/rate.type";
import { useAddRateMutation } from "../../../../utils/api/rate/rate.api";
import { Color } from "../../../../utils/theme";
import { cleannerError } from "../../../../utils/Utils";

function UseAddRateProductForm(product: Product) {
  const validationSchema = yup.object().shape({
    //   product: yup.string().required().label("Le produit"),
    stars: yup
      .number()
      .required()
      .label("La note")
      .nullable()
      .transform((value) => (isNaN(value) ? null : value)),
    comment: yup.string().nullable().label("Le commentaire"),
    motif: yup.string().label("Le motif").nullable(),
    annonyme: yup.boolean().default(false).nullable(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
  } = useForm<RateFormData>({
    resolver: yupResolver(validationSchema),
  });
  const [status, setStatus] = useState(false);
  const [sendData, { isLoading, isSuccess, error, isError }] =
    useAddRateMutation();
  const [note, setNote] = useState(0);

  const handleChangeNote = (nextValue) => {
    setNote(nextValue);
    setValue("stars", nextValue);
  };

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        iconColor: Color.success,
        confirmButtonColor: Color.success,
        title: "Votre avis a été envoyé avec succès !",
        showConfirmButton: false,
        timer: 3000,
      });
    }
    const err = error as any;

    if (isError) {
      console.log("error", err);

      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Une erreur de statut ${err?.status} est survenue`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [error, isError, isSuccess, isLoading]);

  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [clearErrors, errors]);

  const handleChangeStatus = (e: ChangeEvent<HTMLInputElement>) => {
    setStatus(e.target.checked);
    console.log(e.target.checked);
    setValue("annonyme", e.target.checked);
  };

  const handleReset = () => {
    setNote(0);
    setStatus(false);
    reset();
  };

  const onSubmit = async (data: RateFormData) => {
    data["product"] = product?._id;
    console.log("data", data);
    await sendData(data);
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    handleChangeNote,
    note,
    handleChangeStatus,
    status,
    isSuccess,
    handleReset,
  };
}

export default UseAddRateProductForm;
