import React, { useState } from "react";
import "react-image-gallery/styles/css/image-gallery.css";
import { Picture } from "../../../utils/api/product/product.type";
import { getImage } from "../../../utils/Utils";
import ReactImageZoom from "react-image-zoom";

const GaleryProduct = ({ pictures = [] }: { pictures?: Picture[] }) => {
  // const imageGalery = GalleryItem;
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const props = {
    width: 400,
    height: 400,
    // zoomWidth: 300,
    zoomPosition: "right",
    img: getImage(pictures[selectedIndex]?.image),
    id: "react-image-zoom"
  };
  return (
    <div className="container-galery-detail-product">
      <div className="container-galery-group-product">
        <div className="content-img-body-page no-view-mobile">
          {pictures?.map((item, index) => (
            <div
              key={`galery-image-item${item?._id}`}
              className={`galery-item-col ${
                selectedIndex === index && "selectedIndex"
              }`}
              onClick={() => setSelectedIndex(index)}
            >
              <img
                alt={item?._id}
                src={getImage(item?.image)}
                className={`galery-image-item `}
                loading="lazy"
              />
            </div>
          ))}
        </div>
        <div className="galery-left-side-image-container">
          <ReactImageZoom {...props} />
        </div>
        <div className="content-img-body-page no-view-desktop">
          {pictures?.map((item, index) => (
            <div
              key={`galery-image-item${item?._id}`}
              className={`galery-item-col ${
                selectedIndex === index && "selectedIndex"
              }`}
              onClick={() => setSelectedIndex(index)}
            >
              <img
                alt={item?._id}
                src={getImage(item?.image)}
                className={`galery-image-item `}
                loading="lazy"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GaleryProduct;
// const GalleryItem = [
//   {
//     id: 0,
//     image: Commande,
//   },
//   {
//     id: 1,
//     image: Commande2,
//   },
//   {
//     id: 2,
//     image: Commande,
//   },
//   {
//     id: 3,
//     image: Commande2,
//   },
//   {
//     id: 4,
//     image: Commande,
//   },
//   {
//     id: 5,
//     image: Commande2,
//   },
//   {
//     id: 6,
//     image: Commande,
//   },
// ];
