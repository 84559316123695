import { useEffect } from "react";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { cleannerError } from "../../../../utils/Utils";
import {
  Contact,
  ContactFormData,
} from "../../../../utils/api/contact/contact.type";
import { useAddOrEditContactMutation } from "../../../../utils/api/contact/contact.api";
import { Color } from "../../../../utils/theme";
import { useAppSelector } from "../../../../redux/hook";

function UseCrudMessageForm(contact?: Contact, setModalDetailMessage?:any) {
  const validationSchema = yup.object().shape({
    message: yup.string().required().label("Le message").nullable(),
    email: yup.string().email().required().label("L'email").nullable(),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
  } = useForm<ContactFormData>({
    resolver: yupResolver(validationSchema),
  });
  const [sendData, { isLoading, isSuccess, error, isError }] =
    useAddOrEditContactMutation();
  const { user } = useAppSelector((s) => s?.user);

  useEffect(() => {
    if (user && !contact) {
      setValue("email", user?.email);
    }
  }, [user, contact]);
  useEffect(() => {
    if (contact?._id) {
      const fields: (keyof ContactFormData)[] = [
        "_id",
        "email",
        "message",
        "status",
      ];
      for (let field of fields) {
        register(field);
        setValue(field, contact[field]);
      }
    }
  }, [contact]);
  useEffect(() => {
    // window.scroll(0, 10);
    if (isSuccess) {
      window.scroll(0, 10);
      Swal.fire({
        icon: "success",
        title: !contact
          ? "Message envoyé avec succès!"
          : `Statut modifié avec succès!`,
        // text: "Un email de confirmation vous a été envoyé pour valider votre inscription.",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {
        if (!contact) {
          reset();
        } else {
          if (setModalDetailMessage) {
            setModalDetailMessage()
          }
        }
      });
    }
    const err = error as any;

    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Une erreur de statut ${err?.status} est survenue`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [error, isError, isSuccess]);
  useEffect(() => {
    cleannerError(errors, clearErrors, 3000);
  }, [clearErrors, errors]);

  const onSubmit = async (data: ContactFormData) => {
    console.log("data register", data);

    await sendData(data);
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
  };
}

export default UseCrudMessageForm;
