import React from "react";
import {
  AiFillHeart,
  AiOutlineHeart,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import StarRatingComponent from "react-star-rating-component";
import Fade from "react-reveal/Fade";
import { NavLink } from "react-router-dom";
import { Product } from "../../../utils/api/product/product.type";
import { useGetProductListQuery } from "../../../utils/api/product/product.api";
import Skeleton from "react-loading-skeleton";
import { formatCurrency, getImage } from "../../../utils/Utils";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import {
  addCartProduct,
  getCartCount,
  getSubTotal,
  calculateTax,
  getTotalAmount,
} from "../../../redux/slice/useCartSlice";
import { AddedCartConform } from "./ParcoursAchat";
import { AlertInfo } from "../../common/Alert";
import newBanner from "../../../assets/appImages/new.webp";
import { useToggleFavorite } from "./ProduitItemPage";

export const ProductSimilarySkeleton = () => {
  return (
    <>
      {Array(4)
        .fill(4)
        .map((item, i) => (
          <div
            className="col-lg-3 col-md-6 col-produits-similaires mb-3"
            key={i + `${item}`}
          >
            <div
              className="product-item-container"
              style={{ textAlign: "center" }}
            >
              {/* <button className="btn icon-fav-product">
                <Skeleton width={20} height={20} circle={true} />
              </button> */}

              <div className="content-img-product-item">
                <Skeleton className="img-product-item" />
              </div>
              <div className="content-btn-buy-product-item py-3">
                <Skeleton
                  className="btn btn-buy-product-item bg-transparent"
                  width={`50%`}
                />
              </div>
              <div className="text-center">
                <Skeleton
                  className="price-product-item"
                  width={`60%`}
                  height={20}
                />
                <Skeleton
                  className="name-prduct fs-15"
                  height={15}
                  width={`70%`}
                />
                <div className="content-rate-product">
                  <StarRatingComponent
                    name="rate2"
                    starCount={5}
                    value={0}
                    starColor="#000000"
                    emptyStarColor="#D4D6D5"
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
    </>
  );
};

const ProductItem = ({ item }: { item: Product }) => {
  const onConfirm = AddedCartConform(item);
  const dispatch = useAppDispatch();
  const {
    onToggleFavorite,
    isFavorite,
  } = useToggleFavorite(item);

  const addToCart = () => {
    dispatch(addCartProduct({ product: item, qty: 1 }));
    dispatch(getCartCount());
    dispatch(getSubTotal());
    dispatch(calculateTax());
    dispatch(getTotalAmount());
    onConfirm();
  };
  return (
    <div className="content-col-produits-similaires text-center position-relative">
      <Fade top cascade>
        <div className="product-item-container">
          <NavLink
            to={`/produit/${item?._id}`}
            state={item}
            className="no-link w-100"
          >
            <div className="content-img-product-item">
              {item?.isNewProduct ? (
                <div className="new-product-indicator">
                  <img
                    src={newBanner}
                    className="new-product-indicator-banner"
                    loading="lazy"
                  />
                </div>
              ) : null}
              <img
                src={getImage(item?.pictures[0]?.image)}
                alt={item?.name}
                className="img-product-item"
                loading="lazy"
              />
            </div>
          </NavLink>
          <div className="content-btn-buy-product-item py-3"></div>
          <div className="content-btn-buy-product-item py-3">
            <button
              onClick={() => addToCart()}
              className="btn btn-buy-product-item"
            >
              <AiOutlineShoppingCart style={{ fontSize: 16 }} />
              <span className="ps-2">Ajouter </span>
            </button>
          </div>
          <NavLink
            to={`/produit/${item?._id}`}
            state={item}
            className="no-link w-100"
          >
            <div className="content-price-product-solde mb-3">
              <p className="price-product-item mb-0">
                {item?.promote?.price || item?.price}{" "}
                <span style={{ fontWeight: 300 }}>FCFA</span>{" "}
              </p>
              {!!item?.promote?._id && (
                <p className="price-solde-product-item mb-0">
                  {formatCurrency(item?.price)}
                </p>
              )}
            </div>

            <p className="name-prduct fs-15">{item?.name} </p>
            <div className="content-rate-product">
              <StarRatingComponent
                name="rate2"
                starCount={5}
                value={item?.avreageRate}
                starColor="#000000"
                emptyStarColor="#D4D6D5"
              />
            </div>
          </NavLink>
          <button className="btn icon-heard-product" onClick={onToggleFavorite}>
            {isFavorite ? (
              <AiFillHeart style={{ color: "#E20613", fontSize: 20 }} />
            ) : (
              <AiOutlineHeart />
            )}
          </button>
        </div>
      </Fade>
    </div>
  );
};

function ProductSimilary({ item }: { item: Product }) {
  const { store } = useAppSelector((state) => state.config);
  const { data = { results: [], count: 0 }, isLoading } =
    useGetProductListQuery({
      limit: 10,
      productType: item?.productType,
      category: item?.productType === "product" ? item?.category?._id : "",
      store: store?._id,
    });
  return (
    <div className="container-produits-similaires mb-5">
      <h3 className="title-produits-similaires">Produits similaires</h3>
      <div className="row py-4">
        {!!isLoading && <ProductSimilarySkeleton />}

        {!isLoading &&
          (data?.results?.filter((el) => el?._id !== item?._id)?.length ? (
            data?.results
              ?.filter((el) => el?._id !== item?._id)
              ?.slice(0, 8)
              ?.map((produit) => (
                <div
                  className="col-lg-3 col-md-6 col-produits-similaires mb-3"
                  key={produit._id}
                >
                  <ProductItem item={produit} key={produit._id} />
                </div>
              ))
          ) : (
            <AlertInfo message="Aucune produit similaire disponible pour le moment!" />
          ))}
      </div>
    </div>
  );
}

export default ProductSimilary;
