import React from "react";
import { useGetSelectedProductsQuery } from "../../utils/api/product/product.api";
import { AlertInfo } from "../common/Alert";
import { ProductItemsSkeleton } from "../user/ParcoursAchat/ParcoursAchat";
import { ProductItem, responsive } from "./HomePage";
import { useAppSelector } from "../../redux/hook";
import AliceCarousel from "react-alice-carousel";

function PromoteProduct({ havePromo }: { havePromo: boolean }) {
  const { store } = useAppSelector((state) => state.config);
  const { data = [], isLoading } = useGetSelectedProductsQuery(store?._id);
  return (
    <div className="w-100">
      <div className="row w-100 no-view-mobile">
        {!!isLoading && <ProductItemsSkeleton />}
        {!isLoading &&
          (data?.length ? (
            [...data]?.slice(0, havePromo ? 8 : 10)?.map((item) => (
              <div
                className={`${
                  havePromo ? "col-md-3" : "col-md-4"
                } mb-3 col-select-item d-flex`}
                key={item?._id}
              >
                <ProductItem item={item} />
              </div>
            ))
          ) : (
            <AlertInfo message="Aucun produit en promo cette semaine !" />
          ))}
      </div>
      <div className="no-view-desktop">
        <AliceCarousel
          mouseTracking
          items={data?.map((item) => {
            return <ProductItem item={item} key={item?._id} />;
          })}
          responsive={responsive}
          autoPlayStrategy="default"
          controlsStrategy="alternate"
          autoPlay={true}
          infinite={true}
          disableButtonsControls={true}
          autoPlayInterval={2000}
          disableDotsControls={true}
        />
      </div>
    </div>
  );
}

export default PromoteProduct;
