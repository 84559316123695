import React, { Dispatch, SetStateAction } from "react";
import Modal from "react-modal";
import PhoneInput from "react-phone-input-2";
import ErrorMessage from "../../../common/ErrorMessage";
import { InputAdmin } from "../../../common/Input";
import CloseModalBtn from "../../../shared/CloseModalBtn";
import UseCrudUserForm from "../requestUserForm/UseCrudUserForm";

export const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    zIndex: 99999,
  },
};
function AddAdminModal({
  modalAddAdmin,
  setModalAddAdmin,
  type,
}: {
  modalAddAdmin: boolean;
  setModalAddAdmin: Dispatch<SetStateAction<boolean>>;
  type?: string;
}) {
  const {
    register,
    onSubmit,
    isLoading,
    errors,
    handlereset,
    phone,
    handleChangePhone,
  } = UseCrudUserForm(setModalAddAdmin,undefined,type);
  function closeModalAddAdmin() {
    setModalAddAdmin(false);
    handlereset();
  }

  return (
    <Modal
      isOpen={modalAddAdmin}
      onRequestClose={closeModalAddAdmin}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="modal-header flex-sb">
        <h5 className="modal-title-admin">Ajouter un utilisateur</h5>
        <CloseModalBtn onClick={closeModalAddAdmin} />
      </div>

      <div className="pt-3">
        <form onSubmit={onSubmit}>
          <div className="row py-3 px-2">
            <div className="col-md-6 mb-4">
              <InputAdmin
                label="Prénom"
                type={"text"}
                placeholder="Prénom"
                {...register("prenom")}
                error={errors?.prenom?.message}
              />
            </div>
            <div className="col-md-6 mb-4">
              <InputAdmin
                label="Nom"
                type={"text"}
                placeholder="Nom"
                {...register("nom")}
                error={errors?.nom?.message}
              />
            </div>
            <div className="col-md-6 mb-4">
              <InputAdmin
                label="Email"
                type={"email"}
                placeholder="Email"
                {...register("email")}
                error={errors?.email?.message}
              />
            </div>

            <div className="col-md-6 mb-4">
              <label className="form-label form-label-admin">Télépone</label>

              <PhoneInput
                inputProps={{
                  name: "phone",
                }}
                defaultMask={".. ... .. .."}
                country="sn"
                value={phone}
                enableSearch={true}
                onChange={(phone, country) => handleChangePhone(phone, country)}
                inputClass="form-control form-control-admin-tel"
                enableAreaCodes={true}
                prefix="+"
                countryCodeEditable={false}
              />
              <ErrorMessage
                message={errors?.telephone && errors?.telephone?.message}
              />
            </div>
            <div className="flex-r">
              <button
                className="btn btn-cancelled"
                type="button"
                onClick={closeModalAddAdmin}
              >
                Annuler
              </button>
              <button
                type="submit"
                className="btn btn-theme"
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                      role="status"
                    ></span>
                    <span>Ajout...</span>
                  </>
                ) : (
                  "Ajouter"
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default AddAdminModal;

