import React from 'react'
import { BiMessageSquareAdd } from 'react-icons/bi';
import AddOrEditZone from './modal/AddOrEditZone';
import ZoneTable from './table/ZoneTable';

function GestionZones() {
  return (
    <div className="content-graph-admin">
      <div className="dashbord-admin-component admidashboard-bg mb-3">
        <div className="dash-admin-page-content-container mb-3">
          <div className="container-title-content-page mb-4">
            <div className="content-title">
              <h2 className="title-admin">Gestion des zones</h2>
            </div>
            <div className="content-btn-action-admin">
              <button
                className="btn btn-add-admin"
                data-bs-toggle="modal"
                data-bs-target="#AddZoneModal"
              >
                <BiMessageSquareAdd />
                <span className="ps-2">Ajouter une zones</span>
              </button>
              <AddOrEditZone modalId="AddZoneModal" />
            </div>
          </div>
        </div>
      </div>
      <div className="admin-table mb-3">
        {/* <div className="tab-dash-admin-filter-container">
          <form className="row g-3 tab-dash-admin-filter-form">
            <div className="col-auto">
              <label
                htmlFor="filtreProduit"
                className="tab-dash-admin-filter-label"
              >
                Filtrer
              </label>
            </div>
            <div className="col-auto">
              <input
                type="text"
                className="form-control tab-dash-admin-filter-input"
                id="filtreProduit"
                placeholder="Entrer un nom"
                // onChange={(e) => setName(e?.target?.value)}
              />
            </div>
          </form>
        </div> */}
        <ZoneTable />
      </div>
    </div>
  );
}

export default GestionZones