import React from "react";
import { NavLink } from "react-router-dom";
import { getImage } from "../../utils/Utils";
import {
  useGetProductListQuery,
  useGetPromoteProductListQuery,
} from "../../utils/api/product/product.api";
import { useAppSelector } from "../../redux/hook";
import Skeleton from "react-loading-skeleton";
import $ from "jquery";
// import PicturePromo from "../../assets/appImages/picture.png";
// import Zoom from "react-reveal/Zoom";
import Flash from "react-reveal/Flash";
import { useGetAllbannerVisitorQuery } from "../../utils/api/banner/banner.api";
import Carousel from "react-bootstrap/Carousel";

export function getElementRandom(array) {
  var indexRandom = Math.floor(Math.random() * array.length);

  // Retourner l'élément correspondant à l'index aléatoire
  return array[indexRandom];
}

const CarouselSkeleton = () => {
  return (
    <>
      {[...Array(1)]?.map((el, i) => (
        <div key={i}>
          <Skeleton className="d-block w-100 " height={300} />
        </div>
      ))}
    </>
  );
};

export const BannerHomePageSection = () => {
  const { data = [], isLoading } = useGetAllbannerVisitorQuery();

  return (
    <>
      <div className="content-col-left-banner-home-page">
        {!!isLoading && <CarouselSkeleton />}
        {!isLoading && (
          <Carousel>
            {data?.map((item) => (
              <Carousel.Item key={item?._id}>
                <img
                  className="d-block w-100 img-banner-home-page-v2"
                  src={getImage(item?.banner)}
                  alt={item?._id}
                  loading="lazy"
                />
                <Carousel.Caption>
                  <div className="content-btn-col-left-banner">
                    <NavLink
                      to={item?.page}
                      className="btn btn-col-left-banner-home-page animate__animated animate__infinite animate__pulse"
                    >
                      J’en profite
                    </NavLink>
                  </div>
                </Carousel.Caption>
              </Carousel.Item>
            ))}
          </Carousel>
        )}
      </div>
    </>
  );
};



const ProductSlide = () => {
  const { store } = useAppSelector((state) => state.config);
  const { data = { results: [], nbPage: 0 }, isLoading } =
    useGetProductListQuery({
      productType: "product",
      orderBy: "rateCount",
      order: "ASC",
      store: store?._id,
    });

  React.useEffect(() => {
    if (data?.results?.length) {
      setTimeout(() => {
        $(`#slide1`).click();
      }, 5000);
    }
  }, [data]);
  return (
    <>
      {!isLoading && (
        <div className="col-lg-5 col-md-12 col-right-banner-home-page mb-3 d-flex">
          <div className="content-col-right-banner-home-page">
            <div
              id="carouselProductProduct"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-indicators d-none">
                {data?.results?.map((item, i) => (
                  <button
                    type="button"
                    data-bs-target="#carouselProductProduct"
                    data-bs-slide-to={i}
                    key={item?._id}
                    id={`slide${i + 1}`}
                    className={i === 0 ? "active" : ""}
                    aria-current={i === 0 ? true : false}
                    aria-label={`slide ${i + 1}`}
                  ></button>
                ))}
              </div>
              <div className="carousel-inner">
                {data?.results?.map((product, i) => (
                  <div
                    className={`carousel-item ${i === 0 ? "active" : ""}`}
                    key={product?._id}
                    data-bs-interval="5000"
                  >
                    <div className="carousel-item-promos-custom">
                      <div className="content-reduction-card">
                        <Flash>
                          <h3 className="text-title-card-product-no-pertinent">
                            {product?.name}
                          </h3>
                        </Flash>
                        {/* <Zoom top cascade>
                          <h2 className="title-card-reduction">
                            -{product?.promote?.promotion?.taux}%
                          </h2>
                        </Zoom> */}
                        <div className="content-img-promo-reduction-banner2">
                          <img
                            src={getImage(product?.pictures[0]?.image)}
                            alt={product?._id}
                            className="img-product-reduction-promo-banner2 animate__animated animate__zoomInRight"
                            loading="lazy"
                          />
                        </div>
                        <div className="content-btn-card-reduction pt-4">
                          <NavLink
                            // to="/achats"
                            to={`/produit/${product?._id}`}
                            state={product}
                            className="btn btn-card-reduction animate__animated animate__infinite animate__pulse"
                          >
                            J’en profite
                          </NavLink>
                        </div>
                      </div>
                      <div className="content-img-product-banner animate__animated animate__infinite animate__pulse">
                        <img
                          src={getImage(product?.pictures[0]?.image)}
                          alt={product?.pictures[0]?._id}
                          className="img-product-reduction"
                          loading="lazy"
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselProductProduct"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselProductProduct"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
      )}
      {!!isLoading && (
        <div className="col-lg-5 col-md-12 col-right-banner-home-page mb-3 d-flex">
          {" "}
          <Skeleton height={300} width={`38vw`} />
        </div>
      )}
    </>
  );
};

function BannerHomepage() {
  const { store } = useAppSelector((state) => state.config);
  const { data = { results: [] }, isLoading } = useGetPromoteProductListQuery({
    store: store?._id,
  });

  React.useEffect(() => {
    if (data?.results?.length) {
      setTimeout(() => {
        $(`#slide1`).click();
      }, 5000);
    }
  }, [data]);
  return (
    <section className="section-banner-homepage">
      <div className="row row-banner-home-page">
        <div className="col-lg-7 col-md-12 col-left-banner-home-page mb-3 d-flex">
          <BannerHomePageSection />
        </div>
        {!isLoading &&
          (data?.results?.length ? (
            <div className="col-lg-5 col-md-12 col-right-banner-home-page mb-3 d-flex">
              <div className="content-col-right-banner-home-page">
                <div
                  id="carouselProductProduct"
                  className="carousel slide"
                  data-bs-ride="carousel"
                >
                  <div className="carousel-indicators d-none">
                    {data?.results?.map((item, i) => (
                      <button
                        type="button"
                        data-bs-target="#carouselProductProduct"
                        data-bs-slide-to={i}
                        key={item?._id}
                        id={`slide${i + 1}`}
                        className={i === 0 ? "active" : ""}
                        aria-current={i === 0 ? true : false}
                        aria-label={`slide ${i + 1}`}
                      ></button>
                    ))}
                  </div>
                  <div className="carousel-inner">
                    {data?.results?.map((product, i) => (
                      <div
                        className={`carousel-item ${i === 0 ? "active" : ""}`}
                        key={product?._id}
                        data-bs-interval="5000"
                      >
                        <div className="carousel-item-promos-custom">
                          <div className="content-reduction-card">
                            {/* <Flash>
                                <h3 className="text-title-card-reduction">
                                  REDUCTION DE
                                </h3>
                              </Flash>
                              <Zoom top cascade>
                                <h2 className="title-card-reduction">
                                  -{product?.promote?.promotion?.taux}%
                                </h2>
                              </Zoom> */}
                            <div className="content-img-promo-reduction-banner2">
                              <img
                                src={getImage(
                                  product?.promote?.promotion?.image
                                )}
                                alt={product?._id}
                                className="img-product-reduction-promo-banner2 animate__animated animate__zoomInRight"
                                loading="lazy"
                              />
                            </div>
                            <div className="content-btn-card-reduction pt-4">
                              <NavLink
                                // to="/achats"
                                to={`/produit/${product?._id}`}
                                state={product}
                                className="btn btn-card-reduction animate__animated animate__infinite animate__pulse"
                              >
                                J’en profite
                              </NavLink>
                            </div>
                          </div>
                          <div className="content-img-product-banner animate__animated animate__infinite animate__pulse">
                            <img
                              src={getImage(product?.pictures[0]?.image)}
                              alt={product?.pictures[0]?._id}
                              className="img-product-reduction"
                              loading="lazy"
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselProductProduct"
                    data-bs-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselProductProduct"
                    data-bs-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <ProductSlide />
          ))}
        {!!isLoading && (
          <div className="col-lg-5 col-md-12 col-right-banner-home-page mb-3 d-flex">
            {" "}
            <Skeleton height={300} width={`38vw`} />
          </div>
        )}
        {/* {data?.results && data?.results?.length > 0 ? (
          <>
            
          </>
        ) : null} */}
      </div>
    </section>
  );
}



export default BannerHomepage;

