/* eslint-disable @typescript-eslint/no-use-before-define */
import * as React from "react";
import "./HomePage.css";
import FrontHeader from "../shared/FrontHeader/FrontHeader";
import FrontFooter from "../shared/FrontFooter/FrontFooter";
import BannerHomepage from "./BannerHomepage";
import { IoAddOutline } from "react-icons/io5";
import {
  AiFillHeart,
  AiOutlineHeart,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import Fade from "react-reveal/Fade";
import StarRatingComponent from "react-star-rating-component";
import { NavLink } from "react-router-dom";
import Zoom from "react-reveal/Zoom";
import Bounce from "react-reveal/Bounce";
import { useGetProductListQuery } from "../../utils/api/product/product.api";
import { Product } from "../../utils/api/product/product.type";
import { formatCurrency, getImage } from "../../utils/Utils";
import Skeleton from "react-loading-skeleton";
import { AlertInfo } from "../common/Alert";
import { useAppDispatch, useAppSelector } from "../../redux/hook";
import {
  addCartProduct,
  calculateTax,
  getCartCount,
  getPreOrder,
  getSubTotal,
  getTotalAmount,
} from "../../redux/slice/useCartSlice";
import {
  ALertDisponibility,
  AddedCartConform,
} from "../user/ParcoursAchat/ParcoursAchat";
import PackPage from "./PackPage";
import PromoteProduct from "./PromoteProduct";
import { useToggleFavorite } from "../user/ParcoursAchat/ProduitItemPage";
import Swal, { SweetAlertResult } from "sweetalert2";
import { Color } from "../../utils/theme";
import newBanner from "../../assets/appImages/new.webp";
import AliceCarousel from "react-alice-carousel";
import { useGetConfigAppQuery } from "../../utils/api/config/config.api";

export const responsive = {
  0: { items: 1 },
  768: { items: 1 },
  1024: { items: 3 },
};
const HomePage = () => {
  const { store } = useAppSelector((state) => state.config);

  const { data = { results: [], count: 0 }, isLoading } =
    useGetProductListQuery({
      limit: 6,
      productType: "product",
      store: store?._id,
      orderBy: "avreageRate",
    });

  return (
    <div className="landing-page-component">
      <FrontHeader />
      <div className="page-content">
        <div className="container-low-price pt-4">
          <BannerHomepage />
          <div className="container-home-page mb-3">
            <div className="content-title-home-page pb-5">
              <div className="title-home-page-container">
                <Zoom left cascade>
                  <h3 className="title-home-page mb-0">
                    Les produits vedettes
                  </h3>
                </Zoom>
              </div>
              <div className="content-btn-title-home-page">
                <Bounce right>
                  <NavLink to="/achats" className="btn btn-title-home-page">
                    <IoAddOutline
                      style={{
                        color: "#2A71D5",
                        fontSize: 20,
                      }}
                    />
                    <span className="ps-2">Voir plus</span>
                  </NavLink>
                </Bounce>
              </div>
            </div>
            <div className="row no-view-mobile">
              {!!isLoading && <ProductItemSkeleton />}
              {!isLoading &&
                (data?.results?.length ? (
                  data?.results?.map((produit) => (
                    <div
                      className="col-lg-2 col-md-4 mb-3 col-product-item d-flex"
                      key={produit._id}
                    >
                      <Fade bottom>
                        <ProductItem item={produit} key={produit._id} />
                      </Fade>
                    </div>
                  ))
                ) : (
                  <AlertInfo message="Aucun produit disponible pour le moment!" />
                ))}
            </div>
            <div className="no-view-desktop w-100">
              <AliceCarousel
                mouseTracking
                items={data?.results?.map((produit) => {
                  return <ProductItem item={produit} key={produit._id} />;
                })}
                responsive={responsive}
                autoPlayStrategy="default"
                controlsStrategy="alternate"
                autoPlay={true}
                infinite={true}
                disableButtonsControls={true}
                autoPlayInterval={2000}
                disableDotsControls={true}
              />
            </div>
          </div>
          {/* Pack */}
          <PackSection />
          {/* end */}
          {/* Week section */}
          <WeekSection />
          {/* end */}
        </div>
      </div>
      <FrontFooter />
    </div>
  );
};

export default HomePage;

export const ProductItem = ({ item }: { item: Product }) => {
  const onConfirm = AddedCartConform(item);
  const onAlert = ALertDisponibility(item);
  const { cartItems } = useAppSelector((state) => state.cart);
  // const [isFavorite, setIsFavorite] = React.useState(false);
  const dispatch = useAppDispatch();
  const { onToggleFavorite, isFavorite } = useToggleFavorite(item);

  const addToCart = () => {
    let cartItem = cartItems?.find((el) => el?.product?._id === item?._id);

    if (item?.stock > 0) {
      if (cartItem) {
        console.log("cartItem", cartItem);
        if (parseInt(item?.stock as unknown as string) === cartItem?.qty) {
          Swal.fire({
            title: `La quantité demandée n'est actuellement pas disponible en stock. Souhaitez-vous faire une pré-commande ?`,
            icon: "success",
            text: "Vous serez notifié de la disponibilité de ce produit.",
            showCancelButton: true,
            confirmButtonText: "PRÉ-COMMANDER",
            cancelButtonText: "ANNULER",
            showLoaderOnConfirm: true,
            iconColor: Color.default,
            confirmButtonColor: Color.default,
            customClass: {
              confirmButton: "btn btn-sweet-confirm",
              cancelButton: "btn btn-sweet-cancel",
            },
            reverseButtons: true,
          }).then((result: SweetAlertResult<any>) => {
            if (result?.isConfirmed) {
              dispatch(addCartProduct({ product: item, qty: 1 }));
              dispatch(getCartCount());
              dispatch(getSubTotal());
              dispatch(calculateTax());
              dispatch(getTotalAmount());
              dispatch(getPreOrder(true));
              onConfirm();
            }
          });
        } else {
          dispatch(addCartProduct({ product: item, qty: 1 }));
          dispatch(getCartCount());
          dispatch(getSubTotal());
          dispatch(calculateTax());
          dispatch(getTotalAmount());
          onConfirm();
        }
      } else {
        dispatch(addCartProduct({ product: item, qty: 1 }));
        dispatch(getCartCount());
        dispatch(getSubTotal());
        dispatch(calculateTax());
        dispatch(getTotalAmount());
        onConfirm();
      }
    } else {
      onAlert();
    }
  };
  return (
    <Fade bottom cascade>
      <div className="product-item-container">
        <NavLink
          to={`/produit/${item?._id}`}
          state={item}
          className="no-link w-100"
        >
          <div className="content-img-product-item">
            {item?.isNewProduct ? (
              <div className="new-product-indicator">
                <img
                  src={newBanner}
                  className="new-product-indicator-banner"
                  loading="lazy"
                  alt={`new${item?.name}`}
                />
              </div>
            ) : null}
            <img
              src={getImage(item?.pictures[0]?.image)}
              alt={item?.name}
              className="img-product-item"
              loading="lazy"
            />
          </div>
        </NavLink>
        <div className="content-btn-buy-product-item py-3">
          <button
            onClick={() => addToCart()}
            className="btn btn-buy-product-item"
          >
            <AiOutlineShoppingCart style={{ fontSize: 16 }} />
            <span className="ps-2">Ajouter </span>
          </button>
        </div>
        <NavLink
          to={`/produit/${item?._id}`}
          state={item}
          className="no-link w-100"
        >
          <div className="content-price-product-solde mb-3">
            <p className="price-product-item mb-0">
              {item?.promote?.price || item?.price}{" "}
              <span style={{ fontWeight: 300 }}>FCFA</span>{" "}
            </p>
            {!!item?.promote?._id && (
              <p className="price-solde-product-item mb-0">
                {formatCurrency(item?.price)}
              </p>
            )}
          </div>
          {/* <p className="price-product-item">
            {parseInt(item?.stock as unknown as string)}&nbsp;
            <span style={{ fontWeight: 300 }}>Disponible(s)</span>{" "}
          </p> */}
          <p className="name-prduct fs-15">{item?.name} </p>
          <div className="content-rate-product">
            <StarRatingComponent
              name="rate2"
              starCount={5}
              value={item?.avreageRate}
              starColor="#000000"
              emptyStarColor="#D4D6D5"
            />
          </div>
        </NavLink>
        <button className="btn icon-heard-product" onClick={onToggleFavorite}>
          {isFavorite ? (
            <AiFillHeart style={{ color: "#E20613", fontSize: 20 }} />
          ) : (
            <AiOutlineHeart />
          )}
        </button>
      </div>
    </Fade>
  );
};

export const ProductItemSkeleton = () => {
  return (
    <>
      {Array(12)
        .fill(12)
        .map((item, i) => (
          <div
            className="col-lg-2 col-md-4 mb-3 col-product-item d-flex"
            key={i}
          >
            <div
              className="product-item-container"
              style={{ border: "1px solid #ebebeb", textAlign: "center" }}
            >
              <div className="content-img-product-item">
                <Skeleton className="img-product-item" />
              </div>
              <div className="content-btn-buy-product-item py-3">
                <Skeleton
                  className="btn btn-buy-product-item bg-transparent"
                  width={`50%`}
                />
              </div>
              <div className="text-center">
                <Skeleton
                  className="price-product-item"
                  width={`60%`}
                  height={20}
                />
                <Skeleton
                  className="name-prduct fs-15"
                  width={`70%`}
                  height={15}
                />
                <div className="content-rate-product">
                  <StarRatingComponent
                    name="rate2"
                    starCount={5}
                    value={0}
                    starColor="#000000"
                    emptyStarColor="#D4D6D5"
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
    </>
  );
};

const PackSection = () => {
  const { store } = useAppSelector((state) => state.config);
  const { data = { results: [], count: 0 }, isLoading } =
    useGetProductListQuery({
      limit: 6,
      productType: "pack",
      store: store?._id,
    });
  return (
    <>
      {!!data?.results?.length && (
        <div className="container-home-page mb-3">
          <div className="content-title-home-page pb-5">
            <div className="title-home-page-container">
              <Zoom left cascade>
                <h3 className="title-home-page mb-0">Nos packs</h3>
              </Zoom>
            </div>
            <div className="content-btn-title-home-page">
              <Bounce right>
                <NavLink to="/nos-packs" className="btn btn-title-home-page">
                  <IoAddOutline
                    style={{
                      color: "#2A71D5",
                      fontSize: 20,
                    }}
                  />
                  <span className="ps-2">Voir plus</span>
                </NavLink>
              </Bounce>
            </div>
          </div>
          <PackPage data={data?.results} isLoading={isLoading} />
        </div>
      )}
    </>
  );
};

const WeekSection = () => {
  const { data,isLoading } = useGetConfigAppQuery();
  return (
    <div className="container-home-page mb-3">
      <div className="content-title-home-page pb-5">
        <div className="title-home-page-container">
          <Zoom left cascade>
            <h3 className="title-home-page mb-0">Sélection de la semaine</h3>
          </Zoom>
        </div>
        <div className="content-btn-title-home-page">
          <Bounce right>
            <NavLink to="/achats" className="btn btn-title-home-page">
              <IoAddOutline
                style={{
                  color: "#2A71D5",
                  fontSize: 20,
                }}
              />
              <span className="ps-2">Voir plus</span>
            </NavLink>
          </Bounce>
        </div>
      </div>
      <div className="row">
        {!!isLoading && <p>Chargement</p>}
        {!isLoading &&
          (data?.banner ? (
            <div className="col-md-3 col-left-select-home-page mb-3 d-flex">
              <div
                className="content-col-left-select-product-bg bander-promo"
                style={{
                  backgroundImage: `url(${getImage(data?.banner)})`,
                }}
              >
                <div className="content-btn-col-left-select-product-bg text-center">
                  <NavLink
                    to={data?.bannerLink || "/nos-promos"}
                    className="btn btn-col-left-select-product-bg animate__animated animate__infinite animate__pulse"
                  >
                    <span> </span>
                    <span> </span>
                    <span> </span>
                    <span> </span>
                    J’en profite
                  </NavLink>
                </div>
              </div>
            </div>
          ) : null)}

        <div
          className={`${
            data?.banner ? "col-md-9" : "col-md-12"
          } col-right-select-home-page mb-3 d-flex`}
        >
          <PromoteProduct havePromo={data?.banner ? true : false} />
        </div>
      </div>
    </div>
  );
};
