import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import TableSkeleton from "../../../common/TableSkeleton";
import { AiFillEye } from "react-icons/ai";
import { BsTrashFill } from "react-icons/bs";
import { Product } from "../../../../utils/api/product/product.type";
import Pagination from "../../../common/Pagination";
import { MdModeEditOutline } from "react-icons/md";
import { NavLink } from "react-router-dom";
import { useGetListProductAdminQuery } from "../../../../utils/api/product/product.api";
import { getImage } from "../../../../utils/Utils";
import {
  UseDeleteProduct,
  UseToggleProduct,
} from "../requestForm/UseCrudProductForm";

type CustomPaginationProps = {
  nbPages: number | any;
  page: number;
  onChange: (page: number, perPage: number) => any;
  perPage: number;
};
export function CustomPagination({
  nbPages,
  page,
  perPage,
  onChange,
}: CustomPaginationProps) {
  if (parseInt(nbPages) <= 0) return null;
  return (
    <div className="d-flex align-items-baseline justify-content-between">
      <div className="footer-form d-flex mt-3">
        {nbPages > 0 && (
          <select
            className="form-control select-perpage"
            name="limit"
            id="limit"
            value={perPage}
            onChange={(e) => {
              onChange(1, parseInt(e.target.value, 10));
            }}
          >
            <option value="10">10</option>
            <option value="30">30</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        )}
      </div>

      {parseInt(nbPages) > 0 ? (
        <Pagination
          page={page}
          total={nbPages || 0}
          perPage={perPage}
          onPageChange={(page: number) => onChange(page, perPage)}
        />
      ) : null}
    </div>
  );
}

function DeleteProductButton({ item }: { item: Product }) {
  const onDelete = UseDeleteProduct(item);

  return (
    <button
      className="btn with-tooltip btn-action-icon-delete"
      data-tooltip-content="Supprimer"
      onClick={() => onDelete()}
      style={{ color: "#E53E3E" }}
    >
      <BsTrashFill />
    </button>
  );
}

function ToggleProductButton({ item }: { item: Product }) {
  const onToggle = UseToggleProduct(item);

  return (
    <label className="switch">
      <input
        type="checkbox"
        checked={item?.isActive}
        onClick={() => onToggle()}
      />
      <span className="slider"></span>
    </label>
  );
}

function PacksTable({ name }: { name: string }) {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const { data = { results: [], count: 0, nbPage: 0 }, isLoading } =
    useGetListProductAdminQuery({
      page,
      limit: perPage,
      name,
      productType: "pack",
    });

  React.useEffect(() => {
    console.log(data);
  }, [data]);

  const actionFormatter = (cell: string, row: Product) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex gap-3">
          <div className="container-btn-action-icon-modal">
            <NavLink
              to={`/admin/modifier-pack/${cell}`}
              state={row}
              className="btn btn-action-modal-icon with-tooltip"
              data-tooltip-content="Modifier"
            >
              <MdModeEditOutline />
            </NavLink>
          </div>

          <div className="container-btn-action-icon-modal">
            <NavLink
              to={`/admin/pack/${cell}`}
              state={row}
              className="btn btn-action-filter-icon with-tooltip"
              data-tooltip-content="Voir"
            >
              <AiFillEye />
            </NavLink>
          </div>
          <div className="container-btn-action-icon-modal">
            <DeleteProductButton item={row} />
          </div>
        </div>
      </>
    );
  };

  const nameFormatter = (cell: string, row: Product) => {
    return (
      <div className="table-actions-btn-container-img gap-2">
        <div className="content-img-product">
          <img
            src={getImage(row?.pictures[0]?.image)}
            alt="Produit"
            className="img-product-table"
            loading="lazy"
          />
        </div>
        <p className="text-nom-produit-table mb-0">{cell}</p>
      </div>
    );
  };
  const priceFormatter = (cell) => {
    return (
      <div className="table-actions-btn-container-img gap-2 d-flex align-items-center">
        <span>
          {cell} <span className="text-gray-etoile">fcfa</span>
        </span>
      </div>
    );
  };

  const statutFormatter = (cell: boolean, row: Product) => {
    return (
      <div className="switch2">
        <ToggleProductButton item={row} />
      </div>
    );
  };

  const columns = [
    {
      dataField: "name",
      text: "Nom",
      formatter: (cell: string, row: Product) => nameFormatter(cell, row),
    },
    {
      dataField: "price",
      text: "Prix",
      formatter: (cell: number) => priceFormatter(cell),
    },
    {
      dataField: "stockDisponible",
      text: "Stock",
    },
    {
      dataField: "isActive",
      text: "Statut",
      formatter: (cell: boolean, row: Product) => statutFormatter(cell, row),
    },
    {
      dataField: "_id",
      text: "Actions",
      formatter: (cell: string, row: Product) => actionFormatter(cell, row),
    },
  ];

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="_id"
            data={data?.results}
            columns={columns}
            striped
            condensed
            hover
            responsive={true}
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => "Aucune donnée trouvée"}
            // pagination={paginationFactory({ sizePerPage: 10 })}
          />
          <CustomPagination
            nbPages={page}
            page={page}
            onChange={(page, perPage) => {
              setPerPage(perPage);
              setPage(page);
            }}
            perPage={perPage}
          />
        </>
      )}
    </>
  );
}

export default PacksTable;
