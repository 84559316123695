import React, { ChangeEvent } from "react";
import Kpay from "../../../../assets/icons/kpay.png";
import Wave from "../../../../assets/icons/wave.png";
import OrangeMoney from "../../../../assets/icons/orange-money.png";
import CB from "../../../../assets/icons/cb.png";
import Free from "../../../../assets/icons/free.png";
import Paydunya from "../../../../assets/icons/Paydunya.png";
import { useGetPaymentModeListQuery } from "../../../../utils/api/paymentMode/paymentMode.api";
import { AlertInfo } from "../../../common/Alert";
import ErrorMessage from "../../../common/ErrorMessage";
import { useAddOrEditOrderMutation } from "../../../../utils/api/order/order.api";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../redux/hook";
import { PaymentMode } from "../../../../utils/api/paymentMode/paymentMode.type";
import { showModal } from "../../../../utils/Utils";

const getLogo = (
  type:
    | "om"
    | "wave"
    | "kpay"
    | "cb"
    | "fm"
    | "surplace"
    | "livraison"
    | "paydunya"
) => {
  if (type === "om") {
    return OrangeMoney;
  }

  if (type === "wave") {
    return Wave;
  }

  if (type === "kpay") {
    return Kpay;
  }

  if (type === "cb") {
    return CB;
  }

  if (type === "paydunya") {
    return Paydunya;
  }

  return Free;
};
const ModePaiement = ({ orderData, setOrderData, setIndex, index }) => {
  const { store } = useAppSelector((s) => s?.config);
  const { data, isLoading } = useGetPaymentModeListQuery({});
  const [errors, setErrors] = React.useState("");
  const navigate = useNavigate();
  const [
    sendData,
    { isSuccess, isError, error, isLoading: loading, data: results },
  ] = useAddOrEditOrderMutation();
  const [paymentMode, setPaymentMode] = React.useState(
    orderData?.paymentMethod || ""
  );

  React.useEffect(() => {
    if (isSuccess) {
      window.scroll(0, 10);
      console.log("order", results);
      if (results?.url) {
        window.open(results?.url, "_parent");
      } else if (results?.transactionId) {
        let prevState = { ...orderData };
        prevState["transactionId"] = results?.transactionId;
        setOrderData(prevState);
        showModal("otpValidationModal");
      } else {
        navigate("/commande-details", { replace: true, state: results });
        // Swal.fire({
        //   icon: "success",
        //   title: "Bravo!",
        //   text: "Votre commande a été validée avec succès.",
        //   iconColor: Color.success,
        //   showConfirmButton: false,
        //   timer: 3000,
        // }).then(() => {
        //   if (user?._id) {
        //     navigate("/mon-compte/commandes", { replace: true });
        //   } else {
        //     navigate("/", { replace: true });
        //   }

        //   dispatch(removeCart());
        // });
      }
    }
    const err = error as any;

    if (isError) {
      // console.log({ err });

      let message = err?.data?.message
        ? err?.data?.message
        : `Une erreur de statut ${err?.status} est survenue lors de la validation`;

      if (err?.data?.code === 11000) {
        if (err?.data?.keyPattern?.hasOwnProperty("telephone"))
          message = `Le numéro de téléphone "${err?.data?.keyValue["telephone"]}" existe déjà !`;
        if (err?.data?.keyPattern?.hasOwnProperty("email"))
          message = `Cet email "${err?.data?.keyValue["email"]}" existe déjà !`;
      }

      Swal.fire({
        icon: "error",
        title: message,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [error, isError, isSuccess]);

  const handleSelect = (
    e: ChangeEvent<HTMLInputElement>,
    item: PaymentMode
  ) => {
    setPaymentMode(item?._id);
    if (item?.type === "kpay") {
      showModal("phoneNumberModal");
    }
  };

  const onSubmit = async (e) => {
    e?.preventDefault();
    let prevState = { ...orderData };
    if (paymentMode) {
      prevState["paymentMethod"] = paymentMode;
      let modePayment = data?.find((el) => el?._id === paymentMode);
      if (modePayment && modePayment?.type === "paydunya") {
        prevState.redirectUrl = window.location.origin + "/commande-details";
      }

      if (prevState?.adresse) {
        delete prevState?.adresseObj;
      }
      console.log({ ...prevState, store: store?._id }, "final data");
      if (store) {
        prevState["store"] = store?._id;
      }
      await setOrderData(prevState);
      // setIndex(3);
      await sendData({ id: prevState?._id, data: prevState });
    } else {
      window.scrollTo(10, 10);
      setErrors("Le mode de paiement est obligatoire");
      setTimeout(() => {
        setErrors("");
      }, 3000);
    }
  };
  return (
    <div className="form-register-tabs-component mt-5">
      <p className="sub-title-form-register">Choisir un mode de paiement</p>
      <div className="content-form-register-tabs mt-5">
        <form onSubmit={onSubmit}>
          <div className="row row-add mb-4">
            {!!isLoading && <p>Chargement...</p>}
            {!isLoading &&
              (data?.length ? (
                data?.map((item) => (
                  <div
                    className="col-md-8 col-form-register-tabs mb-3"
                    key={item?._id}
                  >
                    <div className="content-col-form-livraison cpntrol d-flex justify-content-between">
                      <label className="label-checkbox-register-tabs">
                        <input
                          type="radio"
                          className="input-checkbox-register-tabs me-2"
                          name="paymentMethod"
                          value={item?._id}
                          defaultChecked={paymentMode === item?._id}
                          onChange={(e) => handleSelect(e, item)}
                        />
                        {item?.name}
                      </label>
                      {item?.type !== "livraison" &&
                      item?.type !== "surplace" ? (
                        <div className="mode-paiement-position">
                          <img
                            src={getLogo(item?.type)}
                            className="mode-payment-logo"
                            alt={item?.type}
                            loading="lazy"
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                ))
              ) : (
                <AlertInfo message="Aucun mode de paiement disponible pour le moment !" />
              ))}
            <div className="col-md-8">
              <ErrorMessage message={errors && errors} />
            </div>
          </div>
          <div className={`${"step-buttons"}`}>
            <button
              className={`${"step-action-btn"} ${"action-btn-secondary"}`}
              type="button"
              onClick={() => setIndex(index - 1)}
            >
              Précédant
            </button>

            <button
              className={`step-action-btn action-btn-primary`}
              type="submit"
              disabled={loading}
            >
              {loading ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                    role="status"
                  ></span>
                  <span>Confirmation...</span>
                </>
              ) : (
                "Confirmer la commande"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ModePaiement;
