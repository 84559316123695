import React from "react";
import Modal from "react-modal";
import CloseModalBtn from "../../../shared/CloseModalBtn";
import { customStyles } from "../../../shared/FrontHeader/modal/ConnexionModal";
import UseCrudMessageForm from "../requestMessage/UseCrudMessageForm";
import { FormError } from "../../../common/Input";

export const contactStatus = [
  {
    value: "nouveau",
    label: "Nouveau",
    class: "statut-nouvelle",
  },
  {
    value: "en cours",
    label: "En cours",
    class: "statut-en-cours-de-prepation",
  },
  {
    value: "terminé",
    label: "Terminé",
    class: "statut-livre",
  },
];
const DetailMessageModal = ({
  modalDetailMessage,
  setModalDetailMessage,
  contact,
}) => {
  const { register, errors, onSubmit, isLoading } = UseCrudMessageForm(
    contact,
    setModalDetailMessage
  );
  function closeModalDetailMessage() {
    setModalDetailMessage(false);
  }

  return (
    <Modal
      isOpen={modalDetailMessage}
      onRequestClose={closeModalDetailMessage}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="modal-header flex-sb">
        <h5 className="modal-title-admin">Changer le statut du message</h5>
        <CloseModalBtn onClick={closeModalDetailMessage} />
      </div>
      <div className="modal-body">
        <div className="pt-3">
          <div className="form-container px-3">
            <form onSubmit={onSubmit}>
              <div className="row mb-4 mt-4">
                <div className="col-md-12 mb-3">
                  <div className="mb-4 form-group">
                    <label className="form-label label-form-admin">
                      Statut {contact?.message}
                    </label>
                    <select
                      id="status"
                      className="form-select form-select-modal-custom mb-2"
                      {...register("status")}
                    >
                      <option value="">Statut du message</option>
                      {contactStatus?.map((option) => (
                        <option value={option.value} key={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    {errors?.status && (
                      <FormError error={errors?.status?.message} />
                    )}
                  </div>
                </div>
              </div>
              <div className="gap-3 flex-r align-center row my-4">
                <div className="col-md-3">
                  <button
                    onClick={closeModalDetailMessage}
                    className="btn btn-cancelled"
                  >
                    Fermer
                  </button>
                </div>
                <div className="col-md-3">
                  <button
                    type="submit"
                    className="btn btn-theme"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                          role="status"
                        ></span>
                        <span>En cours...</span>
                      </>
                    ) : (
                      "Modifier"
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DetailMessageModal;
