import React from "react";
import Livreur from "../../assets/icons/livreur.png";
import Paiement from "../../assets/icons/chart.png";
import Assistant from "../../assets/icons/person.png";
const FooterPage = () => {
  return (
    <div className="row-footer-register-tabs mb-5">
      <div className="content-footer-register-tabs-item">
        <div className="content-icon-footer-register-tabs-item">
          <img
            src={Livreur}
            alt="Livraison"
            className="icon-footer-register-tabs-item"
            loading="lazy"
          />
        </div>
        <p className="text-footer-register-tabs-item">Livraison rapide</p>
      </div>
      <div className="line-border-left"></div>
      <div className="content-footer-register-tabs-item">
        <div className="content-icon-footer-register-tabs-item">
          <img
            src={Paiement}
            alt="Paiement"
            className="icon-footer-register-tabs-item"
            loading="lazy"
          />
        </div>
        <p className="text-footer-register-tabs-item">Paiement sécurisé</p>
      </div>
      <div className="line-border-left"></div>
      <div className="content-footer-register-tabs-item">
        <div className="content-icon-footer-register-tabs-item">
          <img
            src={Assistant}
            alt="Assistance"
            className="icon-footer-register-tabs-item"
            loading="lazy"
          />
        </div>
        <p className="text-footer-register-tabs-item">ASSISTANCE 24/7</p>
      </div>
    </div>
  );
};

export default FooterPage;
