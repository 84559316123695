import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  cleannerError,
  closeModal,
  validatePhone,
} from "../../../../utils/Utils";
import ErrorMessage from "../../../common/ErrorMessage";

function PhoneNumberModal({ orderData, setOrderData }) {
  const [code, setCode] = useState<string>("");
  const validationSchema = yup.object().shape({
    telephone: validatePhone(yup, code).required(),
  });
  const [phone, setPhone] = useState<string | undefined>(
    orderData?.userObj?.telephone || undefined
  );
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm<{ telephone: string }>({
    resolver: yupResolver(validationSchema),
    defaultValues: { telephone: phone },
  });
  // const { user } = useAppSelector((s) => s?.user);

  useEffect(() => {
    if (orderData?.userObj?.telephone) setPhone(orderData?.userObj?.telephone);
  }, [orderData]);

  const handleChangePhone = (item, country) => {
    setCode(country?.countryCode);
    setPhone(item);
    if (parseInt(item) !== parseInt(country?.dialCode)) {
      setValue("telephone", item);
    } else {
      setValue("telephone", "");
    }
  };
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [clearErrors, errors]);

  const onSubmit = async (data: { telephone: string }) => {
    let prevState = { ...orderData };
    prevState["telephone"] = data?.telephone;

    setOrderData(prevState);
    closeModal("phoneNumberModal");
  };
  return (
    <div
      className="modal fade"
      id="phoneNumberModal"
      tabIndex={-1}
      //   data-bs-backdrop="static"
      aria-labelledby="phoneNumberModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body">
            <div className="d-flex justify-content-center align-items-center">
              {" "}
              <form
                className="position-relative"
                onSubmit={handleSubmit(onSubmit)}
              >
                {" "}
                <div className="card cart-otp p-2 text-center">
                  {" "}
                  <h6>Veuillez entrer votre numéro de téléphone.</h6>{" "}
                  <div className="mb-4">
                    {" "}
                    <span>Un code vous sera envoyé </span>{" "}
                    <small>pour valider votre paiement</small>{" "}
                  </div>{" "}
                  <div className="w-100 mt-3">
                    <PhoneInput
                      inputProps={{
                        name: "phone",
                      }}
                      defaultMask={".. ... .. .."}
                      country="sn"
                      value={phone}
                      enableSearch={true}
                      onChange={(phone, country) =>
                        handleChangePhone(phone, country)
                      }
                      inputClass={"form-control form-control-register-tabs-tel"}
                      enableAreaCodes={true}
                      prefix="+"
                      countryCodeEditable={false}
                    />
                    <ErrorMessage
                      message={errors?.telephone && errors?.telephone?.message}
                    />
                  </div>
                  <div className="mt-4 mb-3">
                    {" "}
                    <button
                      className="btn btn-danger px-4 validate"
                      type="submit"
                    >
                      Valider
                    </button>{" "}
                  </div>{" "}
                </div>{" "}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PhoneNumberModal;
