import React from "react";
import { NavLink } from "react-router-dom";
import { RiBarChartBoxFill, RiSettings3Fill } from "react-icons/ri";
import { FaMapMarkedAlt, FaMoneyBillAlt, FaUserPlus } from "react-icons/fa";
import { IoLocationSharp } from "react-icons/io5";
import Logo from "../../../../assets/appImages/logo.png";
import { TbChartDots } from "react-icons/tb";
import { BsFillBagFill, BsFillChatDotsFill } from "react-icons/bs";
import { ImLocation2 } from "react-icons/im";
import { MdDeliveryDining } from "react-icons/md";
import { useAppSelector } from "../../../../redux/hook";
import { isAdmin, isPreparateur } from "../../../../utils/Utils";
import { HiOutlineUserCircle } from "react-icons/hi";
import VersionChecker from "../../../../versionChecker/VersionChecker";
function SidebarAdmin() {
  const { user } = useAppSelector((s) => s?.user);
  return (
    <div className="navbar-vertical-customisation-v2 no-view-mobile navbar-vertical-customisation-admin">
      <div className="hoverable mt-4" id="scrollbar">
        <div className="content">
          <div className="move-left-side-menu"></div>
          <div className="container-user-connect-info pb-5">
            <div className="container-info-user-connect-navmenu">
              <img
                src={Logo}
                className="low-price-logo-header"
                alt="logo de Pitch Palabre"
                loading="lazy"
              />
            </div>
          </div>
          <ul className="nav nav-items-custom flex-column">
            {!!isAdmin(user) && (
              <>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/admin/dashboard"
                    data-tooltip-content="Dashboard"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <RiBarChartBoxFill />
                    </span>
                    <span className="hiddenable-navlink-label">Dashboard</span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/admin/utilisateurs"
                    data-tooltip-content="Utilisateurs "
                  >
                    <span className="icon-container-nav-link-vertival">
                      <FaUserPlus />
                    </span>
                    <span className="hiddenable-navlink-label">
                      Utilisateurs
                    </span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/admin/produits"
                    data-tooltip-content="Produits"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <IoLocationSharp />
                    </span>
                    <span className="hiddenable-navlink-label">Produits</span>
                  </NavLink>
                </li>
              </>
            )}

            <li className="nav-item nav-item-vertical-custom">
              <NavLink
                className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                to={
                  isPreparateur(user)
                    ? "/préparateur/commandes"
                    : "/admin/commandes"
                }
                data-tooltip-content="Commandes"
              >
                <span className="icon-container-nav-link-vertival">
                  <TbChartDots />
                </span>
                <span className="hiddenable-navlink-label">
                  Gestion commandes
                </span>
              </NavLink>
            </li>
            {!!isAdmin(user) && (
              <>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/admin/promos"
                    data-tooltip-content="Promos"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <BsFillBagFill />
                    </span>
                    <span className="hiddenable-navlink-label">Promos</span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/admin/point-edk"
                    data-tooltip-content="Point EDK"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <ImLocation2 />
                    </span>
                    <span className="hiddenable-navlink-label">Point EDK</span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/admin/zones"
                    data-tooltip-content="Zones"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <FaMapMarkedAlt />
                    </span>
                    <span className="hiddenable-navlink-label">Zones</span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/admin/mode-de-paiement"
                    data-tooltip-content="Mode de paiement"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <FaMoneyBillAlt />
                    </span>
                    <span className="hiddenable-navlink-label">
                      Mode de paiement
                    </span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/admin/mode-de-livraison"
                    data-tooltip-content="Mode de livraison"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <MdDeliveryDining />
                    </span>
                    <span className="hiddenable-navlink-label">
                      Mode de livraison
                    </span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/admin/messages"
                    data-tooltip-content="Message"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <BsFillChatDotsFill />
                    </span>
                    <span className="hiddenable-navlink-label">Message</span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/admin/parametres"
                    data-tooltip-content="Paramètres"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <RiSettings3Fill />
                    </span>
                    <span className="hiddenable-navlink-label">Paramètres</span>
                  </NavLink>
                </li>
              </>
            )}
            {!!isPreparateur(user) && (
              <li className="nav-item nav-item-vertical-custom">
                <NavLink
                  className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                  to="/préparateur/profil"
                  data-tooltip-content="Profil"
                >
                  <span className="icon-container-nav-link-vertival">
                    <HiOutlineUserCircle />
                  </span>
                  <span className="hiddenable-navlink-label">Profil</span>
                </NavLink>
              </li>
            )}
          </ul>
          <div className="version-view-container">{"v" + VersionChecker()}</div>
        </div>
      </div>
    </div>
  );
}

export default SidebarAdmin;
