import React, { useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useGetDashboardAppQuery } from "../../../../utils/api/dashboard/dashboard.api";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";

registerLocale("fr", fr);

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

function LineChart() {
  const [date, setDate] = useState(new Date());
  const { data: kpis, isLoading } = useGetDashboardAppQuery({
    displayType: "week",
    date: moment(date).format("YYYY-MM-DD"),
  });
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
        display: false,
      },
    },
  };

  const labels = [
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
    "Dimanche",
  ];

  const data = {
    labels,
    datasets: [
      {
        label: "Commande",
        data: kpis?.map((item) => item?.count) || [],
        borderColor: "#28348A",
        backgroundColor: "#a8bfd51f",
        fill: true,
        lineTension: 0.3,
      },
    ],
  };

  return (
    <div className="chart-item-container">
      <div className="content-chart-title mb-4">
        <h2 className="chart-item-admin-title mb-0">
          Nombre Total de Commandes
        </h2>
        <div className="content-select-date-graph">
          {/* <select className="form-control form-control-select-date-graph">
            <option>Semaine du 30/11/2020</option>
          </select> */}
          <DatePicker
            locale="fr"
            selected={date}
            maxDate={new Date()}
            className="form-control form-control-select-date-graph"
            onChange={(date) => setDate(date)}
            dateFormat="dd/MM/yyyy"
          />
        </div>
      </div>
      {!!isLoading && <Skeleton height={420} />}
      {!isLoading && <Line options={options} data={data} />}
    </div>
  );
}

export default LineChart;
