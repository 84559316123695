import React from "react";
import UseCrudMessageForm from "../../admin/GestionMessages/requestMessage/UseCrudMessageForm";
import { FormError } from "../../common/Input";

function ContactUsForm() {
  const { register, onSubmit, errors, isLoading } = UseCrudMessageForm();
  return (
    <form onSubmit={onSubmit}>
      <div className="row row-contact-footer">
        <div className="col-md-12 col-contact-footer mb-3">
          <div className="content-col-contact-footer">
            <input
              type="email"
              className="form-control form-control-footer"
              placeholder="Adresse mail"
              {...register("email")}
            />
            <FormError error={errors?.email?.message} />
          </div>
        </div>
        <div className="col-md-12 col-contact-footer mb-3">
          <div className="content-col-contact-footer">
            <textarea
              className="form-control form-control-footer"
              rows={3}
              placeholder={"Message"}
              {...register("message")}
            />
            <FormError error={errors?.message?.message} />
          </div>
        </div>
        <div className="col-md-6 offset-md-6 col-contact-footer mb-3">
          <div className="content-col-contact-footer">
            <button
              className="btn btn-submit-contact-footer trans-0-2 w-100"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm me-1 d-inline-block"
                    role="status"
                  ></span>
                  <span>Envoie...</span>
                </>
              ) : (
                "ENVOYER"
              )}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

export default ContactUsForm;
