import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { cleannerError, validatePhone } from "../../../../utils/Utils";
import { AddressFormData } from "../../../../utils/api/user/user.type";
import { Color } from "../../../../utils/theme";
import { useAddOrEditUserAdresseMutation } from "../../../../utils/api/user/user.api";
import { Address } from "../../../../utils/api/auth/auth.type";
import { useGetZoneListByVisitorQuery } from "../../../../utils/api/zone/zone.api";
import { useAppSelector } from "../../../../redux/hook";

function UseCrudAddressForm(adresse?: Address) {
  const [code1, setCode1] = useState<string>("");
  const [code2, setCode2] = useState<string>("");
  const [phone1, setPhone1] = useState<string | null>(null);
  const [phone2, setPhone2] = useState<string | null>(null);
  const validationSchema = yup.object().shape({
    prenom: yup.string().required().label("Le prénom").nullable(),
    nom: yup.string().required().label("Le nom").nullable(),
    adresse: yup.string().required().label("L'adresse").nullable(),
    complementAdresse: yup.string().label("Le complément d'adresse").nullable(),
    quartier: yup.string().label("Le quartier").nullable(),
    zone: yup.string().required().label("La zone").nullable(),
    telephone1: validatePhone(yup, code1).required(),
    telephone2: validatePhone(yup, code2),
    isDefault: yup.boolean().default(false),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
    control,
  } = useForm<AddressFormData>({
    resolver: yupResolver(validationSchema),
  });
  const [options, setOptions] = useState<{ label: string; value: string }[]>(
    []
  );
  const [option, setOption] = useState<
    { label: string; value: string } | undefined
  >();
  const { user } = useAppSelector((s) => s?.user);
  const { data: zones = [] } = useGetZoneListByVisitorQuery();
  const [sendData, { isLoading, isSuccess, error, isError }] =
    useAddOrEditUserAdresseMutation();
  const [address, setAddress] = useState("");

  useEffect(() => {
    if (user?._id && !adresse?._id) {
      setValue("prenom", user?.prenom);
      setValue("nom", user?.nom);
      setValue("telephone1", user?.telephone);
      setPhone1(user?.telephone)
    }
  }, [user, adresse]);

  useEffect(() => {
    if (zones?.length) {
      // console.log("zones", zones);
      setOptions(
        zones?.map((z) => {
          return {
            label: z?.ville,
            value: z?._id,
          };
        })
      );
    }
  }, [zones]);

  const handleSelectZone = (zone) => {
    setOption(zone);
    setValue(`zone`, zone?.value);
  };

  const onChangeAddress = (val: {
    rue: string;
    latitude: string;
    longitude: string;
    ville: string;
    country: string;
  }) => {
    setAddress(val?.rue);
    setValue(`adresse`, val?.rue);
    setValue(`latitude`, val?.latitude);
    setValue(`longitude`, val?.longitude);
    // if (val?.ville) {
    //   setValue(`ville`, val?.ville);
    // }
  };

  const handleChangePhone1 = (item, country) => {
    setCode1(country?.countryCode);
    setPhone1(item);
    if (parseInt(item) !== parseInt(country?.dialCode)) {
      setValue(`telephone1`, item);
    } else {
      setValue(`telephone1`, "");
    }
  };

  const handleChangePhone2 = (item, country) => {
    setCode2(country?.countryCode);
    setPhone2(item);
    if (parseInt(item) !== parseInt(country?.dialCode)) {
      setValue(`telephone2`, item);
    } else {
      setValue(`telephone2`, "");
    }
  };

  useEffect(() => {
    // window.scroll(0, 10);
    if (isSuccess) {
      window.scroll(0, 10);
      Swal.fire({
        icon: "success",
        title: !adresse
          ? "Adresse ajoutée avec succès!"
          : `Adresse modifiée avec succès!`,
        // text: "Un email de confirmation vous a été envoyé pour valider votre inscription.",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {
        window.scroll(0, 10);
        reset();
        setPhone1("+221");
        setPhone2("+221");
        setAddress("");
        setOption(undefined);
      });
    }
    const err = error as any;

    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Une erreur de statut ${err?.status} est survenue lors de l'ajout`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [error, isError, isSuccess]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [clearErrors, errors]);

  const onSubmit = async (data: AddressFormData) => {
    console.log("data register", data);

    await sendData({ id: data?._id, data: data });
  };

  useEffect(() => {
    if (adresse?._id) {
      const fields: (keyof AddressFormData)[] = [
        "_id",
        "prenom",
        "nom",
        "telephone1",
        "telephone2",
        "adresse",
        "complementAdresse",
        "quartier",
        "zone",
        "latitude",
        "longitude",
        "isDefault",
        "zone",
      ];
      for (let field of fields) {
        register(field);
        if (field === "zone") {
          setValue(field, adresse[field]?._id);
        } else {
          setValue(field, adresse[field]);
        }
      }
      setAddress(adresse?.adresse);
      if (adresse?.zone?._id) {
        setOption({
          label: adresse?.zone?.ville,
          value: adresse?.zone?._id,
        });
      }
      if (adresse?.telephone1) {
        setPhone1(adresse?.telephone1);
      }

      if (adresse?.telephone2) {
        setPhone2(adresse?.telephone2);
      }
    }
  }, [adresse]);

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    phone1,
    phone2,
    handleChangePhone1,
    handleChangePhone2,
    onChangeAddress,
    address,
    isSuccess,
    handleSelectZone,
    options,
    option,
    Controller,
    control,
  };
}

export default UseCrudAddressForm;
