import React, { useState } from "react";
import AdresseBoutiqueEdk from "./AdresseBoutiqueEdk";
import { Modal } from "reactstrap";
import { RiStore2Line } from "react-icons/ri";
import { Store } from "../../utils/api/store/store.type";

const ChoiceStoreModal = ({ store }: { store: Store }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      <button
        className="btn-store"
        onClick={() => setIsOpen(true)}
      >
        <RiStore2Line
          fontSize={18}
          style={{ marginBottom: 3, marginRight: 5 }}
        />
        {store.name}
      </button>
      <Modal isOpen={isOpen} size="lg">
        <div className="modal-header">
          <h1 className="modal-title fs-5" id="ChoiceStoreModalLabel">
            Choisir une boutique
          </h1>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={() => setIsOpen(false)}
          ></button>
        </div>
        <div className="modal-body modal-choice-store">
          <AdresseBoutiqueEdk onSelect={() => setIsOpen(false)} />
        </div>
      </Modal>
    </>
  );
};

export default ChoiceStoreModal;
