import React from "react";
import Modal from "react-modal";
import { InputPassword } from "../../../common/Input";
import CloseModalBtn from "../../../shared/CloseModalBtn";
import { customStyles } from "../../../shared/FrontHeader/modal/ConnexionModal";
import UseEditPassordForm from "../requestForm/UseEditPassordForm";

const ChangePasswordModal = ({
  modalChangePassword,
  setModalChangePassword,
}) => {
  const {
    register,
    onSubmit,
    isLoading,
    errors,
    passwordHandleReset,
    passwordHandleChange,
    haveMinCharacter,
    haveMinLowercase,
    haveMinNumber,
    haveMinSpecialCharacter,
    haveMinUppercase,
  } = UseEditPassordForm(setModalChangePassword);
  function closeModalChangePassword() {
    setModalChangePassword(false);
  }

  return (
    <Modal
      isOpen={modalChangePassword}
      onRequestClose={closeModalChangePassword}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="modal-header flex-sb">
        <h5 className="modal-title-admin">Changer de mot de passe</h5>
        <CloseModalBtn
          onClick={() => {
            closeModalChangePassword();
            passwordHandleReset();
          }}
        />
      </div>

      <div className="pt-3">
        <form action="" onSubmit={onSubmit}>
          <div className="row py-3 px-2">
            <div className="col-12 mb-3">
              <InputPassword
                label="Ancien mot de passe"
                id="password"
                placeholder="Ancien mot de passe"
                {...register("oldPassword")}
                error={errors?.oldPassword?.message}
              />
            </div>
            <div className="col-12 mb-3">
              <InputPassword
                label="Nouveau mot de passe"
                id="new_password"
                placeholder="Nouveau mot de passe"
                name="newPassword"
                onChange={(e) => passwordHandleChange(e)}
                error={errors?.newPassword?.message}
              />
            </div>
            <div className="col-12 mb-4">
              <InputPassword
                label="Confirmer le mot de passe"
                id="confirm_password"
                placeholder="Confirmer le mot de passe"
                {...register("confirm_password")}
                error={errors?.confirm_password?.message}
              />
            </div>
            <div className="col-12 mb-4">
              <button
                className="btn btn-theme w-100"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                      role="status"
                    ></span>
                    <span>En cours...</span>
                  </>
                ) : (
                  "Enregistrer"
                )}
              </button>
            </div>
            <div className="col-md-12">
              <div className="input-group my-2 password-validation-terms-row">
                <div className="password-validation-terms-container">
                  <h4 className="password-validation-terms-title">
                    Votre mot de passe doit contenir :{" "}
                  </h4>
                  <ul className="password-validation-terms-ul p-l-14 text-start">
                    <li
                      className={
                        "password-rule-item " +
                        (haveMinCharacter && "text-success")
                      }
                    >
                      Au minimum 8 caractères
                    </li>
                    <li
                      className={
                        "password-rule-item " +
                        (haveMinUppercase && "text-success")
                      }
                    >
                      Au minimum 1 majuscule
                    </li>
                    <li
                      className={
                        "password-rule-item " +
                        (haveMinLowercase && "text-success")
                      }
                    >
                      Au minimum 1 minuscule
                    </li>
                    <li
                      className={
                        "password-rule-item " +
                        (haveMinNumber && "text-success")
                      }
                    >
                      Au minimum 1 chiffre
                    </li>
                    <li
                      className={
                        "password-rule-item " +
                        (haveMinSpecialCharacter && "text-success")
                      }
                    >
                      Au minimum 1 caractère spécial
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default ChangePasswordModal;
