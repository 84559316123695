import React from "react";
import { BiImport, BiMessageSquareAdd } from "react-icons/bi";
import { NavLink } from "react-router-dom";
import ProductsTable from "../ProductsTable/ProductsTable";
import ImportProductFromLeo2Modal from "../modal/ImportProductFromLeo2Modal";

const ProduitsTabs = () => {
  const [name, setName] = React.useState("");
  const [show, setShow] = React.useState(false);

  return (
    <div className="admin-tabs-component">
      <div className="d-flex justify-content-center align-items-center gap-10 mb-3">
        {/* <a
          className="btn btn-add-admin"
          target={"_blank"}
          href={`${ApiBaseUrl}/api/product/download-model`}
        >
          <BiExport />
          <span className="ps-2">Exporter le modèle de fichier</span>
        </a> */}
        <button className="btn btn-add-admin" onClick={() => setShow(true)}>
          <BiImport />
          <span className="ps-2">Importer depuis Léo2</span>
        </button>
      </div>

      <div className="container-title-content-page mb-4">
        <div className="tab-dash-admin-filter-container">
          <form className="row g-3 tab-dash-admin-filter-form">
            <div className="col-auto">
              <label
                htmlFor="filtreProduit"
                className="tab-dash-admin-filter-label"
              >
                Filtrer
              </label>
            </div>
            <div className="col-auto">
              <input
                type="text"
                className="form-control tab-dash-admin-filter-input"
                id="filtreProduit"
                placeholder="Entrer un produit"
                value={name}
                onChange={(e) => setName(e?.target?.value)}
              />
            </div>
          </form>
        </div>

        <div className="content-title">
          <NavLink to="/admin/ajouter-produit" className="btn btn-add-admin">
            <BiMessageSquareAdd />
            <span className="ps-2">Ajouter un produit</span>
          </NavLink>
        </div>
      </div>
      <div className="admin-table">
        <ProductsTable name={name} />
      </div>
      {/* <ImportProductModal show={show} setShow={setShow} /> */}
      <ImportProductFromLeo2Modal show={show} setShow={setShow} />
    </div>
  );
};

export default ProduitsTabs;
