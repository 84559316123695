import React from "react";
import { NavLink } from "react-router-dom";
import { useGetCategoryListQuery } from "../../../utils/api/category/category.api";
import { QueryUrl, sortByAlphabetical } from "../../../utils/Utils";
import { AlertInfo } from "../../common/Alert";
import { MdKeyboardArrowRight } from "react-icons/md";

function CategoriesListHeader() {
  const { data = [], isLoading } = useGetCategoryListQuery();

  return (
    <>
      <div className="card-categorie-accueil">
        <p className="dropdown-item-title ps-3">Nos univers</p>

        <ul className="ul-categories">
          {!isLoading &&
            (data?.filter((el) => el?.name !== "Non catégorisé")?.length ? (
              sortByAlphabetical(data)
                ?.filter((el) => el?.name !== "Non catégorisé")
                ?.map((item) => (
                  <li className="li-categories" key={item?._id}>
                    <div className="dropdown dropdown-categorie-list">
                      <NavLink
                        to={`/achats/${QueryUrl("", {
                          category: item?.subCategories?.length
                            ? JSON.stringify(
                                item?.subCategories?.map((el) => el?._id)
                              )
                            : "",
                        })}`}
                        className="categories-link"
                      >
                        <span>{item?.name}</span>
                        {!!item?.subCategories?.length && (
                          <div>
                            <MdKeyboardArrowRight />
                          </div>
                        )}
                      </NavLink>
                      {!!item?.subCategories?.length && (
                        <div
                          className="dropdown-content-categorie-list"
                          // style={{ width: "max-content" }}
                        >
                          <p className="dropdown-item-title text-uppercase">
                            {item?.name}
                          </p>
                          <div className="row-list-sous-categorie-menu-header">
                            <div className="row">
                              {sortByAlphabetical(item?.subCategories)?.map(
                                (el) => (
                                  <div className="col-md-4" key={el?._id}>
                                    <NavLink
                                      className="h-link-dropdown-categorie-list"
                                      to={`/achats/${QueryUrl("", {
                                        category: JSON.stringify([el?._id]),
                                      })}`}
                                      key={el?._id}
                                    >
                                      {el?.name}
                                    </NavLink>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </li>
                ))
            ) : (
              <AlertInfo message="Aucune catégorie disponible pour le moment!" />
            ))}
        </ul>
      </div>
    </>
  );
}

export default CategoriesListHeader;
