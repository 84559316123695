/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from "@hookform/resolvers/yup";
import { ChangeEvent, useEffect, useState } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useGetAllCategoryListQuery } from "../../../../utils/api/category/category.api";
import {
  useAddOrEditProductMutation,
  useDeleteProductMutation,
} from "../../../../utils/api/product/product.api";
import {
  Product,
  ProductFormData,
} from "../../../../utils/api/product/product.type";
import { Color } from "../../../../utils/theme";
import { cleannerError, sortByAlphabetical } from "../../../../utils/Utils";

function UseCrudProductForm(product?: Product) {
  const validationSchema = yup.object().shape({
    hasEdit: yup.boolean(),
    name: yup.string().required().label("Le nom"),
    description: yup.string().required().label("La description"),
    price: yup.string().required().label("Le prix"),
    productId: yup
      .string()
      .required()
      .label("L'id du produit sur léo2")
      .nullable(),
    category: yup.string().required().label("La catégorie").nullable(),
    isActive: yup.boolean().label("Le statut").nullable().default(false),
    isNewProduct: yup.boolean().label("La nouveauté").nullable().default(false),
    pictures: yup.mixed().when("hasEdit", {
      is: false,
      then: () =>
        yup
          .mixed()
          .required("Veuillez uploader au moins une image")
          .label("Image")
          .nullable(),
    }),
    // weight: yup
    //   .number()
    //   .required()
    //   .label("Le poids")
    //   .nullable()
    //   .transform((value) => (isNaN(value) ? null : value)),
    // width: yup
    //   .number()
    //   .required()
    //   .label("La largeur")
    //   .nullable()
    //   .transform((value) => (isNaN(value) ? null : value)),
    // height: yup
    //   .number()
    //   .required()
    //   .label("La hauteur")
    //   .nullable()
    //   .transform((value) => (isNaN(value) ? null : value)),
    // length: yup
    //   .number()
    //   .required()
    //   .label("La longueur")
    //   .nullable()
    //   .transform((value) => (isNaN(value) ? null : value)),
  });

  const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
		control,
    resetField
  } = useForm<ProductFormData>({
		resolver: yupResolver(validationSchema),
		mode: "onChange",
		
  });
  const { fields } = useFieldArray({
    control,
    name: "stocks",
  });
  const [sendData, { isLoading, isSuccess, error, isError }] =
    useAddOrEditProductMutation();
  const navigate = useNavigate();
  // const { data: stores = [] } = useGetStoreListQuery({});
  const { data } = useGetAllCategoryListQuery();
  const [description, setDescription] = useState("");
  const [images, setImages] = useState<any>([]);
  const [option, setOption] = useState<any>(null);
  const [options, setOptions] = useState<any>([]);
  const [status, setStatus] = useState(true);
  const [nouveau, setNouveau] = useState(true);

  // useEffect(() => {
  //   if (stores?.length) {
  //     if (product?.stocks?.length) {
  //       replace(
  //         stores?.map((item) => {
  //           let el = product?.stocks?.find(
  //             (el) => el?.store?._id === item?._id
  //           );
  //           return {
  //             label: item?.name,
  //             store: item?._id,
  //             stock: el ? `${el?.stock}` : "",
  //             _id: el?._id,
  //           };
  //         })
  //       );
  //     } else {
  //       replace(
  //         stores?.map((item) => {
  //           return {
  //             label: item?.name,
  //             store: item?._id,
  //             stock: "",
  //           };
  //         })
  //       );
  //     }
  //   }
  // }, [stores, product]);

  useEffect(() => {
    setValue("isActive", status);
  }, [status]);
  useEffect(() => {
    setValue("isNewProduct", nouveau);
  }, [nouveau]);
  useEffect(() => {
    if (images?.length) {
      setValue(
        "pictures",
        images?.filter((s) => !s?._id)
      );
    } else {
      resetField("pictures");
    }
  }, [images]);

  useEffect(() => {
    if (data?.length) {
      // console.log("categorie");
      setOptions(
        sortByAlphabetical(data)
          ?.filter((item) => item?.category !== null)
          ?.map((item) => {
            return {
              label: item?.name,
              value: item?._id,
              ...item,
            };
          })
      );
    }
  }, [data]);

  const onChangeDescription = (value) => {
    setValue("description", value);
    setDescription(value);
  };
  const handleSelected = (item) => {
    setValue("category", item?.value);
    setOption(item);
  };

  const handleChangeImage = (e: any) => {
    const file = e.target.files;
    if (file && file?.length > 0) {
      setImages(URL.createObjectURL(file));
      setValue("pictures", file);
    }
  };

  useEffect(() => {
    if (product?._id) {
      setValue("hasEdit", true);
      const fields: (keyof ProductFormData)[] = [
        "_id",
        "name",
        "category",
        "description",
        "isActive",
        "isNewProduct",
        "price",
        // "height",
        // "length",
        // "weight",
        // "width",
        "stockDisponible",
        "productId",
      ];
      for (let field of fields) {
        register(field);
        if (field !== "category") {
          setValue(field, product[field]);
        }
      }
      setStatus(product?.isActive);
      setNouveau(product?.isNewProduct);

      if (product?.description) {
        setDescription(product?.description);
      }

      if (product?.category) {
        setOption({
          value: product?.category?._id,
          label: product?.category?.name,
          ...product?.category,
        });

        setValue("category", product?.category?._id);
      }
      if (product?.pictures?.length) {
        setImages(product?.pictures);
      }
    } else {
      setValue("hasEdit", false);
    }
  }, [product]);

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        iconColor: Color.success,
        confirmButtonColor: Color.success,
        title: product
          ? "Produit modifié avec succès !"
          : "Produit ajouté avec succès !",
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {
        navigate("/admin/produits");
      });
    }
    const err = error as any;

    if (isError) {
      console.log("error", err);

      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Une erreur de statut ${err?.status} est survenue`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [error, isError, isSuccess, isLoading]);

  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [clearErrors, errors]);

  const handleChangeStatus = (e: ChangeEvent<HTMLInputElement>) => {
    setStatus(e.target.checked);
    console.log(e.target.checked);
  };
  const handleChangeNouveau = (e: ChangeEvent<HTMLInputElement>) => {
    setNouveau(e.target.checked);
    console.log(e.target.checked);
  };

  const onSubmit = async (data: ProductFormData) => {
    const fd = new FormData();
    for (let key of Object.keys(data)) {
      if (key === "pictures") {
        if (data[key] && data[key]?.length) {
          data[key]?.forEach((item) => {
            fd.append(key, item);
          });
        }
      } else {
        fd.append(key, data[key]);
      }
    }
    console.log("data", data);
    await sendData({ id: data?._id, data: fd });
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    handleChangeImage,
    images,
    handleChangeStatus,
    handleChangeNouveau,
    option,
    options,
    handleSelected,
    control,
    Controller,
    setImages,
    description,
    onChangeDescription,
    status,
    nouveau,
    fields,
  };
}

export default UseCrudProductForm;
export function UseDeleteProduct(item: Product) {
  const [deleteData] = useDeleteProductMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer ce ${
        item?.productType === "pack" ? "pack" : "produit"
      } ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(item?._id);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      console.log(result, "archiver");
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `Le ${
              item?.productType === "pack" ? "pack" : "produit"
            } a été supprimé avec succès!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };
  return onDelete;
}

export function UseToggleProduct(item: Product) {
  const [sendData] = useAddOrEditProductMutation();
  const onToggle = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir ${
        item?.isActive ? "désactiver" : "activer"
      } ce ${item?.productType === "pack" ? "pack" : "produit"} ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return sendData({
          id: item?._id,
          data: {
            isActive: !item?.isActive,
            name: item?.name,
            description: item?.description,
            category: item?.category?._id,
            price: item?.price,
          },
        });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      console.log(result, "archiver");
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `Le ${
              item?.productType === "pack" ? "pack" : "produit"
            } a été ${item?.isActive ? "désactivé" : "activé"} avec succès!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };
  return onToggle;
}

export function UseToggleProductNouveau(item: Product) {
  const [sendData] = useAddOrEditProductMutation();
  const onToggle = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir ${
        item?.isNewProduct ? "désactiver" : "activer"
      } la nouveauté sur ce produit ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return sendData({
          id: item?._id,
          data: {
            isNewProduct: !item?.isNewProduct,
            name: item?.name,
            description: item?.description,
            category: item?.category?._id,
            price: item?.price,
          },
        });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      console.log(result, "archiver");
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `La nouveauté a été ${item?.isNewProduct ? "désactivé" : "activé"} avec succès!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };
  return onToggle;
}

export function UsePromoteProduct(item: Product) {
  const [sendData] = useAddOrEditProductMutation();
  const onPromote = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir ${
        item?.selectedForTheWeek
          ? "supprimer ce produit de"
          : "ajouter ce produit dans"
      } la section de la semaine ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return sendData({
          id: item?._id,
          data: {
            selectedForTheWeek: !item?.selectedForTheWeek,
            name: item?.name,
            description: item?.description,
            category: item?.category?._id,
            price: item?.price,
          },
        });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      console.log(result, "archiver");
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `Le produit a été ${
              item?.selectedForTheWeek ? "supprimé de" : "ajouté dans"
            } la section de la semaine avec succès!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };
  return onPromote;
}