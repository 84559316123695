import React from "react";
import "./ParametresAdmin.css";
import { useStep } from "react-hooks-helper";
// import RessourcesTabs from "./Steps/RessourcesTabs";
import { useStepType } from "../../../utils/type";
import PolicyAdmin from "./Steps/PolicyAdmin";
import CguAdmin from "./Steps/CguAdmin";
import ListAdmins from "./Steps/ListAdmins";
import ConfidentialAdmin from "./Steps/ConfidentialAdmin";
import { useLocationState } from "../../../utils/Utils";
import ReseauxSociaux from "./Steps/ReseauxSociaux";
import BannerAdmin from "./Steps/BannerAdmin";
import VerticalBanner from "./Steps/VerticalBanner";

const steps = [
  // { id: "ressources", Component: RessourcesTabs },
  { id: "cgu", Component: CguAdmin },
  { id: "policy", Component: PolicyAdmin },
  { id: "intellectual-property", Component: ConfidentialAdmin },
  { id: "admin", Component: ListAdmins },
  { id: "rs", Component: ReseauxSociaux },
  { id: "banner", Component: BannerAdmin },
  { id: "bannerV", Component: VerticalBanner },
];
const tabs = [
  // "Ressources",
  "CGU",
  "PC",
  "PI",
  "Collaborateurs",
  "Réseaux sociaux",
  "Bannières",
  "Bannière verticale",
];

function ParametresAdmin() {
  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: 0, steps });
  const { Component } = step;
  const item = useLocationState<string>(undefined);
  React.useEffect(() => {
    if (item) {
      go(item);
    }
  }, [item]);
  return (
    <div>
      <div className="content-graph-admin mb-3">
        <div className="dash-admin-page-content-container mb-3">
          <div className="dash-organiser-page-params-titre mb-4">
            Paramètres
          </div>
          <div className="container-tabs-admin-setting">
            <ul className="tabs-container gap-tabs">
              {tabs.map((tab, key) => (
                <li
                  className={`tab-item  ${index === key && "tab-active-item"}`}
                  key={key}
                  onClick={() => go(key)}
                  role="button"
                >
                  {tab}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="content-graph-admin mb-3">
        <div className="dash-admin-page-content-container mb-3">
          <div className="tabs-component-container pt-4">
            <Component />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ParametresAdmin;
