import React, { Dispatch, SetStateAction, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import UseImportProductFromLeo2Form from "../requestForm/UseImportProductFromLeo2Form";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { FormError } from "../../../common/Input";

export const animatedComponents = makeAnimated();
function ImportProductFromLeo2Modal({
  show,
  setShow,
}: {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
}) {
  const {
    onSubmit,
    handleSelect,
    errors,
    isLoading,
    isSuccess,
    handleReset,
    options,
    option,
    control,
    Controller,
  } = UseImportProductFromLeo2Form();
  const onHide = () => {
    setShow(false);
    handleReset();
  };

  useEffect(() => {
    if (isSuccess) {
      onHide();
    }
  }, [isSuccess]);
  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Import des produits
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="pt-3">
          <form onSubmit={onSubmit}>
            <div className="row py-3 px-2">
              <div className="col-md-12 mb-4">
                <div className="mb-3">
                  <label className="form-label form-label-admin">
                    Boutique{" "}
                    <span
                      className="text-danger custom-required-text"
                      style={{ fontSize: 17 }}
                    >
                      *
                    </span>
                  </label>
                  <Controller
                    name="store"
                    control={control}
                    render={({ field }) => {
                      return (
                        <Select
                          {...field}
                          closeMenuOnSelect={true}
                          components={animatedComponents}
                          placeholder="Sélectionner une boutique"
                          options={options}
                          classNamePrefix="react-select-custom"
                          onChange={handleSelect}
                          value={option}
                        />
                      );
                    }}
                  />
                  {<FormError error={errors?.store?.message} />}
                </div>
              </div>

              <div className="flex-r">
                <button
                  className="btn btn-cancelled"
                  type="button"
                  onClick={() => onHide()}
                >
                  Annuler
                </button>
                <button
                  type="submit"
                  className="btn btn-theme"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                        role="status"
                      ></span>
                      <span>Envoie...</span>
                    </>
                  ) : (
                    "Envoyer"
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer> */}
    </Modal>
  );
}

export default ImportProductFromLeo2Modal;
