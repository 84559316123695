import React from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import {
  useDeleteProductImageMutation,
  useProductFromLocation,
} from "../../../utils/api/product/product.api";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import ReactQuill from "react-quill";
import { FormError, InputAdmin } from "../../common/Input";
import MyDropzone, { ImageGrid } from "../../common/MyDropzone";
import UseCrudProductForm from "./requestForm/UseCrudProductForm";
import { useNavigate } from "react-router-dom";

const AddProductAdmin = () => {
  const [item] = useProductFromLocation();
  const [deleteData] = useDeleteProductImageMutation();
  const {
    register,
    errors,
    onSubmit,
    isLoading,
    handleSelected,
    options,
    option,
    setImages,
    description,
    images,
    onChangeDescription,
    Controller,
    control,
    status,
    handleChangeStatus,
    nouveau,
    handleChangeNouveau,
  } = UseCrudProductForm(item);
  const animatedComponents = makeAnimated();
  const navigate = useNavigate();

  const deleteFile = (index, file) => {
    const newArr = [...images];
    newArr.splice(index, 1);
    setImages(newArr);
    if (file?.image) {
      deleteData({ productId: item?._id, pictureId: file?._id });
    }
  };
  return (
    <div className="content-graph-admin">
      <div className="container-title-content-page mb-4">
        <div
          className="content-title-page-admin"
          onClick={() => navigate(-1)}
          style={{ cursor: "pointer" }}
        >
          <h2 className="title-page-admin">
            <AiOutlineArrowLeft style={{ color: "#E20613" }} />
            <span className="ps-2">
              {!item ? "Ajouter" : "Modifier"} un produit
            </span>
          </h2>
        </div>
      </div>
      <div className="container-form-add-product-admin mt-5">
        <form onSubmit={onSubmit}>
          <div className="row row-add-product-admin">
            {/* <div className="col-md-6 col-add-product-admin mb-3">
              <InputAdmin label="Photo" type={"file"} />
            </div> */}
            <div className="col-md-6 col-add-product-admin mb-3">
              <InputAdmin
                label="Nom produit"
                type={"text"}
                placeholder="Nom produit"
                required
                {...register("name")}
                error={errors?.name?.message}
              />
            </div>

            <div className="col-md-6 col-add-product-admin mb-3">
              <InputAdmin
                label="Prix"
                type={"number"}
                required
                step={"any"}
                min={1}
                placeholder="Prix du produit"
                {...register("price")}
                error={errors?.price?.message}
              />
            </div>
            <div className="col-md-6 col-add-product-admin mb-3">
              <div className="mb-3">
                <label className="form-label form-label-admin">
                  Catégorie{" "}
                  <span
                    className="text-danger custom-required-text"
                    style={{ fontSize: 17 }}
                  >
                    *
                  </span>
                </label>
                <Controller
                  name="category"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Select
                        {...field}
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        placeholder="Sélectionner une catégorie"
                        options={options}
                        classNamePrefix="react-select-custom"
                        onChange={handleSelected}
                        value={option}
                      />
                    );
                  }}
                />
                {<FormError error={errors?.category?.message} />}
              </div>
            </div>
            <div className="col-md-6 col-add-product-admin mb-3">
              <InputAdmin
                label="L'id du produit sur léo2"
                type={"text"}
                required
                placeholder="L'id du produit sur léo2"
                {...register("productId")}
                error={errors?.productId?.message}
              />
            </div>
            {/* <div className="col-md-6 col-add-product-admin mb-3">
              <InputAdmin
                label="Poids en kg"
                type={"number"}
                required
                step={"any"}
                min={0}
                placeholder="Poids du produit en kg"
                {...register("weight")}
                error={errors?.weight?.message}
              />
            </div>
            <div className="col-md-6 col-add-product-admin mb-3">
              <InputAdmin
                label="Hauteur en cm"
                type={"number"}
                required
                step={"any"}
                min={0}
                placeholder="Hauteur du produit en cm"
                {...register("height")}
                error={errors?.height?.message}
              />
            </div>
            <div className="col-md-6 col-add-product-admin mb-3">
              <InputAdmin
                label="Longueur en cm"
                type={"number"}
                required
                step={"any"}
                min={0}
                placeholder="Longueur du produit en cm"
                {...register("length")}
                error={errors?.length?.message}
              />
            </div>
            <div className="col-md-6 col-add-product-admin mb-3">
              <InputAdmin
                label="Largeur en cm"
                type={"number"}
                required
                step={"any"}
                min={0}
                placeholder="Largeur du produit en cm"
                {...register("width")}
                error={errors?.width?.message}
              />
            </div> */}
            <div className="col-md-12 col-add-product-admin mb-3">
              <div className="mb-3">
                <label className="form-label form-label-admin">
                  Images du produit{" "}
                  <span
                    className="text-danger custom-required-text"
                    style={{ fontSize: 17 }}
                  >
                    *
                  </span>
                </label>
                <div className="dropzone-image-product">
                  <MyDropzone setImages={setImages} />
                  {<FormError error={errors?.pictures?.message} />}
                </div>
                <div className="row">
                  <ImageGrid images={images} deleteFile={deleteFile} />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-add-product-admin mb-3">
              <div className="mb-3">
                <label className="form-label form-label-admin">
                  Description du produit{" "}
                  <span
                    className="text-danger custom-required-text"
                    style={{ fontSize: 17 }}
                  >
                    *
                  </span>
                </label>
                <ReactQuill
                  value={description}
                  className="product-ql-description"
                  onChange={(value) => onChangeDescription(value)}
                />
                {<FormError error={errors?.description?.message} />}
              </div>
            </div>
            {/* <div className="col-md-12 col-add-product-admin mb-3">
              <div className="row">
                {fields?.map((field, i) => (
                  <Fragment key={field?.id}>
                    
                    <div className="col-md-6 col-add-product-admin mb-3">
                      <InputAdmin
                        label={`Stock de ${field?.label}`}
                        type={"number"}
                        step={"1"}
                        min={0}
                        max={10000000}
                        placeholder="Quantité"
                        {...register(`stocks.${i}.stock`)}
                        error={
                          errors?.stocks?.length && errors?.stocks[i]
                            ? errors?.stocks[i]?.stock?.message
                            : ""
                        }
                      />
                    </div>
                  </Fragment>
                ))}
              </div>
            </div> */}

            <div className="col-md-4 col-add-product-admin mb-3">
              <div className="mb-3">
                <label className="form-label form-label-admin">
                  Statut du produit
                </label>
                <div className="switch2">
                  <label className="switch">
                    <input
                      type="checkbox"
                      onChange={(e) => handleChangeStatus(e)}
                      checked={status}
                    />
                    <span className="slider"></span>
                  </label>
                </div>
                {<FormError error={errors?.isActive?.message} />}
              </div>
            </div>
            <div className="col-md-4 col-add-product-admin mb-3">
              <div className="mb-3">
                <label className="form-label form-label-admin">
                  Nouveauté du produit
                </label>
                <div className="switch2">
                  <label className="switch">
                    <input
                      type="checkbox"
                      onChange={(e) => handleChangeNouveau(e)}
                      checked={nouveau}
                    />
                    <span className="slider"></span>
                  </label>
                </div>
                {<FormError error={errors?.isActive?.message} />}
              </div>
            </div>
            <div className="col-md-3 offset-md-9 col-add-product-admin mb-3">
              <div className="mb-3">
                <button
                  className="btn btn-submit-form-add-product-admin w-100"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                        role="status"
                      ></span>
                      <span>En cours...</span>
                    </>
                  ) : item ? (
                    "Modifier"
                  ) : (
                    "Ajouter"
                  )}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddProductAdmin;
