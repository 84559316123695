import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { QueryUrl } from "../../Utils";
import { DashboardKpi, HistoryLogin, Kpi } from "./dashboard.type";

export const DashboardApi = createApi({
  reducerPath: "dashboardApi",
  tagTypes: ["dashboard", "history"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getDashboardApp: builder.query<DashboardKpi[], TypeQuery>({
      providesTags: ["dashboard"],
      query: (query) => QueryUrl("kpi/state/", query),
    }),
    getKpi: builder.query<Kpi, void>({
      query: () => "kpi/",
    }),
    getHistiques: builder.query<PaginationResults<HistoryLogin>, TypeQuery>({
      providesTags: ["history"],
      query: (query) => QueryUrl("login-history/", query),
    }),
  }),
});

export const { useGetDashboardAppQuery, useGetKpiQuery, useGetHistiquesQuery } =
  DashboardApi;
