import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiBaseUrl } from "../../http";
import { QueryUrl, useLocationState } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { Product, ProductFormData } from "./product.type";
import { useAppSelector } from "../../../redux/hook";

export const ProductApi = createApi({
  reducerPath: "productApi",
  tagTypes: [
    "product",
    "productList",
    "productByVisitor",
    "productById",
    "allProductList",
    "productPromote",
    "download",
    "productByIdV2",
    "productSelected",
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getListProductAdmin: builder.query<PaginationResults<Product>, TypeQuery>({
      providesTags: ["productList"],
      query: (query) => QueryUrl("product/for-admin/", query),
    }),
    getProductList: builder.query<PaginationResults<Product>, TypeQuery>({
      providesTags: ["product"],
      query: ({ store, ...query }) => QueryUrl(`product/v2/${store}`, query),
    }),
    getSelectedProducts: builder.query<Product[], string>({
      providesTags: ["productSelected"],
      query: (store) => `product/v2/selection-of-the-week/${store}`,
    }),
    getSearchProductList: builder.query<PaginationResults<Product>, TypeQuery>({
      // providesTags: ["product"],
      query: (query) => QueryUrl("product/search/", query),
    }),
    getPromoteProductList: builder.query<PaginationResults<Product>, TypeQuery>(
      {
        providesTags: ["productPromote"],
        query: ({ store, ...query }) =>
          QueryUrl(`product/v2/promoted/${store}`, query),
      }
    ),
    addOrEditProduct: builder.mutation<
      Product,
      { id?: string; data: ProductFormData | FormData }
    >({
      onCacheEntryAdded(arg, { dispatch }) {
        setTimeout(() => {
          dispatch(
            ProductApi.util.invalidateTags([
              "productList",
              "productByVisitor",
              "product",
            ])
          );
        }, 1000);
      },
      invalidatesTags: [
        "productList",
        "productByVisitor",
        "product",
        "productSelected",
      ],
      query: ({ id, data }) => {
        if (id) {
          return {
            url: `product/${id}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `product/`,
          method: "POST",
          body: data,
        };
      },
    }),
    addOrEditPack: builder.mutation<
      Product,
      { id?: string; data: ProductFormData | FormData }
    >({
      onCacheEntryAdded(arg, { dispatch }) {
        setTimeout(() => {
          dispatch(
            ProductApi.util.invalidateTags([
              "productList",
              "productByVisitor",
              "product",
            ])
          );
        }, 1000);
      },
      invalidatesTags: [
        "productList",
        "productByVisitor",
        "product",
        "productSelected",
      ],
      query: ({ id, data }) => {
        if (id) {
          return {
            url: `product/${id}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `product/create-pack/`,
          method: "POST",
          body: data,
        };
      },
    }),
    deleteProduct: builder.mutation<Product, string>({
      query: (id) => ({
        url: `product/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["product", "productList", "productByVisitor"],
    }),
    productById: builder.query<Product | any, string>({
      query: (id) => `product/${id}/`,
      providesTags: ["productById"],
    }),
    productByIdV2: builder.query<Product, { id: string; store: string }>({
      query: ({ id, store }) => `product/v2/${store}/${id}`,
      providesTags: ["productByIdV2"],
    }),
    deleteProductImage: builder.mutation<
      Product,
      { productId: string; pictureId: string }
    >({
      query: ({ productId, pictureId }) => ({
        url: `product/${productId}/pictures/${pictureId}`,
        method: "DELETE",
      }),
      invalidatesTags: [
        "product",
        "productList",
        "productByVisitor",
        "productById",
      ],
    }),
    getModeleFile: builder.query<Product | any, void>({
      query: () => `product/download-model`,
      providesTags: ["download"],
    }),
    onImportProduct: builder.mutation<Product, FormData>({
      query: (data) => ({
        url: `product/import-excel`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["product", "productList", "productByVisitor"],
    }),
    onImportProductFromLeo2: builder.mutation<any, { store: string }>({
      query: (data) => ({
        url: `product/import-from-leo`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["product", "productList", "productByVisitor"],
    }),
  }),
});

export const {
  useAddOrEditProductMutation,
  useGetListProductAdminQuery,
  useGetProductListQuery,
  useDeleteProductMutation,
  useLazyProductByIdQuery,
  useDeleteProductImageMutation,
  useAddOrEditPackMutation,
  useGetPromoteProductListQuery,
  useGetSearchProductListQuery,
  useLazyGetModeleFileQuery,
  useOnImportProductMutation,
  useLazyProductByIdV2Query,
  useOnImportProductFromLeo2Mutation,
  useGetSelectedProductsQuery,
} = ProductApi;

export function useProductFromLocation(): [
  Product,
  boolean,
  string,
  (id: string) => any
] {
  const itemState = useLocationState<Product | undefined>(undefined);
  const [item, setItem] = useState(itemState);
  const { id } = useParams<{ id: string }>();
  const [findById, { data, isError, isLoading }] = useLazyProductByIdQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      findById(id as string);
    }
  }, [id]);
  useEffect(() => {
    if (isError && !itemState) {
      navigate(-1);
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
      console.log("data", data);
      setItem(data);
    }
  }, [data]);

  return [item as Product, isLoading, id as string, findById];
}

export function useProductV2FromLocation(): [
  Product,
  boolean,
  string,
  ({ id, store }) => any
] {
  const itemState = useLocationState<Product | undefined>(undefined);
  const [item, setItem] = useState(itemState);
  const { id } = useParams<{ id: string }>();
  const { store } = useAppSelector((s) => s?.config);
  const [findById, { data, isError, isFetching }] = useLazyProductByIdV2Query();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      findById({ id: id, store: store?._id });
    }
  }, [id]);
  useEffect(() => {
    if (isError && !itemState) {
      navigate(-1);
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
      console.log("data v2", data);
      setItem(data);
    }
  }, [data]);

  return [item as Product, isFetching, id as string, findById];
}
