import React, { useEffect, useState } from "react";
import { AiFillEdit } from "react-icons/ai";
import PhoneInput from "react-phone-input-2";
import Swal from "sweetalert2";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useAppSelector } from "../../../redux/hook";
import { Address } from "../../../utils/api/auth/auth.type";
import { Color } from "../../../utils/theme";
import ErrorMessage from "../../common/ErrorMessage";
import GoogleInput from "../../common/GoogleInput";
import UseCrudAddressForm from "./requestCrudAddress/UseCrudAddressForm";

const AdressePage = () => {
  const { user } = useAppSelector((s) => s?.user);
  const [item, setItem] = useState<Address | undefined>();
  const {
    register,
    isLoading,
    errors,
    onSubmit,
    phone1,
    phone2,
    onChangeAddress,
    address,
    handleChangePhone1,
    handleChangePhone2,
    isSuccess,
    handleSelectZone,
    options,
    option,
    Controller,
    control,
  } = UseCrudAddressForm(item);
  const animatedComponents = makeAnimated();

  useEffect(() => {
    if (isSuccess) {
      setItem(undefined);
    }
  }, [isSuccess]);

  const onEdit = (selected: Address) => {
    Swal.fire({
      title: `Voulez-vous modifier cet adresse ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
    }).then((result) => {
      console.log(result, "archiver");
      if (result?.isConfirmed) {
        window.scrollTo(400, 400);
        setItem(selected);
      } else {
        setItem(undefined);
      }
    });
  };

  return (
    <div className="form-register-tabs-component">
      <div className="content-title-form-register pb-4">
        <h3 className="title-form-register">Mes adresses</h3>
      </div>
      <div className="row mb-5">
        {!!user?.adresses?.length &&
          user?.adresses?.map((address) => (
            <div className="col-lg-4 col-md-6" key={address?._id}>
              <div
                className={`container-default-adresse h-100 ${
                  address?._id === item?._id ? "active" : ""
                }`}
              >
                <div className="content-info-default-adresse pb-3">
                  <div className="content-text-info-adresse">
                    <p className="form-label-register-tabs">
                      {address?.prenom + " " + address?.nom}
                    </p>
                    <p className="text-info-adresse">{address?.adresse}</p>
                    <p className="text-info-adresse">
                      {address?.quartier
                        ? `${address?.quartier},${address?.ville}`
                        : ""}{" "}
                    </p>
                    <p className="text-info-adresse">{address?.telephone1}</p>
                  </div>
                  <div className="content-icon-edit-default-adresse">
                    <AiFillEdit
                      className="icon-edit-default-adresse linkable"
                      onClick={() => {
                        onEdit(address);
                      }}
                    />
                  </div>
                </div>
                {!!address?.isDefault && (
                  <p className="text-info-adresse-right mb-0">
                    Adresse par défaut
                  </p>
                )}
              </div>
            </div>
          ))}
      </div>
      <p className="sub-title-form-register">
        {" "}
        {!item ? "Nouvelle adresse" : "Modifier l'adresse"}{" "}
      </p>
      <div className="content-form-register-tabs mt-5">
        <form onSubmit={onSubmit}>
          <div className="row row-add" id="formAddress">
            <div className="col-md-6 col-form-register-tabs mb-3">
              <div className="content-col-form-register-tabs">
                <label className="form-label form-label-register-tabs">
                  PRÉNOM*
                </label>
                <input
                  className="form-control form-control-register-tabs"
                  type="text"
                  placeholder="Prénom"
                  {...register("prenom")}
                />
                <ErrorMessage
                  message={errors?.prenom && errors?.prenom?.message}
                />
              </div>
            </div>
            <div className="col-md-6 col-form-register-tabs mb-3">
              <div className="content-col-form-register-tabs">
                <label className="form-label form-label-register-tabs">
                  NOM*
                </label>
                <input
                  className="form-control form-control-register-tabs"
                  type="text"
                  placeholder="Nom"
                  {...register("nom")}
                />
                <ErrorMessage message={errors?.nom && errors?.nom?.message} />
              </div>
            </div>
            <div className="col-md-6 col-form-register-tabs mb-3">
              <div className="content-col-form-register-tabs">
                <label className="form-label form-label-register-tabs">
                  Adresse*
                </label>

                <GoogleInput
                  className="form-control form-control-register-tabs"
                  value={address}
                  onChange={onChangeAddress}
                  placeholder="Adresse"
                />
                <ErrorMessage
                  message={errors?.adresse && errors?.adresse?.message}
                />
              </div>
            </div>
            <div className="col-md-6 col-form-register-tabs mb-3">
              <div className="content-col-form-register-tabs">
                <label className="form-label form-label-register-tabs">
                  Complément d’adresse
                </label>
                <input
                  className="form-control form-control-register-tabs"
                  type="text"
                  placeholder="Complément d’adresse"
                  {...register("complementAdresse")}
                />
                <ErrorMessage
                  message={
                    errors?.complementAdresse &&
                    errors?.complementAdresse?.message
                  }
                />
              </div>
            </div>
            <div className="col-md-6 col-form-register-tabs mb-3">
              <div className="content-col-form-register-tabs">
                <label className="form-label form-label-register-tabs">
                  Quartier
                </label>
                <input
                  className="form-control form-control-register-tabs"
                  type="text"
                  placeholder="Quartier"
                  {...register(`quartier`)}
                />
                <ErrorMessage
                  message={errors?.quartier && errors?.quartier?.message}
                />
              </div>
            </div>
            <div className="col-md-6 col-add-product-admin mb-3">
              <div className="mb-3">
                <label className="form-label form-label-register-tabs">
                  ZONE *
                </label>

                <Controller
                  name="zone"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Select
                        {...field}
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        placeholder="Sélectionnez votre zone"
                        options={options}
                        classNamePrefix="react-select-zone"
                        onChange={handleSelectZone}
                        value={option}
                      />
                    );
                  }}
                />
                <ErrorMessage message={errors?.zone && errors?.zone?.message} />
              </div>
            </div>
            {/* <div className="col-md-6 col-form-register-tabs mb-3">
              <div className="content-col-form-register-tabs">
                <label className="form-label form-label-register-tabs">
                  Ville/RÉGION*
                </label>
                <input
                  className="form-control form-control-register-tabs"
                  type="text"
                  placeholder="Ville/Région"
                  {...register(`ville`)}
                />
                <ErrorMessage
                  message={errors?.ville && errors?.ville?.message}
                />
              </div>
            </div> */}
            <div className="col-md-6 col-form-register-tabs mb-3">
              <div className="content-col-form-register-tabs">
                <label className="form-label form-label-register-tabs">
                  TÉLÉPHONE 1*
                </label>
                <PhoneInput
                  inputProps={{
                    name: "phone",
                  }}
                  defaultMask={".. ... .. .."}
                  country={"sn"}
                  enableSearch={true}
                  value={phone1}
                  onChange={(phone, country) =>
                    handleChangePhone1(phone, country)
                  }
                  inputClass="form-control form-control-register-tabs-tel"
                  enableAreaCodes={true}
                  prefix="+"
                  countryCodeEditable={false}
                />
                <ErrorMessage
                  message={errors?.telephone1 && errors?.telephone1?.message}
                />
              </div>
            </div>
            <div className="col-md-6 col-form-register-tabs mb-3">
              <div className="content-col-form-register-tabs">
                <label className="form-label form-label-register-tabs">
                  TÉLÉPHONE 2
                </label>
                <PhoneInput
                  inputProps={{
                    name: "phone",
                  }}
                  defaultMask={".. ... .. .."}
                  country={"sn"}
                  enableSearch={true}
                  value={phone2}
                  onChange={(phone, country) =>
                    handleChangePhone2(phone, country)
                  }
                  inputClass="form-control form-control-register-tabs-tel"
                  enableAreaCodes={true}
                  prefix="+"
                  countryCodeEditable={false}
                />
                <ErrorMessage
                  message={errors?.telephone2 && errors?.telephone2?.message}
                />
              </div>
            </div>
            <div className="col-md-12 col-form-register-tabs mb-3">
              <div className="content-col-form-register-tabs cpntrol">
                <label className="label-checkbox-register-tabs">
                  <input
                    type="checkbox"
                    className="input-checkbox-register-tabs"
                    defaultChecked={item && item?.isDefault}
                    // value={true}
                    {...register(`isDefault`)}
                  />
                  Définir par défaut
                </label>
                <ErrorMessage
                  message={errors?.isDefault && errors?.isDefault?.message}
                />
              </div>
            </div>
            <div className="col-md-4 col-lg-3 py-5">
              <div className="content-btn-submit">
                <button className="btn btn-theme w-100" disabled={isLoading}>
                  {isLoading ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                        role="status"
                      ></span>
                      <span>Enregistrer...</span>
                    </>
                  ) : (
                    "ENREGISTRER"
                  )}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdressePage;
