import React, { useEffect } from "react";
import { Offcanvas, Stack } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../redux/hook";
import { formatCurrency } from "../../../../utils/Utils";
import CartItem from "../../../user/CartItem/CartItem";
import Panier from "../../../../assets/appImages/panier.png";
import { getPreOrder } from "../../../../redux/slice/useCartSlice";

type ShoppingCartProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
function ShoppingCart({ isOpen, setIsOpen }: ShoppingCartProps) {
  const { cartItems, subAmount,totalAmount } = useAppSelector((state) => state.cart);
  // const user = useAppSelector((s) => s?.user);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (cartItems?.length) {
      let hasPreOrder: boolean = cartItems?.some((el) => el?.qty > el?.product?.stock);
      dispatch(getPreOrder(hasPreOrder));
    } else {
      dispatch(getPreOrder(false));
    }
  }, [cartItems])

  const closeCart = () => {
    setIsOpen(false);
  };
  return (
    <Offcanvas show={isOpen} onHide={closeCart} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Panier</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="px-1">
        <Stack gap={3}>
          <div className="cartItem-container mb-4">
            {!!cartItems?.length &&
              cartItems.map((item) => (
                <CartItem item={item} key={item?.product?._id} />
              ))}
            {!cartItems?.length && (
              <div className="content-panier-vide">
                <div>
                  <div className="content-img-panier-vide pb-4 text-center">
                    <img
                      src={Panier}
                      alt="Panier"
                      className="img-panier-vide"
                      loading="lazy"
                    />
                  </div>
                  <h3 className="titre-panier-vide">Votre panier est vide</h3>
                  <p className="text-panier-vide">
                    Veuillez ajouter des produits pour pouvoir acheter.
                  </p>
                  <div className="content-btn-action-panier pt-4 text-center">
                    <NavLink to="/achats" className="btn btn-theme ">
                      COMMANDER
                    </NavLink>
                  </div>
                </div>
              </div>
            )}
          </div>
          {!!cartItems?.length && (
            <div className="content-info-produit-panier-page pt-3">
              {/* <div className="info-produit-item-panier-page b-b-1">
                <p className="info-produit-item-libelle mb-0">Tax (18%)</p>
                <p className="info-produit-item-value mb-0">
                  {formatCurrency(tax)}
                </p>
              </div> */}
              <div className="info-produit-item-panier-page b-b-1">
                <p className="info-produit-item-libelle mb-0">Sous-total</p>
                <p className="info-produit-item-value mb-0">
                  {formatCurrency(subAmount)}
                </p>
              </div>
              <div className="info-produit-item-panier-page">
                <p className="info-produit-item-libelle-total mb-0">TOTAL</p>
                <p className="info-produit-item-value-total mb-0">
                  {formatCurrency(totalAmount)}
                </p>
              </div>
              <div className="content-btn-commande pt-4 text-center">
                <button
                  onClick={() => {
                    navigate("/valider-commande");
                  }}
                  className="btn btn-theme"
                >
                  Commander
                </button>
              </div>
            </div>
          )}
        </Stack>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default ShoppingCart;
