import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiBaseUrl } from "../../http";
import { QueryUrl, useLocationState } from "../../Utils";
import { ProductApi } from "../product/product.api";
import { prepareHeaders } from "../user/user.api";
import { TypeQuery } from "../user/user.type";
import { PromoteProduct, PromoteProductFormData, Promotion, PromotionFormData } from "./promotion.type";

export const PromotionApi = createApi({
  reducerPath: "promotionApi",
  tagTypes: [
    "promotion",
    "promotionList",
    "promotionByVisitor",
    "promotionById",
    "allPromotionList",
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getPromotionList: builder.query<Promotion[], TypeQuery>({
      providesTags: ["promotion"],
      query: (query) => QueryUrl("promotion/", query),
    }),
    getActivePromotionList: builder.query<Promotion[], TypeQuery>({
      providesTags: ["promotion"],
      query: (query) => QueryUrl("promotion/active-only/", query),
    }),
    addOrEditPromotion: builder.mutation<
      Promotion,
      { id?: string; data: PromotionFormData | FormData }
    >({
      onCacheEntryAdded(arg, { dispatch }) {
        setTimeout(() => {
          dispatch(
            PromotionApi.util.invalidateTags([
              "promotionList",
              "promotionByVisitor",
              "promotion",
            ])
          );
        }, 1000);
      },
      query: ({ id, data }) => {
        if (id) {
          return {
            url: `promotion/${id}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `promotion/`,
          method: "POST",
          body: data,
        };
      },
    }),

    deletePromotion: builder.mutation<Promotion, string>({
      query: (id) => ({
        url: `promotion/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["promotion", "promotionList", "promotionByVisitor"],
    }),
    promotionById: builder.query<Promotion | any, string>({
      query: (id) => `promotion/${id}/`,
      providesTags: ["promotionById"],
    }),
    addPromoteProduct: builder.mutation<PromoteProduct, PromoteProductFormData>(
      {
        query: (data) => ({
          url: `promotion/promote-product/`,
          method: "POST",
          body: data,
        }),
        onCacheEntryAdded(arg, { dispatch }) {
          setTimeout(() => {
            dispatch(ProductApi.util.invalidateTags(["productPromote"]));
          }, 1000);
        },
      }
    ),
    deletePromoteProduct: builder.mutation<PromoteProduct, string>({
      query: (id) => ({
        url: `promotion/unpromote-product/${id}/`,
        method: "DELETE",
      }),
      onCacheEntryAdded(arg, { dispatch }) {
        setTimeout(() => {
          dispatch(ProductApi.util.invalidateTags(["productPromote"]));
        }, 1000);
      },
    }),
  }),
});

export const {
  useAddOrEditPromotionMutation,
  useDeletePromotionMutation,
  useLazyPromotionByIdQuery,
  useGetPromotionListQuery,
  useGetActivePromotionListQuery,
  useAddPromoteProductMutation,
  useDeletePromoteProductMutation
} = PromotionApi;

export function usePromotionFromLocation(): [
  Promotion,
  boolean,
  string,
  (id: string) => any
] {
  const itemState = useLocationState<Promotion | undefined>(undefined);
  const [item, setItem] = useState(itemState);
  const { id } = useParams<{ id: string }>();
  const [findById, { data, isError, isLoading }] = useLazyPromotionByIdQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      findById(id as string);
    }
  }, [id]);
  useEffect(() => {
    if (isError && !itemState) {
      navigate(-1);
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
    //   console.log("data", data);
      setItem(data);
    }
  }, [data]);

  return [item as Promotion, isLoading, id as string, findById];
}