import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { BsTrashFill } from "react-icons/bs";
import { MdModeEditOutline } from "react-icons/md";
import { useGetZoneListQuery } from "../../../../utils/api/zone/zone.api";
import { Zone } from "../../../../utils/api/zone/zone.type";
import TableSkeleton from "../../../common/TableSkeleton";
import { CustomPagination } from "../../GestionProduits/ProductsTable/ProductsTable";
import AddOrEditZone from "../modal/AddOrEditZone";
import { UseDeleteZone } from "../requestForm/UseCrudZoneForm";

export function DeleteZoneButton({ id }: { id: string }) {
  const onDelete = UseDeleteZone(id);

  return (
    <button
      className="btn with-tooltip btn-action-icon-delete"
      data-tooltip-content={"Supprimer"}
      onClick={() => onDelete()}
      style={{ color: "#E53E3E" }}
    >
      <BsTrashFill />
    </button>
  );
}
function ZoneTable() {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const { data = { results: [], nbPage: 0 }, isLoading } = useGetZoneListQuery({
    page,
    limit: perPage,
  });

  useEffect(() => {
    console.log("data zones", data);
  }, [data]);

  const actionFormatter = (cell: string, row: Zone) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex gap-3">
          <div className="container-btn-action-icon-modal">
            <button
              className="btn btn-action-modal-icon with-tooltip"
              data-tooltip-content="Modifier"
              data-bs-toggle="modal"
              data-bs-target={`#editZoneModal${cell}`}
            >
              <MdModeEditOutline />
            </button>
          </div>

          <div className="container-btn-action-icon-modal">
            <DeleteZoneButton id={cell} />
          </div>
        </div>
        <AddOrEditZone modalId={`editZoneModal${cell}`} item={row} />
      </>
    );
  };
  const columns = [
		// {
		//   dataField: "region",
		//   text: "Region",
		// },
		{
			dataField: "ville",
			text: "Zone",
		},
		// {
		// 	dataField: "taux",
		// 	text: "Prix/Taux",
		// 	formatter: (cell: number, row: Zone) =>
		// 		cell ? cell + "%" : formatCurrency(row?.price),
		// },
		{
			dataField: "_id",
			text: "Actions",
			formatter: (cell: string, row: any) => actionFormatter(cell, row),
		},
  ];

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="_id"
            data={data?.results}
            columns={columns}
            striped
            condensed
            hover
            responsive={true}
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => "Aucune donnée trouvée"}
            // pagination={paginationFactory({ sizePerPage: 10 })}
          />
          <CustomPagination
            nbPages={data?.nbPage}
            page={page}
            onChange={(page, perPage) => {
              setPerPage(perPage);
              setPage(page);
            }}
            perPage={perPage}
          />
        </>
      )}
    </>
  );
}

export default ZoneTable;
