import React from "react";
import { useStep } from "react-hooks-helper";
import { useLocation } from "react-router-dom";
import { useStepType } from "../../../utils/type";
import "./GestionPromoAdmin.css";
import BannerTabs from "./PromosTabs/BannerTabs";
import ProduitPromoTabs from "./PromosTabs/ProduitPromoTabs";
const steps = [
  { id: "produits-promo", Component: ProduitPromoTabs },
  { id: "promotion", Component: BannerTabs },
];
const tabs = ["Produits", "Promotions"];

const GestionPromoAdmin = () => {
  const location = useLocation();
  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: location?.state?.step || 0, steps });
  const { Component } = step;
  return (
    <div className="content-graph-admin">
      <div className="dashbord-admin-component admidashboard-bg mb-3">
        <div className="dash-admin-page-content-container mb-3">
          <div className="container-tabs-admin-setting">
            <ul className="tabs-container">
              {tabs.map((tab, key) => (
                <li
                  className={`tab-item  ${index === key && "tab-active-item"}`}
                  key={key}
                  onClick={() => go(key)}
                  role="button"
                >
                  {tab}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="dashbord-admin-component admidashboard-bg mb-3">
        <div className="dash-admin-page-content-container mb-3">
          <div className="tabs-component-container pt-4">
            <Component />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GestionPromoAdmin;
