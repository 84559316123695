/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import Modal from "react-modal";
import { NavLink } from "react-router-dom";
import UseLoginForm from "../../../auth/requestForm/UseLoginForm";
import ErrorMessage from "../../../common/ErrorMessage";
import { CloseModalBtnV2 } from "../../CloseModalBtn";
import PhoneModal from "../../../auth/PhoneModal";

const ConnexionModal = ({ modalConnexion, setModalConnexion }) => {
  function closeModalConnexion() {
    setModalConnexion(false);
  }
  const [showPassword, setShowPassword] = useState(false);

  const { register, errors, isLoading, onSubmit } =
    UseLoginForm(closeModalConnexion);
  return (
    <Modal
      isOpen={modalConnexion}
      onRequestClose={closeModalConnexion}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="modal-header pe-2 py-1 flex-r-header-modal">
        <CloseModalBtnV2 onClick={closeModalConnexion} />
      </div>
      <div className="content-modal-login">
        <div className="text-center pb-4">
          <h2 className="title-login-modal">Connexion</h2>
          <div className="content-text-create-account">
            <span className="text-resgister-modal-login">
              Vous êtes nouveau ?
            </span>
            <NavLink to="/inscription" className="btn btn-register-login-modal">
              Créer un compte
            </NavLink>
          </div>
        </div>
        <form className="mt-4" onSubmit={onSubmit} data-testid="login-form">
          <div className="row row-add-student">
            <div className="col-md-12 col-form-add-student mb-3">
              <div className="content-col-form-login-modal">
                <label className="form-label form-label-login-modal">
                  {" "}
                  Adresse mail/N° de téléphone
                </label>
                <input
                  className="form-control form-control-login-modal"
                  type="text"
                  placeholder="Adresse mail/N° de téléphone"
                  {...register("email")}
                  data-testid="email-input"
                />
                <ErrorMessage
                  message={errors?.email && errors?.email?.message}
                />
              </div>
            </div>
            <div className="col-md-12 col-form-add-student mb-3">
              <div className="content-col-form-login-modal position-relative">
                <label className="form-label form-label-login-modal">
                  Mot de passe
                </label>
                <input
                  className="form-control form-control-login-modal"
                  type={showPassword ? "text" : "password"}
                  placeholder="Mot de passe"
                  {...register("password")}
                  data-testid="password-input"
                />
                <span
                  className="show-hide-password-modal"
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                >
                  {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                </span>
                <ErrorMessage
                  message={errors?.password && errors?.password?.message}
                />
              </div>
            </div>
            <div className="col-md-12 mb-3">
              <div className="flex-r">
                <NavLink
                  className="btn btn-register-login-modal"
                  to={"/mot-de-passe-oublie"}
                >
                  Mot de passe oublié ?
                </NavLink>
              </div>
            </div>
            <div className="col-md-12 pt-3">
              <button className="btn btn-theme w-100" disabled={isLoading} data-testid="connexionId">
                {isLoading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                      role="status"
                    ></span>
                    <span>Connexion...</span>
                  </>
                ) : (
                  "Connexion"
                )}
              </button>
            </div>
          </div>
        </form>
        <PhoneModal setModalConnexion={setModalConnexion} />
      </div>
    </Modal>
  );
};

export default ConnexionModal;
export const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    zIndex: 99999,
  },
};
