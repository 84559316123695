import React from "react";
import { BiMessageSquareAdd } from "react-icons/bi";
import { BsTrashFill } from "react-icons/bs";
import { MdModeEditOutline } from "react-icons/md";
import { NavLink } from "react-router-dom";
import { useGetPromotionListQuery } from "../../../../utils/api/promotion/promotion.api";
import { Promotion } from "../../../../utils/api/promotion/promotion.type";
import { getImage } from "../../../../utils/Utils";
import { AlertInfo } from "../../../common/Alert";
import Pagination from "../../../common/Pagination";
import { UseDeletePromotion, UseTogglePromotion } from "../requestForm/UseCrudPromotionForm";

function DeletePromotionButton({ id }: { id: string }) {
  const onDelete = UseDeletePromotion(id);

  return (
    <button
      className="btn with-tooltip btn-action-icon-delete"
      data-tooltip-content="Supprimer"
      onClick={() => onDelete()}
      style={{ color: "#E53E3E" }}
    >
      <BsTrashFill />
    </button>
  );
}

function ToggleBtn({ item }: { item: Promotion }) {
  const onToggle = UseTogglePromotion(item);

  return (
    <label className="switch">
      <input
        type="checkbox"
        checked={item?.active}
        onClick={() => onToggle()}
      />
      <span className="slider"></span>
    </label>
  );
}

const PromoItem = ({item,i}:{item:Promotion;i:number}) => {
  return (
    <div className="container-banner-admin mb-4">
      <p className="title-banner-admin">Promo {i + 1}</p>
      <div
        className={`content-banner-admin-item ${
          item?.active ? "active" : "inactive"
        }`}
      >
        <p className="title-banner-admin">Photo</p>
        <div className="content-img-banner-admin mb-4">
          <img
            src={getImage(item?.image)}
            alt="Bannière"
            className="img-banner-admin"
            loading="lazy"
          />
        </div>
        {/* <p className="title-banner-admin">Taux de réduction</p>
                  <div className="content-text-banner-admin mb-4">
                    <p className="text-banner-admin">{item?.taux} %</p>
                  </div> */}
        <p className="title-banner-admin">Texte</p>
        <div className="content-text-banner-admin mb-4">
          <div className="text-banner-admin pt-1">
            {item?.title}
            <div className="reduction d-flex align-items-center fw-500">
              Réduction de &nbsp;
              <p className="info-produit-sim-value mb-0 ">
                {item?.taux} <span style={{ fontWeight: 300 }}>%</span>
              </p>
            </div>
          </div>
        </div>
        <div className="content-btn-edit-banner-admin flex-r">
          <ToggleBtn item={item} />
          <NavLink
            to={`/admin/modifier-promotion/${item?._id}`}
            state={item}
            className="btn btn-action-modal-icon with-tooltip mx-2"
            data-tooltip-content="Modifier"
          >
            <MdModeEditOutline />
          </NavLink>
          <DeletePromotionButton id={item?._id} />
        </div>
      </div>
    </div>
  );
}
const BannerTabs = () => {
  const [page, setPage] = React.useState(1);
  // const [perPage, setPerPage] = React.useState(9);
  const [nbPages, setNbPages] = React.useState(0);
  const [currentList, setCurrentList] = React.useState<Promotion[]>([]);
  const { data = [], isLoading } = useGetPromotionListQuery({});

  const formatData = (arr) => {
    let total = Math.ceil(arr?.length / 9);
    setNbPages(total);
    const indexOfLastPost = page * 9;
    const indexOfFirstPage = indexOfLastPost - 9;

    const currentList = arr.slice(indexOfFirstPage, indexOfLastPost);
    setCurrentList(currentList);
  };
  React.useEffect(() => {
    if (data?.length) {
      formatData(
        [...data]?.sort(
          (a, b) =>
            new Date(b?.createdAt)?.getTime() -
            new Date(a?.createdAt)?.getTime()
        )
      );
    }
  }, [data, page]);
  return (
    <div className="admin-tabs-component">
      <div className="content-title flex-r pb-4">
        <NavLink
          to="/admin/ajouter-promotion"
          className="btn btn-add-admin no-link"
        >
          <BiMessageSquareAdd />
          <span className="ps-2">Ajouter une promotion</span>
        </NavLink>
      </div>
      <div className="admin-table">
        <div className="row">
          {!!isLoading && <p>Chargement...</p>}
          {!isLoading &&
            (currentList?.length ? (
              currentList?.map((item, i) => (
                <div className="col-lg-4 col-md-4 mb-3" key={item?._id}>
                  <PromoItem item={item} i={i} />
                </div>
              ))
            ) : (
              <AlertInfo message="Aucune promotion n'est disponible pour le moment!" />
            ))}
        </div>
      </div>
      <div className="d-flex justify-content-center">
        {nbPages && nbPages > 0 ? (
          <Pagination
            page={page}
            total={nbPages}
            onPageChange={(page) => setPage(page)}
          />
        ) : null}
      </div>
    </div>
  );
};

export default BannerTabs;

