import React, { ChangeEvent } from "react";
import LogoEdk from "../../../../assets/appImages/logo-edk.png";
import { GoogleApiWrapper } from "google-maps-react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import ErrorMessage from "../../../common/ErrorMessage";
import { useGetStoreListQuery } from "../../../../utils/api/store/store.api";
import { GoogleApikey } from "../../../../utils/http";
import { DeliveryMode } from "../../../../utils/api/deliveryMode/deliveryMode.type";
import { useDispatch } from "react-redux";
import {
  getDeliveryAmount,
  getTotalAmount,
} from "../../../../redux/slice/useCartSlice";
import { formatCurrency } from "../../../../utils/Utils";

const ModeLivraison = (props) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GoogleApikey,
    libraries: ["geometry", "drawing"],
  });

  const dispatch = useDispatch();
  const { data: stores = [] } = useGetStoreListQuery({
    zone: props?.orderData?.adresseObj?.zone,
  });
  const { data, isLoading } = props;
  const [error, setError] = React.useState({ delivery: "", store: "" });
  const [position, setPosition] = React.useState<
    { lat: string; lng: string } | undefined
  >();
  const [delivery, setDelivery] = React.useState(
    props?.orderData?.modeLivraison || ""
  );

  const handleSelectDeliveryMode = (
    e: ChangeEvent<HTMLInputElement>,
    item: DeliveryMode
  ) => {
    setDelivery(e?.target?.value);
    if (item?.amount) {
      dispatch(getDeliveryAmount(item?.amount));
    } else {
      dispatch(getDeliveryAmount(0));
    }

    window.scrollTo(10, 10);
    dispatch(getTotalAmount());
  };

  const onSubmit = (e) => {
    e?.preventDefault();
    let prevState = { ...props?.orderData };
    if (delivery) {
      prevState["modeLivraison"] = delivery;
      // prevState["store"] = storeSelected;

      props?.setOrderData(prevState);
      props?.setIndex(props?.index + 1);
    } else {
      window.scrollTo(10, 10);
      if (!delivery) {
        setError((prevState) => ({
          ...prevState,
          delivery: "Le mode de livraison est obligatoire",
        }));
      }
      setTimeout(() => {
        setError({ delivery: "", store: "" });
      }, 3000);
    }
  };

  const mapStyles = {
    height: "100%",
    width: "100%",
  };

  let center = {
    lat: position?.lat ? parseFloat(position?.lat) : 14.69823,
    lng: position?.lng ? parseFloat(position?.lng) : -17.43713,
  };
  return (
    <div className="form-register-tabs-component mt-5">
      <p className="sub-title-form-register">Choisir un mode de livraison</p>
      <form onSubmit={onSubmit}>
        <div className="content-form-register-tabs mt-5">
          <div>
            <div className="row row-add">
              {!!isLoading && <p>Chargement...</p>}
              {!isLoading &&
                data?.map((item) => (
                  <div
                    className="col-md-6 col-form-register-tabs mb-3"
                    key={item?._id}
                  >
                    <div className="content-col-form-livraison cpntrol">
                      <label className="label-checkbox-register-tabs">
                        <input
                          type="radio"
                          className="input-checkbox-register-tabs me-2"
                          name="modeLivraison"
                          value={item?._id}
                          defaultChecked={delivery === item?._id}
                          onChange={(e) => handleSelectDeliveryMode(e, item)}
                        />
                        {item?.name}&nbsp;&nbsp;
                        {!!item?.amount &&
                          "( " + formatCurrency(item?.amount) + " )"}
                      </label>
                    </div>
                  </div>
                ))}
              <div className="col-md-12">
                <ErrorMessage message={error?.delivery && error?.delivery} />
              </div>
              {/* <div className="col-md-6 col-form-register-tabs mb-3">
                <div className="content-col-form-livraison cpntrol">
                  <label className="label-checkbox-register-tabs">
                    <input
                      type="checkbox"
                      className="input-checkbox-register-tabs me-2"
                    />
                    Livraison à Domicile
                  </label>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="row row-mode-livraison my-5">
          <div className="col-lg-3 col-md-4 mb-3 col-left-mode-livraison d-flex">
            <div className="content-col-left-mode-livraison w-100">
              <h3 className="title-col-left-mode-livraison pb-5">
                Chercher un point EDK
              </h3>
              <div className="content-logo-mode-livraison">
                <div>
                  {stores?.map((store) => (
                    <div
                      className={`content-logo-edk-item mb-4`}
                      onClick={() => {
                        // setStoreSelected(store?._id);
                        setPosition({
                          lat: store?.latitude,
                          lng: store?.longitude,
                        });
                      }}
                    >
                      <div className="content-logo-item">
                        <img
                          src={LogoEdk}
                          alt="Logo"
                          className="logo-edk"
                          loading="lazy"
                        />
                      </div>
                      <div className="content-info-logo-edk">
                        <p className="title-info-livraison mb-0">
                          {store?.name}
                        </p>
                        <p className="text-info-livraison mb-0">
                          {store?.telephone}
                        </p>
                        <p className="text-info-livraison mb-0">
                          {store?.email}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-9 col-md-8 mb-3 col-right-mode-livraison d-flex">
            <div className="content-col-right-mode-livraison w-100">
              <div
                style={{
                  height: "400px",
                  width: "100%",
                  position: "relative",
                }}
                className="mt-3 mb-5"
              >
                {/* <Map google={props.google}></Map> */}
                {!!isLoaded && (
                  <GoogleMap
                    mapContainerStyle={mapStyles}
                    zoom={position ? 15 : 11}
                    // options={{ mapTypeControl: false }}
                    center={center}
                  >
                    {stores?.map((store) => (
                      <Marker
                        position={{
                          lat: parseFloat(store?.latitude),
                          lng: parseFloat(store?.longitude),
                        }}
                        title={store?.adresse}
                      ></Marker>
                    ))}
                  </GoogleMap>
                )}
              </div>
            </div>
          </div>
          {/* <div className="col-md-12">
            <ErrorMessage message={error?.store && error?.store} />
          </div> */}
        </div>
        <div className={`${"step-buttons"}`}>
          <button
            className={`${"step-action-btn"} ${"action-btn-secondary"}`}
            type="button"
            onClick={() => props.setIndex(props.index - 1)}
          >
            Précédant
          </button>

          <button
            className={`step-action-btn action-btn-primary`}
            type="submit"
          >
            Suivant
          </button>
        </div>
      </form>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyD0klc1PQS8QK--Be3rF3i8kW_idy04AVQ",
})(ModeLivraison);
