import React, { useState } from "react";
import { BiExport, BiMessageSquareAdd } from "react-icons/bi";
import "./GestionUsers.css";
import AddAdminModal from "./modal/AddAdminModal";
import UsersTable from "./UsersTable/UsersTable";
import { ApiBaseUrl } from "../../../utils/http";

const GestionUsers = () => {
	const [name, setName] = useState("");
	const [modalAddAdmin, setModalAddAdmin] = useState(false);

	function openModalAddAdmin(e) {
		e?.preventDefault();
		setModalAddAdmin(true);
	}
	const handleExportClick = () => {
		fetch(`${ApiBaseUrl}/api/user/export?userType=user`, {})
			.then((response) => response.blob())
			.then((blob) => {
				const url = window.URL.createObjectURL(blob);
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", "Base-de-donnee-client.xlsx");
				document.body.appendChild(link);
				link.click();
			});
	};
	return (
		<div className="content-graph-admin">
			<div className="dashbord-admin-component admidashboard-bg mb-3">
				<div className="dash-admin-page-content-container mb-3">
					<div className="container-title-content-page mb-4">
						<div className="content-title">
							<h2 className="title-admin">Utilisateurs</h2>
						</div>
						<div className="content-btn-action-admin">
							<button className="btn btn-add-admin" onClick={openModalAddAdmin}>
								<BiMessageSquareAdd />
								<span className="ps-2">Ajouter un utilisateur</span>
							</button>
							<AddAdminModal modalAddAdmin={modalAddAdmin} setModalAddAdmin={setModalAddAdmin} type="user" />
						</div>
					</div>
				</div>
				<div className="d-flex justify-content-center">
					<button className="btn btn-add-admin" onClick={handleExportClick}>
						<BiExport />
						<span className="ps-2">Exporter la base de donnée client</span>
					</button>
				</div>
			</div>
			<div className="admin-table mb-3">
				<div className="tab-dash-admin-filter-container">
					<form className="row g-3 tab-dash-admin-filter-form">
						<div className="col-auto">
							<label htmlFor="filtreProduit" className="tab-dash-admin-filter-label">
								Filtrer
							</label>
						</div>
						<div className="col-auto">
							<input
								type="text"
								className="form-control tab-dash-admin-filter-input"
								id="filtreProduit"
								placeholder="Entrer un nom"
								onChange={(e) => setName(e?.target?.value)}
							/>
						</div>
					</form>
				</div>
				<UsersTable name={name} />
			</div>
		</div>
	);
};

export default GestionUsers;
