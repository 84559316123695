import React from "react";
import { useGetFavoriteListQuery } from "../../../utils/api/favories/favorie.api";
import { AlertInfo } from "../../common/Alert";
import { ProductItem } from "../../HomePage/HomePage";
import { ProductItemsSkeleton } from "../../user/ParcoursAchat/ParcoursAchat";

const FavorisPage = () => {
  const { data = [], isLoading } = useGetFavoriteListQuery();
  return (
    <div className="form-register-tabs-component">
      <div className="content-title-form-register pb-4">
        <h3 className="title-form-register">Ma liste d’envies</h3>
      </div>
      <div className="content-panier-page mt-5">
        <div className="row row-panier-page">
          {!!isLoading && <ProductItemsSkeleton />}
          {!isLoading &&
            (data?.length ? (
              data?.map((item) => (
                <div
                  className="col-md-6 col-lg-4 col-xl-3 col-favoris-page mb-3"
                  key={item?._id}
                >
                  <ProductItem item={item} />
                </div>
              ))
            ) : (
              <AlertInfo message="Aucun produit disponible dans vos favoris!" />
            ))}
        </div>
        
      </div>
    </div>
  );
};

export default FavorisPage;
