/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from "@hookform/resolvers/yup";
import { ChangeEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";
import {
  Banner,
  BannerFormData,
} from "../../../../utils/api/banner/banner.type";
import {
  useAddOrEditBannerMutation,
  useDeleteBannerMutation,
} from "../../../../utils/api/banner/banner.api";
import { cleannerError, getImage } from "../../../../utils/Utils";
import { Color } from "../../../../utils/theme";

function UseAddOrEditBanner(banner?: Banner) {
  const validationSchema = yup.object().shape({
    hasEdit: yup.boolean(),
    page: yup.string().required().label("La page de redirection"),
    position: yup
      .number()
      .required()
      .min(1)
      .label("La position")
      .transform((value) => (isNaN(value) ? null : value)),
    banner: yup
      .mixed()
      .when("hasEdit", {
        is: false,
        then: () => yup.mixed().required().label("L'image").nullable(),
      })
      .nullable(),
    active: yup.boolean().label("Le statut").nullable().default(false),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm<BannerFormData>({
    resolver: yupResolver(validationSchema),
  });
  const navigate = useNavigate();
  const [image, setImage] = useState<any>(null);
  const [sendData, { isLoading, isSuccess, error, isError }] =
    useAddOrEditBannerMutation();
  const [status, setStatus] = useState(false);

  const handleChangeImage = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      setImage(URL.createObjectURL(file));
      setValue("banner", file);
    }
  };

  useEffect(() => {
    setValue("active", status);
  }, [status]);
  useEffect(() => {
    if (banner?._id) {
      console.log(banner);
      setValue("hasEdit", true);
      const fields: (keyof BannerFormData)[] = ["page", "position", "active"];
      for (let field of fields) {
        register(field);
        setValue(field, banner[field]);
      }
      setStatus(banner?.active);

      if (banner?.banner) {
        setImage(getImage(banner?.banner));
      }
    } else {
      setValue("hasEdit", false);
    }
  }, [banner]);

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        iconColor: Color.success,
        confirmButtonColor: Color.success,
        title: banner
          ? "Bannière modifiée avec succès !"
          : "Bannière ajoutée avec succès !",
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {
        navigate("/admin/parametres", { state: "banner" });
      });
    }
    const err = error as any;

    if (isError) {
      console.log("error", err);

      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Une erreur de statut ${err?.status} est survenue`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [error, isError, isSuccess, isLoading]);

  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [clearErrors, errors]);

  const handleChangeStatus = (e: ChangeEvent<HTMLInputElement>) => {
    setStatus(e.target.checked);
  };

  const onSubmit = async (data: BannerFormData) => {
    const fd = new FormData();
    for (let key of Object.keys(data)) {
      if (key === "banner") {
        if (data[key]) {
          fd.append(key, data[key] as any);
        }
      } else {
        fd.append(key, data[key]);
      }
    }
    console.log("data", data);
    await sendData({ id: banner?._id, data: fd });
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    isLoading,
    handleChangeImage,
    image,
    handleChangeStatus,
    status,
  };
}

export default UseAddOrEditBanner;
export function UseDeleteBanner(id: string) {
  const [deleteData] = useDeleteBannerMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer cette banniere ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(id);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      //   console.log(result, "archiver");
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `La banniere a été supprimée avec succès!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue lors de la suppression`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };
  return onDelete;
}

export function UseToggleBanner(item: Banner) {
  const [sendData] = useAddOrEditBannerMutation();

  const onToggle = async () => {
    let fd = new FormData()
    fd.append("active", !item?.active as any);
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir ${
        item?.active ? "désactiver" : "activer"
      } cette bannière ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return sendData({
          id: item?._id,
          data: fd,
        });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `La bannière a été ${item?.active ? "désactivée" : "activée"} avec succès!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };
  return onToggle;
}