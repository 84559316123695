import React from "react";
import { Zone } from "../../../../utils/api/zone/zone.type";
import ErrorMessage from "../../../common/ErrorMessage";
import GoogleInput from "../../../common/GoogleInput";
import UseCrudZoneForm from "../requestForm/UseCrudZoneForm";

function AddOrEditZone({ modalId, item }: { modalId: string; item?: Zone }) {
  const {
    errors,
    onSubmit,
    isLoading,
    address,
    onChangeAddress,
    handleReset,
  } = UseCrudZoneForm(modalId, item);
  return (
    <div
      className="modal fade"
      id={modalId}
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
      aria-labelledby={`${modalId}Label`}
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id={`${modalId}Label`}>
              {!item ? "Ajouter" : "Modifier"} une zone
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="pt-3">
              <form onSubmit={onSubmit}>
                <div className="row py-3 px-2">
                  {/* <div className="col-md-12 mb-4">
                    <label className="form-label form-label-admin">
                      Région{" "}
                      <span
                        className="text-danger custom-required-text"
                        style={{ fontSize: 17 }}
                      >
                        *
                      </span>
                    </label>
                    <select
                      className="form-control form-control-select-admin"
                      {...register("region")}
                      id="region"
                    >
                      <option value="">Région de ...</option>
                      {options?.map((option, i) => (
                        <option value={option?.value} key={i}>
                          {" "}
                          {option?.label}{" "}
                        </option>
                      ))}
                    </select>

                    <ErrorMessage
                      message={errors?.region && errors?.region?.message}
                    />
                  </div> */}
                  <div className="col-md-12 mb-4">
                    <label className="form-label form-label-admin">
                      Zone{" "}
                      <span
                        className="text-danger custom-required-text"
                        style={{ fontSize: 17 }}
                      >
                        *
                      </span>
                    </label>

                    <GoogleInput
                      className="form-control form-control-admin"
                      value={address}
                      onChange={onChangeAddress}
                      placeholder="Zone"
                    />
                    <ErrorMessage
                      message={errors?.ville && errors?.ville?.message}
                    />
                  </div>
                  {/* <div className="col-md-12 mb-4">
                    <InputAdmin
                      label={"Prix"}
                      type={"number"}
                      min={0}
                      step={"any"}
                      placeholder="Prix"
                      {...register("price")}
                      error={errors?.price?.message}
                      //   required
                    />
                  </div>
                  <div className="col-md-12 mb-4">
                    <InputAdmin
                      label="Taux"
                      type={"number"}
                      min={0}
                      step={"any"}
                      placeholder="Taux"
                      {...register("taux")}
                      error={errors?.taux?.message}
                    />
                  </div> */}

                  <div className="flex-r">
                    <button
                      className="btn btn-cancelled"
                      type="button"
                      data-bs-dismiss="modal"
                      onClick={() => handleReset()}
                      aria-label="Close"
                    >
                      Annuler
                    </button>
                    <button
                      type="submit"
                      className="btn btn-theme"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                            role="status"
                          ></span>
                          <span>En cours...</span>
                        </>
                      ) : item ? (
                        "Modifier"
                      ) : (
                        "Ajouter"
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddOrEditZone;
