/* eslint-disable @typescript-eslint/no-use-before-define */
import * as React from "react";
import "./ParcoursAchat.css";
import FrontHeader from "../../shared/FrontHeader/FrontHeader";
import FrontFooter from "../../shared/FrontFooter/FrontFooter";
import "react-input-range/lib/css/index.css";
import FooterPage from "../../auth/FooterPage";
import FilterItems, { MobileFilterItems } from "./FilterItems";
import StarRatingComponent from "react-star-rating-component";
import { useGetProductListQuery } from "../../../utils/api/product/product.api";
import InfiniteScroll from "react-infinite-scroller";
import { AlertInfo } from "../../common/Alert";
import Skeleton from "react-loading-skeleton";
import { useNavigate, useSearchParams } from "react-router-dom";
import Swal, { SweetAlertResult } from "sweetalert2";
import { Color } from "../../../utils/theme";
import { Product } from "../../../utils/api/product/product.type";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import {
  addCartProduct,
  calculateTax,
  getCartCount,
  getPreOrder,
  getSubTotal,
  getTotalAmount,
} from "../../../redux/slice/useCartSlice";
import { ProductItem } from "../../HomePage/HomePage";
import { BannerHomePageSection } from "../../HomePage/BannerHomepage";

let options = [
  {
    label: "Nombre d'avis",
    value: "avreageRate",
  },
  {
    label: "Date de création",
    value: "createdAt",
  },
  {
    label: "Nom",
    value: "name",
  },
  {
    label: "Note moyenne",
    value: "rateCount",
  },
  {
    label: "Prix",
    value: "price",
  },
];
const ParcoursAchat = () => {
  const { store } = useAppSelector((state) => state.config);
  // const { data: promotions = [] } = useGetActivePromotionListQuery({});
  let [searchParams, setSearchParams] = useSearchParams();
  const [query, setQuery] = React.useState({
    category: searchParams?.get("category") || "",
    min: searchParams?.get("min") || "",
    max: searchParams?.get("max") || "",
    orderBy: searchParams?.get("orderBy") || "",
  });
  const [page, setPage] = React.useState(1);
  const {
    data = { results: [], nbPage: 0 },
    isFetching,
    isLoading,
  } = useGetProductListQuery({
    productType: "product",
    page,
    minPrice: searchParams?.get("min") || "",
    maxPrice: searchParams?.get("max") || "",
    orderBy: searchParams?.get("orderBy") || "",
    order: "ASC",
    category: searchParams?.get("category")
      ? JSON.parse(searchParams?.get("category") || "[]")
      : "",
    store: store?._id,
  });
  const [isAtBottom, setIsAtBottom] = React.useState(false);
  const [items, setItems] = React.useState<Product[]>([]);
  const [loading, setLoading] = React.useState(false);
  const listInnerRef = React.useRef<any>(null);

  React.useEffect(() => {
    if (page === 1) {
      setLoading(true);
      window.scrollTo({
        top: 300,
        behavior: "smooth",
      });
    }
  }, [page, query]);

  React.useEffect(() => {
    const handleScroll = () => {
      const targetDiv = listInnerRef.current;

      if (targetDiv) {
        const divHeight = targetDiv.clientHeight;
        const divOffsetTop = targetDiv.offsetTop;
        const scrollPosition =
          window.scrollY ||
          window.pageYOffset ||
          document.body.scrollTop +
            ((document.documentElement && document.documentElement.scrollTop) ||
              0);

        setIsAtBottom(
          scrollPosition + window.innerHeight >= divHeight + divOffsetTop
        );
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [listInnerRef]);

  React.useEffect(() => {
    if (data?.results) {
      setItems((prevState: Product[]) => {
        if (page === 1) {
          return data?.results;
        }
        return [...prevState, ...data?.results];
      });
      setLoading(false);
    }
  }, [data]);

  // React.useEffect(() => {
  //   console.log(category, "category");
  //   if (category) {
  //     setItems([]);
  //   }
  // }, [category]);

  // React.useEffect(() => {
  //   if (promotions?.length) {
  //     setLastPromo(promotions[promotions?.length - 1]);
  //   }
  // }, [promotions]);

  const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    let queryCopy = { ...query };
    queryCopy = {
      ...queryCopy,
      orderBy: e?.target?.value,
    };
    setQuery(queryCopy);
    // setItems([]);
    setPage(1);
  };

  const fetchItems = () => {
    if (data?.nextPage && !isFetching) {
      setPage(data?.nextPage);
    }
  };

  React.useEffect(() => {
    if (isAtBottom && !isFetching) {
      fetchItems();
    }
  }, [isAtBottom]);

  return (
    <div className="landing-page-component">
      <FrontHeader />
      <div className="page-content">
        <div className="container-low-price">
          <div className="row row-achat-page mb-5">
            <FilterItems
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              query={query}
              setQuery={setQuery}
              setPage={setPage}
            />
            <div className="col-lg-9 col-md-7 col-right-achat-page">
              <div className="content-col-right-achat-page pt-4">
                <BannerHomePageSection />
                <div className="no-view-desktop">
                  <MobileFilterItems
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                    query={query}
                    setQuery={setQuery}
                    setPage={setPage}
                  />
                </div>
                <div
                  className="container-home-page mb-3"
                  style={{ minHeight: "51rem" }}
                >
                  <div className="content-btn-title-home-page flex-r py-3 no-view-mobile">
                    <div className="tab-dash-admin-filter-container">
                      <form className="row g-3 tab-dash-admin-filter-form">
                        <div className="col-auto">
                          <label
                            htmlFor="filtreProduit"
                            className="tab-dash-admin-filter-label"
                            style={{
                              fontWeight: 700,
                              color: "#000",
                            }}
                          >
                            Trier par
                          </label>
                        </div>
                        <div className="col-auto">
                          <select
                            className="form-control filter-select-product"
                            value={searchParams?.get("orderBy") || ""}
                            onChange={(e) => handleSelect(e)}
                          >
                            <option value="">Pertinence</option>
                            {options?.map((opt, i) => (
                              <option value={opt?.value} key={i}>
                                {" "}
                                {opt?.label}{" "}
                              </option>
                            ))}
                          </select>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className=" row mt-3">
                    {!!isLoading && <ProductItemsSkeleton />}

                    <InfiniteScroll
                      loadMore={fetchItems}
                      hasMore={isFetching}
                      loader={
                        <div className="row mt-3">
                          <ProductItemsSkeleton />
                        </div>
                      }
                    >
                      <div className="row mt-3" ref={listInnerRef}>
                        {!!loading && <ProductItemsSkeleton />}
                        {!loading &&
                          (items?.length ? (
                            items?.map((produit) => (
                              <div
                                className="col-md-6 col-lg-4 col-xl-3 mb-3 col-product-item d-flex"
                                key={produit._id}
                              >
                                <ProductItem item={produit} key={produit._id} />
                              </div>
                            ))
                          ) : (
                            <AlertInfo message="Aucun produit disponible pour le moment!" />
                          ))}
                      </div>
                    </InfiniteScroll>
                    {/* {!isFetching &&
                      (data?.results?.length ? (
                        data?.results?.map((produit) => (
                          <div
                            className="col-md-6 col-lg-4 col-xl-3 mb-3 col-product-item d-flex"
                            key={produit._id}
                          >
                            <ProductItem item={produit} key={produit._id} />
                          </div>
                        ))
                      ) : (
                        <AlertInfo message="Aucun produit disponible pour le moment!" />
                      ))} */}
                  </div>
                  {/* <div className="d-flex justify-content-center">
                    {!isFetching &&
                      (data?.nbPage && data?.nbPage > 0 ? (
                        <Pagination
                          page={page}
                          total={data?.nbPage}
                          onPageChange={(page) => setPage(page)}
                        />
                      ) : null)}
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <FooterPage />
        </div>
      </div>
      <FrontFooter />
    </div>
  );
};

export default ParcoursAchat;

export const ProductItemsSkeleton = () => {
  return (
    <>
      {Array(48)
        .fill(48)
        .map((item, i) => (
          <div
            className="col-md-6 col-lg-4 col-xl-3 mb-3 col-product-item d-flex"
            key={i}
          >
            <div
              className="product-item-container"
              style={{ textAlign: "center" }}
            >
              {/* <button className="btn icon-fav-product">
                <Skeleton width={20} height={20} circle={true} />
              </button> */}

              <div className="content-img-product-item">
                <Skeleton className="img-product-item" />
              </div>
              <div className="content-btn-buy-product-item py-3">
                <Skeleton
                  className="btn btn-buy-product-item bg-transparent"
                  width={`50%`}
                />
              </div>
              <div className="text-center">
                <Skeleton
                  className="price-product-item"
                  width={`60%`}
                  height={20}
                />
                <Skeleton
                  className="name-prduct fs-15"
                  height={15}
                  width={`70%`}
                />
                <div className="content-rate-product">
                  <StarRatingComponent
                    name="rate2"
                    starCount={5}
                    value={0}
                    starColor="#000000"
                    emptyStarColor="#D4D6D5"
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
    </>
  );
};

export function AddedCartConform(item: Product) {
  const navigate = useNavigate();
  const onConfirm = async () => {
    await Swal.fire({
      // title: `Êtes-vous sûr de vouloir ?`,
      icon: "success",
      html: `<div class="sweet-container"> ${item?.name} <strong>a été ajouté au panier</strong> </div>`,
      showCancelButton: true,
      confirmButtonText: "CONFIRMER",
      cancelButtonText: "POURSUIVRE MES ACHATS",
      showLoaderOnConfirm: true,
      iconColor: Color.default,
      confirmButtonColor: Color.default,
      customClass: {
        confirmButton: "btn btn-sweet-confirm",
        cancelButton: "btn btn-sweet-cancel",
      },
      reverseButtons: true,
    }).then((result: any) => {
      if (result?.isConfirmed) {
        navigate("/valider-commande");
      }
    });
  };
  return onConfirm;
}

export function ALertDisponibility(item: Product) {
  const onConfirm = AddedCartConform(item);
  // const [isFavorite, setIsFavorite] = React.useState(false);
  const dispatch = useAppDispatch();
  const onAlert = async () => {
    await Swal.fire({
      title: `Le produit n'est actuellement pas disponible en stock. Souhaitez-vous faire une pré-commande ?`,
      icon: "success",
      text: "Vous serez notifié de la disponibilité de ce produit.",
      showCancelButton: true,
      confirmButtonText: "PRÉ-COMMANDER",
      cancelButtonText: "ANNULER",
      showLoaderOnConfirm: true,
      iconColor: Color.default,
      confirmButtonColor: Color.default,
      customClass: {
        confirmButton: "btn btn-sweet-confirm",
        cancelButton: "btn btn-sweet-cancel",
      },
      reverseButtons: true,
    }).then((result: SweetAlertResult<any>) => {
      if (result?.isConfirmed) {
        dispatch(addCartProduct({ product: item, qty: 1 }));
        dispatch(getCartCount());
        dispatch(getSubTotal());
        dispatch(calculateTax());
        dispatch(getTotalAmount());
        dispatch(getPreOrder(true));
        onConfirm();
      }
    });
  };
  return onAlert;
}
