import OTPInput, { ResendOTP } from "otp-input-react";
import React, { Dispatch, SetStateAction } from "react";
import Swal from "sweetalert2";
import { Color } from "../../utils/theme";
import { closeModal } from "../../utils/Utils";
import {
  useConfirmPhoneOtpMutation,
  useResendCodeMutation,
} from "../../utils/api/auth/auth.api";

function ConfirmPhoneNumber({
  phone,
  setModalConnexion,
}: {
  phone: string;
  setModalConnexion: Dispatch<SetStateAction<boolean>>;
}) {
  const [sendCode] = useResendCodeMutation();
  const [sendData, { isLoading, isSuccess, isError, error }] =
    useConfirmPhoneOtpMutation();
  const [OTP, setOTP] = React.useState("");
  const [hasError, setHasError] = React.useState(false);

  const renderTime = (remainingTime) => {
    return (
      <span>
        {remainingTime > 0 ? `Renvoyer dans ${remainingTime} secondes` : ""}
      </span>
    );
  };

  const renderButton = (buttonProps) => {
    return <button {...buttonProps}>Renvoyer le code</button>;
  };

  React.useEffect(() => {
    if (isSuccess) {
      window.scroll(0, 10);
      //   console.log("order", data);

      Swal.fire({
        icon: "success",
        title: "Bravo!",
        text: "Votre compte a été validé avec succès.",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 3000,
      }).then(async () => {
        await closeModal("phoneValidationModal");
        await closeModal("phoneModal");
        
        await setModalConnexion(true);
      });
    }
    const err = error as any;

    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Une erreur de statut ${err?.status} est survenue lors de la validation`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [error, isError, isSuccess]);

  const onSubmit = async(e) => {
    e?.preventDefault();
    if (OTP && OTP?.length === 5) {
        sendData({ telephone: phone, code: OTP });
    } else {
      console.log("error");
      setHasError(true);

      setTimeout(() => {
        setHasError(false);
      }, 2000);
    }
  };

  return (
    <div
      className="modal fade"
      id="phoneValidationModal"
      tabIndex={-1}
      data-bs-backdrop="static"
      aria-labelledby="phoneValidationModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body">
            <div className="d-flex justify-content-center align-items-center">
              {" "}
              <div className="position-relative">
                {" "}
                <div className="card cart-otp p-2 text-center">
                  {" "}
                  <h6>Veuillez entrer votre code de validation.</h6>{" "}
                  <div>
                    {" "}
                    <span>Un code a été envoyé à</span>{" "}
                    <small>*********{phone?.slice(-4)} (valable 10mns)</small>{" "}
                  </div>{" "}
                  <OTPInput
                    value={OTP}
                    onChange={setOTP}
                    autoFocus
                    className="inputs-otp d-flex flex-row justify-content-center mt-2"
                    inputClassName={`m-2 text-center form-control form-control-otp rounded ${
                      hasError ? "error" : ""
                    }`}
                    OTPLength={5}
                    otpType="number"
                    disabled={false}
                  />
                  <div className="mt-4 mb-3">
                    {" "}
                    <button
                      className="btn px-4 py-2 validate"
                      onClick={(e) => onSubmit(e)}
                        disabled={isLoading}
                    >
                      {isLoading ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                            role="status"
                          ></span>
                          <span>Validation...</span>
                        </>
                      ) : (
                        "Valider"
                      )}
                      
                    </button>{" "}
                  </div>{" "}
                  <ResendOTP
                    maxTime={15}
                    className="reset-otp-btn"
                    renderTime={renderTime}
                    renderButton={renderButton}
                    onResendClick={() => sendCode({telephone:phone})}
                  />
                </div>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfirmPhoneNumber;
