import React, { ChangeEvent, useState } from "react";
import { BiFilterAlt } from "react-icons/bi";
import { IoIosArrowDown } from "react-icons/io";
import InputRange from "react-input-range";
import { useGetCategoryListQuery } from "../../../utils/api/category/category.api";
import { AlertInfo } from "../../common/Alert";
import { QueryUrl, sortByAlphabetical } from "../../../utils/Utils";
import Modal from "react-bootstrap/Modal";
import Accordion from "react-bootstrap/Accordion";

let options = [
  {
    label: "Nombre d'avis",
    value: "avreageRate",
  },
  {
    label: "Date de création",
    value: "createdAt",
  },
  {
    label: "Nom",
    value: "name",
  },
  {
    label: "Note moyenne",
    value: "rateCount",
  },
  {
    label: "Prix",
    value: "price",
  },
];
function FilterItems({
  searchParams,
  setSearchParams,
  query,
  setQuery,
  setPage,
  
}) {
  // let [searchParams, setSearchParams] = useSearchParams();

  const { data = [], isLoading } = useGetCategoryListQuery();
  const [selectedCat, setSelectedCat] = React.useState<any>(
    searchParams?.get("category")
      ? JSON.parse(searchParams?.get("category") || "[]")
      : []
  );
  const [selectedIndex, setSelectedIndex] = React.useState<number | null>(null);
  const [value, setValue] = React.useState<any>({
    min: searchParams?.get("min") || 1000,
    max: searchParams?.get("max") || 10000,
  });
  // const [query, setQuery] = React.useState({
  //   category: selectedCat?.length ? JSON.stringify(selectedCat) : "",
  //   min: "",
  //   max: "",
  // });

  const handleFilter = () => {
    let queryCopy = { ...query };
    queryCopy = { ...queryCopy, ...value };

    setPage(1);
    setQuery(queryCopy);
  };

  React.useEffect(() => {
    let params = QueryUrl("", query);
    setSearchParams(params);
  }, [query]);

  React.useEffect(() => {
    let queryCopy = { ...query };
    queryCopy = {
      ...queryCopy,
      category: selectedCat?.length ? JSON.stringify(selectedCat) : "",
    };
    // console.log("query selectedCat", queryCopy);

    setQuery(queryCopy);
  }, [selectedCat]);

  const handleSelectedCat = (e: ChangeEvent<HTMLInputElement>) => {
    let selectedCopy = [...selectedCat];
    if (e?.target?.checked) {
      selectedCopy?.push(e?.target?.value);
    } else {
      selectedCopy = selectedCopy?.filter((el) => el !== e?.target?.value);
    }

    setSelectedCat(selectedCopy);

    setPage(1);
  };

  return (
    <div className="col-lg-3 col-md-5 col-left-achat-page no-view-mobile">
      <div
        className="content-col-left-achat-page pt-5 no-view-mobile"
        style={{ minHeight: "70rem" }}
      >
        <div className="content-title-section-left pb-4">
          <BiFilterAlt />
          <span className="title-section-left ps-2">Affiner ma recherche</span>
        </div>
        <p className="content-title-section-left">
          Prix <span style={{ fontWeight: 300, fontSize: 14 }}>(F CFA)</span>
        </p>
        <div className="pb-4 pt-3">
          <div className="position-relative">
            <InputRange
              maxValue={20000}
              minValue={0}
              value={value}
              onChange={(value) => setValue(value)}
            />
          </div>
          <div className="d-flex gap-2 mt-5 container-price align-items-center">
            <div className="price-container">{value?.min || 0}</div>-
            <div className="price-container">{value?.max || 0}</div>
            <button
              className="btn btn-price-selected"
              onClick={() => handleFilter()}
            >
              ok
            </button>
          </div>
        </div>
        <div className="content-others-value my-4 pb-3">
          <p className="content-title-section-left">Univers</p>
          <Accordion
            className="category-filter-acordion"
            id="accordionCategories"
            defaultActiveKey={["0"]}
            alwaysOpen
          >
            {!!isLoading && <p>Loading...</p>}
            {!isLoading &&
              (sortByAlphabetical(data)?.filter((el) => el?.name !== "Non catégorisé")?.length ? (
                sortByAlphabetical(data)?.filter((el) => el?.name !== "Non catégorisé")?.map((category, i) => (
                  <Accordion.Item
                    eventKey={`${i}`}
                    className="category-filter-acordion-item mb-1"
                    key={category?._id}
                  >
                    <Accordion.Header>{category?.name}</Accordion.Header>
                    <Accordion.Body>
                      {category?.subCategories?.length ? (
                        sortByAlphabetical(category?.subCategories)
                          ?.slice(
                            0,
                            selectedIndex === i
                              ? category?.subCategories?.length
                              : 10
                          )
                          ?.map((sub) => (
                            <div
                              className="content-col-form-register-tabs cpntrol mb-3"
                              key={sub?._id}
                            >
                              <label className="label-checkbox-register-tabs">
                                <input
                                  type="checkbox"
                                  className="input-checkbox-register-tabs me-2"
                                  checked={
                                    selectedCat &&
                                    selectedCat?.length &&
                                    selectedCat?.includes(sub?._id)
                                  }
                                  value={sub?._id}
                                  onChange={(e) => handleSelectedCat(e)}
                                />
                                {sub?.name}
                              </label>
                            </div>
                          ))
                      ) : (
                        <AlertInfo message="Aucune sous catégorie disponible pour cette catégorie" />
                      )}
                      {category?.subCategories?.length > 10 ? (
                        <div className="content-btn-see-more">
                          <button
                            className="btn btn-see-more"
                            onClick={() => {
                              setSelectedIndex((prevState) => {
                                if (prevState !== null && prevState === i) {
                                  return null;
                                }
                                return i;
                              });
                            }}
                          >
                            <IoIosArrowDown style={{ fontSize: 18 }} />
                            <span className="ps-2">
                              {" "}
                              {selectedIndex === i
                                ? "VOIR MOINS"
                                : "VOIR PLUS"}{" "}
                            </span>
                          </button>
                        </div>
                      ) : null}
                    </Accordion.Body>
                  </Accordion.Item>
                ))
              ) : (
                <AlertInfo message="Aucune catégorie disponible pour le moment" />
              ))}
          </Accordion>
        </div>
      </div>
    </div>
  );
}

export default FilterItems;
export const MobileFilterItems = ({
  searchParams,
  setSearchParams,
  query,
  setQuery,
  setPage,
  
}) => {
  const { data = [], isLoading } = useGetCategoryListQuery();
  const [selectedCat, setSelectedCat] = React.useState<any>(
    searchParams?.get("category")
      ? JSON.parse(searchParams?.get("category") || "[]")
      : []
  );
  const [selectedIndex, setSelectedIndex] = React.useState<number | null>(null);
  const [value, setValue] = React.useState<any>({
    min: searchParams?.get("min") || 1000,
    max: searchParams?.get("max") || 10000,
  });

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleFilter = () => {
    let queryCopy = { ...query };
    queryCopy = { ...queryCopy, ...value };
    setPage(1);

    setQuery(queryCopy);
  };

  React.useEffect(() => {
    let params = QueryUrl("", query);
    setSearchParams(params);
  }, [query]);

  React.useEffect(() => {
    let queryCopy = { ...query };
    queryCopy = {
      ...queryCopy,
      category: selectedCat?.length ? JSON.stringify(selectedCat) : "",
    };
    setQuery(queryCopy);
  }, [selectedCat]);

  const handleSelectedCat = (e: ChangeEvent<HTMLInputElement>) => {
    let selectedCopy = [...selectedCat];
    if (e?.target?.checked) {
      selectedCopy?.push(e?.target?.value);
    } else {
      selectedCopy = selectedCopy?.filter((el) => el !== e?.target?.value);
    }

    setSelectedCat(selectedCopy);

    setPage(1);
  };
  const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    let queryCopy = { ...query };
    queryCopy = {
      ...queryCopy,
      orderBy: e?.target?.value,
    };

    setPage(1);
    setQuery(queryCopy);
  };
  return (
    <div className="content-col-left-achat-page-mobile no-view-desktop">
      <div className="container-title-bloc-filter-mobile">
        <div className="content-btn-title-secton-filter-mobile">
          <button
            className="btn content-title-section-left-mobile"
            onClick={handleShow}
          >
            <BiFilterAlt />
            <span className="title-section-left ps-2">
              Affiner ma recherche
            </span>
          </button>
        </div>
        <div>
          <label
            htmlFor="filtreProduit"
            className="form-label form-label-select-filter-option"
          >
            Trier par
          </label>
          <select
            value={searchParams?.get("orderBy") || ""}
            onChange={(e) => handleSelect(e)}
            className="form-control form-control-select-filter-option"
          >
            <option value="pertinence">Pertinence</option>
            {options?.map((opt, i) => (
              <option value={opt?.value} key={i}>
                {" "}
                {opt?.label}{" "}
              </option>
            ))}
          </select>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Affiner ma recherche</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="content-title-section-left">
            Prix <span style={{ fontWeight: 300, fontSize: 14 }}>(F CFA)</span>
          </p>
          <div className="pb-4 pt-3">
            <div className="position-relative">
              <InputRange
                maxValue={20000}
                minValue={0}
                value={value}
                onChange={(value) => setValue(value)}
              />
            </div>
            <div className="d-flex gap-2 mt-5 container-price align-items-center">
              <div className="price-container">{value?.min || 0}</div>-
              <div className="price-container">{value?.max || 0}</div>
              <button
                className="btn btn-price-selected"
                onClick={() => handleFilter()}
              >
                ok
              </button>
            </div>
          </div>
          <div className="content-others-value my-4 pb-3">
            <p className="content-title-section-left">Univers</p>
            <Accordion
              className="category-filter-acordion"
              id="accordionCategories"
            >
              {!!isLoading && <p>Loading...</p>}
              {!isLoading &&
                (sortByAlphabetical(data)?.length ? (
                  sortByAlphabetical(data)?.map((category, i) => (
                    <Accordion.Item
                      eventKey={category?._id}
                      className="category-filter-acordion-item mb-1"
                    >
                      <Accordion.Header>{category?.name}</Accordion.Header>
                      <Accordion.Body>
                        {category?.subCategories?.length ? (
                          sortByAlphabetical(category?.subCategories)
                            ?.slice(
                              0,
                              selectedIndex === i
                                ? category?.subCategories?.length
                                : 10
                            )
                            ?.map((sub) => (
                              <div
                                className="content-col-form-register-tabs cpntrol mb-3"
                                key={sub?._id}
                              >
                                <label className="label-checkbox-register-tabs">
                                  <input
                                    type="checkbox"
                                    className="input-checkbox-register-tabs me-2"
                                    checked={
                                      selectedCat &&
                                      selectedCat?.length &&
                                      selectedCat?.includes(sub?._id)
                                    }
                                    value={sub?._id}
                                    onChange={(e) => handleSelectedCat(e)}
                                  />
                                  {sub?.name}
                                </label>
                              </div>
                            ))
                        ) : (
                          <AlertInfo message="Aucune sous catégorie disponible pour cette catégorie" />
                        )}
                        {category?.subCategories?.length > 10 ? (
                          <div className="content-btn-see-more">
                            <button
                              className="btn btn-see-more"
                              onClick={() => {
                                setSelectedIndex((prevState) => {
                                  if (prevState !== null && prevState === i) {
                                    return null;
                                  }
                                  return i;
                                });
                              }}
                            >
                              <IoIosArrowDown style={{ fontSize: 18 }} />
                              <span className="ps-2">
                                {" "}
                                {selectedIndex === i
                                  ? "VOIR MOINS"
                                  : "VOIR PLUS"}{" "}
                              </span>
                            </button>
                          </div>
                        ) : null}
                      </Accordion.Body>
                    </Accordion.Item>
                  ))
                ) : (
                  <AlertInfo message="Aucune catégorie disponible pour le moment" />
                ))}
            </Accordion>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
