import React, { useEffect } from "react";
import { useLocation, useRoutes } from "react-router-dom";
import { AppRoutes } from "./routes/router";
import { useMeQuery } from "./utils/api/user/user.api";
import { useAppDispatch, useAppSelector } from "./redux/hook";
import { onSetUser, onlogout } from "./redux/slice/User.slice";
import { onCleanConfig, onSetStore } from "./redux/slice/Config.slice";
import { useLazyStoreByIdQuery } from "./utils/api/store/store.api";
import BtnTop from "./components/shared/BtnTop";

function FetchUser() {
  const { user } = useAppSelector((s) => s?.user);
  const { isSuccess, data, isError } = useMeQuery();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (isSuccess) {
      // console.log("user", data);
      if (data?._id === user?._id) {
        dispatch(onSetUser(data));
      }
    }

    if (isError) {
      dispatch(onlogout());
    }
  }, [isSuccess, data, isError]);

  return null;
}

function FetchStore() {
  const { store } = useAppSelector((state) => state.config);
  const [fetchData, { isSuccess, data, isError }] = useLazyStoreByIdQuery();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (store?._id) {
      fetchData(store?._id);
    }
  }, [store]);

  useEffect(() => {
    if (isSuccess) {
      if (data?._id === store?._id) {
        console.log("store", data);
        dispatch(onSetStore(data));
      }
    }

    if (isError) {
      console.log("eerrr");
      dispatch(onCleanConfig());
    }
  }, [isSuccess, data, isError]);

  return null;
}
function ScrollToTop() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return null;
}
function App() {
  const isConnected = useAppSelector((s) => !!s.user?.token);
  const routes = useRoutes(AppRoutes);

  return (
    <div className="app-routing-container">
      <FetchStore />
      <ScrollToTop />
      <BtnTop />
      {isConnected && <FetchUser />}
      {routes}
    </div>
  );
}

export default App;
