import React from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import ReactQuill from "react-quill";
import { useNavigate } from "react-router-dom";
import { FormError } from "../../common/Input";
import UseEditConfigApp from "./request/UseEditConfigApp";

function AddCguAdmin() {
  const { contenu, handleChange, onSubmit, isLoading, errors } =
    UseEditConfigApp("cgu");
     const navigate = useNavigate();
  return (
    <div className="content-graph-admin">
      <div className="container-title-content-page mb-4">
        <div
          className="content-title-page-admin"
          onClick={() => navigate(-1)}
          style={{ cursor: "pointer" }}
        >
          <h2 className="title-page-admin">
            <AiOutlineArrowLeft style={{ color: "#E20613" }} />
            <span className="ps-2">Ajout / Modification des CGU</span>
          </h2>
        </div>
      </div>
      <div className="setting-admin">
        <div className="container-info-user-profil">
          <form className="form-add-student" onSubmit={onSubmit}>
            <div className="row">
              {/* <div className="col-md-12">
                <InputAdmin
                  type="text"
                  label="Titre"
                  id="title"
                  placeholder="Titre"
                />
              </div> */}
              <div className="col-md-12">
                <div className="mb-3">
                  <label
                    htmlFor={"contenu"}
                    className="form-label form-label-admin"
                    aria-labelledby={"contenu"}
                  >
                    Contenu
                  </label>
                  <ReactQuill
                    theme="snow"
                    className="cgu-ql-description"
                    value={contenu}
                    onChange={(val) => handleChange(val)}
                  />
                  {<FormError error={errors?.cgu?.message} />}
                </div>
              </div>
            </div>

            <div className="row row-content-btn-action mt-5">
              <div className="col-md-3 offset-md-9">
                <button
                  className="btn btn-submit-form-add-product-admin w-100"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                        role="status"
                      ></span>
                      <span>En cours...</span>
                    </>
                  ) :
                    "Enregistrer"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddCguAdmin;
