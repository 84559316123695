import React from "react";
import { NavLink } from "react-router-dom";
import { useAppDispatch } from "../../redux/hook";
import {
  addCartProduct,
  calculateTax,
  getCartCount,
  getSubTotal,
  getTotalAmount,
} from "../../redux/slice/useCartSlice";
import Fade from "react-reveal/Fade";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { AlertInfo } from "../common/Alert";
import { getImage } from "../../utils/Utils";
import { Product } from "../../utils/api/product/product.type";
import Skeleton from "react-loading-skeleton";
import { AddedCartConform } from "../user/ParcoursAchat/ParcoursAchat";
import { responsive } from "./HomePage";
import AliceCarousel from "react-alice-carousel";

export const PackSkeleton = () => {
  return (
    <>
      {Array(6)
        .fill(6)
        .map((item, i) => (
          <div className="col-md-3 mb-3 col-pack-item d-flex" key={i}>
            <div
              className="pack-item-container "
              //   style={{ border: "1px solid #ebebeb", textAlign: "center" }}
            >
              <div className="content-img-product-item">
                <Skeleton className="img-product-item" />
              </div>
              <div className="content-btn-buy-product-item py-3">
                <Skeleton
                  className="btn btn-buy-product-item bg-transparent"
                  width={100}
                />
              </div>
              <div className="text-center">
                <Skeleton
                  className="price-product-item"
                  width={120}
                  height={20}
                />
                <Skeleton
                  className="name-prduct fs-15"
                  width={150}
                  height={15}
                />
              </div>
            </div>
          </div>
        ))}
    </>
  );
};

export const PacksItem = ({ item }: { item: Product | any }) => {
  const onConfirm = AddedCartConform(item);
  const dispatch = useAppDispatch();
  const addToCart = () => {
    dispatch(addCartProduct({ product: item, qty: 1 }));
    dispatch(getCartCount());
    dispatch(getSubTotal());
    dispatch(calculateTax());
    dispatch(getTotalAmount());
    onConfirm();
  };
  return (
    <Fade bottom cascade>
      <div className="pack-item-container text-center">
        <NavLink
          to={`/produit/${item?._id}`}
          state={item}
          className="no-link w-100"
        >
          <div className="content-img-pack-item text-center">
            <img
              src={item?.image || getImage(item?.pictures[0]?.image)}
              alt={item?.name}
              className="img-pack-item"
              loading="lazy"
            />
          </div>
        </NavLink>
        <div className="content-btn-buy-product-item py-3 text-center">
          <button
            // to="/mon-compte/panier"
            onClick={() => addToCart()}
            className="btn btn-buy-product-item"
          >
            <AiOutlineShoppingCart style={{ fontSize: 16 }} />
            <span className="ps-2">Ajouter </span>
          </button>
        </div>
        <NavLink
          to={`/produit/${item?._id}`}
          state={item}
          className="no-link w-100"
        >
          <p className="price-product-item">
            {item?.price} <span style={{ fontWeight: 300 }}>FCFA</span>{" "}
          </p>
          <p className="name-prduct fs-15">{item?.name} </p>
          {/* <p className="text-describe-pack-item">{item.description} </p> */}
        </NavLink>
      </div>
    </Fade>
  );
};


function PackPage({
  data,
  isLoading,
}: {
  data: Product[];
  isLoading: boolean;
}) {
  // const { store } = useAppSelector((state) => state.config);
  // const { data = { results: [], count: 0 }, isLoading } =
  //   useGetProductListQuery({
  //     limit: 6,
  //     productType: "pack",
  //     store: store?._id,
  //   });
  return (
    <>
      <div className="row no-view-mobile">
        {!!isLoading && <PackSkeleton />}
        {!isLoading &&
          (data?.length ? (
            data?.map((pack) => (
              <div
                className="col-md-3 mb-3 col-pack-item d-flex"
                key={pack._id}
              >
                <PacksItem item={pack} />
              </div>
            ))
          ) : (
            <AlertInfo message={`Aucun pack disponible pour le moment!`} />
          ))}
      </div>
      <div className="no-view-desktop w-100">
        <AliceCarousel
          mouseTracking
          items={data?.map((pack) => {
            return <PacksItem item={pack} key={pack._id} />;
          })}
          responsive={responsive}
          autoPlayStrategy="default"
          controlsStrategy="alternate"
          autoPlay={true}
          infinite={true}
          disableButtonsControls={true}
          autoPlayInterval={2000}
          disableDotsControls={true}
        />
      </div>
    </>
  );
}

export default PackPage;



