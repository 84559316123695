import React from "react";
import OTPInput, { ResendOTP } from "otp-input-react";
import "./OtpValidationModal.css";
import { useConfirmTransactionMutation, useLazyCodeByTransactionIDQuery } from "../../../../utils/api/order/order.api";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { Color } from "../../../../utils/theme";
import { closeModal } from "../../../../utils/Utils";

function OtpValidationModal({ orderData }) {
  const [sendCode] = useLazyCodeByTransactionIDQuery()
  const [sendData, { isLoading, isSuccess, isError, error, data }] =
    useConfirmTransactionMutation();
  const [OTP, setOTP] = React.useState("");
  const [hasError, setHasError] = React.useState(false);
  const navigate = useNavigate();

  const renderTime = (remainingTime) => {
    return (
      <span>
        {remainingTime > 0 ? `Renvoyer dans ${remainingTime} secondes` : ""}
      </span>
    );
  };

  const renderButton = (buttonProps) => {
    return <button {...buttonProps}>Renvoyer le code</button>;
  };

  React.useEffect(() => {
    if (isSuccess) {
      window.scroll(0, 10);
      console.log("order", data);

      Swal.fire({
        icon: "success",
        title: "Bravo!",
        text: "Votre commande a été validée avec succès.",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {
        closeModal("otpValidationModal");
        navigate("/commande-details", { replace: true, state: data });
      });
    }
    const err = error as any;

    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Une erreur de statut ${err?.status} est survenue lors de la validation`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [error, isError, isSuccess]);

  const onSubmit = (e) => {
    e?.preventDefault();
    if (OTP && OTP?.length === 6) {
    //   console.log("otp", OTP, orderData);
      sendData({ transactionId: orderData?.transactionId, otp: OTP });
    } else {
      console.log("error");
      setHasError(true);

      setTimeout(() => {
        setHasError(false);
      }, 2000);
    }
  };

  return (
    <div
      className="modal fade"
      id="otpValidationModal"
      tabIndex={-1}
      data-bs-backdrop="static"
      aria-labelledby="otpValidationModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body">
            <div className="d-flex justify-content-center align-items-center">
              {" "}
              <div className="position-relative">
                {" "}
                <div className="card cart-otp p-2 text-center">
                  {" "}
                  <h6>Veuillez entrer votre code de validation.</h6>{" "}
                  <div>
                    {" "}
                    <span>Un code a été envoyé à</span>{" "}
                    <small>
                      *******{orderData?.telephone?.slice(-4)} (valable 15mns)
                    </small>{" "}
                  </div>{" "}
                  <OTPInput
                    value={OTP}
                    onChange={setOTP}
                    autoFocus
                    className="inputs-otp d-flex flex-row justify-content-center mt-2"
                    inputClassName={`m-2 text-center form-control form-control-otp rounded ${
                      hasError ? "error" : ""
                    }`}
                    OTPLength={6}
                    otpType="number"
                    disabled={false}
                  />
                  <div className="mt-4 mb-3">
                    {" "}
                    <button
                      className="btn px-4 py-2 validate"
                      onClick={(e) => onSubmit(e)}
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                            role="status"
                          ></span>
                          <span>Validation...</span>
                        </>
                      ) : (
                        "Valider"
                      )}
                    </button>{" "}
                  </div>{" "}
                  <ResendOTP
                    maxTime={15}
                    className="reset-otp-btn"
                    renderTime={renderTime}
                    renderButton={renderButton}
                    onResendClick={() => sendCode(orderData?.transactionId)}
                  />
                </div>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OtpValidationModal;
