import React from "react";
import { NavLink } from "react-router-dom";
// import CommandeImg from "../../../assets/appImages/commande.png";
import StarRatingComponent from "react-star-rating-component";
import { BsTrash } from "react-icons/bs";
import Panier from "../../../assets/appImages/panier.png";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import {
  calculateTax,
  CartItemType,
  decrement,
  getCartCount,
  getPreOrder,
  getSubTotal,
  getTotalAmount,
  increment,
  removeCartItem,
} from "../../../redux/slice/useCartSlice";
import { formatCurrency, getImage } from "../../../utils/Utils";
import Swal, { SweetAlertResult } from "sweetalert2";
import { Color } from "../../../utils/theme";

export function Counter({
  count,
  setCount,
}: {
  count: number;
  setCount: React.Dispatch<React.SetStateAction<number>>;
}) {
  const decrement = () => {
    setCount((prevState) => prevState - 1);
  };

  const increment = () => {
    setCount((prevState) => prevState + 1);
  };

  return (
    <div className="btn-wrap">
      <button
        className="decrement-btn btn-counter-item"
        disabled={count <= 1}
        onClick={decrement}
      >
        −
      </button>
      <div className="num">{count}</div>
      <button className="increment-btn btn-counter-item" onClick={increment}>
        +
      </button>
    </div>
  );
}

const CartItem = ({ item }: { item: CartItemType }) => {
  const [count, setCount] = React.useState(item?.qty);
  const dispatch = useAppDispatch();

  const getCartItems = () => {
    dispatch(getSubTotal());
    dispatch(getCartCount());
    dispatch(calculateTax());
    dispatch(getTotalAmount());
  };
  React.useEffect(() => {
    if (count > item?.qty) {
      // console.log("inc", count, item?.qty);
      // dispatch(increment(item));
      // getCartItems();
      if (
        parseInt(item?.product?.stock as unknown as string) === count ||
        parseInt(item?.product?.stock as unknown as string) === item?.qty
      ) {
        Swal.fire({
          title: `La quantité demandée n'est actuellement pas disponible en stock. Souhaitez-vous faire une pré-commande ?`,
          icon: "success",
          text: "Vous serez notifié de la disponibilité de ce produit.",
          showCancelButton: true,
          confirmButtonText: "PRÉ-COMMANDER",
          cancelButtonText: "ANNULER",
          showLoaderOnConfirm: true,
          iconColor: Color.default,
          confirmButtonColor: Color.default,
          customClass: {
            confirmButton: "btn btn-sweet-confirm",
            cancelButton: "btn btn-sweet-cancel",
          },
          reverseButtons: true,
        }).then((result: SweetAlertResult<any>) => {
          if (result?.isConfirmed) {
            dispatch(increment(item));
            getCartItems();
            dispatch(getPreOrder(true));
          } else {
            setCount(item?.qty);
          }
        });
      } else {
        dispatch(increment(item));
        getCartItems();
      }
    }

    if (count < item?.qty) {
      console.log("dec", count, item?.qty);
      dispatch(decrement(item));
      getCartItems();
    }
  }, [count]);

  const onRemoveItem = (e) => {
    e?.preventDefault();
    dispatch(removeCartItem(item?.product));
    getCartItems();
  };
  return (
    <div className="detail-commande-item card-panier-detail mb-1">
      <div className="content-info-commande-item w-50">
        <div className="content-img-commande-item">
          <NavLink
            to={`/produit/${item?.product?._id}`}
            state={item?.product}
            className="no-link "
          >
            <img
              src={getImage(item?.product?.pictures[0]?.image)}
              alt="Commande"
              className="img-commande-item"
              loading="lazy"
            />
          </NavLink>
        </div>
        <div className="info-commande-item">
          <p className="text-name-commande-item mb-1">{item?.product?.name}</p>
          <div className="text-muted" style={{ fontSize: "0.80rem" }}>
            {formatCurrency(
              item?.product?.promote?.price || item?.product?.price
            )}
          </div>
          <div className="content-rate-product">
            <StarRatingComponent
              name="rate2"
              starCount={5}
              value={item?.product?.rateCount}
              starColor="#DD9812"
              emptyStarColor="#D4D6D5"
            />
          </div>
        </div>
      </div>
      <div className="content-qty-items w-15">
        <div className="counter ">
          <Counter setCount={setCount} count={count} />
        </div>
      </div>
      <div className="content-detail-commande-panier">
        <p className="text-price-commande-item">
          {(item?.product?.promote?.price || item?.product?.price) * item?.qty}{" "}
          <span style={{ fontWeight: 400 }}>FCFA</span>
        </p>
        <div className="content-btn-panier-page mb-3">
          <button className="btn" onClick={(e) => onRemoveItem(e)}>
            <BsTrash style={{ color: "#E20613", fontSize: 20 }} />
          </button>
        </div>
      </div>
    </div>
  );
};
const PanierPage = () => {
  const { cartItems, subAmount, totalAmount } = useAppSelector(
    (state) => state.cart
  );
  // const [count, setCount] = React.useState(1);
  return (
    <div className="form-register-tabs-component">
      <div className="content-title-form-register pb-4">
        <h3 className="title-form-register">Mon panier</h3>
      </div>
      <div className="content-panier-page mt-5">
        <div className="row row-panier-page">
          <div
            className={`${
              !!cartItems?.length ? "col-md-8" : "col-md-12"
            } col-left-panier-page mb-3`}
          >
            {!!cartItems?.length &&
              cartItems.map((item) => (
                <CartItem item={item} key={item?.product?._id} />
              ))}
            {!cartItems?.length && (
              <div className="content-panier-vide">
                <div>
                  <div className="content-img-panier-vide pb-4 text-center">
                    <img
                      src={Panier}
                      alt="Panier"
                      className="img-panier-vide"
                      loading="lazy"
                    />
                  </div>
                  <h3 className="titre-panier-vide">Votre panier est vide</h3>
                  <p className="text-panier-vide">
                    Veuillez ajouter des produits pour pouvoir acheter.
                  </p>
                  <div className="content-btn-action-panier pt-4 text-center">
                    <NavLink to="/achats" className="btn btn-theme ">
                      COMMANDER
                    </NavLink>
                  </div>
                </div>
              </div>
            )}
          </div>
          {!!cartItems?.length && (
            <div className="col-md-4 col-right-panier-page mb-3">
              <div className="content-col-right-panier-page">
                <h3 className="title-col-right-panier-page">
                  RÉSUMÉ DU PANIER
                </h3>
                <div className="content-info-produit-panier-page pt-3">
                  {/* <div className="info-produit-item-panier-page b-b-1">
                    <p className="info-produit-item-libelle mb-0">Tax (18%)</p>
                    <p className="info-produit-item-value mb-0">
                      {formatCurrency(tax)}
                    </p>
                  </div> */}
                  <div className="info-produit-item-panier-page b-b-1">
                    <p className="info-produit-item-libelle mb-0">Sous-total</p>
                    <p className="info-produit-item-value mb-0">
                      {formatCurrency(subAmount)}
                    </p>
                  </div>
                  <div className="info-produit-item-panier-page">
                    <p className="info-produit-item-libelle-total mb-0">
                      TOTAL
                    </p>
                    <p className="info-produit-item-value-total mb-0">
                      {formatCurrency(totalAmount)}
                    </p>
                  </div>
                  <div className="content-btn-commande pt-4">
                    <NavLink
                      to="/valider-commande"
                      className="btn btn-theme w-100"
                    >
                      COMMANDER
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* À afficher si le panier est vide  */}
        {/* <div className="content-panier-vide">
          <div>
            <div className="content-img-panier-vide pb-4">
              <img src={Panier} alt="Panier" className="img-panier-vide" />
            </div>
            <h3 className="titre-panier-vide">Votre panier est vide</h3>
            <p className="text-panier-vide">Libero nibh fames arcu amet.</p>
            <div className="content-btn-action-panier pt-4">
              <NavLink to="/achats" className="btn btn-theme w-100">
                COMMANDER
              </NavLink>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default PanierPage;
