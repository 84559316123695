import React from "react";
import CommandesTable from "./CommandesTable/CommandesTable";
import "./GestionCommandes.css";
import { orderType } from "./CommandeItem";
import { ApiBaseUrl } from "../../../utils/http";
import { BiExport } from "react-icons/bi";

const GestionCommandes = () => {
	const [numOrder, setNumOrder] = React.useState("");
	const [status, setStatus] = React.useState("");

	const handleExportClick = () => {
		fetch(`${ApiBaseUrl}/api/order/export`, {})
			.then((response) => response.blob())
			.then((blob) => {
				const url = window.URL.createObjectURL(blob);
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", "low-price-liste-commandes.xlsx");
				document.body.appendChild(link);
				link.click();
			});
	};

	return (
		<div className="content-graph-admin">
			<div className="dashbord-admin-component admidashboard-bg mb-3">
				<div className="dash-admin-page-content-container mb-3">
					<div className="container-title-content-page mb-4">
						<div className="content-title">
							<h2 className="title-admin">Commandes</h2>
						</div>
					</div>
					<div className="d-flex justify-content-center">
						<button className="btn btn-add-admin" onClick={handleExportClick}>
							<BiExport />
							<span className="ps-2">Exporter la liste des commandes</span>
						</button>
					</div>
				</div>
			</div>
			<div className="admin-table mb-3">
				<div className="tab-dash-admin-filter-container">
					<form className="row g-3 tab-dash-admin-filter-form">
						<div className="col-auto">
							<label htmlFor="filtreProduit" className="tab-dash-admin-filter-label">
								Filtrer
							</label>
						</div>
						<div className="col-auto">
							<input
								type="text"
								className="form-control tab-dash-admin-filter-input"
								id="filtreProduit"
								value={numOrder}
								onChange={(e) => setNumOrder(e?.target?.value)}
								placeholder="N° commande"
							/>
						</div>
						<div className="col-auto">
							<select
								id="status"
								className="form-select tab-dash-admin-filter-input"
								onChange={(e) => setStatus(e?.target?.value)}
							>
								<option value="">Statut</option>
								{orderType?.map((option) => (
									<option value={option.value} key={option.value}>
										{option.value}
									</option>
								))}
							</select>
						</div>
					</form>
				</div>
				<CommandesTable numOrder={numOrder} status={status} />
			</div>
		</div>
	);
};

export default GestionCommandes;
