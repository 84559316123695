import React from "react";
import { IUser } from "../../../../utils/api/user/user.type";
import { InputAdmin } from "../../../common/Input";
import PhoneInput from "react-phone-input-2";
import UseCrudUserForm from "../../GestionUsers/requestUserForm/UseCrudUserForm";
import ErrorMessage from "../../../common/ErrorMessage";

function CrudAdminModal({ modalId, user }: { modalId: string; user?: IUser }) {
  const { register, onSubmit, isLoading, errors, phone, handleChangePhone,handlereset } =
    UseCrudUserForm(undefined, user, undefined, modalId);
  return (
    <div
      className="modal fade"
      id={modalId}
      tabIndex={-1}
      aria-labelledby={`${modalId}Label`}
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              {!user ? "Ajouter" : "Modifier"} un collaborateur
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => handlereset()}
            ></button>
          </div>
          <div className="modal-body">
            <div className="pt-3">
              <form onSubmit={onSubmit}>
                <div className="row py-3 px-2">
                  <div className="col-md-6 mb-4">
                    <InputAdmin
                      label="Prénom"
                      type={"text"}
                      placeholder="Prénom"
                      {...register("prenom")}
                      error={errors?.prenom?.message}
                    />
                  </div>
                  <div className="col-md-6 mb-4">
                    <InputAdmin
                      label="Nom"
                      type={"text"}
                      placeholder="Nom"
                      {...register("nom")}
                      error={errors?.nom?.message}
                    />
                  </div>
                  <div className="col-md-6 mb-4">
                    <InputAdmin
                      label="Email"
                      type={"email"}
                      placeholder="Email"
                      {...register("email")}
                      error={errors?.email?.message}
                    />
                  </div>

                  <div className="col-md-6 mb-4">
                    <label className="form-label form-label-admin">
                      Télépone
                    </label>

                    <PhoneInput
                      inputProps={{
                        name: "phone",
                      }}
                      defaultMask={".. ... .. .."}
                      country="sn"
                      value={phone}
                      enableSearch={true}
                      onChange={(phone, country) =>
                        handleChangePhone(phone, country)
                      }
                      inputClass="form-control form-control-admin-tel"
                      enableAreaCodes={true}
                      prefix="+"
                      countryCodeEditable={false}
                    />
                    <ErrorMessage
                      message={errors?.telephone && errors?.telephone?.message}
                    />
                  </div>
                  <div className="col-md-6 mb-4">
                    <label className="form-label form-label-admin">
                      Type de collaborateur
                    </label>

                    <select
                      {...register("userType")}
                      className="form-control form-control-admin"
                    >
                      <option value="">Type de collaborateur</option>
                      <option value="admin">Administrateur</option>
                      <option value="preparer">Préparateur</option>
                      <option value="deliveryman">Livreur</option>
                    </select>
                    <ErrorMessage
                      message={errors?.userType && errors?.userType?.message}
                    />
                  </div>
                  <div className="flex-r">
                    <button
                      className="btn btn-cancelled"
                      type="button"
                      data-bs-dismiss="modal"
                      onClick={() => handlereset()}
                    >
                      Annuler
                    </button>
                    <button
                      type="submit"
                      className="btn btn-theme"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                            role="status"
                          ></span>
                          <span>Ajout...</span>
                        </>
                      ) : !user ? (
                        "Ajouter"
                      ) : (
                        "Modifier"
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CrudAdminModal;
