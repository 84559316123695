import React from "react";
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useProductFromLocation } from "../../../utils/api/product/product.api";
import { createMarkup, getImage } from "../../../utils/Utils";
import { AlertInfo } from "../../common/Alert";
const PackDetailAdmin = () => {
  const [item] = useProductFromLocation();
  const navigate = useNavigate();

  return (
    <div className="container-commande-item">
      <div
        onClick={() => navigate(-1)}
        style={{ cursor: "pointer" }}
        className="title-admin"
      >
        <BsArrowLeft style={{ color: "#E20613" }} />
        <span className="ps-2">Pack ramadan</span>
      </div>
      <div className="content-graph-admin my-4">
        <div className="content-col-detail-commande-admin">
          <h3 className="title-col-detail-commande-admin pb-3">Image</h3>
          <div className="content-img-detail-product">
            {!!item?.pictures?.length &&
              item?.pictures?.map((img) => (
                <div className="content-img-product-admin" key={img?._id}>
                  <img
                    src={getImage(img?.image)}
                    alt={item?.name}
                    className="img-product-detail-admin"
                    loading="lazy"
                  />
                </div>
              ))}
            {!item?.pictures?.length && (
              <AlertInfo message="Aucune image disponible pour le moment !" />
            )}
          </div>
        </div>
      </div>
      <div className="content-graph-admin mb-4">
        <div className="content-col-detail-commande-admin">
          <h3 className="title-col-detail-commande-admin pb-3">Nom pack</h3>
          <p className="nom-commande-detail-admin-value mb-0">{item?.name}</p>
        </div>
      </div>
      <div className="content-graph-admin mb-4">
        <div className="content-col-detail-commande-admin">
          <h3 className="title-col-detail-commande-admin pb-3">
            Caractéristique
          </h3>
          <p
            className="nom-commande-detail-admin-value"
            dangerouslySetInnerHTML={createMarkup(item?.description)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-md-12 mb-4">
          <div className="content-graph-admin">
            <div className="content-col-detail-commande-admin">
              <h3 className="title-col-detail-commande-admin pb-3">Prix</h3>
              <p className="nom-commande-detail-admin-value">
                {item?.price} fcfa
              </p>
            </div>
          </div>
        </div>
        {/* <div className="col-lg-6 col-md-12 mb-4">
          <div className="content-graph-admin">
            <div className="content-col-detail-commande-admin">
              <h3 className="title-col-detail-commande-admin pb-3">
                Stock disponible
              </h3>
              <p className="nom-commande-detail-admin-value">
                {item?.stockDisponible || 0}
              </p>
            </div>
          </div>
        </div> */}
        {/* <div className="col-lg-6 col-md-12 mb-4">
          <div className="content-graph-admin">
            <div className="content-col-detail-commande-admin">
              <h3 className="title-col-detail-commande-admin pb-3">
                Hauteur du produit
              </h3>
              <p className="nom-commande-detail-admin-value">
                {item?.height} cm
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-12 mb-4">
          <div className="content-graph-admin">
            <div className="content-col-detail-commande-admin">
              <h3 className="title-col-detail-commande-admin pb-3">
                Poids du produit
              </h3>
              <p className="nom-commande-detail-admin-value">
                {item?.weight} kg
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-12 mb-4">
          <div className="content-graph-admin">
            <div className="content-col-detail-commande-admin">
              <h3 className="title-col-detail-commande-admin pb-3">
                Longueur du produit
              </h3>
              <p className="nom-commande-detail-admin-value">
                {item?.length} cm
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-12 mb-4">
          <div className="content-graph-admin">
            <div className="content-col-detail-commande-admin">
              <h3 className="title-col-detail-commande-admin pb-3">
                Largeur du produit
              </h3>
              <p className="nom-commande-detail-admin-value">
                {item?.width} cm
              </p>
            </div>
          </div>
        </div> */}
        <div className="col-lg-6 col-md-12 mb-4">
          <div className="content-graph-admin">
            <div className="content-col-detail-commande-admin">
              <h3 className="title-col-detail-commande-admin pb-3">Statut</h3>
              <p className="nom-commande-detail-admin-value">
                {item?.isActive ? "En ligne" : "Désactivé"}
              </p>
            </div>
          </div>
        </div>
        {/* <div className="col-lg-12 col-md-12 mb-4">
          <div className="content-graph-admin">
            <div className="content-col-detail-commande-admin">
              <h3 className="title-col-detail-commande-admin pb-3">Stocks</h3>
              <div className="row">
                {item?.stocks?.map((s) => (
                  <div className="col-md-4">
                    <p className="nom-commande-detail-admin-value">
                      {`${s?.store?.name}: `}
                      <span className="title-col-detail-commande-admin pb-3">
                        {s?.stock}
                      </span>{" "}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default PackDetailAdmin;
