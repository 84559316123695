import React, { Dispatch, SetStateAction } from "react";
import Modal from "react-modal";
import { Product } from "../../../../utils/api/product/product.type";
import { FormError } from "../../../common/Input";
import StarRatingComponent from "react-star-rating-component";
import CloseModalBtn from "../../../shared/CloseModalBtn";
import { customStyles } from "../../../shared/FrontHeader/modal/ConnexionModal";
import UseAddRateProductForm from "../request/UseAddRateProductForm";

const AvisProductModal = ({
  show,
  setShow,
  product,
}: {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  product: Product;
}) => {
  const {
    register,
    errors,
    onSubmit,
    isLoading,
    handleChangeNote,
    note,
    handleChangeStatus,
    status,
    isSuccess,
    handleReset,
  } = UseAddRateProductForm(product);
  function closeModal() {
    setShow(false);
    handleReset()
  }

  React.useEffect(() => {
    if (isSuccess) {
      closeModal()
    }
  }, [isSuccess])

  return (
    <Modal
      isOpen={show}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="modal-header flex-sb">
        <h5 className="modal-title-admin">Avis client</h5>
        <CloseModalBtn onClick={closeModal} />
      </div>
      <div className="pt-3">
        <form onSubmit={onSubmit}>
          <div className="row py-3 px-2">
            {/* <div className="col-md-12 ">
              <Input
                label={`Objet`}
                type={"text"}
                placeholder="Nom "
                // {...register("name")}
                // error={errors?.name?.message}
              />
            </div> */}
            <div className="col-md-12">
              <div className="mb-3 note-container-input">
                <label
                  htmlFor={"rate"}
                  className="form-label-modal-custom"
                  aria-labelledby={"rate"}
                >
                  Note {note} / 5
                </label>
                <StarRatingComponent
                  name="rate2"
                  starCount={5}
                  value={note}
                  onStarClick={handleChangeNote}
                  starColor="#DDB012"
                  emptyStarColor="#D4D6D5"
                />
              </div>
              {<FormError error={errors?.stars?.message} />}
            </div>

            <div className="col-md-12">
              <div className="mb-3">
                <label
                  htmlFor={"comment"}
                  className="form-label form-label-modal-custom"
                  aria-labelledby={"comment"}
                >
                  Commentaire
                </label>
                <textarea
                  id="comment"
                  className={`form-control form-control-modal-custom`}
                  cols={30}
                  rows={5}
                  {...register("comment")}
                ></textarea>
                {<FormError error={errors?.comment?.message} />}
              </div>
            </div>
            <div className="col-md-12 col-add-product-admin mb-3">
              <div className="mb-3">
                <label className="form-label form-label-modal-custom">
                  Voulez-vous rester anonyme ?
                </label>
                <div className="switch2-rate">
                  <label className="switch-rate">
                    <input
                      type="checkbox"
                      onChange={(e) => handleChangeStatus(e)}
                      checked={status}
                    />
                    <span className="slider-rate"></span>
                  </label>
                </div>
              </div>
            </div>
            <div className="flex-r">
              <button
                className="btn btn-cancelled"
                type="button"
                data-bs-dismiss="modal"
                onClick={() => closeModal()}
                aria-label="Close"
              >
                Annuler
              </button>
              <button
                type="submit"
                className="btn btn-theme"
                disabled={isLoading}
              >
                
                {isLoading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                      role="status"
                    ></span>
                    <span>En cours...</span>
                  </>
                ) : (
                  "Envoyer"
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
     
    </Modal>
  );
};

export default AvisProductModal;
