/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useForm,Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  cleannerError,
  showModal,
  validatePassword,
  validatePhone,
} from "../../../utils/Utils";
import { RegisterFormData } from "../../../utils/api/auth/auth.type";
import { useRegisterUserMutation } from "../../../utils/api/auth/auth.api";
import { Color } from "../../../utils/theme";
import { useGetZoneListByVisitorQuery } from "../../../utils/api/zone/zone.api";

function UseRegisterForm() {
  const [code, setCode] = useState<string>("");
  const [code1, setCode1] = useState<string>("");
  const [code2, setCode2] = useState<string>("");
  const validationSchema = yup.object().shape({
    step: yup.number(),
    prenom: yup.string().when("step", {
      is: 0,
      then: () =>yup.string().required().label("Le prénom"),
    }),
    nom: yup.string().when("step", {
      is: 0,
      then: () =>yup.string().required().label("Le nom"),
    }),
    telephone: yup.string().when("step", {
      is: 0,
      then: () =>validatePhone(yup, code).required(),
    }),
    email: yup.string().when("step", {
      is: 0,
      then: () =>yup.string().email().nullable().label("L'email"),
    }),
    password: yup.string().when("step", {
      is: 0,
      then: () =>validatePassword(yup),
    }),
    confirmPassword: yup.string().when("step", {
      is: 0,
      then: () =>yup
        .string()
        .oneOf(
          [yup.ref("password")],
          "Les mots de passe doivent correspondre"
        )
        .required()
        .label("La confirmation du mot de passe"),
    }),
    adresses: yup.array().when("step", {
      is: 1,
      then: () =>yup.array().of(
        yup.object().shape({
          prenom: yup.string().required().label("Le prénom").nullable(),
          nom: yup.string().required().label("Le nom").nullable(),
          adresse: yup.string().required().label("L'adresse").nullable(),
          complementAdresse: yup
            .string()
            .label("Le complément d'adresse")
            .nullable(),
          quartier: yup.string().label("Le quartier").nullable(),
          zone: yup.string().required().label("La zone").nullable(),
          telephone1: validatePhone(yup, code1).required(),
          telephone2: validatePhone(yup, code2),
          isDefault: yup.boolean().default(true),
        })
      ),
    }),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    control
  } = useForm<RegisterFormData>({
    resolver: yupResolver(validationSchema),
  });
  const [options, setOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [option, setOption] = useState<{label:string,value:string} | undefined>()
  const [modalConnexion, setModalConnexion] = useState(false);
  const [dataForm, setDataForm] = useState<any>();
  const [level, setLevel] = useState(0);
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [haveMinUppercase, setHaveMinUppercase] = useState(false);
  const [haveMinLowercase, setHaveMinLowercase] = useState(false);
  const [haveMinNumber, setHaveMinNumber] = useState(false);
  const [haveMinCharacter, setHaveMinCharacter] = useState(false);
  const [haveMinSpecialCharacter, setHaveMinSpecialCharacter] = useState(false);
  const [address, setAddress] = useState("");
  const {data:zones} = useGetZoneListByVisitorQuery()
  //  Calling the Register Mutation
  const [registerUser, { isLoading, isSuccess, error, isError }] =
    useRegisterUserMutation();

  const [phone, setPhone] = useState<string>("");
  const [phone1, setPhone1] = useState<string | null>(null);
  const [phone2, setPhone2] = useState<string | null>(null);

  useEffect(() => {
    if (zones?.length) {
      // console.log("zones",zones)
      setOptions(zones?.map((z) => {
        return {
          label: z?.ville,
          value: z?._id
        }
      }))
    }
  }, [zones])

  useEffect(() => {
    if (dataForm?.prenom && !dataForm?.adresses?.length) {
      setValue(`adresses.${0}.prenom`, dataForm?.prenom);
      setValue(`adresses.${0}.nom`, dataForm?.nom);
      setValue(`adresses.${0}.telephone1`, dataForm?.telephone);
      setValue(`adresses.${0}.isDefault`, true);
      setPhone1("+" + dataForm?.telephone);
    }
  }, [dataForm]);

  const handleSelectZone = (zone) => {
    setOption(zone)
    setValue(`adresses.${0}.zone`, zone?.value);
  }
  const onChangeAddress = (val: {
    rue: string;
    latitude: string;
    longitude: string;
    ville: string;
    country: string;
  }) => {
    setAddress(val?.rue);
    setValue(`adresses.${0}.adresse`, val?.rue);
    setValue(`adresses.${0}.latitude`, val?.latitude);
    setValue(`adresses.${0}.longitude`, val?.longitude);
    // if (val?.ville) {
    //   setValue(`adresses.${0}.ville`, val?.ville);
    // }
  };

  const countUppercase = (str: any) => {
    if (str.replace(/[^A-Z]/g, "")?.length >= 1) {
      return true;
    }
    return false;
  };

  const countLowercase = (str: any) => {
    if (str.replace(/[^a-z]/g, "")?.length >= 1) {
      return true;
    }
    return false;
  };

  const countNumber = (str: any) => {
    if (str.replace(/[^0-9]/g, "")?.length >= 1) {
      return true;
    }
    return false;
  };

  const countCharacters = (str: any) => {
    if (str?.length >= 8) {
      return true;
    }
    return false;
  };

  const countSpecial = (str: any) => {
    const punct = `/[!@#$%^&*()_+\\-=\\[\\]{};':"\\|,.<>\\/?]+/;`;
    let count = 0;
    for (let i = 0; i < str?.length; i++) {
      if (!punct.includes(str[i])) {
        continue;
      }
      count++;
    }
    // return count;
    if (count >= 1) {
      return true;
    }
    return false;
  };

  const passwordHandleChange = (e: any) => {
    var password = e.target.value;
    setPassword(password);
    if (countUppercase(password)) {
      setHaveMinUppercase(true);
    } else {
      setHaveMinUppercase(false);
    }
    if (countLowercase(password)) {
      setHaveMinLowercase(true);
    } else {
      setHaveMinLowercase(false);
    }
    if (countNumber(password)) {
      setHaveMinNumber(true);
    } else {
      setHaveMinNumber(false);
    }
    if (countCharacters(password)) {
      setHaveMinCharacter(true);
    } else {
      setHaveMinCharacter(false);
    }
    if (countSpecial(password)) {
      setHaveMinSpecialCharacter(true);
    } else {
      setHaveMinSpecialCharacter(false);
    }

    setValue("password", e.target.value);
  };

  const handleChangePhone = (item, country) => {
    setCode(country?.countryCode);
    setPhone("+" + item);
    if (parseInt(item) !== parseInt(country?.dialCode)) {
      setValue("telephone", item);
    } else {
      setValue("telephone", "");
    }
  };

  const handleChangePhone1 = (item, country) => {
    setCode1(country?.countryCode);
    setPhone1("+" + item);
    if (parseInt(item) !== parseInt(country?.dialCode)) {
      setValue(`adresses.${0}.telephone1`, item);
    } else {
      setValue(`adresses.${0}.telephone1`, "");
    }
  };

  const handleChangePhone2 = (item, country) => {
    setCode2(country?.countryCode);
    setPhone2("+"+item);
    if (parseInt(item) !== parseInt(country?.dialCode)) {
      setValue(`adresses.${0}.telephone2`, item);
    } else {
      setValue(`adresses.${0}.telephone2`, "");
    }
  };

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: "Inscription reussie!",
        text: "Un code de confirmation vous a été envoyé pour valider votre inscription.",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 5000,
      }).then(() => {
        // setModalConnexion(true)
        showModal("phoneValidationModal");
      });
    }
    const err = error as any;

    if (isError) {
      console.log("error", err);
      if (err?.data?.email?.includes("The email has already been taken.")) {
        Swal.fire({
          icon: "error",
          title: "Cet email est déjà associé à un compte.",
          showConfirmButton: false,
          timer: 5000,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: err?.data?.message
            ? err?.data?.message
            : `Une erreur de statut ${err?.status} est survenue lors de l'inscription`,
          showConfirmButton: false,
          timer: 5000,
        });
      }
    }
  }, [error, isError, isSuccess, navigate]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [clearErrors, errors]);

  const onSubmit = async (data: any) => {
    console.log("data register", data);
    setDataForm(data);
    
    if (data?.step < 1) {
      setLevel(data?.step + 1);

      // console.log("data step ", data);
    } else {
      if (!data?.email) {
        delete data.email
      }
      await registerUser(data);
    }
    
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    phone,
    handleChangePhone,
    passwordHandleChange,
    haveMinCharacter,
    haveMinLowercase,
    haveMinNumber,
    haveMinSpecialCharacter,
    haveMinUppercase,
    modalConnexion,
    setModalConnexion,
    level,
    dataForm,
    password,
    phone1,
    phone2,
    handleChangePhone1,
    handleChangePhone2,
    onChangeAddress,
    address,
    handleSelectZone,
    options,
    option,
    Controller,
    control
  };
}

export default UseRegisterForm;
