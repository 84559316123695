/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useGetListProductAdminQuery } from "../../../../utils/api/product/product.api";
import {
  useAddPromoteProductMutation,
  useDeletePromoteProductMutation,
  useGetPromotionListQuery,
} from "../../../../utils/api/promotion/promotion.api";
import { PromoteProductFormData } from "../../../../utils/api/promotion/promotion.type";
import { Color } from "../../../../utils/theme";
import { cleannerError } from "../../../../utils/Utils";

function UseAddProductToPromotionForm(setModalAddProduitPromo) {
  const validationSchema = yup.object().shape({
    product: yup.string().required().label("Le produit"),
    promotion: yup.string().required().label("La promotion"),
    price: yup
      .number()
      .required()
      .label("Le prix")
      .transform((value) => (isNaN(value) ? null : value))
      .nullable(),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    control,
    reset,
  } = useForm<PromoteProductFormData>({
    resolver: yupResolver(validationSchema),
  });
  const [name, setName] = useState("");
  const [promo, setPromo] = useState<any>(null);
  const [promos, setPromos] = useState<any>([]);
  const [option, setOption] = useState<any>(null);
  const [options, setOptions] = useState<any>([]);
  const { data: promotions = [] } = useGetPromotionListQuery({});
  const { data = { results: [] } } = useGetListProductAdminQuery({
    name,
    productType: "product",
  });
  const [sendData, { isLoading, isSuccess, error, isError }] =
    useAddPromoteProductMutation();
  useEffect(() => {
    if (data?.results?.length) {
      setOptions(
        data?.results?.map((item) => {
          return {
            label: item?.name,
            value: item?._id,
            ...item,
          };
        })
      );
    }
  }, [data]);

  useEffect(() => {
    if (promotions?.length) {
      setPromos(
        promotions?.map((item) => {
          return {
            label: item?.title,
            value: item?._id,
            ...item,
          };
        })
      );
    }
  }, [promotions]);

  useEffect(() => {
    let reducer = 0;
    let price = 0;
    if (promo?._id && option?._id) {
      reducer = (option?.price * promo?.taux) / 100;
      price = option?.price - reducer;
    }
    if (price > 0) {
      setValue("price", price);
    } else {
      setValue("price", NaN);
    }
    // console.log("price",price)
  }, [promo, option]);

  const handleChange = (value) => {
    setName(value);
  };
  const handleSelected = (item) => {
    setValue("product", item?.value);
    setOption(item);
  };

  const handleSelectedPromo = (item) => {
    setValue("promotion", item?.value);
    setPromo(item);
  };

  const handleReset = () => {
    reset();
    setOption(null);
    setPromo(null);
  };

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        iconColor: Color.success,
        confirmButtonColor: Color.success,
        title: `Le produit ${option?.name} a été mis en promo avec succès !`,
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {
        setModalAddProduitPromo(false);
        handleReset();
      });
    }
    const err = error as any;

    if (isError) {
      console.log("error", err);

      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Une erreur de statut ${err?.status} est survenue`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [error, isError, isSuccess, isLoading]);

  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [clearErrors, errors]);

  const onSubmit = async (data: PromoteProductFormData) => {
    console.log("data", data);
    await sendData(data);
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    handleSelectedPromo,
    promos,
    promo,
    handleReset,
    option,
    options,
    handleSelected,
    control,
    Controller,
    handleChange,
  };
}

export default UseAddProductToPromotionForm;

export function UseDeletePromoteProduct(id: string) {
  const [deleteData] = useDeletePromoteProductMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer la promotion de ce produit ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(id);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      console.log(result, "archiver");
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `La promotion du produit a été supprimée avec succès!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue lors de la suppression`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };
  return onDelete;
}
