import React, { useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { useGetSearchProductListQuery } from "../../../utils/api/product/product.api";
import { formatCurrency, getImage } from "../../../utils/Utils";
import { AlertInfo } from "../../common/Alert";
import Skeleton from "react-loading-skeleton";

const SearchSkeleton = () => {
  return (
    <>
      {[...Array(5)].map((el, i) => (
        <li className="search-result-item" key={i}>
          <div className="row align-items-center">
            <div className="col-sm-3 m-b-20">
              <Skeleton
                className="img-thumbs-commande-item"
                width={75}
                height={75}
              />
            </div>
            <div
              className="pl-lg-0 col-sm-9 m-b-20"
              style={{ marginTop: "-10px" }}
            >
              <Skeleton className="name-prduct fs-15 mb-3" />
              <div className="content-price-product-solde justify-content-start align-items-baseline">
                <Skeleton
                  className="price-product-item"
                  width={100}
                  height={15}
                />
              </div>
            </div>
          </div>
        </li>
      ))}
    </>
  );
};


function SearchForm() {
  const [query, setQuery] = React.useState("");
  const { data = { results: [] }, isFetching } = useGetSearchProductListQuery({
    search: query,
  });

  const ref = useRef<any>(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref?.current?.contains(event.target) && query?.length >= 3) {
        setQuery("")
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, query]);
  return (
    <div className="input-group-search relative-search-form">
      <div ref={ref}>
        <input
          type="text"
          value={query}
          className="form-control search-field"
          onChange={(e) => setQuery(e?.target?.value)}
          placeholder="Rechercher un article"
        />
        {query && query?.length >= 3 && (
          <div className="list-container">
            <ul className="search-result-container">
              {!!isFetching && <SearchSkeleton />}
              {!isFetching &&
                (data?.results?.length ? (
                  data?.results?.map((item) => (
                    <li className="search-result-item" key={item?._id}>
                      <NavLink
                        to={{
                          pathname: `/produit/${item?._id}`,
                        }}
                        state={item}
                        //   onClick={() => setSearching("")}
                        key={`search${item?._id}`}
                        className="no-link"
                      >
                        <div className="row align-items-center">
                          <div className="col-sm-3 m-b-20">
                            <img
                              className="img-thumbs-commande-item"
                              src={getImage(item?.pictures[0]?.image)}
                              alt={item?.name}
                              style={{
                                width: "75px",
                                height: "75px",
                                //   objectFit: "contain",
                              }}
                              loading="lazy"
                            />
                          </div>
                          <div className="pl-lg-0 col-sm-9 m-b-20">
                            <p className="name-prduct fs-15">{item?.name}</p>
                            <div className="content-price-product-solde justify-content-start align-items-baseline">
                              <p className="price-product-item ">
                                {item?.promote?.price || item?.price}{" "}
                                <span style={{ fontWeight: 300 }}>FCFA</span>{" "}
                              </p>
                              {!!item?.promote?._id && (
                                <p className="price-solde-product-item mb-0">
                                  {formatCurrency(item?.price)}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* {r.name} */}
                      </NavLink>
                    </li>
                  ))
                ) : (
                  <li className="search-result-item">
                    <AlertInfo message="Aucun résultat trouvé!" />
                  </li>
                ))}
            </ul>
          </div>
        )}
      </div>

      <span className="input-group-btn">
        <button className="btn search-btn search-btn-item" type="button">
          <i className="fa fa-search"></i>
        </button>
      </span>
    </div>
  );
}

export default SearchForm;

