import React, { ReactElement, Suspense } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import AdminLayout from "../components/admin/AdminLayout/AdminLayout";
import DashboardAdmin from "../components/admin/DashboardAdmin/DashboardAdmin";
import AddProductAdmin from "../components/admin/GestionProduits/AddProductAdmin";
import GestionProduits from "../components/admin/GestionProduits/GestionProduits";
import Login from "../components/auth/Login";
import Register from "../components/auth/Register";
// import HomePage from "../components/HomePage/HomePage";
import MonCompteLayout from "../components/MonCompte/MonCompteLayout";
import AdressePage from "../components/MonCompte/MonComptePage/AdressePage";
import CommandePage from "../components/MonCompte/MonComptePage/CommandePage";
import FavorisPage from "../components/MonCompte/MonComptePage/FavorisPage";
import FinaliserCommande from "../components/MonCompte/MonComptePage/FinaliserCommande";
import PanierPage from "../components/MonCompte/MonComptePage/PanierPage";
import PointFidelitePage from "../components/MonCompte/MonComptePage/PointFidelitePage";
import ProfilPage from "../components/MonCompte/MonComptePage/ProfilPage";
import MentionLegale from "../components/shared/FooterPage/MentionLegale";

import ProduitItemPage from "../components/user/ParcoursAchat/ProduitItemPage";
import CguPage from "../components/shared/FooterPage/Cgu";
import GestionCommandes from "../components/admin/GestionCommande/GestionCommandes";
import CommandeItem from "../components/admin/GestionCommande/CommandeItem";
import GestionUsers from "../components/admin/GestionUsers/GestionUsers";
import ParametresAdmin from "../components/admin/Parametres/ParametresAdmin";
import AddPolicyAdmin from "../components/admin/Parametres/AddPolicyAdmin";
import AddCguAdmin from "../components/admin/Parametres/AddCguAdmin";
import GestionMessages from "../components/admin/GestionMessages/GestionMessages";
import ProfilAdmin from "../components/admin/ProfilAdmin/ProfilAdmin";
import GestionPointEdk from "../components/admin/GestionPointEdk/GestionPointEdk";
import GestionModePaiement from "../components/admin/GestionModePaiement/GestionModePaiement";
import { useAppSelector } from "../redux/hook";
import { isAdmin, isPreparateur, isUser } from "../utils/Utils";

import ProduitDetail from "../components/admin/GestionProduits/ProduitDetail";
import AddPackAdmin from "../components/admin/GestionProduits/AddPackAdmin";
import PackDetailAdmin from "../components/admin/GestionProduits/PackDetailAdmin";
import GestionPromoAdmin from "../components/admin/GestionPromo/GestionPromoAdmin";
import UserDetailAdmin from "../components/admin/GestionUsers/UserDetailAdmin";
import ForgetPassword from "../components/auth/ForgetPassword";
import ResetPassword from "../components/auth/ResetPassword";
import GestionModeLivraison from "../components/admin/GestionModeLivraison/GestionModeLivraison";
import AddOrEditPromotion from "../components/admin/GestionPromo/ajoutPromotion/AddOrEditPromotion";
import DetailCommandePage from "../components/MonCompte/MonComptePage/DetailCommandePage";
import GestionZones from "../components/admin/GestionZones/GestionZones";
import OrderItem from "../components/MonCompte/MonComptePage/OrderItem";
import LoaderSuspense from "../components/common/LoaderSuspense";
import AdresseBoutiqueEdk from "../components/HomePage/AdresseBoutiqueEdk";
import AddCopyright from "../components/admin/Parametres/AddCopyright";
import Copyright from "../components/shared/FooterPage/Copyright";
import CategoryPageMobile from "../components/HomePage/CategoryPageMobile";
import AddOrEditBanner from "../components/admin/Parametres/AddOrEditBanner";
import PagesError404 from "../components/shared/PagesError/PagesError404";
import AddOrEditBannerVertical from "../components/admin/Parametres/AddOrEditBannerVertical";

// import NosPromos from "../components/user/NosPromos/NosPromos";
// import NosPacks from "../components/user/NosPacks/NosPacks";
// import ParcoursAchat from "../components/user/ParcoursAchat/ParcoursAchat";

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<LoaderSuspense />}>
      <Component {...props} />
    </Suspense>
  );
const HomePage = Loader(
  React.lazy(() => import("../components/HomePage/HomePage"))
);

const NosPromos = Loader(
  React.lazy(() => import("../components/user/NosPromos/NosPromos"))
);

const NosPacks = Loader(
  React.lazy(() => import("../components/user/NosPacks/NosPacks"))
);

const ParcoursAchat = Loader(
  React.lazy(() => import("../components/user/ParcoursAchat/ParcoursAchat"))
);

// UserRoutes
const IsUserAllow = ({ children }: { children: ReactElement }) => {
  const { user, token } = useAppSelector((state) => state.user);
  const [params] = useSearchParams();
  let next = params.get("next");
  let path: string = params.get("next") || "/";
  if (token) {
    if (isAdmin(user)) {
      path = "/admin/dashboard";
    }

    if (isPreparateur(user)) {
      path = "/préparateur/commandes";
    }
  }

  // console.log("path",path)

  // return children;
  return !token || !isUser(user) || next ? (
    <Navigate to={path} replace />
  ) : (
    children
  );
};

const IsAdminAllow = ({ children }: { children: ReactElement }) => {
  const { user, token } = useAppSelector((state) => state.user);
  let path: string = "/connexion";
  if (token) {
    // if (isUser(user)) {
    //   path = "/mon-compte/profil";
    // }

    if (isPreparateur(user)) {
      path = "/préparateur/commandes";
    }
  }

  // return children;
  return !token || !isAdmin(user) ? <Navigate to={path} replace /> : children;
};

const IsPreparateurAllow = ({ children }: { children: ReactElement }) => {
  const { user, token } = useAppSelector((state) => state.user);
  let path: string = "/connexion";
  if (token) {
    // if (isUser(user)) {
    //   path = "/mon-compte/profil";
    // }

    if (isAdmin(user)) {
      path = "/admin/dashboard";
    }
  }

  // return children;
  return !token || !isPreparateur(user) ? (
    <Navigate to={path} replace />
  ) : (
    children
  );
};

export const RedirectAuthRoute = ({ children }: { children: ReactElement }) => {
  const { token, user } = useAppSelector((state) => state.user);
  let path: string = "/";

  if (isAdmin(user)) {
    path = "/admin/dashboard";
  }

  if (isUser(user)) {
    path = "/mon-compte/profil";
  }

  if (isPreparateur(user)) {
    path = "/préparateur/commandes";
  }

  return !token && !user?.id ? children : <Navigate to={path} replace />;
};

const HaveConfigAllow = ({
  children,
  path,
}: {
  children: ReactElement;
  path: string;
}) => {
  const { store } = useAppSelector((state) => state.config);

  return !store ? (
    <Navigate to={`/localisation?next=${path}`} replace />
  ) : (
    children
  );
};

export const AppRoutes = [
  {
    path: "/",
    element: (
      <HaveConfigAllow path="/">
        <HomePage />
      </HaveConfigAllow>
    ),
  },
  {
    path: "/categories",
    element: <CategoryPageMobile />,
  },
  {
    path: "/localisation",
    element: <AdresseBoutiqueEdk />,
  },
  {
    path: "/nos-promos",
    element: (
      <HaveConfigAllow path="/nos-promos">
        <NosPromos />
      </HaveConfigAllow>
    ),
  },
  {
    path: "/nos-packs",
    element: (
      <HaveConfigAllow path="/nos-packs">
        <NosPacks />
      </HaveConfigAllow>
    ),
  },
  {
    path: "/inscription",
    element: (
      <RedirectAuthRoute>
        <Register />
      </RedirectAuthRoute>
    ),
  },
  {
    path: "/connexion",
    element: (
      <RedirectAuthRoute>
        <Login />
      </RedirectAuthRoute>
    ),
  },
  {
    path: "/mot-de-passe-oublie",
    element: (
      <RedirectAuthRoute>
        <ForgetPassword />
      </RedirectAuthRoute>
    ),
  },
  {
    path: "/modifier-mot-de-passe",
    element: (
      <RedirectAuthRoute>
        <ResetPassword />
      </RedirectAuthRoute>
    ),
  },
  {
    path: "/achats",
    element: (
      <HaveConfigAllow path="/achats">
        <ParcoursAchat />
      </HaveConfigAllow>
    ),
  },
  {
    path: "/mentions-legales",
    element: <MentionLegale />,
  },
  {
    path: "/cgu",
    element: <CguPage />,
  },
  {
    path: "/propriete-intellectuelle",
    element: <Copyright />,
  },
  {
    path: "/produit/:id",
    element: <ProduitItemPage />,
  },
  {
    path: "/valider-commande",
    element: <FinaliserCommande />,
  },
  {
    path: "/commande-details",
    element: <OrderItem />,
  },
  {
    path: "mon-compte/",
    element: (
      <IsUserAllow>
        <MonCompteLayout />
      </IsUserAllow>
    ),
    children: [
      { element: <Navigate to="/mon-compte/profil" replace />, index: true },
      {
        path: "profil",
        element: <ProfilPage />,
      },
      {
        path: "commandes",
        element: <CommandePage />,
      },
      {
        path: "commande/:slug",
        element: <DetailCommandePage />,
      },
      {
        path: "panier",
        element: <PanierPage />,
      },
      {
        path: "favoris",
        element: <FavorisPage />,
      },
      {
        path: "mes-adresses",
        element: <AdressePage />,
      },
      {
        path: "points-de-fidelite",
        element: <PointFidelitePage />,
      },
    ],
  },
  {
    path: "admin/",
    element: (
      <IsAdminAllow>
        <AdminLayout />
      </IsAdminAllow>
    ),
    children: [
      { element: <Navigate to="/admin/dashboard" replace />, index: true },
      {
        path: "dashboard",
        element: <DashboardAdmin />,
      },
      {
        path: "produits",
        element: <GestionProduits />,
      },
      {
        path: "produit/:id",
        element: <ProduitDetail />,
      },
      {
        path: "ajouter-produit",
        element: <AddProductAdmin />,
      },
      {
        path: "ajouter-pack",
        element: <AddPackAdmin />,
      },
      {
        path: "modifier-pack/:id",
        element: <AddPackAdmin />,
      },
      {
        path: "pack/:id",
        element: <PackDetailAdmin />,
      },
      {
        path: "modifier-produit/:id",
        element: <AddProductAdmin />,
      },
      {
        path: "commandes",
        element: <GestionCommandes />,
      },
      {
        path: "commande/:id",
        element: <CommandeItem />,
      },
      {
        path: "utilisateurs",
        element: <GestionUsers />,
      },
      {
        path: "utilisateur/:id",
        element: <UserDetailAdmin />,
      },
      {
        path: "parametres",
        element: <ParametresAdmin />,
      },
      {
        path: "point-edk",
        element: <GestionPointEdk />,
      },
      {
        path: "mode-de-paiement",
        element: <GestionModePaiement />,
      },
      {
        path: "mode-de-livraison",
        element: <GestionModeLivraison />,
      },
      {
        path: "ajouter-politique-de-confidentialite",
        element: <AddPolicyAdmin />,
      },
      {
        path: "ajouter-cgu",
        element: <AddCguAdmin />,
      },
      {
        path: "ajouter-propriete-intellectuelle",
        element: <AddCopyright />,
      },
      {
        path: "messages",
        element: <GestionMessages />,
      },
      {
        path: "promos",
        element: <GestionPromoAdmin />,
      },
      {
        path: "ajouter-promotion",
        element: <AddOrEditPromotion />,
      },
      {
        path: "modifier-promotion/:id",
        element: <AddOrEditPromotion />,
      },
      {
        path: "profil",
        element: <ProfilAdmin />,
      },
      {
        path: "zones",
        element: <GestionZones />,
      },
      {
        path: "ajouter-banniere",
        element: <AddOrEditBanner />,
      },
      {
        path: "modifier-banniere/:id",
        element: <AddOrEditBanner />,
      },
      {
        path: "ajouter-banniere-verticale",
        element: <AddOrEditBannerVertical />,
      },
    ],
  },
  {
    path: "préparateur/",
    element: (
      <IsPreparateurAllow>
        <AdminLayout />
      </IsPreparateurAllow>
    ),
    children: [
      {
        element: <Navigate to="/préparateur/commandes" replace />,
        index: true,
      },
      {
        path: "commandes",
        element: <GestionCommandes />,
      },
      {
        path: "commande/:id",
        element: <CommandeItem />,
      },
      {
        path: "profil",
        element: <ProfilAdmin />,
      },
    ],
  },
  {
    path: "*",
    element: <PagesError404 />,
  },
];
