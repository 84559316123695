export const IconDelete = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="4.45508"
        y="5.92969"
        width="8.71111"
        height="6.84444"
        stroke="#65635F"
      />
      <path
        d="M7.5 7.79757L10.1667 7.77535"
        stroke="#65635F"
        strokeLinecap="square"
      />
      <rect
        x="3.83301"
        y="3.44141"
        width="9.95555"
        height="2.48889"
        stroke="#65635F"
      />
    </svg>
  );
};

export const IconHeart = () => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 18L8.55 16.7052C3.4 12.1243 0 9.103 0 5.3951C0 2.37384 2.42 0 5.5 0C7.24 0 8.91 0.794551 10 2.05014C11.09 0.794551 12.76 0 14.5 0C17.58 0 20 2.37384 20 5.3951C20 9.103 16.6 12.1243 11.45 16.715L10 18Z"
        fill="#ff561d"
      />
    </svg>
  );
};

export const IconWallet = () => {
  return (
    <svg
      width="28"
      height="27"
      viewBox="0 0 28 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.2988 17.3424H20.9011C18.9192 17.3412 17.3127 15.6858 17.3115 13.6419C17.3115 11.598 18.9192 9.94266 20.9011 9.94141H26.2988"
        stroke="#200E32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.5117 13.5563H21.0961"
        stroke="#200E32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.77716 1.67188H19.3017C23.1659 1.67188 26.2986 4.90245 26.2986 8.88741V18.7558C26.2986 22.7408 23.1659 25.9714 19.3017 25.9714H7.77716C3.91295 25.9714 0.780273 22.7408 0.780273 18.7558V8.88741C0.780273 4.90245 3.91295 1.67188 7.77716 1.67188Z"
        stroke="#200E32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.82812 7.91172H14.0267"
        stroke="#200E32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const IconSend = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.2484 8.7134L11.4607 16.5532L2.34315 10.9414C1.14843 10.2059 1.39059 8.39146 2.73648 8.00082L24.2628 1.7371C25.4869 1.38283 26.6188 2.5305 26.2522 3.76035L19.8652 25.2644C19.4652 26.6101 17.6711 26.8432 16.946 25.6416L11.4607 16.5532"
        stroke="#200E32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const IconChatDots = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.02866 16.961C8.14211 16.961 7.42188 16.2407 7.42188 15.3542C7.42188 14.4702 8.14211 13.75 9.02866 13.75C9.91521 13.75 10.6354 14.4702 10.6354 15.3542C10.6354 16.2407 9.91521 16.961 9.02866 16.961ZM15.2751 16.961C14.3885 16.961 13.6683 16.2407 13.6683 15.3542C13.6683 14.4702 14.3885 13.75 15.2751 13.75C16.1616 13.75 16.8819 14.4702 16.8819 15.3542C16.8819 16.2407 16.1616 16.961 15.2751 16.961ZM19.9149 15.3542C19.9149 16.2407 20.6351 16.961 21.5216 16.961C22.4082 16.961 23.1284 16.2407 23.1284 15.3542C23.1284 14.4702 22.4082 13.75 21.5216 13.75C20.6351 13.75 19.9149 14.4702 19.9149 15.3542Z"
        fill="#200E32"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9748 1.29688C6.98602 1.29688 1.19727 7.82279 1.19727 15.0675C1.19727 17.381 1.87102 19.7619 3.05352 21.9377C3.27352 22.2971 3.30102 22.7502 3.14977 23.1784L2.22852 26.2631C2.02227 27.0067 2.65477 27.5561 3.35602 27.3358L6.13352 26.5109C6.88977 26.2631 7.48102 26.5784 8.18227 27.0067C10.1898 28.1896 12.6923 28.7969 14.9473 28.7969C21.7673 28.7969 28.6973 23.5227 28.6973 15.0262C28.6973 7.69885 22.7848 1.29688 14.9748 1.29688Z"
        stroke="#200E32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const IconArrowFillRight = () => {
  return (
    <svg
      width="8"
      height="10"
      viewBox="0 0 8 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.34375 0.34375L7.65625 5L0.34375 9.65625V0.34375Z"
        fill="#666666"
      />
    </svg>
  );
};
export const IconArrowFillDown = () => {
  return (
    <svg
      width="10"
      height="8"
      viewBox="0 0 10 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.65625 0.34375L5 7.65625L0.34375 0.34375L9.65625 0.34375Z"
        fill="#666666"
      />
    </svg>
  );
};

export const IconCheck = () => {
  return (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 11.1719L16.5938 0.578125L18 1.98438L6 13.9844L0.421875 8.40625L1.82812 7L6 11.1719Z"
        fill="#21A374"
      />
    </svg>
  );
};
export const IconClose = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9844 1.42188L8.40625 7L13.9844 12.5781L12.5781 13.9844L7 8.40625L1.42188 13.9844L0.015625 12.5781L5.59375 7L0.015625 1.42188L1.42188 0.015625L7 5.59375L12.5781 0.015625L13.9844 1.42188Z"
        fill="#CD1010"
      />
    </svg>
  );
};

export const IconDelete2 = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9 9H10.5V18H9V9Z" fill="#B7B1B1" />
      <path d="M13.5 9H15V18H13.5V9Z" fill="#B7B1B1" />
      <path
        d="M3 4.5V6H4.5V21C4.5 21.3978 4.65804 21.7794 4.93934 22.0607C5.22064 22.342 5.60218 22.5 6 22.5H18C18.3978 22.5 18.7794 22.342 19.0607 22.0607C19.342 21.7794 19.5 21.3978 19.5 21V6H21V4.5H3ZM6 21V6H18V21H6Z"
        fill="#B7B1B1"
      />
      <path d="M9 1.5H15V3H9V1.5Z" fill="#B7B1B1" />
    </svg>
  );
};

export const IconEdit = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.7188 7.03125L18.8906 8.85938L15.1406 5.10938L16.9688 3.28125C17.1562 3.09375 17.3906 3 17.6719 3C17.9531 3 18.1875 3.09375 18.375 3.28125L20.7188 5.625C20.9062 5.8125 21 6.04688 21 6.32812C21 6.60938 20.9062 6.84375 20.7188 7.03125ZM3 17.25L14.0625 6.1875L17.8125 9.9375L6.75 21H3V17.25Z"
        fill="#C8C8C8"
      />
    </svg>
  );
};

export const IconDelete3 = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9 9H10.5V18H9V9Z" fill="#F42D2D" />
      <path d="M13.5 9H15V18H13.5V9Z" fill="#F42D2D" />
      <path
        d="M3 4.5V6H4.5V21C4.5 21.3978 4.65804 21.7794 4.93934 22.0607C5.22064 22.342 5.60218 22.5 6 22.5H18C18.3978 22.5 18.7794 22.342 19.0607 22.0607C19.342 21.7794 19.5 21.3978 19.5 21V6H21V4.5H3ZM6 21V6H18V21H6Z"
        fill="#F42D2D"
      />
      <path d="M9 1.5H15V3H9V1.5Z" fill="#F42D2D" />
    </svg>
  );
};

export const IconImage = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 0C18.6569 0 20 1.34315 20 3V17C20 18.6569 18.6569 20 17 20H3C1.34315 20 0 18.6569 0 17V3C0 1.34315 1.34315 0 3 0H17ZM18 10.302L13.8321 16.5547C13.5243 17.0163 12.9063 17.1338 12.454 16.838L12.3598 16.7682L7.1875 12.458L3.228 18H17C17.5523 18 18 17.5523 18 17V10.302ZM17 2H3C2.44772 2 2 2.44772 2 3V16.278L6.18627 10.4188C6.50017 9.9793 7.09949 9.87371 7.54124 10.159L7.64018 10.2318L12.7853 14.5193L18 6.696V3C18 2.44772 17.5523 2 17 2ZM6 4C7.10457 4 8 4.89543 8 6C8 7.10457 7.10457 8 6 8C4.89543 8 4 7.10457 4 6C4 4.89543 4.89543 4 6 4Z"
        fill="black"
      />
    </svg>
  );
};
export const IconChat = () => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3738 2.32033C9.98767 2.31942 8.61984 2.63675 7.37571 3.24786C6.13157 3.85898 5.04434 4.74756 4.19779 5.84513C3.35124 6.94271 2.76797 8.21999 2.49291 9.57854C2.21786 10.9371 2.25838 12.3407 2.61133 13.6811C2.96429 15.0215 3.62026 16.263 4.52871 17.31C5.43717 18.3569 6.57384 19.1813 7.85115 19.7196C9.12846 20.2579 10.5123 20.4958 11.8961 20.415C13.2798 20.3341 14.6266 19.9367 15.8325 19.2532L19.2372 20.3878C19.3297 20.4171 19.4263 20.4314 19.5233 20.4304C19.6667 20.4303 19.808 20.3961 19.9357 20.3307C20.0633 20.2653 20.1735 20.1705 20.2574 20.0542C20.3412 19.9378 20.3962 19.8032 20.4178 19.6614C20.4394 19.5197 20.4271 19.3748 20.3817 19.2388L19.2517 15.8341C20.0344 14.4572 20.4418 12.899 20.4331 11.3153C20.4245 9.73154 20.0002 8.17784 19.2026 6.8096C18.4049 5.44136 17.262 4.30656 15.8881 3.51873C14.5142 2.73089 12.9575 2.31764 11.3738 2.32033ZM17.3981 16.0124L18.0917 18.0951L16.0091 17.4015C15.882 17.3582 15.7468 17.3438 15.6135 17.3595C15.4802 17.3751 15.352 17.4204 15.2385 17.492C13.7282 18.4478 11.9186 18.8131 10.1558 18.518C8.39309 18.2229 6.80107 17.2882 5.68446 15.8926C4.56785 14.4971 4.00508 12.7388 4.10384 10.9542C4.20261 9.16969 4.95598 7.48424 6.21978 6.22045C7.48357 4.95666 9.16902 4.20328 10.9536 4.10452C12.7381 4.00575 14.4964 4.56852 15.8919 5.68513C17.2875 6.80174 18.2223 8.39376 18.5174 10.1565C18.8125 11.9192 18.4472 13.7289 17.4914 15.2391C17.4193 15.353 17.3735 15.4815 17.3573 15.6153C17.3412 15.749 17.3551 15.8847 17.3981 16.0124Z"
        fill="#8E8EA1"
      />
      <path
        d="M14.9958 8.20312H7.75182C7.51166 8.20312 7.28134 8.29853 7.11153 8.46834C6.94171 8.63816 6.84631 8.86847 6.84631 9.10863C6.84631 9.34878 6.94171 9.5791 7.11153 9.74892C7.28134 9.91873 7.51166 10.0141 7.75182 10.0141H14.9958C15.236 10.0141 15.4663 9.91873 15.6361 9.74892C15.8059 9.5791 15.9014 9.34878 15.9014 9.10863C15.9014 8.86847 15.8059 8.63816 15.6361 8.46834C15.4663 8.29853 15.236 8.20312 14.9958 8.20312ZM11.3738 12.7306H7.75182C7.51166 12.7306 7.28134 12.826 7.11153 12.9959C6.94171 13.1657 6.84631 13.396 6.84631 13.6361C6.84631 13.8763 6.94171 14.1066 7.11153 14.2764C7.28134 14.4462 7.51166 14.5417 7.75182 14.5417H11.3738C11.614 14.5417 11.8443 14.4462 12.0141 14.2764C12.1839 14.1066 12.2793 13.8763 12.2793 13.6361C12.2793 13.396 12.1839 13.1657 12.0141 12.9959C11.8443 12.826 11.614 12.7306 11.3738 12.7306Z"
        fill="#8E8EA1"
      />
    </svg>
  );
};

export const IconNotif = () => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.7227 17.8278H10.6854C10.21 17.8278 9.8025 18.2353 9.8025 18.7107C9.8025 19.1861 10.21 19.5935 10.6854 19.5935H12.7227C13.1981 19.5935 13.6056 19.1861 13.6056 18.7107C13.6056 18.2353 13.1981 17.8278 12.7227 17.8278ZM17.7483 15.7225H17.205V10.4253C17.205 7.64089 15.1676 5.39977 12.519 4.99229V4.10943C12.519 3.63404 12.1794 3.22656 11.7041 3.22656C11.2287 3.22656 10.8212 3.63404 10.8212 4.10943V4.99229C8.17259 5.39977 6.13521 7.70881 6.13521 10.4253V15.7225H5.72773C5.25234 15.7225 4.91278 16.13 4.91278 16.6054C4.91278 17.0808 5.32026 17.4882 5.79565 17.4882H7.08599H16.458H17.8162C18.2916 17.4882 18.6991 17.0808 18.6991 16.6054C18.6991 16.13 18.2237 15.7225 17.7483 15.7225ZM7.83303 15.7225V10.4253C7.83303 8.32002 9.53085 6.6222 11.6361 6.6222C13.7414 6.6222 15.4393 8.32002 15.4393 10.4253V15.7225H7.83303Z"
        fill="#8E8EA1"
      />
    </svg>
  );
};

export const IconPlusSquare = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0001 8.32812V15.6545"
        stroke="url(#paint0_linear_2634_32969)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6666 11.9914H8.33325"
        stroke="url(#paint1_linear_2634_32969)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6857 2H7.31429C4.04762 2 2 4.31208 2 7.58516V16.4148C2 19.6879 4.0381 22 7.31429 22H16.6857C19.9619 22 22 19.6879 22 16.4148V7.58516C22 4.31208 19.9619 2 16.6857 2Z"
        stroke="url(#paint2_linear_2634_32969)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2634_32969"
          x1="11.6178"
          y1="10.9229"
          x2="12.3424"
          y2="10.9217"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ff561d" />
          <stop offset="1" stopColor="#FF9866" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2634_32969"
          x1="9.05626"
          y1="11.8526"
          x2="14.5842"
          y2="11.3192"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ff561d" />
          <stop offset="1" stopColor="#FF9866" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2634_32969"
          x1="3.97183"
          y1="9.08333"
          x2="19.1859"
          y2="8.89286"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ff561d" />
          <stop offset="1" stopColor="#FF9866" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const IconPlusCircle = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.27344 14.7266C6.60156 16.0286 8.17708 16.6797 10 16.6797C11.8229 16.6797 13.3854 16.0286 14.6875 14.7266C16.0156 13.3984 16.6797 11.8229 16.6797 10C16.6797 8.17708 16.0156 6.61458 14.6875 5.3125C13.3854 3.98438 11.8229 3.32031 10 3.32031C8.17708 3.32031 6.60156 3.98438 5.27344 5.3125C3.97135 6.61458 3.32031 8.17708 3.32031 10C3.32031 11.8229 3.97135 13.3984 5.27344 14.7266ZM4.10156 4.14062C5.74219 2.5 7.70833 1.67969 10 1.67969C12.2917 1.67969 14.2448 2.5 15.8594 4.14062C17.5 5.75521 18.3203 7.70833 18.3203 10C18.3203 12.2917 17.5 14.2578 15.8594 15.8984C14.2448 17.513 12.2917 18.3203 10 18.3203C7.70833 18.3203 5.74219 17.513 4.10156 15.8984C2.48698 14.2578 1.67969 12.2917 1.67969 10C1.67969 7.70833 2.48698 5.75521 4.10156 4.14062ZM10.8203 5.82031V9.17969H14.1797V10.8203H10.8203V14.1797H9.17969V10.8203H5.82031V9.17969H9.17969V5.82031H10.8203Z"
        fill="url(#paint0_linear_1762_25796)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1762_25796"
          x1="3.32031"
          y1="7.57324"
          x2="15.9789"
          y2="7.41476"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ff561d" />
          <stop offset="1" stopColor="#FF9866" />
        </linearGradient>
      </defs>
    </svg>
  );
};
