import React from "react";
import PhoneInput from "react-phone-input-2";
import { Store } from "../../../../utils/api/store/store.type";
import ErrorMessage from "../../../common/ErrorMessage";
import GoogleInput from "../../../common/GoogleInput";
import { InputAdmin } from "../../../common/Input";
import UseCrudStoreForm from "../requestCrudStore/UseCrudStoreForm";
import Select from "react-select";
import makeAnimated from "react-select/animated";

function CrudStoreModal({
  modalId,
  store,
}: {
  modalId: string;
  store?: Store;
}) {
  const {
    register,
    errors,
    onSubmit,
    isLoading,
    address,
    phone,
    handleChangePhone,
    onChangeAddress,
    handleReset,
    handleSelectZone,
    options,
    option,
    Controller,
    control,
  } = UseCrudStoreForm(modalId, store);
  const animatedComponents = makeAnimated();
  return (
    <div
      className="modal fade"
      id={`${modalId}`}
      tabIndex={-1}
      data-bs-backdrop="static"
      aria-labelledby="addStoreModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header flex-sb">
            <h5 className="modal-title-admin">
              {!store ? "Ajouter" : "Modifier"} une adresse
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              onClick={() => handleReset()}
              aria-label="Close"
            ></button>
          </div>
          <div className="pt-3">
            <form onSubmit={onSubmit}>
              <div className="row py-3 px-2">
                <div className="col-md-12 mb-4">
                  <InputAdmin
                    label={"Nom du Point EDK"}
                    type={"text"}
                    placeholder="Nom du Point EDK"
                    {...register("name")}
                    error={errors?.name?.message}
                    required
                  />
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label className="form-label form-label-admin">
                      Zone{" "}
                      <span
                        className="text-danger custom-required-text"
                        style={{ fontSize: 17 }}
                      >
                        *
                      </span>
                    </label>

                    <Controller
                      name="zone"
                      control={control}
                      render={({ field }) => {
                        return (
                          <Select
                            {...field}
                            closeMenuOnSelect={true}
                            components={animatedComponents}
                            placeholder="Sélectionnez votre zone"
                            options={options}
                            classNamePrefix="react-select-custom"
                            onChange={handleSelectZone}
                            value={option}
                          />
                        );
                      }}
                    />
                    <ErrorMessage
                      message={errors?.zone && errors?.zone?.message}
                    />
                  </div>
                </div>
                <div className="col-md-12 mb-4">
                  {/* <InputAdmin
                    label="Adresse"
                    type={"text"}
                    placeholder="Adresse"
                  /> */}
                  <label className="form-label form-label-admin">
                    Adresse{" "}
                    <span
                      className="text-danger custom-required-text"
                      style={{ fontSize: 17 }}
                    >
                      *
                    </span>
                  </label>

                  <GoogleInput
                    className="form-control form-control-admin"
                    value={address}
                    onChange={onChangeAddress}
                    placeholder="Adresse"
                  />
                  <ErrorMessage
                    message={errors?.adresse && errors?.adresse?.message}
                  />
                </div>
                <div className="col-md-12 mb-4">
                  <label className="form-label form-label-admin">
                    Téléphone{" "}
                    <span
                      className="text-danger custom-required-text"
                      style={{ fontSize: 17 }}
                    >
                      *
                    </span>
                  </label>
                  <PhoneInput
                    inputProps={{
                      name: "phone",
                    }}
                    defaultMask={".. ... .. .."}
                    country={"sn"}
                    enableSearch={true}
                    value={phone}
                    onChange={(phone, country) =>
                      handleChangePhone(phone, country)
                    }
                    inputClass="form-control-admin-tel"
                    enableAreaCodes={true}
                    prefix="+"
                    countryCodeEditable={false}
                  />
                  <ErrorMessage
                    message={errors?.telephone && errors?.telephone?.message}
                  />
                </div>
                <div className="col-md-12 mb-4">
                  <InputAdmin
                    label="Email"
                    type={"text"}
                    placeholder="Email"
                    {...register("email")}
                    error={errors?.email?.message}
                    required
                  />
                </div>
                <div className="col-md-12 mb-4">
                  <InputAdmin
                    label={"L'id de la boutique sur Léo2"}
                    type={"text"}
                    placeholder="L'id de la boutique sur Léo2"
                    {...register("boutiqueId")}
                    error={errors?.boutiqueId?.message}
                    required
                  />
                </div>
                <div className="col-md-12 mb-4">
                  <InputAdmin
                    label={"La clé publique de la boutique sur léo2"}
                    type={"text"}
                    placeholder="La clé publique de la boutique sur léo2"
                    {...register("boutiquePubKey")}
                    error={errors?.boutiquePubKey?.message}
                    required
                  />
                </div>

                <div className="flex-r">
                  <button
                    className="btn btn-cancelled"
                    type="button"
                    data-bs-dismiss="modal"
                    onClick={() => handleReset()}
                    aria-label="Close"
                  >
                    Annuler
                  </button>
                  <button
                    type="submit"
                    className="btn btn-theme"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                          role="status"
                        ></span>
                        <span>En cours...</span>
                      </>
                    ) : store ? (
                      "Modifier"
                    ) : (
                      "Ajouter"
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CrudStoreModal;
