import React from "react";
import Logo from "../../assets/appImages/logo.png";
import { UseForgetPasswordForm } from "./requestForm/UseForgetPasswordForm";
import ErrorMessage from "../common/ErrorMessage";

const ForgetPassword = () => {
  const { register, onSubmit, errors, isLoading } = UseForgetPasswordForm();
  return (
    <div className="container-page-login-app">
      <div className="auth-row">
        <div className="auth-col auth-right-side-col">
          <div className="auth-right-side-container">
            <div className="auth-form-container text-center pb-4 w-100">
              <div className="content-logo-auth pb-2 pt-5">
                <img
                  src={Logo}
                  alt="logo"
                  className="logo-auth-page"
                  loading="lazy"
                />
              </div>
              <div className="text-center pb-4">
                <h2 className="title-auth-form-text pt-5">
                  Mot de passe oublié?
                </h2>
                <div className="content-text-create-account">
                  <span className="text-resgister-modal-login">
                    Entrez votre mail pour réinitialiser votre mot de passe
                  </span>
                </div>
              </div>
              <div className="mt-5">
                <form onSubmit={onSubmit}>
                  <div className="row row-add-student">
                    <div className="col-md-12 col-form-add-student mb-3">
                      <div className="content-col-form-login-modal text-start">
                        <label className="form-label form-label-login-modal">
                          {" "}
                          Adresse mail ou N° Téléphone
                        </label>
                        <input
                          className="form-control form-control-login-modal"
                          type="text"
                          placeholder="Adresse mail ou Téléphone"
                          {...register("email")}
                        />
                        <ErrorMessage
                          message={errors?.email && errors?.email?.message}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 pt-3">
                      <button
                        className="btn btn-theme w-100"
                        // onClick={(e) => openModalResetPassword(e)}
                        type="submit"
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <>
                            <span
                              className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                              role="status"
                            ></span>
                            <span>Envoie...</span>
                          </>
                        ) : (
                          "Envoyer"
                        )}
                      </button>
                      {/* <ResetPasswordModal
                      modalResetPassword={modalResetPassword}
                      setModalResetPassword={setModalResetPassword}
                    /> */}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
