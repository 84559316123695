/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { NavLink } from "react-router-dom";
import { useGetOrderListQuery } from "../../../utils/api/order/order.api";
import { formatDateHour, getImage } from "../../../utils/Utils";
import { orderType } from "../../admin/GestionCommande/CommandeItem";
import Pagination from "../../common/Pagination";
import Empty from "../../../assets/appImages/Empty.png";
// import { useGetOrderListQuery } from "../../../utils/api/order/order.api";

const CommandePage = () => {
  // const { user } = useAppSelector((s) => s?.user);
  const [page, setPage] = useState(1);
  const { data = { results: [], nbPage: 0 }, isLoading } = useGetOrderListQuery(
    {
      limit: 5,
      page,
    }
  );

  return (
    <div className="form-register-tabs-component">
      <div className="content-title-form-register pb-4">
        <h3 className="title-form-register">Mes commandes</h3>
      </div>
      <div className="content-detail-commande-page mt-3 mb-3">
        <div className="row row-list-commande-users-connect">
          {!!isLoading && <OrderSkeleton />}
          {!isLoading &&
            (data?.results?.length ? (
              data?.results?.map((order) => (
                <div
                  className="col-lg-6 col-lg-commande-users-list d-flex mb-4"
                  key={order?._id}
                >
                  <div className="detail-commande-item">
                    <div className="content-info-commande-item">
                      <div className="content-img-commande-item">
                        <img
                          src={getImage(
                            order?.orderItems[0]?.product?.pictures[0]?.image
                          )}
                          alt={order?.orderItems[0]?.product?.name}
                          className="img-commande-item"
                          loading="lazy"
                        />
                      </div>
                      <div className="info-commande-item">
                        <p className="text-name-commande-item mb-1">
                          Commande N° {order?.numOrder}
                        </p>
                        <p className="text-price-commande-item mb-1">
                          {order?.totalAmount}{" "}
                          <span style={{ fontWeight: 400 }}>FCFA</span>
                        </p>
                        <p className="text-date-pub-commande-item mb-2">
                          Le {formatDateHour(order?.createdAt)}
                        </p>
                        {/* <span className="statut-commande-item">
                      {order?.status}
                    </span> */}
                        <div
                          className={`${
                            orderType?.find((el) => el?.value === order?.status)
                              ?.class
                          } statut-commande`}
                        >
                          {order?.status}
                        </div>
                      </div>
                    </div>
                    <div className="content-detail-commande-btn btn-detail-commande-user-compte">
                      <NavLink
                        to={`/mon-compte/commande/${order?._id}`}
                        state={order}
                        className="btn btn-detail-commande"
                      >
                        Détails de la commande
                      </NavLink>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              // <AlertInfo message="Vous n'avez aucune commande pour le moment !" />

              <div className="content-panier-vide">
                <div>
                  <div className="content-img-panier-vide pb-4 text-center">
                    <img
                      src={Empty}
                      alt="Empty"
                      className="img-panier-vide w-30"
                      loading="lazy"
                    />
                  </div>
                  <h3 className="titre-panier-vide">
                    Aucune commande disponible!
                  </h3>
                  <p className="text-panier-vide">
                    Veuillez ajouter des produits pour pouvoir faire une
                    commande.
                  </p>
                  <div className="content-btn-action-panier pt-4 text-center">
                    <NavLink to="/achats" className="btn btn-theme ">
                      COMMANDER
                    </NavLink>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="d-flex justify-content-center">
        {data?.nbPage && data?.nbPage > 1 ? (
          <Pagination
            page={page}
            total={data?.nbPage}
            onPageChange={(page) => setPage(page)}
          />
        ) : null}
      </div>
    </div>
  );
};

export default CommandePage;

export const OrderSkeleton = () => {
  return (
    <>
      {Array(5)
        .fill(5)
        .map((item, i) => (
          <div
            className="col-lg-6 col-lg-commande-users-list d-flex mb-4"
            key={i}
          >
            <div className="detail-commande-item">
              <div className="content-info-commande-item">
                <div className="content-img-commande-item">
                  <Skeleton className="img-commande-item" height={150} />
                </div>
                <div className="info-commande-item">
                  <Skeleton
                    className="text-name-commande-item mb-1"
                    width={`25rem`}
                  />
                  <Skeleton
                    className="text-price-commande-item mb-1"
                    width={`10rem`}
                  />
                  <Skeleton
                    className="text-date-pub-commande-item mb-2"
                    width={`12rem`}
                  />
                  <Skeleton className="statut-commande-item" width={`9rem`} />
                </div>
              </div>
              <div className="content-detail-commande-btn">
                <Skeleton
                  className="btn btn-detail-commande"
                  width={`5rem`}
                  height={10}
                />
              </div>
            </div>
          </div>
        ))}
    </>
  );
};
