import React from "react";
import { useGetConfigAppQuery } from "../../../../utils/api/config/config.api";
import { NavLink } from "react-router-dom";
import { FaImage } from "react-icons/fa";
import { AlertInfo } from "../../../common/Alert";
import { getPageName } from "./BannerAdmin";
import { getImage } from "../../../../utils/Utils";

function VerticalBanner() {
  const { data, isLoading } = useGetConfigAppQuery();
  return (
    <div className="admin-tabs-component">
      <div className="content-title flex-r pb-4">
        <NavLink
          to="/admin/ajouter-banniere-verticale"
          className="btn btn-add-admin no-link"
        >
          <FaImage />
          <span className="ps-2">
            {data?.banner ? "Modifier la" : "Ajouter une"} bannière
          </span>
        </NavLink>
      </div>
      <div className="admin-table">
        <div className="row">
          {/* {!!isLoading && <CardSkeleton />} */}
          {!isLoading &&
            (data?.banner ? (
              <div className="col-lg-12 col-md-12 mb-3">
                <div className="container-banner-admin mb-4">
                  <p className="title-banner-admin">Bannière verticale</p>
                  <div className={`content-banner-admin-item active`}>
                    <p className="title-banner-admin">Photo</p>
                    <div className="content-img-banner-admin mb-4">
                      <img
                        src={getImage(data?.banner)}
                        alt={data?._id}
                        className="img-banner-admin vertical-banner-img"
                        loading="lazy"
                      />
                    </div>

                    <p className="title-banner-admin">Page de redirection</p>
                    <div className="content-text-banner-admin mb-4">
                      <div className="text-banner-admin pt-1">
                        <a
                          href={data?.bannerLink}
                          className="no-link"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {getPageName(data?.bannerLink)}
                        </a>
                      </div>
                    </div>
                    {/* <div className="content-btn-edit-banner-admin flex-r">
                        <ToggleBannerBtn item={item} />
                        <NavLink
                          to={`/admin/modifier-banniere/${item?._id}`}
                          state={item}
                          className="btn btn-action-modal-icon with-tooltip mx-2"
                          data-tooltip-content="Modifier"
                        >
                          <MdModeEditOutline />
                        </NavLink>
                        <DeleteBannerBtn id={item?._id} />
                      </div> */}
                  </div>
                </div>
              </div>
            ) : (
              <AlertInfo message="Aucune donnée n'est disponible pour le moment!" />
            ))}
        </div>
      </div>
    </div>
  );
}

export default VerticalBanner;
