import React from "react";
import "./CartItem.css";
import {
  calculateTax,
  CartItemType,
  decrement,
  getCartCount,
  getPreOrder,
  getSubTotal,
  getTotalAmount,
  increment,
  removeCartItem,
} from "../../../redux/slice/useCartSlice";
import { Button, Stack } from "react-bootstrap";
import { useAppDispatch } from "../../../redux/hook";
import { formatCurrency, getImage } from "../../../utils/Utils";
import { NavLink } from "react-router-dom";
import { Counter } from "../../MonCompte/MonComptePage/PanierPage";
import Swal, { SweetAlertResult } from "sweetalert2";
import { Color } from "../../../utils/theme";

function CartItem({ item }: { item: CartItemType }) {
  const [count, setCount] = React.useState(item?.qty);
  const dispatch = useAppDispatch();

  const getCartItems = () => {
    dispatch(getSubTotal());
    dispatch(getCartCount());
    dispatch(calculateTax());
    dispatch(getTotalAmount());
  };
  React.useEffect(() => {
    if (count > item?.qty) {
      if (
        parseInt(item?.product?.stock as unknown as string) === count ||
        parseInt(item?.product?.stock as unknown as string) === item?.qty
      ) {
        Swal.fire({
          title: `La quantité demandée n'est actuellement pas disponible en stock. Souhaitez-vous faire une pré-commande ?`,
          icon: "success",
          text: "Vous serez notifié de la disponibilité de ce produit.",
          showCancelButton: true,
          confirmButtonText: "PRÉ-COMMANDER",
          cancelButtonText: "ANNULER",
          showLoaderOnConfirm: true,
          iconColor: Color.default,
          confirmButtonColor: Color.default,
          customClass: {
            confirmButton: "btn btn-sweet-confirm",
            cancelButton: "btn btn-sweet-cancel",
          },
          reverseButtons: true,
        }).then((result: SweetAlertResult<any>) => {
          if (result?.isConfirmed) {
            dispatch(increment(item));
            getCartItems();
            dispatch(getPreOrder(true));
          } else {
            setCount(item?.qty);
          }
        });
      } else {
        dispatch(increment(item));
        getCartItems();
      }
    }

    if (count < item?.qty) {
      // console.log("dec", count, item?.qty);
      dispatch(decrement(item));
      getCartItems();
    }
  }, [count]);

  const onRemoveItem = (e) => {
    e?.preventDefault();
    dispatch(removeCartItem(item?.product));
    getCartItems();
  };
  return (
    <Stack direction="horizontal" gap={2} className="d-flex align-items-center">
      <NavLink
        to={`/produit/${item?.product?._id}`}
        state={item?.product}
        className="no-link "
      >
        <img
          src={getImage(item?.product?.pictures[0]?.image)}
          // style={{ width: "125px", height: "75px", objectFit: "cover" }}
          className="cartItem-img"
          loading="lazy"
        />
      </NavLink>

      <div className="me-auto">
        <div className="name-prduct fs-15">
          {item?.product?.name}

          {/* {item?.qty > 0 && (
            <span
              className="text-muted cart-qty-text"
              style={{ fontSize: "0.75rem" }}
            >
              x{item?.qty}
            </span>
          )} */}
        </div>
        <div className="text-muted" style={{ fontSize: "0.80rem" }}>
          {formatCurrency(
            item?.product?.promote?.price || item?.product?.price
          )}
        </div>
      </div>
      <div className="counter mt-3">
        <Counter setCount={setCount} count={count} />
      </div>
      <div className="cart-price-text">
        {formatCurrency(
          (item?.product?.promote?.price || item?.product?.price) * item?.qty
        )}
      </div>
      <Button
        type="button"
        variant="outline-danger"
        size="sm"
        onClick={(e) => onRemoveItem(e)}
      >
        &times;
      </Button>
    </Stack>
  );
}

export default CartItem;
