import React from "react";
import UseEditConfigApp from "./request/UseEditConfigApp";
import { useNavigate } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { SelectInputAdmin } from "../../common/SelectInput";
import { PageList } from "./AddOrEditBanner";
import { InputAdmin } from "../../common/Input";

function AddOrEditBannerVertical() {
  const { handleChangeBanner, onSubmit, isLoading, errors, banner, register } =
    UseEditConfigApp("banner");
  const navigate = useNavigate();
  return (
    <div className="content-graph-admin">
      <div className="container-title-content-page mb-4">
        <div
          className="content-title-page-admin"
          onClick={() => navigate(-1)}
          style={{ cursor: "pointer" }}
        >
          <h2 className="title-page-admin">
            <AiOutlineArrowLeft style={{ color: "#E20613" }} />
            <span className="ps-2">
              Ajout / Modification de la bannière verticale
            </span>
          </h2>
        </div>
      </div>
      <div className="container-form-add-product-admin mt-5">
        <form onSubmit={onSubmit}>
          <div className="row row-add-product-admin">
            <div className="col-md-12 col-add-product-admin mb-3">
              <SelectInputAdmin
                label="Page de redirection"
                options={PageList}
                {...register("bannerLink")}
                error={errors?.bannerLink?.message}
              />
            </div>
            <div className="col-md-12 col-add-product-admin mb-3">
              <InputAdmin
                label="Image"
                type={"file"}
                accept="image/*"
                onChange={(e) => handleChangeBanner(e)}
                error={errors?.banner?.message}
                hasSmallText
                smallText="Dimensions: 250 × 746"
              />
              {!!banner && (
                <div>
                  <div className="product-img-card">
                    <img
                      src={banner}
                      alt="category-img"
                      style={{
                        width: "100%",
                        height: "15vw",
                        objectFit: "contain",
                      }}
                      loading="lazy"
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="col-md-3 offset-md-9 col-add-product-admin mb-3">
              <div className="mb-3">
                <button
                  className="btn btn-submit-form-add-product-admin w-100"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                        role="status"
                      ></span>
                      <span>En cours...</span>
                    </>
                  ) : (
                    "Enregistrer"
                  )}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddOrEditBannerVertical;
