import React, {  } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Category } from "../../../../utils/api/category/category.type";
import { FormError, InputAdmin } from "../../../common/Input";
import UseCrudCategoriesForm from "../requestForm/UseCrudCategoriesForm";

function AddCategoryModal({
  isSub,
  modalId,
  category,
}: {
  isSub: boolean;
  modalId: string;
  category?:Category
}) {
  // const [item] = useCategoryFromLocation()
  const {
    register,
    onSubmit,
    errors,
    isLoading,
    image,
    handleChangeImage,
    handleReset,
    options,
    option,
    handleSelected,
    control,
    Controller,
  } = UseCrudCategoriesForm(isSub, modalId, category);
  const animatedComponents = makeAnimated();

  return (
    <div
      className="modal fade"
      id={`${modalId}`}
      tabIndex={-1}
      data-bs-backdrop="static"
      aria-labelledby="addCategoryModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header flex-sb">
            <h5 className="modal-title-admin">
              {!category ? "Ajouter" : "Modifier"} une{" "}
              {isSub ? "sous catégorie" : "catégorie"}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              onClick={() => handleReset()}
              aria-label="Close"
            ></button>
          </div>
          <div className="pt-3">
            <form onSubmit={onSubmit}>
              <div className="row py-3 px-2">
                <div className="col-md-12 mb-4">
                  <InputAdmin
                    label={`Nom de la ${isSub ? "sous" : ""} catégorie`}
                    type={"text"}
                    placeholder="Nom "
                    {...register("name")}
                    error={errors?.name?.message}
                  />
                </div>
                {!!isSub ? (
                  <div className="col-md-12 mb-4">
                    <div className="mb-3">
                      <label
                        htmlFor={"category"}
                        className="form-label form-label-admin"
                        aria-labelledby={"category"}
                      >
                        Catégorie principale
                      </label>

                      <Controller
                        name="category"
                        control={control}
                        render={({ field }) => {
                          return (
                            <Select
                              {...field}
                              closeMenuOnSelect={true}
                              components={animatedComponents}
                              placeholder="Sélectionner une catégorie"
                              options={options}
                              classNamePrefix="react-select-custom"
                              onChange={handleSelected}
                              value={option}
                            />
                          );
                        }}
                      />
                      {<FormError error={errors?.category?.message} />}
                    </div>
                  </div>
                ) : (
                  <div className="col-md-12 mb-4">
                    <InputAdmin
                      label={`Image de la ${isSub ? "sous" : ""} catégorie`}
                      type={"file"}
                      accept="image/*"
                      onChange={(e) => handleChangeImage(e)}
                      error={errors?.image?.message}
                    />
                    {!!image && (
                      <div>
                        <div className="product-img-card">
                          <img
                            src={image}
                            alt="category-img"
                            style={{
                              width: "100%",
                              height: "15vw",
                              objectFit: "contain",
                            }}
                            loading="lazy"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}

                <div className="flex-r">
                  <button
                    className="btn btn-cancelled"
                    type="button"
                    data-bs-dismiss="modal"
                    onClick={() => handleReset()}
                    aria-label="Close"
                  >
                    Annuler
                  </button>
                  <button
                    type="submit"
                    className="btn btn-theme"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                          role="status"
                        ></span>
                        <span>En cours...</span>
                      </>
                    ) : category ? (
                      "Modifier"
                    ) : (
                      "Ajouter"
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddCategoryModal;
export const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    zIndex: 99999,
  },
};
