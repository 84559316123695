import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useForgetPasswordMutation } from "../../../utils/api/auth/auth.api";
import { ForgetPasswordFormData } from "../../../utils/api/auth/auth.type";
import { Color } from "../../../utils/theme";
import { cleannerError } from "../../../utils/Utils";
// import { useForgetPasswordMutation } from "../../../../utils/api/auth/auth.api";
// import { ForgetPasswordFormData } from "../../../../utils/api/auth/auth.type";
// import { Color } from "../../../../utils/theme";
// import { cleannerError } from "../../../../utils/utils";
export function UseForgetPasswordForm(
  closeModalForgetPassword?: () => void,
  openModalResetPassword?: () => void
) {
  const [forgetPassword, { error, isSuccess, isLoading, originalArgs }] =
    useForgetPasswordMutation();
  const navigate = useNavigate();
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup.string().nullable().required().label("Email ou Téléphone"),
      }),
    []
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setError,
  } = useForm<ForgetPasswordFormData>({
    resolver: yupResolver(validationSchema),
  });

  
  useEffect(() => {
    if (error) {
      const err = error as any;
      console.log("erreur", err);
      const message = err?.data?.error || "Une erreur a survenue lors de l'envoi";
      setError("email", { message: message });
    }
  }, [error]);

  useEffect(() => {
    if (isSuccess) {
      if (closeModalForgetPassword && openModalResetPassword) {
        openModalResetPassword();
        closeModalForgetPassword();
        
      }
      Swal.fire({
        icon: "success",
        iconColor: Color.success,
        confirmButtonColor: Color.success,
        title: "Un code vous a été envoyé, vérifiez s'il vous plaît!",
        timer: 2500,
      }).then(() => {
        if (!closeModalForgetPassword) {
          navigate("/modifier-mot-de-passe", {
            replace: true,
            state: { email: originalArgs?.email },
          });
        }
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (errors) {
      cleannerError(errors, clearErrors);
    }
  }, [errors]);

  const onSubmit = async (data: ForgetPasswordFormData) => {
    data["email"] = data.email?.toLowerCase();
    await forgetPassword(data);
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    clearErrors,
    isLoading,
  };
}
