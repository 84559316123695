/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useState } from "react";
import { BiMessageSquareAdd } from "react-icons/bi";
import { NavLink } from "react-router-dom";
import { BsTrashFill } from "react-icons/bs";
import AddProduitPromoModal from "../modal/AddProduitPromoModal";
import { useGetPromoteProductListQuery } from "../../../../utils/api/product/product.api";

import { AlertInfo } from "../../../common/Alert";
import { formatCurrency, getImage } from "../../../../utils/Utils";
import Pagination from "../../../common/Pagination";
import { Product } from "../../../../utils/api/product/product.type";
import { UseDeletePromoteProduct } from "../requestForm/UseAddProductToPromotionForm";
import Skeleton from "react-loading-skeleton";
import { useAppSelector } from "../../../../redux/hook";

const ProduitPromoTabs = () => {
  const { store } = useAppSelector((state) => state.config);
  const [page, setPage] = useState(1);
  const { data = { results: [], nbPages: 0 }, isLoading } =
    useGetPromoteProductListQuery({
      page,
      limit: 24,
      store: store?._id
    });
  const [modalAddProduitPromo, setModalAddProduitPromo] = useState(false);

  useEffect(() => {
    console.log("data promote", data);
  }, [data]);
  function openModalAddProduitPromo(e: any) {
    e.preventDefault();
    setModalAddProduitPromo(true);
  }
  return (
    <div className="admin-tabs-component">
      <div className="content-title flex-r pb-4">
        <button
          onClick={openModalAddProduitPromo}
          className="btn btn-add-admin"
        >
          <BiMessageSquareAdd />
          <span className="ps-2">Ajouter un produit en promo</span>
        </button>
        <AddProduitPromoModal
          modalAddProduitPromo={modalAddProduitPromo}
          setModalAddProduitPromo={setModalAddProduitPromo}
        />
      </div>
      <div className="admin-table">
        <div className="row mt-3">
          {!!isLoading && <ProductItemSkeleton />}
          {!isLoading &&
            (data?.results?.length ? (
              data?.results.map((item) => (
                <div
                  className="col-md-3 col-lg-4 col-xl-2 mb-4 col-product-item d-flex"
                  key={item?._id}
                >
                  <ProductSoldeAdminItem item={item} />
                </div>
              ))
            ) : (
              <AlertInfo message="Aucun produit n'est en promo pour le moment !" />
            ))}
        </div>
        <div className="d-flex justify-content-center">
          {data?.nbPages && data?.nbPages > 1 ? (
            <Pagination
              page={page}
              total={data?.nbPages}
              onPageChange={(page) => setPage(page)}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ProduitPromoTabs;
function DeletePromotionButton({ id }: { id: string }) {
  const onDelete = UseDeletePromoteProduct(id);

  return (
    <button
      className="btn with-tooltip btn-action-icon-delete"
      data-tooltip-content="Supprimer"
      onClick={() => onDelete()}
      style={{ color: "#E53E3E" }}
    >
      <BsTrashFill />
    </button>
  );
}
export const ProductSoldeAdminItem = ({ item }: { item: Product }) => {
  return (
    <div className="product-item-container">
      <NavLink
        to={`/admin/produit/${item?._id}`}
        state={item}
        className="no-link w-100"
      >
        <div className="content-img-product-item text-center pb-3">
          <img
            src={getImage(item?.pictures[0]?.image)}
            alt={item?.name}
            className="img-product-item"
            loading="lazy"
          />
        </div>
        <div className="content-price-product-solde mb-3">
          <p className="price-product-item mb-0">
            {item?.promote?.price} <span style={{ fontWeight: 300 }}>FCFA</span>{" "}
          </p>
          <p className="price-solde-product-item mb-0">
            {formatCurrency(item?.price)}
          </p>
        </div>
        <p className="name-prduct fs-15">{item?.name} </p>
      </NavLink>
      <div className="content-btn-action-product-solde">
        <DeletePromotionButton id={item?._id} />
      </div>
    </div>
  );
};

 const ProductItemSkeleton = () => {
   return (
     <>
       {Array(12)
         .fill(12)
         .map((item, i) => (
           <div
             className="col-md-3 col-lg-4 col-xl-2 mb-4 col-product-item d-flex"
             key={i}
           >
             <div
               className="product-item-container"
              //  style={{ border: "1px solid #ebebeb", textAlign: "center" }}
             >
               <div className="content-img-product-item mb-2">
                 <Skeleton className="img-product-item" />
               </div>
               
               <div className="text-center">
                 <Skeleton
                   className="price-product-item"
                   width={`80%`}
                   height={16}
                 />
                 <Skeleton
                   className="name-prduct fs-15"
                   width={`60%`}
                   height={15}
                 />
                 <div className="content-btn-action-product-solde">
                   {/* <DeletePromotionButton id={item?._id} /> */}
                   <Skeleton
                     className="btn with-tooltip btn-action-icon-delete"
                    //  width={`70%`}
                    //  height={15}
                   />
                 </div>
               </div>
             </div>
           </div>
         ))}
     </>
   );
 };